import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { AddBox, Close, Edit } from "@material-ui/icons";
import Loading from "components/Loading";
import queryClient from "config/query";
import { RootState } from "config/store";
import { format, getDaysInMonth, setDate } from "date-fns";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  HaccpItemDateComment,
  createHaccpItemDateComment,
  modifyHaccpItemDateComment,
} from "shared/network/haccp-item-date-comment.api";

type Props = {
  date: Date;
  haccpItemId: number;
  width: number;
  haccpItemDateComments: HaccpItemDateComment[];
  isDaily?: boolean;
  noHeader?: boolean;
};

type FormValues = {
  comment: string;
};

const HaccpItemCheckComment = ({
  date,
  haccpItemId,
  width,
  haccpItemDateComments,
  isDaily,
  noHeader,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);
  const form = useForm<FormValues>();
  const [selectedComment, setSelectectedComment] = useState<HaccpItemDateComment | null>(null);
  const [createOpen, setCreateOpen] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  async function onSubmit(values: FormValues) {
    setLoading(true);
    try {
      if (!selectedComment && createOpen) {
        await createHaccpItemDateComment(
          {
            ...values,
            haccpItemId,
            date: format(
              setDate(date, isDaily ? Number(format(date, "d")) : createOpen),
              "yyyy-MM-dd",
            ),
          },
          tenant?.id,
        );
      } else {
        await modifyHaccpItemDateComment({ ...selectedComment, ...values }, tenant?.id);
      }
      queryClient.refetchQueries("haccpItemDateCommentQuery");
      setCreateOpen(null);
      setSelectectedComment(null);
      enqueueSnackbar(t("common:notification.save.success"), { variant: "success" });
    } catch {
      enqueueSnackbar(t("common:notification.save.failure"), { variant: "error" });
    }

    setCreateOpen(null);
    setSelectectedComment(null);
    form.setValue("comment", "");
    setLoading(false);
  }

  return (
    <>
      <Loading open={loading} />
      {!noHeader && (
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          p={1}
          width="100%"
          style={{
            borderBottom: "1px solid lightgrey",
          }}
        >
          <Typography noWrap style={{ fontWeight: "bold", fontSize: 18 }}>
            {t("comment.title")}
          </Typography>
        </Box>
      )}
      {Array.from(Array(isDaily ? format(date, "d") : getDaysInMonth(date))).map((_, index) => {
        const current = isDaily
          ? haccpItemDateComments
              ?.filter(value => value.haccpItemId === haccpItemId)
              ?.find(
                comment =>
                  Number(format(new Date(comment.date), "d")) === Number(format(date, "d")),
              )
          : haccpItemDateComments?.find(
              comment => Number(format(new Date(comment.date), "d")) === index + 1,
            );
        return (
          <Box
            key={index}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            padding={1}
            height={57}
            style={{
              borderBottom: "1px solid lightgrey",
            }}
          >
            <ButtonBase
              style={{ textAlign: "unset", flexWrap: "nowrap" }}
              onClick={() => {
                if (!current) {
                  setCreateOpen(index + 1);
                } else {
                  setSelectectedComment(current);
                  form.setValue("comment", current.comment);
                }
              }}
            >
              {current ? (
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                  <Typography
                    noWrap
                    style={{
                      inlineSize: width - 36,
                      overflow: "hidden",
                    }}
                  >
                    {current?.comment}
                  </Typography>
                  <Edit fontSize="small" color="primary" />
                </Box>
              ) : (
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                  <Typography
                    style={{ color: "lightgray", inlineSize: width - 36, overflow: "hidden" }}
                  >
                    {t("comment.create")}...
                  </Typography>
                  <AddBox fontSize="small" color="primary" />
                </Box>
              )}
            </ButtonBase>
          </Box>
        );
      })}
      <Dialog
        fullWidth
        maxWidth="md"
        open={!!createOpen || !!selectedComment}
        onClose={() => {
          setCreateOpen(null);
          setSelectectedComment(null);
          form.setValue("comment", "");
        }}
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <Box> {t("comment.title")}</Box>
            <IconButton
              size="small"
              onClick={() => {
                setCreateOpen(null);
                setSelectectedComment(null);
                form.setValue("comment", "");
              }}
            >
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <DialogContent>
              <Controller
                control={form.control}
                name="comment"
                defaultValue={""}
                rules={{
                  required: t("validation.required").toString(),
                  maxLength: {
                    value: 500,
                    message: "A megjegyzés maximum 500 karakter hosszú lehet.",
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    autoFocus
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    InputLabelProps={{ shrink: true, required: true }}
                    multiline
                    minRows={4}
                  />
                )}
              />
            </DialogContent>
            <DialogActions>
              <Button type="submit">{t("common:button.save")}</Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};
export default HaccpItemCheckComment;
