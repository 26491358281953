import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { AttachFile, Check } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import Loading from "components/Loading";
import queryClient from "config/query";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { addCashRegisterAmount } from "shared/network/cash-register-amount.api";
import { CashRegister } from "shared/network/cash-register.api";
import { createComment } from "shared/network/comment.api";
import { listCurrencyByTenant } from "shared/network/currency.api";
import { BaseCurrency } from "shared/types";

type Props = {
  cashRegister: CashRegister;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export type CashRegisterAmountFormValues = {
  quantity: string;
  currency: BaseCurrency | null;
  type: "ADD" | "REMOVE";
  document: any;
  note: string;
};

const AddCashRegisterAmountDialog = ({ cashRegister, open, setOpen }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const tenantId = useSelector(
    (state: RootState) => state.authentication?.selectedRelTenant?.tenant?.id,
  );
  const form = useForm<CashRegisterAmountFormValues>();
  const { control, handleSubmit } = form;

  const [loading, setLoading] = useState(false);

  async function onSubmit(values: CashRegisterAmountFormValues) {
    setLoading(true);
    try {
      const { data } = await addCashRegisterAmount(
        {
          ...values,
          quantity:
            values?.type === "REMOVE" ? Number(values?.quantity) * -1 : Number(values?.quantity),
          cashRegister,
        },
        tenantId,
      );
      if (values.note || values.document) {
        await createComment(
          tenantId,
          values.document ? "DOCUMENT" : "TEXT",
          "CASH_REGISTER_AMOUNT",
          data?.item?.id?.toString(),
          values.note,
          values.document?.[0],
          false,
          true,
        );
      }
      setOpen(false);
      queryClient.refetchQueries("cashRegisterAmountList");
      enqueueSnackbar(t("common:notification.create.success"), { variant: "success" });
    } catch {
      enqueueSnackbar(t("common:notification.create.failure"), { variant: "error" });
    }
    setLoading(false);
  }

  const currencyListQuery = useQuery(["currencyListForTransaction", tenantId], async () => {
    const { data } = await listCurrencyByTenant(0, 2000, tenantId, "");
    return data.page;
  });

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={() => setOpen(false)}>
      <Loading open={loading} />
      <DialogTitle style={{ paddingBottom: 0 }}>{t("cashRegister.addAmount")}</DialogTitle>
      <DialogContent>
        <FormProvider {...form}>
          <form id="cash-register-amount-create" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="type"
                  defaultValue="ADD"
                  render={({ field }) => (
                    <FormControl component="fieldset">
                      <RadioGroup {...field} style={{ flexDirection: "row" }}>
                        <FormControlLabel
                          value="ADD"
                          control={<Radio />}
                          label={t("cashRegister.addMoney")}
                        />
                        <FormControlLabel
                          value="REMOVE"
                          control={<Radio />}
                          label={t("cashRegister.removeMoney")}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  control={control}
                  name="quantity"
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="number"
                      label={t("cashRegister.quantity")}
                      InputLabelProps={{ shrink: true, required: true }}
                      inputProps={{ min: 0 }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                  rules={{
                    required: t("validation.required").toString(),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Controller
                  control={control}
                  name="currency"
                  defaultValue={null}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      onChange={(_, value) => field.onChange(value)}
                      options={currencyListQuery.data?.content || []}
                      getOptionLabel={(option: BaseCurrency) => option.name}
                      getOptionSelected={option => option.id === field.value?.id}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={t("warehouse.transaction.formValues.currency")}
                          InputLabelProps={{ shrink: true, required: true }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                  rules={{
                    required: t("validation.required").toString(),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="note"
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      multiline
                      minRows={5}
                      label={t("comment.note")}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                container
                xs={12}
                justifyContent="center"
                alignItems="center"
                direction="column"
                style={{ overflow: "auto" }}
              >
                {/* @ts-ignore */}
                <Typography style={{ wordWrap: "anywhere" }}>
                  {form.watch("document")?.[0]?.name}
                </Typography>
                <Button
                  variant="text"
                  component="label"
                  htmlFor="noteFile"
                  startIcon={
                    !form.watch("document")?.length ? (
                      <AttachFile />
                    ) : (
                      <Check style={{ color: COLORS.green }} />
                    )
                  }
                >
                  {form.watch("document")?.length
                    ? t("personalData.formValues.chooseAnotherFile")
                    : t("personalData.formValues.chooseFile")}
                  <input
                    id="noteFile"
                    style={{ display: "none" }}
                    type="file"
                    {...form.register("document", {
                      required: {
                        value: false,
                        message: t("personalData.formValues.fileRequired"),
                      },
                      validate: value => {
                        if (value?.[0]?.size >= 5242880) {
                          return t("validation.maxSize", {
                            size: 5,
                            unit: "MB",
                          }).toString();
                        }
                      },
                    })}
                  />
                </Button>
                {"Maximum 5 MB"}
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => setOpen(false)}>
          {t("common:button.cancel")}
        </Button>
        <Button form="cash-register-amount-create" type="submit">
          {t("common:button.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCashRegisterAmountDialog;
