import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { RootState } from "config/store";

import { AttachFile, CloudDownload, Delete } from "@material-ui/icons";
import ConfirmationButton from "components/ConfirmationButton";
import FormCard from "components/FormCard";
import Loading from "components/Loading";
import supportedLocales from "config/supportedLocales";
import { format } from "date-fns";
import i18n from "i18n";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { createComment, deleteComment, listComment } from "shared/network/comment.api";
import { getFileByPath } from "shared/network/file.api";
import { getPersonalDataById } from "shared/network/personaldata.api";
import { fileDownload } from "shared/util/fileDownload";
import { PersonalDataFormValues } from "./PersonalDataForm";

type Params = {};
type Props = RouteComponentProps<Params>;

const PersonalDataDetails = ({ history }: Props) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const personalDataId = query.get("personalDataId") || "";
  const [loading, setLoading] = useState(false);

  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<PersonalDataFormValues>();
  const { formState, register, watch, handleSubmit } = form;

  const personalDataQuery = useQuery(["personalDataQuery", personalDataId], async () => {
    const { data } = await getPersonalDataById(personalDataId, tenant.id);
    return data.item;
  });
  const personalData = personalDataQuery.data;

  const commentListQuery = useQuery(
    ["personalDataFileList", tenant.id, personalData?.id],
    async () => {
      if (personalData?.id) {
        const { data } = await listComment(tenant.id, "PERSONAL_DATA", personalData.id, undefined);
        return data.items;
      }
      return Promise.reject();
    },
  );
  const files = commentListQuery.data;

  async function onDelete(id: number) {
    try {
      await deleteComment(id, tenant.id);
      commentListQuery.refetch();
      enqueueSnackbar(t("common:notification.delete.success"), {
        variant: "success",
      });
    } catch {
      enqueueSnackbar(t("common:notification.delete.success"), {
        variant: "success",
      });
    }
  }

  const onSubmit = async (values: PersonalDataFormValues) => {
    try {
      setLoading(true);

      if (values?.files?.length && personalData?.id) {
        await Promise.all(
          Array.from(values.files).map(async (file: any, index: number, array: any[]) => {
            return await createComment(
              tenant.id,
              "DOCUMENT",
              "PERSONAL_DATA",
              personalData.id + "",
              "",
              file,
            );
          }),
        );

        commentListQuery.refetch();
      }
      setLoading(false);
      form.reset();
      enqueueSnackbar(t("common:notification.create.success"), { variant: "success" });
    } catch (e: any) {
      setLoading(false);
      if (e.data?.status === "FILE_NOT_FOUND") {
        enqueueSnackbar(t("common:notification.create.failure", {}), {
          variant: "error",
        });
      }
    }
  };

  return (
    <Box>
      <Loading open={loading} />
      {personalDataQuery.isFetching ? (
        <Box display="flex" alignItems="center" justifyContent="center" height="300px">
          <CircularProgress />
        </Box>
      ) : (
        <Container maxWidth="sm">
          <FormCard title={""}>
            <Grid container spacing={2}>
              {!!personalData?.type && (
                <Grid item xs={12} sm={6}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {t("personalData.formValues.type")}
                  </Typography>
                  <Typography>{t(`common:documentTypes.${personalData?.type}`)}</Typography>
                </Grid>
              )}
              {!!personalData?.status && (
                <Grid item xs={12} sm={6}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {t("personalData.formValues.status")}
                  </Typography>
                  <Typography>{t(`personalData.statuses.${personalData?.status}`)}</Typography>
                </Grid>
              )}
              {!!personalData?.validTo && (
                <Grid item xs={12} sm={6}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {t("personalData.formValues.validTo")}
                  </Typography>
                  <Typography>
                    {format(new Date(personalData.validTo), "P", {
                      locale: supportedLocales[i18n.language],
                    })}
                  </Typography>
                </Grid>
              )}
              {!!personalData?.comment && (
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {t("personalData.formValues.comment")}{" "}
                  </Typography>
                  <Typography>{personalData?.comment}</Typography>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={2} style={{ paddingTop: 16 }}>
              {!!personalData?.name && (
                <Grid item xs={12} sm={6}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {t("qualification.formValues.name")}
                  </Typography>
                  <Typography>{personalData?.name}</Typography>
                </Grid>
              )}
              {!!personalData?.timeOfObtaining && (
                <Grid item xs={12} sm={6}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {t("qualification.formValues.timeOfObtaining")}
                  </Typography>
                  <Typography>
                    {format(new Date(personalData.timeOfObtaining), "P", {
                      locale: supportedLocales[i18n.language],
                    })}
                  </Typography>
                </Grid>
              )}
              {!!personalData?.placeOfObtaining && (
                <Grid item xs={12} sm={6}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {t("qualification.formValues.placeOfObtaining")}
                  </Typography>
                  <Typography>{personalData.placeOfObtaining}</Typography>
                </Grid>
              )}
              <Grid item xs={12} sm={12}>
                <Typography style={{ fontWeight: "bold", paddingBottom: 8 }}>
                  Csatolt fájlok:
                </Typography>
                {files?.length ? (
                  files?.map(file => (
                    <>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                      >
                        <Typography>{file.originalFileName}</Typography>
                        <Box display="flex" gridGap={4}>
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={async event => {
                              event.preventDefault();
                              setLoading(true);
                              try {
                                const { data } = await getFileByPath(file.filePath, tenant.id);
                                fileDownload(data, file.originalFileName);
                              } catch (e) {}
                              setLoading(false);
                            }}
                          >
                            <CloudDownload />
                          </IconButton>
                          <ConfirmationButton
                            color="primary"
                            size="small"
                            onSubmit={() => onDelete(file.id)}
                            buttonType="ICON"
                            title="Törlés"
                            body="Biztosan törli a fájlt?"
                          >
                            <Delete />
                          </ConfirmationButton>
                        </Box>
                      </Box>
                      <Divider />
                    </>
                  ))
                ) : (
                  <Typography>Nincsen csatolt fájl.</Typography>
                )}
              </Grid>
              <Grid
                item
                container
                xs={12}
                justifyContent="center"
                alignItems="center"
                direction="column"
                style={{ paddingTop: 8 }}
              >
                <Typography style={{ fontWeight: "bold" }}>Fájlok feltöltése:</Typography>
                <FormProvider {...form}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Box display="flex" alignItems="center" flexDirection="column">
                      <Button
                        variant="text"
                        component="label"
                        htmlFor="personalDataFiles"
                        startIcon={<AttachFile />}
                      >
                        {t("personalData.formValues.chooseFiles")}
                        <input
                          id="personalDataFiles"
                          style={{ display: "none" }}
                          type="file"
                          multiple
                          {...register("files", {
                            required: {
                              value: false,
                              message: t("personalData.formValues.fileRequired"),
                            },
                            validate: value => {
                              if (
                                Array.from(watch("files"))?.find(
                                  (file: any) => file.size >= 5242880,
                                )
                              ) {
                                return t("validation.maxSize", {
                                  size: 5,
                                  unit: "MB",
                                }).toString();
                              }
                            },
                          })}
                        />
                      </Button>
                      {formState?.errors?.files?.message ? (
                        <FormHelperText error>{formState.errors.files?.message}</FormHelperText>
                      ) : (
                        "Fájlonként maximum 5 MB"
                      )}
                      {!!watch("files")?.length && (
                        <Box mt={1}>
                          <Typography style={{ fontWeight: "bold" }}>
                            Kiválasztott fájlok:
                          </Typography>
                          {Array.from(watch("files"))?.map((file: any, index) => (
                            <Typography key={index}>{file.name}</Typography>
                          ))}
                        </Box>
                      )}
                      {!!watch("files")?.length && (
                        <Button type="submit" color="primary" style={{ marginTop: 8 }}>
                          {t("Feltöltés")}
                        </Button>
                      )}
                    </Box>
                  </form>
                </FormProvider>
              </Grid>
            </Grid>
          </FormCard>
        </Container>
      )}
    </Box>
  );
};

export default PersonalDataDetails;
