import { Box, Button, Card, Divider, Typography } from "@material-ui/core";
import { AddBox } from "@material-ui/icons";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getPageCashRegister } from "shared/network/cash-register.api";
import CashRegisterCreateDialog from "./CashRegisterCreateDialog";
import CashRegisterRow from "./CashRegisterRow";
import { hasAnyAuthority } from "shared/authorization";

const CashRegisterList = () => {
  const { t } = useTranslation();
  const tenantId = useSelector(
    (state: RootState) => state.authentication?.selectedRelTenant?.tenant?.id,
  );
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const [open, setOpen] = useState(false);

  const cashRegisterList = useQuery(["cashRegisterList", tenantId], async () => {
    const { data } = await getPageCashRegister({ page: 0, size: 2000, tenantId });
    return data?.page?.content?.sort((a, b) => a?.name?.localeCompare(b?.name));
  });

  return (
    <Box>
      <Box maxWidth={600} margin="auto">
        <Loading open={cashRegisterList.isFetching} />
        <Box width="100%" textAlign="center" pb={2}>
          {selectedRelTenant.tenant.isUseDefault &&
            hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
              "CASH_REGISTER_ADMIN",
            ]) && (
              <Button variant="outlined" startIcon={<AddBox />} onClick={() => setOpen(true)}>
                {t("cashRegister.add")}
              </Button>
            )}
        </Box>
        {open && <CashRegisterCreateDialog open={open} setOpen={setOpen} />}
        <Card>
          {!cashRegisterList.data?.length && (
            <Box display="flex" justifyContent="center" p={2}>
              <Typography>{t("common:noData")} </Typography>
            </Box>
          )}
          {cashRegisterList.data?.map((cashRegister, index, array) => (
            <Box key={cashRegister?.id}>
              <CashRegisterRow cashRegister={cashRegister} />
              {array?.length !== index + 1 && <Divider />}
            </Box>
          ))}
        </Card>
      </Box>
    </Box>
  );
};

export default CashRegisterList;
