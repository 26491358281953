import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Typography,
} from "@material-ui/core";
import { orange } from "@material-ui/core/colors";
import { AttachFile, Check } from "@material-ui/icons";
import { urlCreator } from "components/Layout/SideBar/SideBar";
import Loading from "components/Loading";
import { COLORS } from "config/theme";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getTenantPicture } from "shared/network/files.api";
import { addInvoiceIcon } from "shared/network/tenant.api";

export type InvoiceIconFormValues = {
  invoiceIcon: FileList | null;
};

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  tenantId?: string | number;
};

const CompanyLogoDialog = ({ open, setOpen, tenantId }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { formState, register, watch, handleSubmit, setValue } = useForm<InvoiceIconFormValues>();
  const imageRef = useRef<HTMLImageElement | null>(null);
  const imageList = watch("invoiceIcon");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (imageList?.[0]) {
      const reader = new FileReader();
      reader.onload = e => {
        if (imageRef.current && e) {
          // @ts-ignore
          imageRef.current.src = e.target?.result;
        }
      };
      reader.readAsDataURL(imageList[0]);
    }
  }, [imageList]);

  const onSubmitCreateInviceIcon = async (value: InvoiceIconFormValues) => {
    setLoading(true);
    try {
      await addInvoiceIcon(tenantId, value?.invoiceIcon?.[0]);
      enqueueSnackbar(
        t("common:notification.upload.success", {
          subject: t("tenant.invoiceIconSubject"),
        }),
        {
          variant: "success",
        },
      );
      companyLogoQuery?.refetch();
      setValue("invoiceIcon", null);
    } catch {
      enqueueSnackbar(
        t("common:notification.upload.failure", {
          subject: t("tenant.invoiceIconSubject"),
        }),
        {
          variant: "error",
        },
      );
    }
    setLoading(false);
  };

  const companyLogoQuery = useQuery(["companyLogoQuery", tenantId], async () => {
    const response = getTenantPicture(Number(tenantId));
    return response;
  });

  return (
    <>
      <Loading open={loading} />
      <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)}>
        <DialogTitle style={{ paddingBottom: 0 }}>{t("tenant.addInvoiceIcon")}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmitCreateInviceIcon)}>
          <DialogContent>
            <Typography style={{ fontWeight: "bold", color: COLORS.main }}>
              {t("tenant.logoHelp")}
            </Typography>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              p={1}
              mb={1}
              style={{ border: "1px solid rgba(0,0,0,0.15)", borderRadius: 10 }}
              overflow="hidden"
            >
              <Typography style={{ fontWeight: "bold", fontSize: 16, paddingBottom: 8 }}>
                {t("tenant.currentLogo")}
              </Typography>
              {companyLogoQuery.data?.data && companyLogoQuery.data.data.size > 0 && (
                <img
                  src={urlCreator.createObjectURL(companyLogoQuery.data.data)}
                  alt=""
                  style={{ maxWidth: 200, maxHeight: 200 }}
                />
              )}
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              gridGap={8}
              p={1}
              style={{ border: "1px solid rgba(0,0,0,0.15)", borderRadius: 10 }}
              overflow="hidden"
            >
              <Typography style={{ fontWeight: "bold", fontSize: 16 }}>
                {t("tenant.newLogo")}
              </Typography>
              {imageList?.[0] && (
                <Typography style={{ textOverflow: "ellipsis", wordBreak: "break-all" }}>
                  <span style={{ fontWeight: "bold" }}>{t("tenant.fileName")}</span>
                  {imageList[0].name.toString()}
                </Typography>
              )}
              {imageList?.[0] && (
                <img alt="preview" ref={imageRef} style={{ maxWidth: 200, maxHeight: 200 }} />
              )}
              <Button
                size="small"
                variant="outlined"
                component="label"
                htmlFor="noteFile"
                startIcon={!watch("invoiceIcon")?.length ? <AttachFile /> : <Check />}
              >
                {imageList?.[0]
                  ? t("personalData.formValues.fileChosen")
                  : t("personalData.formValues.chooseFile")}
                <input
                  id="noteFile"
                  style={{ display: "none" }}
                  type="file"
                  {...register("invoiceIcon", {
                    required: {
                      value: true,
                      message: t("personalData.formValues.fileRequired"),
                    },
                    validate: value => {
                      if ((value?.[0]?.size || 0) >= 5242880) {
                        return t("validation.maxSize", {
                          size: 5,
                          unit: "MB",
                        }).toString();
                      }
                    },
                  })}
                />
              </Button>
              {formState.errors.invoiceIcon?.message && (
                <FormHelperText error>{formState.errors.invoiceIcon?.message}</FormHelperText>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Box display="flex" gridGap={8}>
              <Button color="primary" variant="text" onClick={() => setOpen(false)}>
                {t("common:button.close")}
              </Button>
              <Button type="submit" color="primary">
                {t("common:button.save")}
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default CompanyLogoDialog;
