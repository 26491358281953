import axios from "config/axios";
import { GenericPageResponse, GenericResponse } from "shared/common";
import {
  Company,
  PaymentPeriod,
  Project,
  ProjectType,
  RelOfferItemEntry,
  RelProjectItemEntry,
  TaxEntry,
} from "shared/types";
import { UsedProjectItem } from "./used-project-item.api";

export type ProjectRequest = {
  company: Company | null;
  deliveryPeriod?: string | PaymentPeriod;
  deliveryPeriodOffsetDays?: string;
  description: string;
  end?: string;
  id?: number | string;
  name: string;
  notificationEmail: string;
  paymentDueDays: string;
  paymentPeriod: string | PaymentPeriod;
  paymentPeriodOffsetDays: string;
  start: string;
  type: string | ProjectType;
  projectItems: RelProjectItemEntry[];
};

export type ProjectStatistic = {
  internalPlannedProjectItems: RelProjectItemEntry[];
  internalPlannedWorkTime: number;
  internalTimeEntriesSummary: number;
  partnerPlannedProjectItems: RelOfferItemEntry[];
  partnerPlannedWorkTime: number;
  project: Project;
  summarizedUsedProjectItems: UsedProjectItem[];
  timeEntriesByCompany: {
    company: Company;
    time: number;
  }[];
  usedInternalProjectItems: UsedProjectItem[];
  usedPartnerProjectItems: UsedProjectItem[];
};

const ENDPOINT = "/project";

export const createProject = (
  project: ProjectRequest,
  useOfferItems: boolean,
  useContractItems: boolean,
  tenantId: number,
) =>
  axios.post(`${ENDPOINT}`, {
    project,
    useOfferItems,
    useContractItems,
    tenantId,
  }); //PROJECT_ADMIN

export const modifyProject = (project: ProjectRequest, tenantId: number) =>
  axios.put(`${ENDPOINT}`, {
    project,
    relProjectItems: project.projectItems,
    tenantId,
  }); //PROJECT_ADMIN

export const listProject = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<Project>>(
    `${ENDPOINT}/all?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //LOGIN_REQUIRED

export const getProjectById = (tenantId: number, id?: number | string) =>
  axios.post<GenericResponse<Project>>(`${ENDPOINT}/get-by-id`, {
    param: id,
    tenantId: tenantId,
  }); //LOGIN_REQUIRED

export const getProjectGanttDataById = (tenantId: number, id?: number | string) =>
  axios.post(`${ENDPOINT}/get-extra-by-id`, {
    param: id,
    tenantId: tenantId,
  }); //LOGIN_REQUIRED

export const getProjectStatistics = ({
  projectId,
  summarizedUsedProjectItems,
  tenantId,
}: {
  projectId: number;
  summarizedUsedProjectItems: boolean;
  tenantId: number;
}) =>
  axios.post<GenericResponse<ProjectStatistic>>(`${ENDPOINT}/project-statistic`, {
    projectId,
    summarizedUsedProjectItems,
    tenantId,
  });

export const generateFromOrder = (param: number, tenantId: number) =>
  axios.post<GenericResponse<Project>>(`${ENDPOINT}/generate-from-order`, {
    param,
    tenantId,
  });

export const updateStatus = (params: {
  status: string;
  projectId: number;
  tenantId: number;
  isGenerateInvoice: boolean;
  invoiceType: string;
  percent: number;
  isMerged: boolean;
  mergeName: string;
  taxPercent: TaxEntry;
}) => axios.post<GenericResponse<Project>>(`${ENDPOINT}/update-status`, params); //LOGIN_REQUIRED

export const generateProjectFromProjectTemplate = (param: number, tenantId: number) =>
  axios.post<GenericResponse<Project>>(`${ENDPOINT}/generate-from-project-template`, {
    param,
    tenantId,
  }); //LOGIN_REQUIRED
