import axios from "config/axios";
import { GenericListResponse, GenericPageResponse, GenericResponse } from "shared/common";
import { IncomingInvoice, InvoiceItem, Warehouse } from "shared/types";

export type IncomingInvoiceRequest = {
  id?: string;
  tenantId: number;
  paymentType: string;
  isPaid: boolean; //+
  completionDate: string; //+
  paymentDeadline: string;
  serialNumber: string; //+
  supplierCompanyName: string;
  supplierCompanyZip: string;
  supplierCompanyCity: string;
  supplierCompanyAddress: string;
  supplierTaxNumber: string;
  customerId: number | string;
  customerName: string;
  customerZip: string;
  customerCity: string;
  customerAddress: string;
  customerTaxNumber: string;
  supplierAccountNumber: string;
  customerAccountNumber: string;
  creationDate: string; //+
  comment: string; //+
  customerEuTaxNumber: string;
  supplierEuTaxNumber: string;
  paidDate: string; //+
  paySource: string; //+
  netPrice: string | number;
  vatPrice: string | number;
  grossPrice: string | number;
  currencyId: string | number;
  invoiceItems?: any; //InvoiceItem
};

const ENDPOINT = "/incoming-invoice";

export const listIncomingInvoice = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
  searchItemName?: string,
) =>
  axios.get<GenericPageResponse<IncomingInvoice>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}${
      searchItemName ? `&searchItemName=${searchItemName}` : ""
    }`,
  ); //INCOMING_INVOICE_ADMIN

export const createIncomingInvoice = (tenantId: number, param: IncomingInvoiceRequest) =>
  axios.post(`${ENDPOINT}/create-new`, { tenantId, param }); //INCOMING_INVOICE_ADMIN

export const createImportedIncomingInvoice = ({
  tenantId,
  incomingInvoice,
  warehouse,
}: {
  tenantId: number;
  incomingInvoice: IncomingInvoiceRequest;
  warehouse?: Warehouse;
}) => axios.post(`${ENDPOINT}/imported`, { tenantId, incomingInvoice, warehouse }); //INCOMING_INVOICE_ADMIN

export const modifyIncomingInvoice = (tenantId: number, param: IncomingInvoiceRequest) =>
  axios.put(`${ENDPOINT}/modify`, { tenantId, param }); //INCOMING_INVOICE_ADMIN

export const expenseHandling = (tenantId: number, param: { id: number; expenseList: any[] }) =>
  axios.post(`${ENDPOINT}/expense-handling`, { tenantId, param }); //INCOMING_INVOICE_ADMIN

export const getIncomingInvoiceById = (param: number, tenantId: number) =>
  axios.post<GenericResponse<IncomingInvoice>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //INCOMING_INVOICE_ADMIN

export const calculatePrice = (
  incomingInvoiceEntry: IncomingInvoiceRequest,
  isSave: boolean,
  tenantId: number,
) =>
  axios.post<GenericListResponse<InvoiceItem>>(`${ENDPOINT}/calculate-price`, {
    incomingInvoiceEntry,
    isSave,
    tenantId,
  }); //INCOMING_INVOICE_ADMIN

export const addItemToInvoiceItem = (param: {
  incomingInvoice: IncomingInvoice;
  existingItemsToInvoiceItems: InvoiceItem[];
  generatingItemsToInvoiceItems: InvoiceItem[];
  generateSupplierPrice: boolean;
  tenantId: number;
}) =>
  axios.post<GenericResponse<any>>(`${ENDPOINT}/add-items-to-incoming-invoice-items`, {
    ...param,
  }); //INCOMING_INVOICE_ADMIN
