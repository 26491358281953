import { Box, Checkbox, Container, FormControlLabel, Typography } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { endOfMonth, format, startOfMonth } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getInvoiceTransactionSummary } from "shared/network/invoice-transaction.api";
import { useGetSessionStorageKey } from "views/Comment/function";

export type TableState = {
  page: number;
  pageSize: number;
  filterOpen: boolean;
  filterValue: string;
};

const InvoiceTransactionSummary = () => {
  const { t } = useTranslation();
  const tenant = useSelector(
    (state: RootState) => state?.authentication?.selectedRelTenant?.tenant,
  );

  const [from, setFrom] = useState<Date | null>(startOfMonth(new Date()));
  const [to, setTo] = useState<Date | null>(endOfMonth(new Date()));
  const [isDonation, setIsDonation] = useState(false);
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey("rap-invoice-transaction-summary-page-number"),
      ) || JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey("rap-invoice-transaction-summary-page-size"),
      ) || JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize } = tableState;

  const summaryQuery = useQuery(
    ["getInvoiceTransactionSummaryQuery", tenant?.id, isDonation, from, to, page, pageSize],
    async () => {
      if (tenant?.id && from && to) {
        const { data } = await getInvoiceTransactionSummary({
          page: page,
          size: pageSize,
          tenantId: tenant.id,
          isDonation,
          from: format(from, "yyyy-MM-dd"),
          to: format(to, "yyyy-MM-dd"),
        });

        return {
          ...data,
          page: {
            ...data.page,
            content: data.page.content.map((value, index) => {
              return { ...value, id: index };
            }),
          },
        };
      }
      return Promise.reject();
    },
  );

  const columns: GridColDef[] = [
    {
      field: "company.name",
      headerName: t("invoiceTransaction.summary.companyName"),
      sortable: true,
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => {
        return row?.company?.name || "-";
      },
    },
    {
      field: "transactionPrice",
      headerName: t("invoiceTransaction.summary.transactionPrice"),
      sortable: true,
      type: "number",
      flex: 0.75,
      renderCell: ({ row }: GridRenderCellParams) => {
        return row?.transactionPrice ? t("common:number", { num: row?.transactionPrice }) : "-";
      },
    },
    {
      field: "currency",
      headerName: t("invoiceTransaction.summary.currency"),
      sortable: true,
      flex: 0.75,
    },
  ];

  return (
    <Container maxWidth="xl">
      <Box p={1.5} mb={1.5} style={{ border: "1px solid rgba(0,0,0,0.15", borderRadius: 10 }}>
        <Typography style={{ fontWeight: "bold", fontSize: 14, paddingBottom: 8, paddingLeft: 8 }}>
          Befizetési időre szűrés:
        </Typography>
        <Box display="flex" justifyContent="space-between" gridGap={8} flexWrap="wrap">
          <Box display="flex" justifyContent="space-between" gridGap={8} flexWrap="wrap">
            <KeyboardDatePicker
              size="small"
              value={from}
              onChange={date => {
                if (date) {
                  setFrom(date);
                }
              }}
              label={t("invoiceTransaction.summary.from")}
              format="yyyy.MM.dd."
              InputLabelProps={{ shrink: true, required: true }}
              variant="inline"
              autoOk
              style={{ maxWidth: 200 }}
              invalidDateMessage={t("invalidDate")}
            />
            <KeyboardDatePicker
              size="small"
              value={to}
              onChange={date => {
                if (date) {
                  setTo(date);
                }
              }}
              label={t("invoiceTransaction.summary.to")}
              format="yyyy.MM.dd."
              InputLabelProps={{ shrink: true, required: true }}
              variant="inline"
              autoOk
              style={{ maxWidth: 200 }}
              invalidDateMessage={t("invalidDate")}
              minDate={from}
              minDateMessage={t("invalidMinDate")}
            />
          </Box>
          <Box>
            <FormControlLabel
              style={{ margin: "auto", marginRight: 8 }}
              control={
                <Checkbox
                  size="small"
                  color="primary"
                  checked={isDonation}
                  onChange={(_, checked) => {
                    setIsDonation(checked);
                  }}
                />
              }
              label={t("invoiceTransaction.summary.isDonation")}
            />
          </Box>
        </Box>
      </Box>
      <PageableTable
        noSearch
        query={summaryQuery}
        columns={columns}
        setTableState={setTableState}
        tableState={tableState}
        sessionStorageKey={`invoice-transaction-summary-page-number`}
        minWidth={{ value: "sm" }}
      />
    </Container>
  );
};

export default InvoiceTransactionSummary;
