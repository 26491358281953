import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { addItemsToTimebeez, listItems } from "shared/network/item.api";
import { getTimebeezServices } from "shared/network/tenant.api";
import { useDebouncedCallback } from "use-debounce";

type Props = {
  timebeezOpen: boolean;
  setTimebeezOpen: Dispatch<SetStateAction<boolean>>;
};

const TimebeezDialog = ({ timebeezOpen, setTimebeezOpen }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant.tenant;

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [selectedItemIds, setSelectedItemIds] = useState<number[]>([]);
  const [itemSearch, setItemSearch] = useState("");
  const [allSelect, setAllSelect] = useState(false);
  const [selectedService, setSelectedService] = useState("");

  const getTimebeezServicesQuery = useQuery(["getTimebeezServicesQuery", tenant.id], async () => {
    const { data } = await getTimebeezServices(tenant.id);
    return JSON.parse(data?.item)?.services;
  });

  useEffect(() => {
    if (getTimebeezServicesQuery.data?.[0]?.id) {
      setSelectedService(getTimebeezServicesQuery.data?.[0]?.id);
    }
  }, [getTimebeezServicesQuery.data, timebeezOpen]);

  const listItemsQuery = useQuery(
    ["itemSearchTimebeez", tenant.id, itemSearch, page, pageSize],
    async () => {
      const { data } = await listItems(
        page,
        pageSize,
        tenant.id,
        itemSearch &&
          `(name:$${itemSearch};(OR)productCode:$${itemSearch};)
    `,
      );
      return data.page;
    },
  );

  const handleItemSearchChange = useDebouncedCallback((value: string) => {
    setItemSearch(value);
    setPage(0);
    setAllSelect(false);
  }, 500);

  function selectAll() {
    setSelectedItemIds(state => {
      let tempState = [...state];
      if (!allSelect) {
        listItemsQuery.data?.content.forEach(value => {
          if (!tempState.find(id => id === value?.id)) {
            tempState.push(Number(value.id));
          }
        });
      } else {
        listItemsQuery.data?.content.forEach(value => {
          let index = tempState.findIndex(id => id === value?.id);
          if (index >= 0) {
            tempState.splice(index, 1);
          }
        });
      }
      return tempState;
    });
    if (allSelect) {
      setAllSelect(false);
    } else {
      setAllSelect(true);
    }
  }
  const [loading, setLoading] = useState(false);
  async function addToTimebeez() {
    setLoading(true);
    try {
      await addItemsToTimebeez(selectedService, selectedItemIds, tenant?.id);
      enqueueSnackbar(t("common:notification.save.success"), {
        variant: "success",
      });
      onClose();
    } catch {
      enqueueSnackbar(t("common:notification.save.failure"), {
        variant: "error",
      });
    }
    setLoading(false);
  }

  function onClose() {
    setTimebeezOpen(false);
    setPage(0);
    setPageSize(50);
    setSelectedItemIds([]);
    setSelectedService("");
    setItemSearch("");
    setAllSelect(false);
  }

  return (
    <Dialog maxWidth="sm" fullWidth open={timebeezOpen} onClose={() => onClose()}>
      <Loading open={getTimebeezServicesQuery.isFetching || loading} />
      <DialogTitle style={{ paddingBottom: 0 }}>
        Termékek exportálása TimeBeeZ webshopba
      </DialogTitle>
      <Box m={3} mb={0} mt={1}>
        <Typography style={{ paddingBottom: 8 }}>
          A termékek {tenant?.baseCurrency?.name} ({tenant?.baseCurrency?.code}) pénznemben megadott
          árral lesznek exportálva. Ha nincs ilyen pénznemben megadva ár, akkor 0{" "}
          {tenant?.baseCurrency?.code} árral kerül exportálásra.
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t("serialItems.item")}
              onChange={event => {
                handleItemSearchChange(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="TimeBeeZ webshop"
              required
              value={selectedService}
              onChange={event => {
                setSelectedService(event.target.value);
              }}
              select
            >
              {getTimebeezServicesQuery.data?.map((service: { id: number; title: string }) => (
                <MenuItem key={service.id} value={service.id}>
                  {service.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <ListItem
          button
          style={{ padding: 0, marginBottom: 8, marginTop: 8 }}
          onClick={() => selectAll()}
        >
          <ListItemIcon>
            <Checkbox size="small" disableRipple style={{ padding: 0 }} checked={allSelect} />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ style: { fontWeight: "bold" } }}
            primary="Összes kiválasztása ezen az oldalon"
          />
        </ListItem>
      </Box>
      <Divider style={{ marginBottom: 8 }} />
      <DialogContent style={{ height: "100vh" }}>
        {listItemsQuery.isFetching ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%" p={2}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {listItemsQuery.data?.content?.map(item => (
              <ListItem
                key={item.id}
                button
                style={{ padding: 0 }}
                onClick={event =>
                  setSelectedItemIds(state => {
                    let temp = [...state];
                    const currentItemId = temp.find(value => value === item.id);
                    if (currentItemId) {
                      return temp.filter(value => value !== item.id);
                    } else {
                      temp.push(Number(item.id));
                      return temp;
                    }
                  })
                }
              >
                <ListItemIcon>
                  <Checkbox
                    size="small"
                    disableRipple
                    style={{ padding: 0 }}
                    checked={!!selectedItemIds?.find(value => value === item.id)}
                  />
                </ListItemIcon>
                <ListItemText primary={item.name + " (" + item.productCode + ")"} />
              </ListItem>
            ))}
          </>
        )}
      </DialogContent>
      {listItemsQuery.data && listItemsQuery?.data?.totalPages > 1 && (
        <Box display="flex" justifyContent="center" alignItems="center" gridGap={8} p={2}>
          <Pagination
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            count={listItemsQuery?.data?.totalPages}
            color="primary"
            page={listItemsQuery.data?.number + 1}
            onChange={(e, page) => {
              sessionStorage.setItem(`IssueTypePageNumber-${tenant?.id}`, JSON.stringify(page - 1));
              setPage(page - 1);
              setAllSelect(false);
            }}
          />
          <TextField
            size="small"
            select
            style={{ width: 80 }}
            value={pageSize}
            onChange={event => {
              setPageSize(Number(event.target.value));
              setPage(0);
              setAllSelect(false);
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}> 50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={250}>250</MenuItem>
          </TextField>
        </Box>
      )}
      <DialogActions>
        <Button variant="text" onClick={() => onClose()}>
          {t("common:button.cancel")}
        </Button>
        <Button
          onClick={() => addToTimebeez()}
          disabled={!selectedService || !selectedItemIds?.length}
        >
          {t("common:button.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TimebeezDialog;
