import axios from "config/axios";
import { GenericPageResponse, GenericResponse } from "shared/common";
import { Contract, Invoice, Issue, Order, TaxEntry } from "shared/types";

const ENDPOINT = "/invoice";

export const createInvoice = (
  invoice: Invoice,
  issues: Issue[] | undefined,
  tenantId: number,
  from?: string,
  to?: string,
  contract?: Contract,
  order?: Order,
) =>
  axios.post(`${ENDPOINT}/create`, {
    invoice,
    issues,
    from,
    to,
    contract,
    order,
    tenantId,
  }); //INVOICE_ADMIN

export const modifyInvoice = (param: Invoice, tenantId: number) =>
  axios.put(`${ENDPOINT}/modify`, { param, tenantId }); //INVOICE_ADMIN

export const deleteInvoice = (param: number, tenantId: number) =>
  axios.delete(`${ENDPOINT}`, { data: { param, tenantId } }); //INVOICE_ADMIN

export const approveInvoice = (invoiceIds: number[], sendToNav: boolean, tenantId: number) =>
  axios.post(`${ENDPOINT}/approve`, { invoiceIds, sendToNav, tenantId }); //INVOICE_GET

export const sendInvoiceToNAV2 = (params: number[], sendingToPartner: boolean, tenantId: number) =>
  axios.post(`${ENDPOINT}/nav-send`, { invoiceIds: params, sendingToPartner, tenantId });

export const sendInvoiceToNAV = (
  params: number[],
  sendingToPartner: boolean,
  tenantId: number,
  attachments: File[],
) => {
  const formData = new FormData();
  formData.append("tenantId", tenantId.toString());
  formData.append("sendingToPartner", sendingToPartner.toString());
  formData.append("invoiceIds", JSON.stringify(params)?.replace("[", "")?.replace("]", "") || "");
  Array.from(attachments).forEach(attachment => formData.append("attachments", attachment));

  return axios.post(`${ENDPOINT}/nav-send`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}; //COMMENT_ADMIN

export const getInvoiceById = (param: number, tenantId: number) =>
  axios.post<GenericResponse<Invoice>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //INVOICE_GET

export const listInvoicePageable = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
  searchItemName?: string,
) =>
  axios.get<GenericPageResponse<Invoice>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}${
      searchItemName ? `&searchItemName=${searchItemName}` : ""
    }`,
  ); //INVOICE_GET

export const updateInvoiceStatus = (tenantId: number, status: string, invoiceId?: number) =>
  axios.put(
    `${ENDPOINT}/update-status?tenantId=${tenantId}&status=${status}&invoiceId=${invoiceId}`,
  ); //INVOICE_GET

export const exportInvoiceCSV = (tenantId: number, startDate: string, endDate: string) =>
  axios.get(
    `${ENDPOINT}/csv-export?tenantId=${tenantId}&startDate=${startDate}&endDate=${endDate}`,
  ); //INVOICE_GET

export const invoiceGenerate = ({
  contract,
  year,
  month,
  quarter,
  summaryInvoiceItemName,
  tenantId,
}: {
  contract: Contract;
  year?: string;
  month?: string;
  quarter?: string;
  summaryInvoiceItemName?: string;
  tenantId: number;
}) =>
  axios.post<{ contract: Contract; invoice: Invoice; from: string; to: string }>(
    `${ENDPOINT}/from-contract`,
    {
      contract,
      year,
      month,
      quarter,
      summaryInvoiceItemName,
      tenantId,
    },
  ); //INVOICE_GET

export const invoiceGenerateFromOrder = ({
  orderId,
  tenantId,
}: {
  orderId: number;
  tenantId: number;
}) => axios.get(`${ENDPOINT}/from-order?orderId=${orderId}&tenantId=${tenantId}`); //INVOICE_GET

export const createStornoInvoice = (param: number, tenantId: number) =>
  axios.get(`${ENDPOINT}/storno?invoiceId=${param}&tenantId=${tenantId}`); //INVOICE_GET

export const getInvoicePreview = (param: Invoice, tenantId: number) =>
  axios.post<GenericResponse<{ content: string }>>(`${ENDPOINT}/invoice-preview`, {
    param,
    tenantId,
  });

export const generateInvoiceFromWorksheet = (issueIds: number[], tenantId: number) =>
  axios.post<{ contract: Contract; invoice: Invoice; from: string; to: string }>(
    `${ENDPOINT}/from-work-sheet`,
    {
      issueIds,
      tenantId,
    },
  );
export const getInvoiceForOrder = (param: number, tenantId: number) =>
  axios.post<{ requesterInvoice: Invoice; normalInvoice: Invoice }>(
    `${ENDPOINT}/get-invoice-for-order`,
    { param, tenantId },
  );

export const getVatSummary = (
  param: {
    dateFrom: string;
    dateTo: string;
    tax?: { id: string };
  },
  tenantId: number,
) =>
  axios.post<{
    invoices: Invoice[];
    taxSummaries: {
      tax: TaxEntry;
      taxPrice: number;
    }[];
    grossPrice: number;
    netPrice: number;
    taxPrice: number;
  }>(`${ENDPOINT}/get-summary`, {
    ...param,
    tenantId,
  });

export const addIntoTimebeez = (email: string, invoiceId: number, tenantId: number) =>
  axios.post<any>(`${ENDPOINT}/add-into-timebeez`, {
    email: email || null,
    invoiceId,
    tenantId,
  });
