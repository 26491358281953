import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { RootState } from "config/store";
import { Dispatch, SetStateAction, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  CashRegister,
  createCashRegister,
  getPageCashRegister,
  modifyCashRegister,
} from "shared/network/cash-register.api";
import CashRegisterForm from "./CashRegisterForm";
import queryClient from "config/query";
import { useSnackbar } from "notistack";
import Loading from "components/Loading";

type Props = {
  cashRegister: CashRegister;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export type CashRegisterFormValues = {
  name: string;
};

const CashRegisterModifyDialog = ({ cashRegister, open, setOpen }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const tenantId = useSelector(
    (state: RootState) => state.authentication?.selectedRelTenant?.tenant?.id,
  );
  const form = useForm<CashRegisterFormValues>();
  const { handleSubmit } = form;
  const [loading, setLoading] = useState(false);

  async function onSubmit(values: CashRegisterFormValues) {
    setLoading(true);
    try {
      await modifyCashRegister({ ...cashRegister, ...values }, tenantId);
      setOpen(false);
      queryClient.refetchQueries("cashRegisterList");
      enqueueSnackbar(t("common:notification.modify.success"), { variant: "success" });
    } catch {
      enqueueSnackbar(t("common:notification.modify.failure"), { variant: "error" });
    }
    setLoading(false);
  }

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={() => setOpen(false)}>
      <Loading open={loading} />
      <DialogTitle>{t("cashRegister.modify")}</DialogTitle>
      <DialogContent>
        <FormProvider {...form}>
          <form id="cash-register-modify" onSubmit={handleSubmit(onSubmit)}>
            <CashRegisterForm cashRegister={cashRegister} />
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => setOpen(false)}>
          {t("common:button.cancel")}
        </Button>
        <Button form="cash-register-modify" type="submit">
          {t("common:button.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CashRegisterModifyDialog;
