import { Box, Button, Container, IconButton, Tooltip } from "@material-ui/core";
import { AddBox, Edit } from "@material-ui/icons";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import SingleQueryTable from "components/PageableTable/SingleQueryTable";
import { RootState } from "config/store";
import supportedLocales from "config/supportedLocales";
import { format } from "date-fns";
import i18n from "i18n";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listEmployeePersonalData } from "shared/network/personaldata.api";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";

const EmployeePersonalData = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const { setHeaderButtons } = useHeader();
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey("rap-list-personal-data-page-number"),
      ) || JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-list-personal-data-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const listPersonalDataQuery = useQuery(["personalDataListQuery", id], async () => {
    if (id) {
      const { data } = await listEmployeePersonalData(id, selectedRelTenant.tenant.id);
      return data;
    }
    return Promise.reject();
  });

  const columns: GridColDef[] = [
    {
      field: "type",
      headerName: t("personalData.formValues.type"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => t(`common:documentTypes.${row.type}`),
    },
    {
      field: "validTo",
      headerName: t("personalData.formValues.validTo"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        format(new Date(row.validTo), "P", {
          locale: supportedLocales[i18n.language],
        }),
    },
    {
      field: "comment",
      headerName: t("personalData.formValues.comment"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.comment || "-",
    },
    {
      field: "actions",
      headerName: " ",
      width: 140,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "EMPLOYEE_ADMIN",
          ]) && (
            <Tooltip title={t("issues.modifyIssue").toString()}>
              <IconButton
                component={Link}
                to={`/my-company/employees/employee-details/documents/personal-data-modify?id=${id}&personalDataId=${row.id}`}
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
              >
                <Edit color="primary" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, ["EMPLOYEE_ADMIN"]) && (
          <Button
            component={Link}
            to={`/my-company/employees/employee-details/documents/personal-data-create?id=${id}`}
            startIcon={<AddBox />}
          >
            {t("personalData.create")}
          </Button>
        )}
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant]); //eslint-disable-line

  function onCellClick(params: GridCellParams) {
    if (!["actions", "multiSelect"].includes(params.field)) {
      history.push(
        `/my-company/employees/employee-details/documents/personal-data-details?id=${id}&personalDataId=${params.row.id}`,
      );
    }
  }

  return (
    <Container>
      <SingleQueryTable
        query={listPersonalDataQuery}
        columns={columns}
        setTableState={setTableState}
        tableState={tableState}
        sessionStorageKey={`employeePersonalDataList_${selectedRelTenant.tenant.id}`}
        onCellClick={onCellClick}
      />
    </Container>
  );
};

export default EmployeePersonalData;
