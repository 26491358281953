import { Box, Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { RootState } from "config/store";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery } from "react-query";
import { useSelector } from "react-redux";
import { CashRegister } from "shared/network/cash-register.api";
import { listUsers } from "shared/network/user.api";
import { User } from "shared/types";
import { useDebouncedCallback } from "use-debounce";
import { ListboxComponent } from "views/Project/components/ProjectOfferAddDialog";
import { nextPageFunction } from "views/Reminder/ReminderForm";

type Props = {
  cashRegister?: CashRegister;
};

export type CashRegisterFormValues = {
  name: string;
  responsibleUser: User | null;
  isDefault: boolean;
};

const CashRegisterForm = ({ cashRegister }: Props) => {
  const { t } = useTranslation();
  const form = useFormContext<CashRegisterFormValues>();
  const { control } = form;
  const tenantId = useSelector(
    (state: RootState) => state.authentication?.selectedRelTenant?.tenant?.id,
  );

  const [userSearch, setUserSearch] = useState("");

  const userListQuery = useInfiniteQuery(
    [`userListQuery`, tenantId, userSearch],
    async ({ pageParam = 0 }) => {
      const { data } = await listUsers(
        pageParam,
        20,
        tenantId,
        userSearch ? `name:${userSearch}` : "",
      );
      return data;
    },
    { getNextPageParam: lastGroup => nextPageFunction(lastGroup) },
  );
  const userFlatPages = userListQuery.data?.pages.map(page => page.page?.content).flat();
  const userHasNextPage =
    userListQuery.data &&
    userFlatPages &&
    userListQuery.data?.pages?.[0]?.page?.totalElements > userFlatPages?.length;

  const onUserChange = useDebouncedCallback((value: string) => {
    setUserSearch(value);
  }, 200);

  return (
    <Box>
      <Controller
        control={control}
        name="name"
        defaultValue={cashRegister?.name || ""}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            style={{ marginBottom: 16 }}
            label={t("cashRegister.name")}
            InputLabelProps={{ shrink: true, required: true }}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
          />
        )}
        rules={{
          required: t("validation.required").toString(),
        }}
      />
      <Controller
        control={control}
        name="responsibleUser"
        defaultValue={cashRegister?.responsibleUser || null}
        rules={{ required: t("validation.required").toString() }}
        render={({ field, fieldState }) => (
          <Autocomplete
            {...field}
            style={{ marginBottom: 4 }}
            onChange={(_, value) => {
              field.onChange(value);
              onUserChange("");
            }}
            onInputChange={(event, newInputValue) => {
              onUserChange(newInputValue);
            }}
            options={userFlatPages || []}
            getOptionLabel={(option: User) => option.name}
            getOptionSelected={option => option.id === field.value?.id}
            renderInput={params => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true, required: true }}
                label={t("user.formValues.name")}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
            ListboxComponent={props => (
              <ListboxComponent
                {...props}
                hasNextPage={!!userHasNextPage}
                fetchNextPage={userListQuery.fetchNextPage}
              />
            )}
          />
        )}
      />
      <Controller
        name="isDefault"
        control={control}
        defaultValue={cashRegister?.isDefault || false}
        render={({ field: { onChange, value, ref } }) => (
          <FormControlLabel
            label={t("cashRegister.isDefault")}
            control={
              <Checkbox
                onChange={(e, checked) => onChange(checked)}
                checked={value}
                inputRef={ref}
                color="primary"
              />
            }
          />
        )}
      />
    </Box>
  );
};

export default CashRegisterForm;
