import { Box, Button, Card, Grid, IconButton, Typography } from "@material-ui/core";
import { AddBox, Delete } from "@material-ui/icons";
import queryClient from "config/query";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getContractTypeList } from "shared/network/contract-type";
import {
  deleteRelCompanyTypeTenant,
  getRelCompanyTypeTenantList,
} from "shared/network/rel-contract-type-tenant.api";
import ContractTypeAdd from "./ContractTypeAdd";

const ContractType = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const contractTypeList = useQuery(["contractTypeList", tenant.id], async () => {
    const { data } = await getContractTypeList(tenant.id);
    return data.items;
  });

  const relContractTypeTenantList = useQuery(["relContractTypeTenantList", tenant.id], async () => {
    const { data } = await getRelCompanyTypeTenantList(tenant.id);
    return data.items;
  });

  const onDelete = async (contractTypeId: number) => {
    try {
      await deleteRelCompanyTypeTenant(contractTypeId, tenant.id);
      queryClient.invalidateQueries(["relContractTypeTenantList", tenant.id]);
    } catch {
      enqueueSnackbar(t("common:notification.delete.failure"), {
        variant: "error",
      });
    }
  };

  return (
    <Card style={{ padding: 16, backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
      <ContractTypeAdd
        open={open}
        setOpen={setOpen}
        relContractTypeTenantList={relContractTypeTenantList.data}
        contractTypeList={contractTypeList.data}
      />
      <Grid container justifyContent="space-between" style={{ padding: "0 0 16px 0" }}>
        <Typography variant="h2">{t("contract.types")}</Typography>
        <Button
          color="primary"
          variant="text"
          disabled={relContractTypeTenantList.data?.length === contractTypeList.data?.length}
          startIcon={<AddBox />}
          style={{ height: 24 }}
          onClick={() => setOpen(true)}
        >
          Hozzárendelés
        </Button>
      </Grid>
      <Grid container spacing={2}>
        {relContractTypeTenantList.data?.length ? (
          relContractTypeTenantList.data?.map(value => (
            <Grid key={value.id} item xs={6} sm={4} md={3} lg={2}>
              <Card variant="outlined">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  padding="8px 16px"
                >
                  <Typography>{t(`contract.type.${value?.contractType?.name}`)}</Typography>
                  <IconButton size="small" onClick={() => onDelete(value?.contractType?.id)}>
                    <Delete />
                  </IconButton>
                </Box>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Card>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding="8px 16px"
              >
                <Typography>Nincs még szerződéstípus</Typography>
              </Box>
            </Card>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default ContractType;
