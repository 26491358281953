import { Box, Card, CircularProgress, Container, Typography } from "@material-ui/core";
import { RootState } from "config/store";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getReportById } from "shared/network/report.api";
import CommentList from "views/Comment/CommentList";
import Login from "views/Login/Login";
import ReportDetailsCard from "./components/ReportDetailsCard";

export type Report = {
  closeTime: string;
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  name: string;
  description: string;
  tenantId: number | null;
  status: string;
  createdOn: string;
  identifier: number;
  isVisibleForCompany: boolean;
  hasOlderReportSameIssue: boolean;
  hasAnyOtherKnownCourtCase: boolean;
  isValidData: boolean;
};

const ReportDetails = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const getReportByIdQuery = useQuery(
    ["getReportByIdQuery", id, selectedRelTenant?.tenant.id],
    async () => {
      if (id && selectedRelTenant?.tenant.id) {
        const { data } = await getReportById(id, selectedRelTenant?.tenant.id);
        return data.item;
      }
      return Promise.reject();
    },
  );

  const report = getReportByIdQuery.data;
  if (selectedRelTenant?.tenant?.id) {
    return (
      <Container maxWidth="md">
        {getReportByIdQuery?.isFetching ? (
          <Box display="flex" alignItems="center" justifyContent="center" height="300px">
            <CircularProgress />
          </Box>
        ) : report ? (
          <>
            <Box pb={2}>
              <ReportDetailsCard report={report} tenantId={selectedRelTenant?.tenant.id} />
            </Box>
            {id && <CommentList paramId={id} />}
          </>
        ) : (
          (getReportByIdQuery.error as any)?.data?.status === "REPORT_NOT_VISIBLE_FOR_COMPANY" && (
            <Card style={{ padding: 16, textAlign: "center" }}>
              <Typography>{t("report.noPerm")}</Typography>
            </Card>
          )
        )}
      </Container>
    );
  } else {
    return <Login />;
  }
};

export default ReportDetails;
