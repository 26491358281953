import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import supportedLocales from "config/supportedLocales";
import { format } from "date-fns";
import i18n from "i18n";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getVehicleStatusHistoryListPageable } from "shared/network/vehicle-status-history.api";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";

const DepartureList = () => {
  const { t } = useTranslation();

  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-departure-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-departure-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const listDeparturesQuery = useQuery(
    ["departureListQuery", page, pageSize, selectedRelTenant.tenant.id, filterValue, sortState],
    async () => {
      const { data } = await getVehicleStatusHistoryListPageable(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        filterValue,
        sortState,
      );
      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "vehicle.licensePlateNumber",
      headerName: t("drawer.vehicle"),
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams) => row.vehicle.licensePlateNumber,
    },
    {
      field: "dateTime",
      headerName: t("delivery.formValues.date"),
      valueGetter: ({ row }: GridValueGetterParams) =>
        format(new Date(row.dateTime), "Pp", {
          locale: supportedLocales[i18n.language],
        }),
      flex: 2,
      type: "dateTime",
    },
    {
      field: "numberAtDate",
      headerName: t("vehicle.numberAtDate"),
      flex: 2,
    },
  ];

  return (
    <PageableTable
      sortState={sortState}
      setSortState={setSortState}
      sessionStorageKey="vehicle"
      tableState={tableState}
      setTableState={setTableState}
      columns={columns}
      query={listDeparturesQuery}
    />
  );
};

export default DepartureList;
