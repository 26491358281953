import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { AddAlarm, Delete, KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import ConfirmationButton from "components/ConfirmationButton";
import { PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY } from "config/constants";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useState } from "react";
import { Controller, FieldValues, UseFieldArrayReturn, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery } from "react-query";
import { useSelector } from "react-redux";
import { DailyReport } from "shared/network/daily-report.api";
import { listIssues } from "shared/network/issues.api";
import {
  createRelDailyReportIssue,
  deleteRelDailyReportIssue,
} from "shared/network/rel-daily-report-issue.api";
import { Issue } from "shared/types";
import { useDebouncedCallback } from "use-debounce";
import ProjectTimeEntryCreate from "views/Issues/IssueDetails/ProjectTimeEntryCreate";
import { ListboxComponent } from "views/Project/components/ProjectOfferAddDialog";
import DailyReportCreateIssueTimeEntry from "./DailyReportCreateIssueTimeEntry";
import { useSnackbar } from "notistack";
import Loading from "components/Loading";
import { useColoredRowStyles } from "views/Issues/NewIssueOverviewList";

type Props = {
  index: number;
  dailyReport?: DailyReport | null;
  fieldArray: UseFieldArrayReturn<FieldValues, "issues", "key">;
  isClosed: boolean;
};

const DailyReportCreateIssueRow = ({ index, dailyReport, fieldArray, isClosed }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const tenant = useSelector((state: RootState) => state?.authentication?.selectedRelTenant);
  const form = useFormContext();
  const { control, watch, setValue } = form;
  const project = watch("project");
  const [itemSearch, setItemSearch] = useState<string>("");
  const [createOpen, setCreateOpen] = useState<"IN" | "OUT" | null>(null);
  const [openTime, setOpenTime] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data, fetchNextPage } = useInfiniteQuery(
    [`listItemsAsdQuery`, tenant.id, itemSearch, project?.id],
    async ({ pageParam = 0 }) => {
      if (project?.id) {
        const { data } = await listIssues(
          pageParam,
          20,
          tenant.id,
          `project.id:${project.id}` + (itemSearch ? `;name:${itemSearch}` : ""),
        );
        return data;
      }
      return Promise.reject();
    },
    {
      getNextPageParam: lastGroup => {
        const nextPage = lastGroup.page.number + 1;
        if (nextPage <= lastGroup.page.totalPages - 1) {
          return nextPage;
        }
        return false;
      },
    },
  );

  const flatPages = data?.pages.map(page => page.page?.content).flat();
  const hasNextPage =
    data && flatPages && data?.pages?.[0]?.page?.totalElements > flatPages?.length;

  const handleIssueSearchChange = useDebouncedCallback((value: string) => {
    setItemSearch(value);
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  const issue = watch(`issues.${index}.issue`);
  const relIssueId = watch(`issues.${index}.id`);

  async function saveRelDailyReportIssue(issue: Issue) {
    try {
      if (dailyReport?.id) {
        const { data } = await createRelDailyReportIssue(
          {
            dailyReportId: Number(dailyReport.id),
            issue,
          },
          tenant?.id,
        );
        setValue(`issues.${index}.id`, data?.item?.id);
        enqueueSnackbar(t("dailyReport.saveSuccess"), {
          variant: "success",
        });
      }
    } catch {
      enqueueSnackbar(t("dailyReport.saveError"), {
        variant: "error",
      });
    }
  }

  async function removeRow(id: number) {
    setLoading(true);
    try {
      if (dailyReport?.id) {
        await deleteRelDailyReportIssue(id, tenant?.id);
        setValue(`issues.${index}.id`, null);
      }
      enqueueSnackbar(t("dailyReport.deleteSuccess"), {
        variant: "success",
      });
    } catch {
      enqueueSnackbar(t("dailyReport.deleteError"), {
        variant: "error",
      });
    }
    setLoading(false);
  }

  return (
    <Grid item xs={12}>
      <Loading open={loading} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        gridGap={8}
        pb={2}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={12} md={4}>
            <Box display="flex" gridGap={8} alignItems="center">
              <Box display="flex" width={40} alignItems="center" justifyContent="center">
                <Typography variant="h3">{index + 1}.</Typography>
              </Box>
              {!isClosed ? (
                <Controller
                  control={control}
                  name={`issues.${index}.issue`}
                  // rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      onChange={(_, value) => {
                        field.onChange(value);
                        handleIssueSearchChange("");

                        if (relIssueId) {
                          removeRow(relIssueId);
                        }
                        if (value) {
                          saveRelDailyReportIssue(value);
                        }
                      }}
                      onInputChange={(event, newInputValue) => {
                        handleIssueSearchChange(newInputValue);
                      }}
                      options={flatPages || []}
                      getOptionLabel={(option: Issue) => option.name}
                      getOptionSelected={option => option.id === field.value?.id}
                      style={{ width: "100%" }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={t("milestone.formValues.issueList.issue")}
                          InputLabelProps={{ shrink: true, required: true }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                      ListboxComponent={props => (
                        <ListboxComponent
                          {...props}
                          hasNextPage={hasNextPage}
                          fetchNextPage={fetchNextPage}
                        />
                      )}
                    />
                  )}
                />
              ) : (
                <Typography variant="h3">{issue?.name}</Typography>
              )}
            </Box>
          </Grid>
          {!isClosed && (
            <Grid item xs={12} sm={12} md={8} container justifyContent="flex-end">
              <Box
                display="flex"
                alignItems="center"
                gridGap={4}
                flexWrap="wrap"
                style={{ borderRadius: 10, border: "1px solid rgba(0,0,0,0.22)" }}
                p={0.5}
              >
                <AddAlarm />
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    color: COLORS.main,
                    paddingRight: 4,
                    paddingLeft: 4,
                  }}
                >
                  {t("dailyReport.newTimeEntry")}
                </Typography>
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={() => setCreateOpen("IN")}
                  disabled={!issue}
                  style={{ boxShadow: "unset" }}
                >
                  {t("dailyReport.newTimeEntryIn")}
                </Button>
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => setCreateOpen("OUT")}
                  disabled={!issue}
                  style={{ boxShadow: "unset" }}
                >
                  {t("dailyReport.newTimeEntryOut")}
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
        {!!project && (
          <ProjectTimeEntryCreate
            open={createOpen}
            onClose={() => setCreateOpen(null)}
            project={project}
            issue={issue}
            reportedDate={watch("reportedDate")}
          />
        )}
        <Box display="flex" alignItems="flex-start" gridGap={8}>
          {!isClosed && (
            <ConfirmationButton
              variant="text"
              size="small"
              color="primary"
              buttonType="ICON"
              title={t("dailyReport.removeIssueTitle")}
              body={t("dailyReport.removeIssueBody")}
              onSubmit={() => {
                fieldArray.remove(index);
                removeRow(relIssueId);
              }}
            >
              <Delete />
            </ConfirmationButton>
          )}
          <IconButton size="small" onClick={() => setOpenTime(!openTime)}>
            {openTime ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </Box>
      </Box>
      <Collapse in={openTime}>
        <DailyReportCreateIssueTimeEntry
          issue={issue}
          openTime={openTime}
          setOpenTime={setOpenTime}
          reportedDate={dailyReport?.reportedDate}
          isClosed={isClosed}
        />
      </Collapse>
      <Divider />
    </Grid>
  );
};

export default DailyReportCreateIssueRow;
