import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import { AddBox, CalendarToday } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";
import { RootState } from "config/store";
import supportedLocales from "config/supportedLocales";
import { endOfDay, format, startOfDay } from "date-fns";
import i18n from "i18n";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getSterilizationPage } from "shared/network/sterilization.api";
import SterilizationDialog from "./SterilizationDialog";

const SterilizationList = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndOfDate] = useState<Date | null>(new Date());

  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const sterilizationListQuery = useQuery(
    ["sterilizationListQuery", page, startDate, endDate, tenant.id],
    async () => {
      const { data } = await getSterilizationPage(
        page,
        50,
        tenant.id,
        startDate && endDate
          ? `sterilizationTime>=${startOfDay(
              startDate,
            ).toISOString()};sterilizationTime<=${endOfDay(endDate).toISOString()}`
          : "",
        "sterilizationTime",
      );
      return data;
    },
  );

  return (
    <Container>
      <SterilizationDialog open={open} onClose={() => setOpen(false)} />
      <Card style={{ padding: 16 }}>
        <Box display="flex" margin="auto" gridGap={8} maxWidth={500} pb={2}>
          <DatePicker
            format="P"
            label={"invoiceTransaction.summary.from"}
            InputLabelProps={{ shrink: true, required: true }}
            value={startDate}
            onChange={date => setStartDate(date)}
            InputProps={{ endAdornment: <CalendarToday color="primary" /> }}
          />
          <DatePicker
            format="P"
            label={t("invoiceTransaction.summary.to")}
            InputLabelProps={{ shrink: true, required: true }}
            value={endDate}
            onChange={date => setEndOfDate(date)}
            InputProps={{ endAdornment: <CalendarToday color="primary" /> }}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gridGap={16}
          pb={2}
        >
          <Button startIcon={<AddBox />} onClick={() => setOpen(true)}>
            {t("haccpSterilization.new")}
          </Button>
        </Box>
        <Box
          style={{
            border: "1px solid lightgrey",
            borderRadius: "10px",
            overflow: "auto",
          }}
        >
          <Box style={{ minWidth: 1000 }}>
            <Grid container style={{ borderBottom: "1px solid lightgrey" }}>
              <Grid item xs={2}>
                <Typography style={{ fontWeight: "bold", padding: 8 }}>
                  {t("haccpSterilization.number")}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={{ fontWeight: "bold", padding: 8 }}>
                  {t("haccpSterilization.date")}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={{ fontWeight: "bold", padding: 8 }}>
                  {t("haccpSterilization.name")}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={{ fontWeight: "bold", padding: 8 }}>
                  {t("haccpSterilization.factoryNumber")}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ fontWeight: "bold", padding: 8 }}>
                  {t("haccpSterilization.quantity")}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ fontWeight: "bold", padding: 8 }}>
                  {t("haccpSterilization.fluidQuantity")}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={{ fontWeight: "bold", padding: 8 }}>
                  {t("haccpSterilization.user")}
                </Typography>
              </Grid>
            </Grid>
            {sterilizationListQuery.isFetching && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="50px"
                padding={4}
              >
                <CircularProgress />
              </Box>
            )}
            {!sterilizationListQuery.isFetching &&
            sterilizationListQuery.data?.page?.content?.length
              ? sterilizationListQuery.data?.page?.content?.map((sterilization, index, array) => (
                  <Grid
                    container
                    style={{
                      borderBottom: index + 1 !== array?.length ? "1px solid lightgrey" : undefined,
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography style={{ padding: 8 }}>{sterilization.number}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography style={{ padding: 8 }}>
                        {format(new Date(sterilization.sterilizationTime), "Pp", {
                          locale: supportedLocales[i18n.language],
                        })}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography style={{ padding: 8 }}>
                        {sterilization.sterilizationItem?.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography style={{ padding: 8 }}>
                        {sterilization.sterilizationItem?.serialNumber}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography style={{ padding: 8 }}>{sterilization.quantity}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography style={{ padding: 8 }}>{sterilization.fluidQuantity}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography style={{ padding: 8 }}>
                        {sterilization.createdBy?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                ))
              : !sterilizationListQuery.isFetching && (
                  <Box display="flex" justifyContent="center" padding={4}>
                    <Typography> {t("haccpSterilization.noData")}</Typography>
                  </Box>
                )}
          </Box>
        </Box>
        {sterilizationListQuery.data && sterilizationListQuery.data?.page?.totalPages > 1 && (
          <Pagination
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "24px 0",
            }}
            count={sterilizationListQuery?.data?.page?.totalPages}
            color="primary"
            page={sterilizationListQuery.data?.page?.number + 1}
            onChange={(_, page) => {
              setPage(page - 1);
            }}
          />
        )}
      </Card>
    </Container>
  );
};

export default SterilizationList;
