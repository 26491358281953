import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormHelperText,
  Typography,
} from "@material-ui/core";
import { AttachFile } from "@material-ui/icons";
import queryClient from "config/query";
import { RootState } from "config/store";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { sendInvoiceToNAV } from "shared/network/invoice.api";

export type Props = {
  sendOpen: boolean;
  setSendOpen: Dispatch<SetStateAction<boolean>>;
  toSelectedList: any;
  setLoading: Dispatch<SetStateAction<boolean>>;
  onSuccess?: () => void;
};

const InvoiceSendAttachmentDialog = ({
  sendOpen,
  setSendOpen,
  toSelectedList,
  setLoading,
  onSuccess,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [sendingToPartner, setSendingToPartner] = useState(false);
  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant?.tenant;

  async function sendInvoiceToNAVFunction(ids: number[], sendingToPartner: boolean, values: any) {
    setLoading(true);
    try {
      if (!isEmpty(ids)) {
        await sendInvoiceToNAV(ids, sendingToPartner, tenant.id, values.files);
        enqueueSnackbar(t("common:notification.navSend.success"), {
          variant: "success",
        });

        setSendingToPartner(false);
        queryClient.refetchQueries("getAllInvoiceListQuery");
        queryClient.refetchQueries("invoiceByIdQuery");
        setSendOpen(false);
        onSuccess?.();
      }
    } catch (error: any) {
      enqueueSnackbar(t("common:notification.navSend.failure"), {
        variant: "error",
      });
    }
    setLoading(false);
  }

  async function onSubmit(values: any) {
    await sendInvoiceToNAVFunction([...toSelectedList], !!sendingToPartner, values);
  }

  const form = useForm<{ files: any }>();
  const { formState, register, watch, handleSubmit } = form;

  return (
    <Dialog maxWidth="sm" fullWidth open={sendOpen} onClose={() => setSendOpen(false)}>
      <DialogTitle>{t("invoice.multiNavSend")}</DialogTitle>
      <DialogContent>
        <FormProvider {...form}>
          <form id="invoice-attachment" onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Typography style={{ fontWeight: "bold", fontSize: 16, marginBottom: 8 }}>
                {t("invoice.attachments")}:
              </Typography>
              <Box>
                <Button
                  size="small"
                  variant="outlined"
                  component="label"
                  htmlFor="attachmentFiles"
                  startIcon={<AttachFile />}
                  style={{ marginBottom: 8 }}
                >
                  {t("personalData.formValues.chooseFiles")}
                  <input
                    id="attachmentFiles"
                    style={{ display: "none" }}
                    type="file"
                    multiple
                    {...register("files", {
                      required: {
                        value: false,
                        message: t("personalData.formValues.fileRequired"),
                      },
                      validate: value => {
                        if (Array.from(watch("files"))?.find((file: any) => file.size >= 5242880)) {
                          return t("validation.maxSize", {
                            size: 5,
                            unit: "MB",
                          }).toString();
                        }
                      },
                    })}
                  />
                </Button>
              </Box>
              {formState?.errors?.files?.message ? (
                <FormHelperText error>{formState.errors.files?.message}</FormHelperText>
              ) : (
                "Fájlonként maximum 5 MB"
              )}
              {!!watch("files")?.length && (
                <Box
                  mt={1}
                  style={{ border: "1px solid rgba(0,0,0,0.15)", borderRadius: 10, padding: 12 }}
                >
                  <Typography style={{ fontWeight: "bold" }}>Kiválasztott fájlok:</Typography>
                  {Array.from(watch("files"))?.map((file: any, index) => (
                    <Typography key={index}>{file.name}</Typography>
                  ))}
                </Box>
              )}
            </Box>
          </form>
          <Box pt={2} pb={2}>
            <Divider />
          </Box>
          <Box textAlign="center">
            <FormControlLabel
              labelPlacement="end"
              label={t("customNotification.sendingToPartner")}
              control={
                <Checkbox
                  checked={sendingToPartner}
                  size="small"
                  onChange={e => setSendingToPartner(e.target.checked)}
                  color="primary"
                />
              }
            />
          </Box>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => setSendOpen(false)}>
          {t("common:button.cancel")}
        </Button>
        <Button form="invoice-attachment" type="submit">
          {t("common:button.submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvoiceSendAttachmentDialog;
