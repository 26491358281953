import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { AddBox, CheckCircleOutline, Edit, Image } from "@material-ui/icons";
import Block from "@material-ui/icons/Block";
import DetailsCard from "components/DetailsCard";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { createWorksheet, listWorksheet } from "shared/network/worksheet.api";
import { Tenant } from "shared/types";
import displayAddress from "shared/util/displayAddress";
import TenantWorkSheetForm, { WorksheetFormValues } from "views/Admin/Tenants/TenantWorksheetForm";
import TenantWorksheetList from "views/Admin/Tenants/TenantWorksheetList";
import TenantBundleModify from "./TenantBundleModify";
import CompanyLogoDialog from "./TenantIcon/CompanyLogoDialog";

type Props = {
  tenant: Tenant | null;
  loading: boolean;
  refetch: () => void;
  isLoading?: boolean;
};

const useStyles = makeStyles({
  divider: {
    borderBottom: `1px ${COLORS.greyWater} solid`,
    marginBottom: 8,
  },
  dataHeaderItems: {
    justifyContent: "space-between",
  },
  dataHeader: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    padding: "0 8px 8px 8px",
    width: "100%",
  },
  dataContainer: {
    border: `1px solid ${COLORS.mainGrey}`,
    borderRadius: 4,
    padding: 12,
    marginBottom: 20,
    width: "100%",
  },
  createButtonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "16px",
  },
  createButton: {
    height: 36,
  },
});

const TenantDetails = ({ tenant, isLoading, loading, refetch }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<WorksheetFormValues>();
  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const [invoiceIcon, setInvoiceIcon] = useState(false);
  const [open, setOpen] = useState(false);
  const [workSheetOpen, setWorkSheetOpen] = useState(false);

  const worksheetListQuery = useQuery(
    [`worksheetList_${selectedRelTenant.tenant.id}`, selectedRelTenant.tenant.id],
    async () => {
      const { data } = await listWorksheet(selectedRelTenant.tenant.id);
      return data;
    },
  );

  const onSubmitCreate = async (values: WorksheetFormValues) => {
    try {
      await createWorksheet(values, selectedRelTenant.tenant.id);
      worksheetListQuery.refetch();
      setWorkSheetOpen(true);
      enqueueSnackbar(
        t("common:notification.create.success", {
          subject: t("issues.worksheet.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch {
      enqueueSnackbar(
        t("common:notification.create.failure", {
          subject: t("issues.worksheet.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  };

  return (
    <>
      <Container
        maxWidth="lg"
        style={{
          alignSelf: "center",
          alignItems: "center",
        }}
      >
        <>
          <Loading open={loading} />
          <DetailsCard
            style={{ padding: 0 }}
            title={t("tenant.datas")}
            headerButtons={
              <Box display="flex" gridGap={8}>
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={() => setInvoiceIcon(true)}
                  startIcon={<Image />}
                >
                  {t("tenant.addInvoiceIcon")}
                </Button>
                <CompanyLogoDialog
                  setOpen={setInvoiceIcon}
                  open={invoiceIcon}
                  tenantId={tenant?.id.toString()}
                />
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to={`/admin/tenants/tenant-modify?id=${id}`}
                  startIcon={<Edit />}
                >
                  {t("common:button.modify")}
                </Button>
              </Box>
            }
            columns={2}
            dataList={[
              {
                name: t("tenant.formValues.name"),
                value: tenant?.name || "-",
              },
              {
                name: t("tenant.formValues.tenantCode"),
                value: tenant?.tenantCode || "-",
              },
              {
                name: t("tenant.formValues.bankAccount"),
                value: tenant?.bankAccount || "-",
              },
              {
                name: t("tenant.formValues.iban"),
                value: tenant?.iban || "-",
              },
              {
                name: t("tenant.formValues.taxNumber"),
                value: tenant?.taxNumber || "-",
              },
              {
                name: t("tenant.formValues.registrationNumber"),
                value: tenant?.registrationNumber || "-",
              },
              {
                name: t("tenant.formValues.baseLanguage"),
                value: tenant?.baseLanguage ? t(`common:language.${tenant.baseLanguage}`) : "-",
              },
              {
                name: t("tenant.formValues.baseCurrency.title"),
                value: tenant?.baseCurrency.name || "-",
              },
              {
                name: t("tenant.formValues.baseCurrencySource"),
                value: tenant?.baseCurrencySource || "-",
              },
              {
                name: t("tenant.formValues.motto"),
                value: tenant?.motto || "-",
              },
              {
                name: t("tenant.formValues.description"),
                value: tenant?.description || "-",
              },
            ]}
          />
          <DetailsCard
            style={{ padding: 0, margin: "16px 0 0 0" }}
            title={t("tenant.availability")}
            columns={2}
            dataList={[
              {
                name: t("tenant.formValues.phone"),
                value: tenant?.phone || "-",
              },
              {
                name: t("tenant.formValues.fullAddress"),
                value: tenant?.address ? displayAddress(tenant?.address) : "-",
              },
              {
                name: t("tenant.formValues.email"),
                value: tenant?.notificationEmail || "-",
              },
            ]}
          />
          <DetailsCard
            style={{ padding: 0, margin: "16px 0 16px 0" }}
            title={t("subscription.title")}
            columns={2}
            dataList={[
              {
                name: t("subscription.formValues.isActive"),
                value: tenant?.subscriptions?.[0]?.isActive ? t("common:YES") : t("common:NO"),
              },
              {
                name: t("subscription.formValues.autoRenew"),
                value: tenant?.subscriptions?.[0]?.autoRenew ? t("common:YES") : t("common:NO"),
              },
              {
                name: t("subscription.formValues.subscriptionStart"),
                value: tenant?.subscriptions?.[0]?.subscriptionStart
                  ? format(new Date(tenant?.subscriptions?.[0]?.subscriptionStart), "yyyy-MM-dd")
                  : "-",
              },
              {
                name: t("subscription.formValues.subscriptionEnd"),
                value: tenant?.subscriptions?.[0]?.subscriptionEnd
                  ? format(new Date(tenant?.subscriptions?.[0]?.subscriptionEnd), "yyyy-MM-dd")
                  : "-",
              },
            ]}
          />
          <Box className={classes.dataHeader}>
            <Typography variant="h2">{t("bundle.tenantDetails.title")}</Typography>
            {/* modosito */}
            <Button
              color="primary"
              variant="text"
              startIcon={<Edit />}
              onClick={() => {
                setOpen(true);
              }}
              style={{ height: 24 }}
            >
              {t("common:button.modify")}
            </Button>
          </Box>
          <Box className={classes.dataContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body2">{t("bundle.formValues.name")}</Typography>
                <Typography variant="body1">
                  {tenant?.subscriptions?.[0]?.bundle.name || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body2">{t("bundle.formValues.price")}</Typography>
                <Typography variant="body1">
                  {tenant?.subscriptions?.[0]?.bundle.price || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="body2">{t("bundle.formValues.description")}</Typography>
                <Typography variant="body1">
                  {tenant?.subscriptions?.[0]?.bundle.description || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} style={{ textAlign: "center" }}>
                <Typography variant="body2">{t("bundle.formValues.hr")}</Typography>
                <Typography variant="body1">
                  {tenant?.subscriptions?.[0]?.bundle.hr ? (
                    <CheckCircleOutline style={{ color: COLORS.green }} />
                  ) : (
                    <Block style={{ color: COLORS.red }} />
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1} style={{ textAlign: "center" }}>
                <Typography variant="body2">{t("bundle.formValues.depot")}</Typography>
                <Typography variant="body1">
                  {tenant?.subscriptions?.[0]?.bundle.depot ? (
                    <CheckCircleOutline style={{ color: COLORS.green }} />
                  ) : (
                    <Block style={{ color: COLORS.red }} />
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={2} style={{ textAlign: "center" }}>
                <Typography variant="body2">{t("bundle.formValues.invoiceManagement")}</Typography>
                <Typography variant="body1">
                  {tenant?.subscriptions?.[0]?.bundle.invoiceManagement ? (
                    <CheckCircleOutline style={{ color: COLORS.green }} />
                  ) : (
                    <Block style={{ color: COLORS.red }} />
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={2} style={{ textAlign: "center" }}>
                <Typography variant="body2">{t("bundle.formValues.deliveryNote")}</Typography>
                <Typography variant="body1">
                  {tenant?.subscriptions?.[0]?.bundle.deliveryNote ? (
                    <CheckCircleOutline style={{ color: COLORS.green }} />
                  ) : (
                    <Block style={{ color: COLORS.red }} />
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={2} style={{ textAlign: "center" }}>
                <Typography variant="body2">{t("bundle.formValues.manageTasks")}</Typography>
                <Typography variant="body1">
                  {tenant?.subscriptions?.[0]?.bundle.manageTasks ? (
                    <CheckCircleOutline style={{ color: COLORS.green }} />
                  ) : (
                    <Block style={{ color: COLORS.red }} />
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={2} style={{ textAlign: "center" }}>
                <Typography variant="body2">{t("bundle.formValues.productionControl")}</Typography>
                <Typography variant="body1">
                  {tenant?.subscriptions?.[0]?.bundle.productionControl ? (
                    <CheckCircleOutline style={{ color: COLORS.green }} />
                  ) : (
                    <Block style={{ color: COLORS.red }} />
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={2} style={{ textAlign: "center" }}>
                <Typography variant="body2">{t("bundle.formValues.isWorksheetAllowed")}</Typography>
                <Typography variant="body1">
                  {tenant?.subscriptions?.[0]?.bundle.isWorksheetAllowed ? (
                    <CheckCircleOutline style={{ color: COLORS.green }} />
                  ) : (
                    <Block style={{ color: COLORS.red }} />
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {/* worksheet */}
          <Box className={classes.dataHeader}>
            <Typography variant="h2">{t("issues.worksheet.new")}</Typography>
            <Button
              color="primary"
              variant="contained"
              startIcon={<AddBox />}
              onClick={() => {
                setWorkSheetOpen(true);
              }}
            >
              {t("issues.worksheet.new")}
            </Button>
          </Box>
          <Box className={classes.dataContainer}>
            <TenantWorksheetList />
          </Box>
        </>
      </Container>
      {/* csomag dialog */}
      <Dialog open={open}>
        <DialogTitle>{t("bundle.tenantDetails.modify")}</DialogTitle>
        <DialogContent>
          <TenantBundleModify
            bundle={tenant?.subscriptions?.[0]?.bundle}
            setOpen={setOpen}
            refetch={refetch}
          />
        </DialogContent>
      </Dialog>
      {/* worksheet dialog */}
      <Dialog open={workSheetOpen}>
        <DialogTitle>{t("issues.worksheet.new")}</DialogTitle>
        <DialogContent>
          <form onSubmit={form.handleSubmit(onSubmitCreate)}>
            {/* <form> */}
            <FormProvider {...form}>
              <TenantWorkSheetForm setOpen={setWorkSheetOpen} />
            </FormProvider>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default TenantDetails;
