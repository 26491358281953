import axios from "config/axios";
import { GenericListResponse, GenericPageResponse, GenericResponse } from "shared/common";
import {
  Address,
  Company,
  CompanySite,
  ConnectionData,
  Contract,
  Item,
  Order,
  Project,
  Warehouse,
} from "shared/types";

export type OrderRequest = {
  order: {
    id?: string;
    isPaid: boolean;
    deliveryName: string;
    deliveryComment: string;
    deliveryFrom: string;
    deliveryTo: string;
    paymentType: string;
    deliveryAddress: Address;
  };
  companyId: string | number; //?
  company: Company | null;
  project: Project | null;
  contract: Contract | null;
  companySiteId: CompanySite;
  projectId: "" | number | string; //?
  relOrderItem: {
    id: string;
    quantity: string;
    price: string;
    tax: string;
    amountType: string;
    item: Item;
    serialNumber?: string;
  }[];
  fullNetPrice?: string;
  fullGrossPrice?: string;
  companySite?: CompanySite | undefined; //?
  receivingWarehouse?: Warehouse;
};

export type RelOrderItem = {
  id: number;
  tenantId: number;
  item: Item;
  itemSerial: any;
  order: Order;
  quantity: number;
  price: number;
  netPrice: number;
};

const ENDPOINT = "/order";

export const createOrder = (param: OrderRequest, tenantId: number) =>
  axios.post(`${ENDPOINT}/create`, { ...param, tenantId }); //ORDER_ADMIN

export const createLightOrder = (param: OrderRequest, tenantId: number) =>
  axios.post(`${ENDPOINT}/create-light`, { ...param, tenantId }); //ORDER_ADMIN

export const createSupplierOrder = (param: OrderRequest, tenantId: number) =>
  axios.post(`${ENDPOINT}/create-outgoing`, { ...param, tenantId }); //ORDER_ADMIN

export const modifyOrder = (param: OrderRequest, tenantId: number) =>
  axios.put(`${ENDPOINT}/modify`, { ...param, tenantId }); //ORDER_ADMIN

export const fastModifyOrder = (param: OrderRequest, tenantId: number) =>
  axios.put(`${ENDPOINT}/modify-light`, { ...param, tenantId }); //ORDER_ADMIN

export const getOrderById = (param: string, tenantId: number) =>
  axios.post<GenericResponse<Order>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //ORDER_GET

export const getOrderListByOffer = (param: number, tenantId: number) =>
  axios.post<GenericListResponse<Order>>(`${ENDPOINT}/list-by-offer`, {
    param,
    tenantId,
  }); //ORDER_GET

export const getOrderListByCompanyId = (
  page: number,
  size: number,
  companyId: string | number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.post<GenericPageResponse<Order>>(
    `${ENDPOINT}/get-by-company-id-page?page=${page}&size=${size}&companyId=${companyId}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //ORDER_GET

export const getOrderListByTenantId = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
  invoiceSent: string = "",
) =>
  axios.post<GenericPageResponse<Order>>(
    `${ENDPOINT}/get-page-by-tenant?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}&invoiceSent=${invoiceSent}`,
  ); //ORDER_GET

export const getSupplierOrderListByTenantId = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.post<GenericPageResponse<Order>>(
    `${ENDPOINT}/get-page-by-tenant-outgoing?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //ORDER_GET

export const getPlanningOrderList = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.post<GenericPageResponse<Order>>(
    `${ENDPOINT}/get-not-planned?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //ORDER_GET

export const getOrderItemList = (orderId: string | number, tenantId: number) =>
  axios.post(`${ENDPOINT}/get-items-by-order-id`, {
    param: orderId,
    tenantId,
  }); //ORDER_GET

export const getIdOfIssueOrInvoiceOrDeliveryNoteOfOrder = (
  orderId: number,
  tenantId: number,
  type: string,
) =>
  axios.get<GenericResponse<string>>(
    `${ENDPOINT}/get-links-by-id?tenantId=${tenantId}&orderId=${orderId}&linkType=${type}`,
  );

export const cancelOrder = (orderId: number, tenantId: number) =>
  axios.post(`${ENDPOINT}/cancel`, {
    param: orderId,
    tenantId: tenantId,
  });

export const getAllConnectionData = ({
  tenantId,
  orderId,
  invoiceId,
  deliveryId,
  deliveryNoteId,
}: {
  tenantId: number;
  orderId?: number;
  invoiceId?: number;
  deliveryId?: number;
  deliveryNoteId?: number;
}) =>
  axios.post<ConnectionData>(`${ENDPOINT}/get-all-connection-data`, {
    orderId,
    invoiceId,
    deliveryId,
    deliveryNoteId,
    tenantId,
  });

export const addItemToOrder = (param: RelOrderItem, tenantId: number) =>
  axios.post<GenericResponse<Order>>(`${ENDPOINT}/add-item-to-order`, { param, tenantId });

export const deleteItemFromOrder = (param: number, tenantId: number) =>
  axios.delete(`${ENDPOINT}/delete-item`, { data: { param, tenantId } });

export const getOrderPdf = (order: Order & { isWithoutPrice: boolean }, tenantId: number) =>
  axios.post(`${ENDPOINT}/get-order-pdf`, {
    param: order,
    tenantId,
  }); //ORDER_GET

export const updateOrderStatus = (orderId: number, status: string, tenantId: number) =>
  axios.post(`${ENDPOINT}/update-status`, { orderId, status, tenantId }); //ORDER_ADMIN
