import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import Loading from "components/Loading";
import queryClient from "config/query";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createCashRegister } from "shared/network/cash-register.api";
import CashRegisterForm from "./CashRegisterForm";
import { COLORS } from "config/theme";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onSuccess?: () => void;
};

export type CashRegisterFormValues = {
  name: string;
};

const CashRegisterCreateDialog = ({ open, setOpen, onSuccess }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const tenantId = useSelector(
    (state: RootState) => state.authentication?.selectedRelTenant?.tenant?.id,
  );
  const form = useForm<CashRegisterFormValues>();
  const { handleSubmit } = form;
  const [loading, setLoading] = useState(false);

  async function onSubmit(values: CashRegisterFormValues) {
    setLoading(true);
    try {
      await createCashRegister(values, tenantId);
      setOpen(false);
      queryClient.refetchQueries("cashRegisterList");
      enqueueSnackbar(t("common:notification.create.success"), { variant: "success" });
      onSuccess?.();
    } catch {
      enqueueSnackbar(t("common:notification.create.failure"), { variant: "error" });
    }
    setLoading(false);
  }

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={() => setOpen(false)}>
      <Loading open={loading} />
      <DialogTitle>{t("cashRegister.add")}</DialogTitle>
      <DialogContent>
        {!!onSuccess && (
          <Typography style={{ fontWeight: "bold", color: COLORS.red, paddingBottom: 16 }}>
            {t("cashRegister.createHelp")}
          </Typography>
        )}
        <FormProvider {...form}>
          <form id="cash-register-create" onSubmit={handleSubmit(onSubmit)}>
            <CashRegisterForm />
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => setOpen(false)}>
          {t("common:button.cancel")}
        </Button>
        <Button form="cash-register-create" type="submit">
          {t("common:button.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CashRegisterCreateDialog;
