import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { RootState } from "config/store";
import { useCallback, useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { login } from "shared/reducers/authentication";
import { googleAuthValidate } from "shared/network/google-auth.api";
import { Close, Info } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { authenticatorLogout } from "shared/network/user.api";

type Props = {
  open: boolean;
  onClose: () => void;
  loginValues: any;
  authToken: string | null;
};

type FormValues = {
  givenKey: string;
};

const AuthenticatorDialog = ({ open, onClose, loginValues, authToken }: Props) => {
  const dispatch = useDispatch();
  const location: any = useLocation();
  const { t } = useTranslation();
  const form = useForm<FormValues>();
  const { handleSubmit, register, control } = form;
  const [errorState, setErrorState] = useState("");

  const tenant = useSelector(
    (state: RootState) => state?.authentication?.selectedRelTenant?.tenant,
  );
  const { status, isAuthenticated } = useSelector((state: RootState) => state.authentication);

  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }
    const token = await executeRecaptcha("submit");
    return token;
  }, [executeRecaptcha]);

  const { from } = location.state ?? {
    from: { pathname: "/", search: location.search },
  };

  async function onSubmit(values: FormValues) {
    const { data } = await googleAuthValidate(values.givenKey, loginValues.email);
    if (data.item) {
      dispatch(login(loginValues.email, loginValues.password, loginValues.rememberMe));
      onClose();
    } else {
      setErrorState(t("twoFactor.loginError"));
    }
  }

  async function logout(token: string) {
    await authenticatorLogout(token);
  }

  useEffect(() => {
    const timeoutId = setTimeout(async function () {
      if (authToken) {
        await authenticatorLogout(authToken);
      }
      setErrorState("");
      onClose();
    }, 300000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [authToken]);

  if (status === "success" && isAuthenticated) {
    return <Redirect to={from} />;
  }

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      onClose={() => {
        onClose();
        setErrorState("");
        if (authToken) {
          logout(authToken);
        }
      }}
    >
      <Box position="absolute" right={8} top={8}>
        <IconButton
          onClick={() => {
            onClose();
            setErrorState("");
            if (authToken) {
              logout(authToken);
            }
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <DialogTitle>{t("twoFactor.loginTitle")}</DialogTitle>
      <DialogContent>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {errorState && (
              <Box pb={2}>
                <Alert severity="error" style={{ borderRadius: 10 }}>
                  {errorState}
                </Alert>
              </Box>
            )}

            <Controller
              control={control}
              name="givenKey"
              defaultValue={""}
              rules={{
                required: t("validation.required").toString(),
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="number"
                  size="small"
                  label={t("twoFactor.codeLabel")}
                  required
                  autoFocus
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  InputLabelProps={{ shrink: true, required: true }}
                  onChange={event => {
                    field.onChange(event);
                    setErrorState("");
                  }}
                />
              )}
            />
            <Button type="submit" fullWidth style={{ marginTop: 12 }}>
              {t("twoFactor.loginSubmit")}
            </Button>
          </form>
        </FormProvider>
        <Divider style={{ marginTop: 12, marginBottom: 12 }} />
        <Box display="flex" gridGap={8} mb={2}>
          <Info color="primary" />
          <Typography style={{ fontSize: 14 }}>{t("twoFactor.loginHelp")}</Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AuthenticatorDialog;
