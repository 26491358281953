import { faDownload, faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, IconButton, Tooltip } from "@material-ui/core";
import { AddBox, Assignment, Clear, Done, Edit, ImportExport } from "@material-ui/icons";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import Loading from "components/Loading";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import supportedLocales from "config/supportedLocales";
import { COLORS } from "config/theme";
import { format, isPast, subDays } from "date-fns";
import i18n from "i18n";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { getFileByPath } from "shared/network/file.api";
import { listIncomingInvoice } from "shared/network/incoming-invoice.api";
import { IncomingInvoice } from "shared/types";
import { fileDownload } from "shared/util/fileDownload";
import { getFilterAndSearchItemName } from "views/Admin/Tenants/TenantAllInvoice";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";
import { useColoredRowStyles } from "views/Issues/NewIssueOverviewList";
import DocumentUpload from "../components/DocumentUpload";
import NavImportDialog from "./NavImportDialog";

const IncomingInvoiceList = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const colorClasses = useColoredRowStyles();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const isCompany = location.pathname.includes("/companies");
  const isAll = location.pathname.includes("/list-all");
  const isCustomer = location.pathname.includes("/list-customer");
  const isInvoices = location.pathname.includes("/invoices");

  const history = useHistory();
  const { setHeaderButtons } = useHeader();
  const [open, setOpen] = useState(false);
  const [selectedIncomingInvoice, setSelectedIncomingInvoice] = useState<IncomingInvoice | null>(
    null,
  );
  const [docOpenLoading, setDocOpenLoading] = useState(false);

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey("rap-tenantincominginvoice-page-number"),
      ) || JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey("rap-tenantincominginvoice-page-size"),
      ) || JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });

  const { page, pageSize, filterValue } = tableState;

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant.tenant;

  const invoiceListQuery = useQuery(
    ["IncomingInvoiceListQuery", page, pageSize, tenant.id, filterValue, sortState],
    async () => {
      const filterContent = getFilterAndSearchItemName(filterValue);

      const { data } = await listIncomingInvoice(
        page,
        pageSize,
        tenant.id,
        (!!account.user.relUserCompany
          ? account?.user?.relUserCompany?.[0]?.company.name
            ? `supplier:${account?.user?.relUserCompany?.[0]?.company.name};`
            : ""
          : "") + filterContent?.filter,
        sortState,
        filterContent?.itemName,
      );
      return data;
    },
  );

  async function onDownload(incomingInvoice: IncomingInvoice) {
    setDocOpenLoading(true);
    if (incomingInvoice?.id) {
      try {
        const { data } = await getFileByPath(incomingInvoice?.uploadedDocumentPath, tenant.id);
        // if (subjectType === "INVOICE_DOCUMENT") {
        //   fileDownload(data, `${t("common:pdfDownload.INVOICE")}_${subjectId}`);
        // } else {
        fileDownload(
          data,
          `${t("common:pdfDownload.INCOMING_INVOICE")}_${incomingInvoice?.id || ""}`,
        );
        // }
      } catch (e) {
        enqueueSnackbar(
          t("common:notification.download.failure", {
            subject: t("comment.subject"),
          }),
          {
            variant: "error",
          },
        );
      }
    }
    setDocOpenLoading(false);
  }

  const columns: GridColDef[] = [
    {
      field: "serialNumber",
      headerName: t("invoice.serialNumber"),
      flex: 1,
      type: "stringContainsNumber",
    },
    {
      field: "supplierCompanyName",
      headerName: t("invoice.formValues.supplier.supplierSelect"),
      flex: 1.25,
    },
    {
      field: "paymentDeadline",
      headerName: t("invoice.formValues.paymentDeadline"),
      flex: 0.75,
      valueGetter: ({ row }: GridValueGetterParams) =>
        format(new Date(row.creationDate), "P", { locale: supportedLocales[i18n.language] }),
      type: "date",
    },
    {
      field: "creationDate",
      headerName: t("invoice.formValues.creationDate"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => {
        return row.creationDate
          ? format(new Date(row.creationDate), "P", { locale: supportedLocales[i18n.language] })
          : "";
      },
      type: "date",
    },
    {
      field: "completionDate",
      headerName: t("invoice.formValues.completionDate"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => {
        return row.completionDate
          ? format(new Date(row.completionDate), "P", { locale: supportedLocales[i18n.language] })
          : "";
      },
      type: "date",
    },
    {
      field: "paymentDeadline",
      headerName: t("invoice.formValues.paymentDeadline"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => {
        return row.paymentDeadline
          ? format(new Date(row.paymentDeadline), "P", { locale: supportedLocales[i18n.language] })
          : "";
      },
      type: "date",
    },
    {
      //bejövő számla
      field: "grossPrice",
      headerName: t("invoice.invoiceItem.grossPrice"),
      flex: 0.75,
      align: "right",
      valueGetter: ({ row }: GridValueGetterParams) =>
        t("common:number", { num: row.grossPrice }) + " Ft",
    },
    {
      field: "isTakenToWarehouse",
      headerName: t("incomingInvoice.isTakenToWarehouse"),
      flex: 0.25,
      type: "select",
      renderCell: ({ row }: GridRenderCellParams) =>
        row.isTakenToWarehouse ? (
          <Tooltip title={t(`warehouse.transaction.incomeCreateDone`).toString()}>
            <Done style={{ color: COLORS.green }} />
          </Tooltip>
        ) : (
          <Tooltip title={t(`warehouse.transaction.incomeCreateNotDone`).toString()}>
            <Clear style={{ color: COLORS.red }} />
          </Tooltip>
        ),
    },
    {
      field: "isPaid",
      headerName: t("incomingInvoice.isPaid.title"),
      flex: 0.25,
      type: "select",
      renderCell: ({ row }: GridRenderCellParams) =>
        row.isPaid ? (
          <Tooltip title={t(`incomingInvoice.isPaid.true`).toString()}>
            <Done style={{ color: COLORS.green }} />
          </Tooltip>
        ) : (
          <Tooltip title={t(`incomingInvoice.isPaid.false`).toString()}>
            <Clear style={{ color: COLORS.red }} />
          </Tooltip>
        ),
    },
    {
      field: "actions",
      headerName: " ",
      width: 160,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {!row?.uploadedDocumentPath ? (
            <Tooltip title={t("invoice.document.add").toString()}>
              <IconButton
                color="primary"
                size="small"
                style={{ marginRight: 4 }}
                onClick={() => setSelectedIncomingInvoice(row as IncomingInvoice)}
              >
                <FontAwesomeIcon
                  icon={faFileArrowUp}
                  style={{
                    fontSize: 20,
                    width: 20,
                    color: COLORS.main,
                  }}
                />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={t("invoice.document.download").toString()}>
              <IconButton
                color="primary"
                size="small"
                style={{ marginRight: 4 }}
                onClick={async () => onDownload(row as IncomingInvoice)}
              >
                <FontAwesomeIcon
                  icon={faDownload}
                  style={{
                    fontSize: 20,
                    width: 20,
                    color: COLORS.main,
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["INVOICE_GET"]) && (
            <Tooltip title={t("invoice.details").toString()}>
              <IconButton
                color="primary"
                size="small"
                component={Link}
                to={
                  isCompany
                    ? `/companies/${
                        isAll ? "list-all" : isCustomer ? "list-customer" : "list-supplier"
                      }/company-details/incoming-invoices/incoming-invoice-details?id=${
                        row.id
                      }&backId=${id}`
                    : isInvoices
                    ? `/invoices/incoming-invoice/invoice-details?id=${row.id}`
                    : ``
                }
              >
                <Assignment />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "INVOICE_ADMIN",
          ]) && (
            <Tooltip title={t("invoice.modify").toString()}>
              <IconButton
                color="primary"
                size="small"
                component={Link}
                to={
                  isCompany
                    ? `/companies/${
                        isAll ? "list-all" : isCustomer ? "list-customer" : "list-supplier"
                      }/company-details/incoming-invoices/incoming-invoice-details?id=${
                        row.id
                      }&backId=${id}`
                    : isInvoices
                    ? `/invoices/incoming-invoice/invoice-modify?id=${row.id}`
                    : ``
                }
              >
                <Edit />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
    {
      field: "searchItemName",
      headerName: t("invoice.invoiceItemFilter"),
      hide: true,
      type: "stringContainsNumber",
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={8}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, ["INVOICE_ADMIN"]) && (
          <>
            <Button
              size="small"
              variant="outlined"
              startIcon={<ImportExport />}
              onClick={() => setOpen(true)}
            >
              {t("incomingInvoice.import")}
            </Button>
            <Button
              size="small"
              component={Link}
              to={
                isCompany
                  ? `/companies/${
                      isAll ? "list-all" : isCustomer ? "list-customer" : "list-supplier"
                    }/company-details/incoming-invoices/incoming-invoice-create?id=${id}`
                  : isInvoices
                  ? `/invoices/incoming-invoice/invoice-create`
                  : ``
              }
              startIcon={<AddBox />}
            >
              {t("incomingInvoice.create")}
            </Button>
          </>
        )}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant]); //eslint-disable-line

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions") {
      history.push(
        isCompany
          ? `/companies/${
              isAll ? "list-all" : isCustomer ? "list-customer" : "list-supplier"
            }/company-details/incoming-invoices/incoming-invoice-details?id=${
              params.row.id
            }&backId=${id}`
          : isInvoices
          ? `/invoices/incoming-invoice/invoice-details?id=${params.row.id}`
          : ``,
      );
    }
  }

  return (
    <>
      <Loading open={docOpenLoading} />
      {selectedIncomingInvoice?.id && (
        <DocumentUpload
          subjectType={"INCOMING_INVOICE_DOCUMENT"}
          subjectId={selectedIncomingInvoice.id}
          open={!!selectedIncomingInvoice}
          setOpen={() => setSelectedIncomingInvoice(null)}
          refetch={() => invoiceListQuery?.refetch()}
        />
      )}
      <NavImportDialog open={open} setOpen={setOpen} />
      <PageableTable
        filterable={[
          "serialNumber",
          "supplierCompanyName",
          "creationDate",
          "isPaid",
          "searchItemName",
        ]}
        filterOptions={[
          {
            columnName: "isPaid",
            options: [
              {
                translated: t(`incomingInvoice.isPaid.true`),
                value: "true",
              },
              {
                translated: t(`incomingInvoice.isPaid.false`),
                value: "false",
              },
            ],
          },
        ]}
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="tenantincominginvoice"
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={invoiceListQuery}
        onCellClick={onCellClick}
        getRowClassName={params => {
          if (selectedRelTenant?.tenant?.isInvoiceColoring) {
            if (params.row?.paidDate) {
              return colorClasses.green;
            } else if (isPast(new Date(params.row?.paymentDeadline))) {
              return colorClasses.red;
            } else if (isPast(subDays(new Date(params.row?.paymentDeadline), 2))) {
              return colorClasses.orange;
            } else {
              return colorClasses.green;
            }
          } else {
            return "";
          }
        }}
      />
    </>
  );
};
export default IncomingInvoiceList;
