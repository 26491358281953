import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { AddBox, Check } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import clsx from "clsx";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Food } from "shared/network/food.api";
import {
  createGoodsAcceptance,
  getGoodsAcceptancePage,
  servingSignature,
} from "shared/network/goods-acceptance-sheet.api";
import HaccpGoodsNewRow from "./components/HaccpGoodsNewRow";
import theme from "config/roraTheme";
import SignatureCanvas from "components/SignatureCanvas";

type DateFormValues = {
  date: Date;
};

export type GoodsFormValues = {
  number: number;
  delivererName: string;
  deliveryNoteNumber: string;
  food: Food;
  amount: number;
  temperature: number;
  isOk: boolean;
  isNotOk: boolean;
};

export const useStyles = makeStyles({
  tg: {
    borderCollapse: "collapse",
    borderSpacing: 0,
  },
  td: {
    borderColor: "darkgrey",
    borderStyle: "solid",
    borderWidth: "1px",
    fontFamily: "Arial, sansSerif",
    fontSize: "14px",
    overflow: "hidden",
    padding: "4px",
    wordBreak: "normal",
    height: "100%",
  },
  th: {
    borderColor: "darkgrey",
    borderStyle: "solid",
    borderWidth: "1px",
    fontFamily: "Arial, sans-serif",
    fontSize: "14px",
    fontWeight: "bold",
    overflow: "hidden",
    padding: "4px",
    wordBreak: "normal",
  },
  pky: {
    borderColor: "inherit",

    verticalAlign: "center",
  },
  lax: {
    textAlign: "center",
  },
  datepicker: {
    "& .MuiIconButton-root": {
      padding: 0,
      width: 20,
    },
  },
});

const HaccpServingGoods = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

  const tenant = useSelector((state: RootState) => state.authentication?.selectedRelTenant?.tenant);

  const dateForm = useForm<DateFormValues>();
  const { setValue } = dateForm;
  const form = useForm<GoodsFormValues>();
  const { handleSubmit } = form;
  const date = dateForm.watch("date");

  const [openNew, setOpenNew] = useState(false);
  const [loading, setLoading] = useState(false);

  const getGoodsAcceptancePageQuery = useQuery(
    ["getGoodsAcceptancePageQuery", tenant.id, date],
    async () => {
      const { data } = await getGoodsAcceptancePage(
        0,
        2000,
        tenant.id,
        `date=${format(date, "yyyy-MM-dd")}`,
        "number,asc",
      );
      return data?.page?.content || [];
    },
    { enabled: !!date },
  );

  const [signatureOpen, setSignatureOpen] = useState(false);
  const [signatureFile, setSignatureFile] = useState<File | null>(new File([], ""));

  async function signSubmit(values: GoodsFormValues) {
    setSignatureOpen(true);
  }

  async function onSubmit(values: GoodsFormValues) {
    setLoading(true);

    const signature = document.getElementById("signature") as HTMLCanvasElement;

    try {
      if (signature) {
        const { data } = await createGoodsAcceptance(
          {
            ...values,
            food: typeof values?.food === "string" ? { name: values.food } : values.food,
            date,
          },
          tenant?.id,
        );
        signature.toBlob(blob => {
          if (blob) {
            setSignatureFile(
              new File([blob], "signature.png", {
                type: "image/png",
              }),
            );
          }
        });
        if (signatureFile) {
          await servingSignature(data.item.id, tenant?.id.toString(), signatureFile);
        }
        setSignatureOpen(false);
        setSignatureFile(null);
      }
      getGoodsAcceptancePageQuery.refetch();
      setOpenNew(false);
      form.reset();
      enqueueSnackbar(t("common:notification.save.success"), { variant: "success" });
    } catch {
      enqueueSnackbar(t("common:notification.save.failure"), { variant: "error" });
    }
    setLoading(false);
  }

  useEffect(() => {
    setValue("date", new Date());
  }, [setValue]);

  const ref = useRef<HTMLDivElement | null>();
  const [offset, setOffset] = useState<{ top: number; left: number }>({ top: 0, left: 0 });

  return (
    <Card style={{ padding: 8 }}>
      <Dialog open={signatureOpen} onClose={() => setSignatureOpen(false)} disablePortal>
        <DialogContent
          ref={ref}
          onScroll={() => {
            setOffset({
              top: ref.current?.scrollTop || 0,
              left: ref.current?.scrollLeft || 0,
            });
          }}
        >
          <Grid item xs={12} container justifyContent="center">
            <SignatureCanvas id={"signature"} offsetTop={offset.top} offsetLeft={offset.left} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="center" gridGap={8}>
            <Button color="primary" variant="text" onClick={() => setSignatureOpen(false)}>
              {t("common:button.cancel")}
            </Button>
            <Button onClick={() => onSubmit(form.watch())} color="primary">
              {t("common:button.save")}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Loading open={getGoodsAcceptancePageQuery?.isFetching || loading} />
      <Box style={{ overflow: "auto", padding: 8 }}>
        <FormProvider {...dateForm}>
          <form>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
              style={{ paddingBottom: 16 }}
            >
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Controller
                  control={dateForm.control}
                  name="date"
                  defaultValue={new Date()}
                  render={({ field }) => (
                    <KeyboardDatePicker
                      {...field}
                      ref={undefined}
                      className="date"
                      variant="inline"
                      label={t("haccpItem.date")}
                      format="yyyy.MM.dd."
                      InputLabelProps={{ shrink: true }}
                      autoOk
                      views={["year", "month", "date"]}
                      openTo="date"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        <Box overflow="auto">
          <FormProvider {...form}>
            <form onSubmit={handleSubmit(signSubmit)}>
              <table className={classes.tg} style={{ margin: "auto" }}>
                <thead className={classes.th}>
                  <tr>
                    <th className={clsx(classes.tg, classes.th, classes.lax)}>
                      {t("haccpServing.number")}
                    </th>
                    <th className={clsx(classes.tg, classes.th, classes.lax)}>
                      {t("haccpServing.delivererName")}
                    </th>
                    <th className={clsx(classes.tg, classes.th, classes.lax)}>
                      {t("haccpServing.deliveryNoteNumber")}
                    </th>
                    <th
                      className={clsx(classes.tg, classes.th, classes.lax)}
                      style={{ minWidth: 160 }}
                    >
                      {t("haccpServing.goodsName")}
                    </th>
                    <th className={clsx(classes.tg, classes.th, classes.lax)}>
                      {t("haccpServing.goodsAmount")}
                    </th>
                    <th className={clsx(classes.tg, classes.th, classes.lax)}>
                      {t("haccpServing.temperature")}
                    </th>
                    <th className={clsx(classes.tg, classes.th, classes.lax)}>
                      {t("haccpServing.isOk")}
                    </th>
                    <th className={clsx(classes.tg, classes.th, classes.lax)}>
                      {t("haccpServing.isNotOk")}
                    </th>
                    <th className={clsx(classes.tg, classes.th, classes.lax)}>
                      {t("haccpServing.sign")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {getGoodsAcceptancePageQuery?.data?.map((goods, index) => (
                    <>
                      <tr key={index}>
                        <td className={clsx(classes.tg, classes.td)} style={{ textAlign: "right" }}>
                          {goods?.number}
                        </td>
                        <td className={clsx(classes.tg, classes.td)}>{goods?.delivererName}</td>
                        <td className={clsx(classes.tg, classes.td)}>
                          {goods?.deliveryNoteNumber}
                        </td>
                        <td className={clsx(classes.tg, classes.td)}>{goods?.food?.name}</td>
                        <td className={clsx(classes.tg, classes.td)} style={{ textAlign: "right" }}>
                          {goods?.amount}
                        </td>
                        <td className={clsx(classes.tg, classes.td)} style={{ textAlign: "right" }}>
                          {goods?.temperature}
                        </td>
                        <td className={clsx(classes.tg, classes.td, classes.lax)}>
                          {!!goods?.isOk && <Check />}
                        </td>
                        <td className={clsx(classes.tg, classes.td, classes.lax)}>
                          {!!goods?.isNotOk && <Check />}
                        </td>
                        <td className={clsx(classes.tg, classes.td)}>{goods?.user?.name}</td>
                        {openNew && <td className={clsx(classes.tg, classes.td, classes.lax)}></td>}
                      </tr>
                    </>
                  ))}
                  {openNew ? (
                    <HaccpGoodsNewRow setOpenNew={setOpenNew} />
                  ) : (
                    <tr>
                      <td
                        className={clsx(classes.tg, classes.td, classes.lax)}
                        colSpan={16}
                        style={{ textAlign: matchesSm ? "left" : "center" }}
                      >
                        <Button
                          size="small"
                          style={{ margin: 4 }}
                          startIcon={<AddBox />}
                          onClick={() => setOpenNew(true)}
                        >
                          {t("common:button.add")}
                        </Button>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </form>
          </FormProvider>
        </Box>
      </Box>
    </Card>
  );
};

export default HaccpServingGoods;
