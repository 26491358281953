import { faHouseUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListItemIcon, MenuItem, MenuList, Popover, Typography } from "@material-ui/core";
import { AccountBoxOutlined, ExitToAppRounded } from "@material-ui/icons";
import { RootState } from "config/store";
import { Dispatch, MouseEvent, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { logout } from "shared/reducers/authentication";
import TenantChooserPopover from "./TenantChooserPopover";
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "react-i18next";

type Props = {
  anchorElProfile: HTMLElement | null;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setAnchorElProfile: Dispatch<SetStateAction<HTMLElement | null>>;
};

const ProfileMenu = ({ anchorElProfile, setOpen, setAnchorElProfile }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorElTenant, setAnchorElTenant] = useState<HTMLElement | null>(null);

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const handleClickTenantMenu = (event: MouseEvent<HTMLLIElement>) => {
    setAnchorElTenant(event.currentTarget);
  };
  const handleCloseTenantMenu = () => {
    setAnchorElTenant(null);
  };

  const handleCloseProfileMenu = () => {
    setAnchorElProfile(null);
    setAnchorElTenant(null);
  };

  useEffect(() => {
    if (!account) {
      history.replace("/login");
    }
  }, [history, account]);

  return (
    <>
      <Popover
        anchorEl={anchorElProfile}
        keepMounted
        open={!!anchorElProfile}
        onClose={handleCloseProfileMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuList>
          <LanguageSelector menu />
          <MenuItem
            component={Link}
            to="/profile/details"
            onClick={() => {
              handleCloseProfileMenu();
              setOpen(false);
            }}
          >
            <ListItemIcon>
              <AccountBoxOutlined />
            </ListItemIcon>
            <Typography variant="inherit">{t("myProfile")}</Typography>
          </MenuItem>
          <MenuItem onClick={handleClickTenantMenu}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faHouseUser} style={{ fontSize: "20px" }} />
            </ListItemIcon>
            <Typography variant="inherit">{selectedRelTenant?.tenant?.name}</Typography>
          </MenuItem>
          <MenuItem button onClick={() => dispatch(logout())}>
            <ListItemIcon>
              <ExitToAppRounded />
            </ListItemIcon>
            <Typography variant="inherit">{t("drawer.logout")}</Typography>
          </MenuItem>
        </MenuList>
      </Popover>
      <TenantChooserPopover
        anchorElTenant={anchorElTenant}
        setOpen={setOpen}
        handleCloseProfileMenu={handleCloseProfileMenu}
        handleCloseTenantMenu={handleCloseTenantMenu}
      />
    </>
  );
};

export default ProfileMenu;
