import axios from "config/axios";
import { GenericListResponse, GenericResponse } from "shared/common";
import { Tenant, RelItemCompany } from "shared/types";

const ENDPOINT = "/tenant";

export const createTenant = (tenant: Tenant) =>
  axios.post(`${ENDPOINT}/create`, {
    tenant: {
      name: tenant.name,
      taxNumber: tenant.taxNumber,
      registrationNumber: tenant.registrationNumber,
      notificationEmail: tenant.notificationEmail,
      phone: tenant.phone,
      bankAccount: tenant.bankAccount,
      address: tenant.address,
      baseLanguage: tenant.baseLanguage,
      baseCurrency: tenant.baseCurrency,
      baseCurrencySource: tenant.baseCurrencySource,
      tenantCode: tenant.tenantCode,
      invoicePrefix: tenant.invoicePrefix,
      isUseDefault: tenant.isUseDefault,
      isUseReport: tenant.isUseReport,
      isHACCP: tenant.isHACCP,
      isActive: tenant.isActive,
      sterilizationCount: tenant.sterilizationCount,
      haccpDefaultAfternoonTime: tenant.haccpDefaultAfternoonTime,
      haccpDefaultMorningTime: tenant.haccpDefaultMorningTime,
    },
  }); //superadmin

export const listTenants = () =>
  axios.get<GenericListResponse<Tenant>>(`${ENDPOINT}/list`, {
    data: { tenantId: 0 },
  }); //superadmin

export const listReportTenants = () =>
  axios.get<GenericListResponse<Tenant>>(`${ENDPOINT}/list-report`, {
    data: { tenantId: 0 },
  });

export const getTenantById = (id?: number) =>
  axios.post<GenericResponse<Tenant>>(`${ENDPOINT}/get-by-id`, {
    param: id,
    tenantId: id,
  }); //istenantadmin superadmin

export const modifyTenant = (tenant: Tenant) =>
  axios.put(`${ENDPOINT}`, {
    param: tenant,
    tenantId: tenant.id,
  }); //istenantadmin superadmin

export const addTechnicalUserRequest = (
  tenantId: number,
  login: string,
  passwordHash: string,
  signatureKey: string,
  exchangeKey: string,
) =>
  axios.post(`${ENDPOINT}/add-technical-user`, {
    tenantId,
    login,
    passwordHash,
    signatureKey,
    exchangeKey,
  }); //ADMIN

export const technicalUserValidator = (
  tenantId: number,
  login: string,
  passwordHash: string,
  signatureKey: string,
  exchangeKey: string,
) =>
  axios.post(`${ENDPOINT}/technical-user-validate`, {
    tenantId,
    login,
    passwordHash,
    signatureKey,
    exchangeKey,
  }); //ADMIN

export const listItemByCompany = (companyId: string, tenantId: number) =>
  axios.post<GenericListResponse<RelItemCompany>>(`/rel-item-company/get-by-company`, {
    param: companyId,
    tenantId,
  }); //REl_ITEM_COMPANY_ADMIN

export const createItemByCompany = (
  relList: RelItemCompany[],
  tenantId: number,
  companyId: string,
) => axios.post("/rel-item-company", { relList, tenantId, companyId }); //REl_ITEM_COMPANY_ADMIN

export const addInvoiceIcon = (tenantId?: string | number, invoiceIconFile?: File) => {
  const formData = new FormData();
  tenantId && formData.set("tenantId", tenantId.toString());
  invoiceIconFile && formData.set("invoiceIconFile", invoiceIconFile);

  return axios.post(`${ENDPOINT}/add-invoice-icon`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getApiKey = (tenantId: number) =>
  axios.get<GenericResponse<any>>(`${ENDPOINT}/get-api-key?tenantId=${tenantId}`);

export const generateApiKey = (tenantId: number) =>
  axios.post<GenericResponse<any>>(`${ENDPOINT}/generate-api-key?tenantId=${tenantId}`);

export const deleteApiKey = (tenantId: number) =>
  axios.delete<GenericResponse<any>>(`${ENDPOINT}/api-key?tenantId=${tenantId}`);

export const getTimebeezServices = (tenantId: number) =>
  axios.get<GenericResponse<any>>(
    `/company/get-timebeez-company-and-services?tenantId=${tenantId}`,
  );
