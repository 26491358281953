import { Card, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, GridCellParams, GridColDef, MuiEvent } from "@mui/x-data-grid";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { Dispatch, SetStateAction, SyntheticEvent } from "react";
import { UseQueryResult } from "react-query";
import { useSelector } from "react-redux";
import { GenericListResponse } from "shared/common";
import { TableState } from "views/Company/CompanyList";
import { FilterOptionsEntry } from "./FilterBar";
import { huHU } from "./huHU";
import { deDE } from "./deDE";
import { useTranslation } from "react-i18next";
import { CustomErrorOveryLayProps } from "./PageableTable";
import i18n from "i18n";

export type FilterOptionsType = {
  translated: string;
  value: string;
};

type Props = {
  transparent?: boolean;
  columns: GridColDef[];
  query: UseQueryResult<GenericListResponse<any>, unknown>;
  setTableState: Dispatch<SetStateAction<TableState>>;
  onCellClick?: (params: GridCellParams, event: MuiEvent<SyntheticEvent<Element, Event>>) => void;
  noSearch?: boolean;
  filterable?: string[];
  filterOptions?: FilterOptionsEntry[];
  sessionStorageKey: string;
  tableState: TableState;
};

const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-columnsContainer": {
      background: COLORS.mainGrey,
    },
    "& .MuiDataGrid-footerContainer": {
      background: COLORS.mainGrey,
    },
    "& .MuiDataGrid-windowContainer": {
      oveflow: "hidden",
    },
    "& .MuiDataGrid-overlay": {
      backgroundColor: "unset",
    },
  },
});

const SingleQueryTable = ({
  transparent,
  query,
  columns,
  onCellClick,
  setTableState,
  sessionStorageKey,
  tableState,
}: Props) => {
  const classes = useStyles();
  const id = useSelector((state: RootState) => state.authentication?.selectedRelTenant?.tenant?.id);

  function onPageChange(newPage: number) {
    sessionStorage.setItem(`rap-${sessionStorageKey}-page-number-${id}`, JSON.stringify(newPage));
    setTableState(prevState => {
      return { ...prevState, page: newPage };
    });
  }

  function onPageSizeChange(pageSize: number) {
    sessionStorage.setItem(`rap-${sessionStorageKey}-page-size-${id}`, JSON.stringify(pageSize));
    setTableState(prevState => {
      return {
        ...prevState,
        page: 0,
        pageSize,
      };
    });
  }

  const CustomErrorOverlay = ({
    cols = columns.map((col, index) => {
      return {
        name: col.headerName || "",
        flex: col.flex || "auto",
        align: col.align,
        key: index,
      };
    }) || [{ name: "", flex: 1 }],
  }: CustomErrorOveryLayProps) => {
    const { t } = useTranslation();

    return (
      <>
        <Grid
          container
          item
          xs={12}
          style={{
            height: 56,
            minHeight: 56,
            maxHeight: 56,
            background: "#E7EDF1",
            display: "flex",
          }}
        >
          {cols.map(column => (
            <Grid
              item
              style={{
                padding: 16,
                display: "flex",
                flex: column.flex,
                overflow: "hidden",
                overflowY: "clip",
                justifyContent: column.align || "inherit",
              }}
            >
              <Typography style={{ fontWeight: 500, textAlign: "center" }} noWrap>
                {column.name}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          style={{
            flexDirection: "column",
            justifyContent: "center",
            height: 104,
          }}
        >
          <Typography style={{ textAlign: "center" }}>{t("common:error.default")}</Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{ height: 56, minHeight: 56, maxHeight: 56, background: "#E7EDF1" }}
        />
      </>
    );
  };

  return (
    <Card
      style={{
        width: "100%",
        backgroundColor: transparent ? "unset" : "rgba(255, 255, 255, 0.6)",
        border: transparent ? "unset" : undefined,
      }}
    >
      {/* <FilterBar
          filterOptions={filterOptions}
          columns={columns}
          filterable={filterable}
          setTableState={setTableState}
        /> */}
      <DataGrid
        className={classes.root}
        autoHeight
        localeText={i18n.language === "hu" ? huHU : deDE}
        loading={query.isFetching}
        error={query.isError ? true : undefined}
        rows={query.data?.items || []}
        columns={columns}
        rowCount={query.data?.items?.length || 0}
        rowsPerPageOptions={[10, 20, 50, 100]}
        pagination
        paginationMode="client"
        disableColumnMenu
        disableSelectionOnClick
        onCellClick={onCellClick}
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        page={tableState.page}
        pageSize={tableState.pageSize}
        components={{
          ErrorOverlay: CustomErrorOverlay,
        }}
      />
    </Card>
  );
};

export default SingleQueryTable;
