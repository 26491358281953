import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { RootState } from "config/store";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { listComment } from "shared/network/comment.api";
import CommentRow from "views/Comment/CommentRow";

type Props = {
  selectedHistory: any;
  setSelectedHistory: Dispatch<SetStateAction<any>>;
};

const CashCommentDialog = ({ selectedHistory, setSelectedHistory }: Props) => {
  const { t } = useTranslation();
  const tenantId = useSelector(
    (state: RootState) => state.authentication?.selectedRelTenant?.tenant?.id,
  );

  const commentListQuery = useQuery(
    ["issueCommentList", tenantId, selectedHistory?.id],
    async () => {
      if (selectedHistory?.id) {
        const { data } = await listComment(
          tenantId,
          "CASH_REGISTER_AMOUNT",
          selectedHistory?.id,
          undefined,
          undefined,
        );
        return data.items;
      }
      return Promise.reject();
    },
  );

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={!!selectedHistory}
      onClose={() => setSelectedHistory(null)}
    >
      <DialogTitle style={{ paddingBottom: 0 }}>Részletek</DialogTitle>
      <DialogContent>
        {commentListQuery.isError ? (
          <Typography>{t("common:errorWhileFetching")}</Typography>
        ) : commentListQuery.data?.length === 0 ? (
          <Typography>{t("comment.noComment")}</Typography>
        ) : (
          commentListQuery.data?.map((comment: any) => (
            <CommentRow key={comment.id} comment={comment} subjectType={"CASH_REGISTER_AMOUNT"} />
          ))
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => setSelectedHistory(null)}>
          {t("common:button.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CashCommentDialog;
