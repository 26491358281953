import isEmpty from "lodash/isEmpty";
import { User, Permission, RelUserTenants } from "./types";

export function checkUserPermissions(
  user: User,
  userPermissions: Permission[],
  tenant: RelUserTenants,
  permissions: string[],
  strict: boolean,
) {
  const selectedTenantPermissions = userPermissions.find(
    userPermission => userPermission.id === tenant.tenant.id,
  );

  if (strict) {
    return permissions.every(permission => {
      if (selectedTenantPermissions) {
        return selectedTenantPermissions?.permissions?.findIndex(perm => permission === perm) > -1;
      } else {
        return false;
      }
    });
  } else {
    return permissions.some(permission => {
      if (selectedTenantPermissions) {
        return selectedTenantPermissions?.permissions?.findIndex(perm => permission === perm) > -1;
      } else {
        return false;
      }
    });
  }
}

function checkAuthority(
  user: User,
  userPermissions: Permission[],
  tenant: RelUserTenants,
  permissions: string[],
  strict = false,
) {
  if (!isEmpty(user)) {
    if (user?.isSuperAdmin) {
      return true;
    }
    if (tenant.isTenantAdmin) {
      return true;
    }
    if (isEmpty(permissions)) {
      return true;
    }
    if (!isEmpty(permissions)) {
      return checkUserPermissions(user, userPermissions, tenant, permissions, strict);
    }
    return false;
  }
  return false;
}

export function hasAnyAuthority(
  user: User,
  userPermissions: Permission[],
  tenant: RelUserTenants,
  permissions: string[],
) {
  return checkAuthority(user, userPermissions, tenant, permissions, false);
}

export function hasAuthority(
  user: User,
  userPermissions: Permission[],
  tenant: RelUserTenants,
  permissions: string[],
) {
  return checkAuthority(user, userPermissions, tenant, permissions, true);
}
