import { Box, Button, Container, IconButton, Tooltip } from "@material-ui/core";
import { Add, Edit } from "@material-ui/icons";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getTaxEntryListPageable } from "shared/network/tax-api";
import { TableState } from "views/Company/CompanyList";
import { useHistory } from "react-router";
import { useHeader } from "components/Layout/HeaderContext";
import { useGetSessionStorageKey } from "views/Comment/function";

const TaxTypeList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setHeaderButtons } = useHeader();

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-tax-type-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-tax-type-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const taxTypeListQuery = useQuery(
    ["taxTypeListQuery", page, pageSize, tenant.id, filterValue, sortState],
    async () => {
      const { data } = await getTaxEntryListPageable(
        page,
        pageSize,
        tenant.id,
        filterValue,
        sortState,
      );

      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("taxType.name"),
      flex: 2,
    },
    {
      field: "taxType",
      headerName: t("taxType.taxType"),
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => t(`taxType.types.${row?.taxType}`),
    },
    {
      field: "percent",
      headerName: t("taxType.percent"),
      flex: 1,
      type: "number",
      renderCell: ({ row }: GridRenderCellParams) => `${row.percent || 0}%`,
    },
    {
      field: "description",
      headerName: t("taxType.description"),
      flex: 2,
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%" alignItems="center">
          <Tooltip title={t("taxType.buttons.modify").toString()}>
            <IconButton
              size="small"
              color="primary"
              style={{ margin: "0 8px" }}
              onClick={() => history.push("/admin/tax-modify", { taxEntry: row })}
            >
              <Edit color="primary" />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        <Button component={Link} to="/admin/tax-create" startIcon={<Add />}>
          {t("taxType.buttons.create")}
        </Button>
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, []); //eslint-disable-line

  return (
    <Container maxWidth="xl">
      <PageableTable
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="tax-type"
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={taxTypeListQuery}
        minWidth={{ value: "sm" }}
      />
    </Container>
  );
};

export default TaxTypeList;
