import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { KeyboardTimePicker } from "@material-ui/pickers";
import Loading from "components/Loading";
import queryClient from "config/query";
import { RootState } from "config/store";
import supportedLocales from "config/supportedLocales";
import { COLORS } from "config/theme";
import { format, set } from "date-fns";
import i18n from "i18n";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { createCheckTime, getCheckTimePage, modifyCheckTime } from "shared/network/check-time.api";
import { createHaccpItem, modifyHaccpItem } from "shared/network/haccp-item.api";

type Props = {
  open: boolean;
  onClose: () => void;
  defaultValues: any | null;
  isModify: boolean;
};

type FormValues = {
  name: string;
  workingTemperature: string;
  status: string;
  haccpDefaultMorningTime: Date | null;
  haccpDefaultAfternoonTime: Date | null;
};

type CheckTimeFormValues = {
  checkAt: string;
  isActive: boolean;
};

export type HaccpItemStatus = "BROKEN" | "WAITING_FOR_MECHANIC" | "ACTIVE" | "INACTIVE";

export const HACCP_ITEM_STATUS = ["ACTIVE", "INACTIVE", "BROKEN", "WAITING_FOR_MECHANIC"];

const HaccpItemModal = ({ open, onClose, defaultValues, isModify }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<FormValues>();
  const checkTimeForm = useForm<CheckTimeFormValues>();
  const { handleSubmit, setValue } = form;
  const tenant = useSelector((state: RootState) => state.authentication?.selectedRelTenant?.tenant);
  const [loading, setLoading] = useState(false);
  const [checkItemOpen, setCheckItemOpen] = useState(false);

  const checkTimePageQuery = useQuery(
    ["checkTimePageQuery", tenant?.id, defaultValues?.id],
    async () => {
      const { data } = await getCheckTimePage(
        0,
        2000,
        tenant.id,
        defaultValues ? `haccpItem.id=${defaultValues.id}` : "",
        "checkAt,asc",
      );
      return data.page.content;
    },
    { enabled: !!defaultValues },
  );

  async function onCreateSubmit(values: FormValues) {
    setLoading(true);
    try {
      const { data } = await createHaccpItem(values, tenant?.id);
      if (values.haccpDefaultMorningTime) {
        await createCheckTime(
          {
            checkAt: set(new Date(values.haccpDefaultMorningTime), {
              year: 2000,
              month: 0,
              date: 1,
            }).toISOString(),
            haccpItem: data.item,
            isActive: true,
          },
          tenant?.id,
        );
      }
      if (values.haccpDefaultAfternoonTime) {
        await createCheckTime(
          {
            checkAt: set(new Date(values.haccpDefaultAfternoonTime), {
              year: 2000,
              month: 0,
              date: 1,
            }).toISOString(),
            haccpItem: data.item,
            isActive: true,
          },
          tenant?.id,
        );
      }
      onClose();
      queryClient.refetchQueries("haccpItemPageQuery");
      enqueueSnackbar(t("common:notification.create.success"), { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(t("common:notification.create.failure"), { variant: "error" });
    }
    setLoading(false);
  }

  async function onModifySubmit(values: FormValues) {
    setLoading(true);
    try {
      await modifyHaccpItem({ ...defaultValues, ...values }, tenant?.id);
      onClose();
      queryClient.refetchQueries("haccpItemPageQuery");
      enqueueSnackbar(t("common:notification.modify.success"), { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(t("common:notification.modify.failure"), { variant: "error" });
    }
    setLoading(false);
  }

  async function onCheckItemSubmit(values: CheckTimeFormValues) {
    setLoading(true);
    try {
      await createCheckTime(
        {
          checkAt: set(new Date(values.checkAt), {
            year: 2000,
            month: 0,
            date: 1,
          }).toISOString(),
          haccpItem: defaultValues,
          isActive: values.isActive,
        },
        tenant?.id,
      );
      setCheckItemOpen(false);
      checkTimeForm.reset();
      queryClient.refetchQueries("checkTimePageQuery");

      enqueueSnackbar(t("common:notification.create.success"), { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(t("common:notification.create.failure"), { variant: "error" });
    }
    setLoading(false);
  }

  useEffect(() => {
    setValue("name", defaultValues?.name || "");
    setValue("workingTemperature", defaultValues?.workingTemperature || "");
    setValue("status", defaultValues?.status || "ACTIVE");
  }, [defaultValues?.name, defaultValues?.status, defaultValues?.workingTemperature, setValue]);

  return (
    <>
      <Loading open={loading} />
      <Dialog maxWidth="xs" fullWidth open={!!open} onClose={onClose}>
        <DialogTitle>
          {isModify && t("haccpItem.modify")}
          {!isModify && t("haccpItem.create")}
        </DialogTitle>
        <DialogContent>
          <FormProvider {...form}>
            <form
              id="haccp-item-form"
              onSubmit={handleSubmit(!defaultValues ? onCreateSubmit : onModifySubmit)}
            >
              <Box display="flex" flexDirection="column" gridGap={16}>
                <Controller
                  name="name"
                  defaultValue=""
                  rules={{
                    required: t("validation.required").toString(),
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("haccpItem.name")}
                      InputLabelProps={{ shrink: true, required: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                <Controller
                  name="workingTemperature"
                  defaultValue=""
                  rules={{
                    required: t("validation.required").toString(),
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("haccpItem.workingTemperature")}
                      InputLabelProps={{ shrink: true, required: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                <Controller
                  name="status"
                  defaultValue="ACTIVE"
                  rules={{
                    required: t("validation.required").toString(),
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("haccpItem.status.label")}
                      InputLabelProps={{ shrink: true, required: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      select
                    >
                      <MenuItem value="" disabled>
                        {t("common:choose")}
                      </MenuItem>
                      {HACCP_ITEM_STATUS.map(value => (
                        <MenuItem key={value} value={value}>
                          {t(`haccpItem.status.${value}`)}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
                {!defaultValues && (
                  <Box>
                    <Typography style={{ marginLeft: 8, marginBottom: 12, fontWeight: "bold" }}>
                      {t("haccpItem.itemCheckDates")}:
                    </Typography>
                    <Box display="flex" gridGap={8}>
                      <Controller
                        name="haccpDefaultMorningTime"
                        defaultValue={
                          tenant.haccpDefaultMorningTime ||
                          set(new Date(), {
                            year: 2000,
                            month: 0,
                            date: 1,
                            hours: 9,
                            minutes: 0,
                          })
                        }
                        rules={{ required: t("validation.required").toString() }}
                        render={({ field, fieldState }) => (
                          <KeyboardTimePicker
                            {...field}
                            label="Délelőtt"
                            ref={undefined}
                            InputLabelProps={{ shrink: true, required: true }}
                            ampm={false}
                            autoOk
                          />
                        )}
                      />
                      <Controller
                        name="haccpDefaultAfternoonTime"
                        defaultValue={
                          tenant.haccpDefaultAfternoonTime ||
                          set(new Date(), {
                            year: 2000,
                            month: 0,
                            date: 1,
                            hours: 16,
                            minutes: 0,
                          })
                        }
                        rules={{ required: t("validation.required").toString() }}
                        render={({ field }) => (
                          <KeyboardTimePicker
                            {...field}
                            label="Délután"
                            ref={undefined}
                            InputLabelProps={{ shrink: true, required: true }}
                            ampm={false}
                            autoOk
                          />
                        )}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </form>
          </FormProvider>
          {!!defaultValues && (
            <Card variant="outlined" style={{ padding: 16, marginTop: 16, boxShadow: "unset" }}>
              <Typography style={{ fontWeight: "bold", fontSize: 18, paddingBottom: 8 }}>
                {t("haccpItem.checkTimes")}
              </Typography>
              {checkTimePageQuery.data?.map(checkTime => (
                <Tooltip title={!checkTime.isActive ? "Aktiválás" : "Inaktiválás"}>
                  <Chip
                    label={format(new Date(checkTime.checkAt), "p", {
                      locale: supportedLocales[i18n.language],
                    })}
                    style={{ margin: 4 }}
                    color={checkTime.isActive ? "primary" : "default"}
                    onClick={async () => {
                      try {
                        await modifyCheckTime(
                          { ...checkTime, isActive: !checkTime.isActive },
                          tenant?.id,
                        );
                        checkTimePageQuery.refetch();
                      } catch {}
                    }}
                  />
                </Tooltip>
              ))}
              <IconButton
                size="small"
                style={{ background: COLORS.main, color: "white", margin: 4 }}
                onClick={() => setCheckItemOpen(true)}
              >
                <Add />
              </IconButton>
              <Dialog open={checkItemOpen} onClose={() => setCheckItemOpen(false)}>
                <FormProvider {...checkTimeForm}>
                  <form onSubmit={checkTimeForm.handleSubmit(onCheckItemSubmit)}>
                    <DialogTitle>{t("haccpItem.checkTimes")}</DialogTitle>
                    <DialogContent>
                      <Controller
                        name="checkAt"
                        defaultValue={new Date()}
                        rules={{ required: t("validation.required").toString() }}
                        render={({ field, fieldState }) => (
                          <KeyboardTimePicker
                            {...field}
                            label="Ellenőrzés ideje"
                            ref={undefined}
                            InputLabelProps={{ shrink: true, required: true }}
                            ampm={false}
                            autoOk
                          />
                        )}
                      />
                      <Controller
                        name="isActive"
                        defaultValue={true}
                        render={props => (
                          <FormControlLabel
                            labelPlacement="end"
                            label={t("haccpItem.ACTIVE")}
                            control={
                              <Checkbox
                                {...props.field}
                                checked={props.field.value}
                                size="small"
                                onChange={e => props.field.onChange(e.target.checked)}
                                color="primary"
                              />
                            }
                          />
                        )}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button variant="text" onClick={() => setCheckItemOpen(false)}>
                        {t("common:button.cancel")}
                      </Button>
                      <Button variant="contained" type="submit">
                        {t("common:button.save")}
                      </Button>
                    </DialogActions>
                  </form>
                </FormProvider>
              </Dialog>
            </Card>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={onClose}>
            {t("common:button.cancel")}
          </Button>
          <Button form="haccp-item-form" variant="contained" type="submit">
            {t("common:button.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default HaccpItemModal;
