import { Box, Container } from "@material-ui/core";
import { RootState } from "config/store";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { hasAuthority } from "shared/authorization";
import NewIssueOverviewList from "views/Issues/NewIssueOverviewList";
import AddEntityBar from "./AddEntityBar";
import ReminderList from "../Reminder/ReminderList";

const Home = () => {
  const history = useHistory();
  const location = useLocation();

  const selectedRelTenant = useSelector(
    (state: RootState) => state.authentication?.selectedRelTenant,
  );
  const account = useSelector((state: RootState) => state.authentication?.account);
  const homePage = account?.user?.homePage;

  useEffect(() => {
    if (location.pathname === "/") {
      history.replace("/home");
    }
  }, [history, homePage]); //eslint-disable-line

  return (
    <Container>
      {account?.user && (
        <Box display="flex" flexDirection="column" gridGap={16} pb={2}>
          <AddEntityBar />
          <ReminderList needTitle />
          {hasAuthority(account?.user, account?.permissions, selectedRelTenant, ["ISSUE_GET"]) && (
            <NewIssueOverviewList />
          )}
        </Box>
      )}
    </Container>
  );
};
export default Home;
