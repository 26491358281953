import axios from "axios";

export type CompanyInfo = {
  address: string;
  compId: number;
  displayName: string;
  dunsNumber: string;
  groupTaxNumber: string | null;
  hasAnnouncement: boolean;
  hasEvent: boolean;
  hasLegalCourtEvent: boolean;
  hasMerit: boolean;
  hasOtherEvent: boolean;
  hasPositiveEvent: boolean;
  highlightDisplayValue: Array<string>;
  id: string;
  regName: string;
  regNumber: string;
  shortName: string;
  status: number;
  taxNumber: string;
  type: number;
  url: string;
};

const ENDPOINT = "https://api.bisnode.hu";

export const getCompanySearchFromPartnerControl = (query?: string) =>
  axios.get<CompanyInfo[]>(
    `${ENDPOINT}/search/get-quick/v2?onlyActive=true&paused=true&types=0,1&fields=1,2,3&inhistory=false&exact=true&userId=&sessionId=&query=${query}`,
  );
