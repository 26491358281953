import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Card, Container, Grid, Typography } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Loading from "components/Loading";
import { RootState } from "config/store";
import supportedLocales from "config/supportedLocales";
import { COLORS } from "config/theme";
import { endOfDay, format, getDaysInMonth, isValid, setDate, startOfDay } from "date-fns";
import i18n from "i18n";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { getCheckTimePage } from "shared/network/check-time.api";
import { getChecklistItemPage } from "shared/network/haccp-checklist.api";
import { getHaccpItemDateCommentPage } from "shared/network/haccp-item-date-comment.api";
import { getHaccpItem } from "shared/network/haccp-item.api";
import HaccpItemCheckComment from "./HaccpItemCheckComment";
import HaccpItemCheckInputCell from "./HaccpItemCheckInputCell";

const HaccpItemSet = () => {
  const { t } = useTranslation();
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);
  const form = useForm<any>();
  const { control, watch } = form;
  const date = watch("date");
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  const haccpItemQuery = useQuery(["haccpItemPageQuery", id, tenant.id], async () => {
    if (id) {
      const { data } = await getHaccpItem(id, tenant.id);
      return data?.item;
    }
    return Promise.reject();
  });

  const checkListItemPageQuery = useQuery(
    ["checkListItemPageQuery", tenant.id, date],
    async () => {
      const { data } = await getChecklistItemPage(
        0,
        2000,
        tenant.id,
        `realCheckTime>=${startOfDay(date).toISOString()};realCheckTime<=${endOfDay(
          date,
        ).toISOString()}`,
      );
      return data?.page?.content || [];
    },
    { enabled: !!date },
  );

  const checkTimePageQuery = useQuery(["checkTimePageQuery", tenant.id], async () => {
    const { data } = await getCheckTimePage(0, 2000, tenant.id, ` isActive=true`, "checkAt,asc");
    return data.page.content;
  });

  const haccpItemDateCommentQuery = useQuery(
    ["haccpItemDateCommentQuery", date, tenant.id],
    async () => {
      if (isValid(date)) {
        const { data } = await getHaccpItemDateCommentPage(
          0,
          2000,
          tenant.id,
          `date>=${format(startOfDay(date), "yyyy-MM-dd")};date<=${format(
            endOfDay(date),
            "yyyy-MM-dd",
          )}`,
        );
        return data?.page?.content || [];
      }
      return Promise.reject();
    },
  );

  const haccpItemDateComments = haccpItemDateCommentQuery.data || [];

  return (
    <Container>
      <Loading
        open={
          checkTimePageQuery?.isFetching ||
          checkListItemPageQuery?.isFetching ||
          haccpItemQuery?.isFetching ||
          haccpItemDateCommentQuery?.isFetching
        }
      />
      <Card style={{ padding: 16 }}>
        <FormProvider {...form}>
          <form>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Controller
                  control={control}
                  name="date"
                  defaultValue={new Date()}
                  render={({ field }) => (
                    <KeyboardDatePicker
                      {...field}
                      ref={undefined}
                      className="date"
                      variant="inline"
                      label={t("haccpItem.date")}
                      format="yyyy.MM.dd."
                      InputLabelProps={{ shrink: true }}
                      autoOk
                      views={["year", "month", "date"]}
                      openTo="date"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        <Typography
          style={{
            textAlign: "center",
            paddingTop: 24,
            fontSize: 16,
            fontWeight: "bold",
            color: COLORS.mainLight,
          }}
        >
          {t("haccpItem.help1")}
        </Typography>
        <Typography
          style={{
            textAlign: "center",
            fontSize: 16,
            fontWeight: "bold",
            color: COLORS.mainLight,
            paddingBottom: 16,
          }}
        >
          {t("haccpItem.help2")}
        </Typography>
        <Box style={{ overflow: "auto" }}>
          <Box style={{ minWidth: 780 }}>
            <Box>
              {!!getDaysInMonth(date) &&
                !!haccpItemQuery?.data &&
                !!checkTimePageQuery.data?.filter(
                  value => value?.haccpItem?.id === haccpItemQuery?.data?.id,
                ).length && (
                  <Box display="flex" justifyContent="center">
                    <Box width={200}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        p={1}
                        width="100%"
                        style={{
                          borderBottom: "1px solid lightgrey",
                        }}
                      >
                        <Typography noWrap style={{ fontWeight: "bold", fontSize: 18 }}>
                          Hűtő neve
                        </Typography>
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        style={{
                          borderBottom: "1px solid lightgrey",
                          padding: 8,
                          height: 57,
                        }}
                      >
                        <Typography style={{ fontSize: 18 }}>
                          {haccpItemQuery?.data?.name}
                        </Typography>
                      </Box>
                    </Box>
                    {checkTimePageQuery.data
                      ?.filter(value => value?.haccpItem?.id === haccpItemQuery?.data?.id)
                      ?.map(checkTime => {
                        const checkListItems = checkListItemPageQuery.data?.filter(
                          value => value.checkTime.id === checkTime.id,
                        );

                        return (
                          <Box
                            key={checkTime.id}
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            width={110}
                          >
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              p={1}
                              width="100%"
                              style={{
                                borderBottom: "1px solid lightgrey",
                              }}
                            >
                              <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
                                {format(new Date(checkTime.checkAt), "p", {
                                  locale: supportedLocales[i18n.language],
                                })}
                              </Typography>
                            </Box>
                            <HaccpItemCheckInputCell
                              index={Number(format(date, "d")) - 1}
                              checkTime={checkTime}
                              date={date}
                              checkListItem={checkListItems?.find(value => {
                                return (
                                  format(
                                    setDate(new Date(date), Number(format(date, "d"))),
                                    "yyyy-MM-dd",
                                  ) === format(new Date(value.realCheckTime), "yyyy-MM-dd")
                                );
                              })}
                            />
                          </Box>
                        );
                      })}
                    <Box width={360}>
                      <HaccpItemCheckComment
                        date={date}
                        haccpItemId={haccpItemQuery?.data?.id}
                        haccpItemDateComments={haccpItemDateComments}
                        width={360}
                        isDaily
                      />
                    </Box>
                  </Box>
                )}
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            startIcon={
              <FontAwesomeIcon
                icon={faQrcode}
                style={{
                  fontSize: 24,
                  width: 24,
                }}
              />
            }
            onClick={() => history.push("/haccp-item/qr")}
          >
            Másik QR-kód beolvasása
          </Button>
        </Box>
      </Card>
    </Container>
  );
};
export default HaccpItemSet;
