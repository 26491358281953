import axios from "config/axios";
import { GenericListResponse, GenericResponse } from "shared/common";

export enum CustomNotificationEventType {
  INVOICE_CLOSING,
}

export interface CustomNotification {
  id: number;
  tenantId: number;
  eventType: CustomNotificationEventType;
  title: string;
  content: string;
  containsCustomParameter: boolean;
}

const ENDPOINT = "/custom-notification";
export const CUSTOM_NOTIFICATION_EVENT_TYPES = [
  "INVOICE_CLOSING",
  "INVOICE_PAYMENT_DEADLINE_IS_OVER",
  "OFFER",
];

export const createCustomNotification = (param: CustomNotification) =>
  axios.post<GenericResponse<CustomNotification>>(`${ENDPOINT}`, {
    param: param,
    tenantId: param.tenantId,
  });

export const modifyCustomNotification = (param: CustomNotification) =>
  axios.put<GenericResponse<CustomNotification>>(`${ENDPOINT}`, {
    param: param,
    tenantId: param.tenantId,
  });

export const listCustomNotification = (tenantId: number) =>
  axios.get<GenericListResponse<CustomNotification>>(`${ENDPOINT}/all?tenantId=${tenantId}`);

export const customNotificationGetById = (id: number, tenantId: number) =>
  axios.get<GenericResponse<CustomNotification>>(`${ENDPOINT}?id=${id}&tenantId=${tenantId}`);
