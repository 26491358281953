import axios from "config/axios";
import { GenericListResponse, GenericPageResponse, GenericResponse } from "shared/common";
import { Item, Vehicle, Warehouse } from "shared/types";

type TransferRequestType = {
  fromWarehouseId: string | number;
  toWarehouseId: string | number;
  itemWithQuantityEntries: {
    quantity: string;
    itemEntry: Item | null;
  }[];
};

type GetItemResponse = {
  item: Item;
  quantity: number | string;
};

const ENDPOINT = "/warehouse";

export const createWarehouse = (values: Warehouse, tenantId: number) =>
  axios.post(`${ENDPOINT}`, { param: values, tenantId: tenantId }); //WAREHOUSE_ADMIN

export const modifyWarehouse = (values: Warehouse, tenantId: number) =>
  axios.put(`${ENDPOINT}`, { param: values, tenantId: tenantId }); //WAREHOUSE_ADMIN

export const listWarehousePageable = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.post<GenericPageResponse<Warehouse>>(
    `${ENDPOINT}/all?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //WAREHOUSE_GET

export const listOwnWarehousePageable = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.post<GenericPageResponse<Warehouse>>(
    `${ENDPOINT}/all-own?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //OWN_WAREHOUSE_GET

export const getWarehouseById = (param: string, tenantId: number) =>
  axios.post<GenericResponse<Warehouse>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //WAREHOUSE_GET

export const getOwnWarehouseById = (param: string, tenantId: number) =>
  axios.post<GenericResponse<Warehouse>>(`${ENDPOINT}/get-own-by-id`, {
    param,
    tenantId,
  }); //OWN_WAREHOUSE_GET

export const listItemsInWarehouse = (id: string, tenantId: number) =>
  axios.post<GenericListResponse<{ item: Item; quantity: number }>>(
    `${ENDPOINT}/list-items-in-warehouse`,
    {
      param: id,
      tenantId: tenantId,
    },
  ); //WAREHOUSE_ADMIN

export const listItemsInWarehousePageable = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.post<GenericPageResponse<{ item: Item; quantity: number }>>(
    `${ENDPOINT}/list-items-in-warehouse-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //WAREHOUSE_GET

export const listOwnItemsInWareHousePageable = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.post<GenericPageResponse<{ item: Item; quantity: number }>>(
    `${ENDPOINT}/list-own-items-in-warehouse-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //OWN_WAREHOUSE_GET

export const getItemInWarehouse = (warehouseId: number, itemId: number, tenantId: number) =>
  axios.post<GenericResponse<GetItemResponse>>(`${ENDPOINT}/get-item-in-warehouse`, {
    warehouseId: warehouseId,
    itemId: itemId,
    tenantId: tenantId,
  }); //WAREHOUSE_ADMIN

export const wrappingToWarehouse = (tenantId: number, vehicle: Vehicle, warehouse: Warehouse) =>
  axios.post(`${ENDPOINT}/wrapping-to-warehouse`, {
    tenantId: tenantId,
    vehicle: { id: vehicle },
    warehouse: { id: warehouse },
  }); //WAREHOUSE_ADMIN

export const transferItems = (param: TransferRequestType, tenantId: number) =>
  axios.post(`${ENDPOINT}/instant-transfer`, {
    fromWarehouseId: param.fromWarehouseId,
    toWarehouseId: param.toWarehouseId,
    itemWithQuantityEntries: param.itemWithQuantityEntries,
    tenantId,
  }); //WAREHOUSE_ADMIN

export const getUnderOverFlow = (tenantId: number) =>
  axios.post(`${ENDPOINT}/get-under-over-flow`, {
    tenantId,
  });

export const exportWarehousePdf = (warehouseId: string | number, tenantId: string | number) => {
  return axios.post(`${ENDPOINT}/export-to-csv?tenantId=${tenantId}&warehouseId=${warehouseId}`);
};
