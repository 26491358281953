import { Box, Card, Grid, Typography } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { RootState } from "config/store";
import { format } from "date-fns";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { getDeliveryByTenantIdAndVehicleIdAndDate } from "shared/network/delivery.api";
import MyDeliveryCard from "./MyDeliveryCard";

const MyDeliveryList = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const vehicleId = query.get("id");
  const { t } = useTranslation();
  const [date, setDate] = useState(new Date());
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const deliveryListQuery = useQuery(
    ["deliveryListQueryVehicles", date, vehicleId, tenant.id],
    async () => {
      if (vehicleId) {
        const { data } = await getDeliveryByTenantIdAndVehicleIdAndDate(
          tenant.id,
          Number.parseInt(vehicleId),
          format(date, "yyyy-MM-dd"),
        );
        return data.items.filter(entry => !entry.deliveryRound);
      }
      return Promise.reject();
    },
  );

  return (
    <Card style={{ backgroundColor: "rgba(255, 255, 255, 0.6)", padding: 16 }}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={3} style={{ marginBottom: 16 }}>
          <KeyboardDatePicker
            size="small"
            value={date}
            onChange={date => {
              date && setDate(date);
            }}
            label={t("delivery.formValues.date")}
            format="yyyy.MM.dd."
            InputLabelProps={{ shrink: true, required: true }}
            variant="inline"
            autoOk
            ref={undefined}
          />
        </Grid>
      </Grid>
      {isEmpty(deliveryListQuery.data) && (
        <Box width="100%" style={{ textAlign: "center" }}>
          <Typography>{t("common:noData")}</Typography>
        </Box>
      )}
      {deliveryListQuery.data?.map((entry, index) => {
        return (
          <MyDeliveryCard
            key={index}
            index={index}
            delivery={entry}
            deliveryListRefetch={deliveryListQuery.refetch}
          />
        );
      })}
    </Card>
  );
};

export default MyDeliveryList;
