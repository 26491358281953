import axios from "config/axios";
import { GenericPageResponse, GenericResponse } from "shared/common";
import { BaseCurrency, Invoice } from "shared/types";
import { CashRegister } from "./cash-register.api";

export type CashRegisterAmount = {
  id: number;
  tenantId: number;
  currency: BaseCurrency | null;
  quantity: string | number;
  cashRegister: CashRegister;
  invoiceEntry?: Partial<Invoice>;
};

const ENDPOINT = "/cash-register-amount";

export const addCashRegisterAmount = (param: Partial<CashRegisterAmount>, tenantId: number) =>
  axios.post<GenericResponse<CashRegisterAmount>>(`${ENDPOINT}/add-amount`, { ...param, tenantId });

export const getPageCashRegisterAmount = (param: {
  page: number;
  size: number;
  tenantId: number;
  search?: string;
  sort?: string;
}) =>
  axios.get<GenericPageResponse<CashRegisterAmount>>(
    `${ENDPOINT}/get-page?page=${param.page}&size=${param.size}&tenantId=${param.tenantId}&search=${
      param.search || ""
    }&sort=${param.sort || ""}`,
  );

export const getCashRegisterAmountById = (param: number, tenantId: number) =>
  axios.post<GenericResponse<CashRegisterAmount>>(`${ENDPOINT}`, { param, tenantId });
