import axios from "config/axios";
import { GenericPageResponse, HttpResponse } from "../common";
import { Company, Project } from "shared/types";

export type PageableParams = {
  page: number;
  size: number;
  search: string;
  sort: string;
  tenantId: number;
};

export type EmailLog = {
  id: number | null;
  receiver: string;
  subject: string;
  batched: boolean | null;
  isSent: boolean | null;
  errorMessage: string | null;
  batchId: string | null;
  company: Company | null;
  project: Project | null;
  content: string;
  attachments: string | null;
  createdOn: string;
};

const ENDPOINT = "/email-log";

export const getEmailLogPage = ({
  tenantId,
  page,
  size,
  search,
  sort,
  onlyOfferEmails,
}: PageableParams & { onlyOfferEmails: boolean }) =>
  axios.get<GenericPageResponse<EmailLog>>(
    `${ENDPOINT}/list?page=${page}&size=${size}${search ? `&search=${search}` : ""}${
      sort ? `&sort=${sort}` : ""
    }&tenantId=${tenantId}&onlyOfferEmails=${!!onlyOfferEmails}`,
  );
