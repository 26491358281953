import { Box, Container, Tab, Tabs } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useHeader } from "components/Layout/HeaderContext";
import PrivateRoute from "components/PrivateRoute";
import { RootState } from "config/store";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router";
import { Link, Switch } from "react-router-dom";
import { hasAnyAuthority } from "shared/authorization";
import { getCompanyById } from "shared/network/company.api";
import RelItemCompanyList from "views/Company/ItemCompany/RelItemCompanyList";
import DeliveryNoteList from "views/Delivery/DeliveryNote/DeliveryNoteList";
import IncomingInvoiceList from "views/Invoice/IncomingInvoice/IncomingInvoiceList";
import InvoiceList from "views/Invoice/InvoiceList";
import OfferList from "views/Offer/OfferList";
import SupplierPriceHistoryList from "views/SupplierPriceHistory/SupplierPriceHistoryList";
import CompanyDelivery from "../CompanyDelivery";
import CompanyDetails from "./CompanyDetails/CompanyDetails";
import CompanyOrders from "../CompanyOrders";
import ReminderList from "views/Reminder/ReminderList";
import { ADMIN_TABS } from "views/Admin/AdminTabs";
import SentEmails from "views/Admin/EmailList/SentEmails";

export const LIST_ALL = "/companies/list-all/company-details";
export const LIST_CUSTOMER = "/companies/list-customer/company-details";
export const LIST_SUPPLIER = "/companies/list-supplier/company-details";

export const COMPANY_DETAILS_TABS = [
  `/details`,
  `/reminders`,
  `/orders`,
  `/products`,
  `/invoices`,
  `/incoming-invoices`,
  `/delivery-notes`,
  `/delivery`,
  "/offers/in",
  "/offers/out",
  "/supplier-prices",
  "/sent-emails",
];

const CompanyDetailsTabs = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { setHeaderName } = useHeader();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const isSupplier = query.get("isSupplier");
  const isAll = location.pathname.includes("/list-all");
  const isCustomer = location.pathname.includes("/list-customer");

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const companyQuery = useQuery(["companyByIdQuery", id], async () => {
    if (id) {
      const { data } = await getCompanyById(Number.parseInt(id), selectedRelTenant.tenant.id);
      return data.item;
    }
    return Promise.reject();
  });
  const companyIsSupplier = companyQuery.data?.isSupplier;
  const companyIsConsumer = companyQuery.data?.isCustomer;

  useEffect(() => {
    if (companyQuery.isFetching) {
      setHeaderName(<Skeleton width={200} />);
    } else if (companyQuery.data?.name) {
      setHeaderName(`${companyQuery.data.name} ${t("common:details.details")}`);
    }
    return () => {
      setHeaderName(null);
    };
  }, [companyQuery.data?.name, companyQuery.isFetching]);

  const { setTopPadding } = useHeader();
  useEffect(() => {
    setTopPadding(false);

    return () => {
      setTopPadding(true);
    };
  }, [setTopPadding]);

  return (
    <>
      {location.pathname === `/companies/list-all/company-details` && (
        <Redirect to={`/companies/list-all/company-details/details?id=${id}`} />
      )}
      {location.pathname === `/companies/list-customer/company-details` && (
        <Redirect to={`/companies/list-customer/company-details/details?id=${id}`} />
      )}
      {location.pathname === `/companies/list-supplier/company-details` && (
        <Redirect to={`/companies/list-supplier/company-details/details?id=${id}`} />
      )}
      {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
        "OFFER_ADMIN",
        "OFFER_GET",
      ]) &&
        companyIsSupplier &&
        location.pathname === "/companies/list-all/company-details/offers" && (
          <Redirect to={`/companies/list-all/company-details/offers/in?id=${id}`} />
        )}
      {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
        "OFFER_ADMIN",
        "OFFER_GET",
      ]) &&
        companyIsSupplier &&
        location.pathname === "/companies/list-consumer/company-details/offers" && (
          <Redirect to={`/companies/list-consumer/company-details/offers/in?id=${id}`} />
        )}
      {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
        "OFFER_ADMIN",
        "OFFER_GET",
      ]) &&
        companyIsSupplier &&
        location.pathname === "/companies/list-supplier/company-details/offers" && (
          <Redirect to={`/companies/list-supplier/company-details/offers/in?id=${id}`} />
        )}
      {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
        "OFFER_ADMIN",
        "OFFER_GET",
      ]) &&
        companyIsConsumer &&
        location.pathname === "/companies/list-all/company-details/offers" && (
          <Redirect to={`/companies/list-all/company-details/offers/out?id=${id}`} />
        )}
      {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
        "OFFER_ADMIN",
        "OFFER_GET",
      ]) &&
        companyIsConsumer &&
        location.pathname === "/companies/list-consumer/company-details/offers" && (
          <Redirect to={`/companies/list-consumer/company-details/offers/out?id=${id}`} />
        )}
      {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
        "OFFER_ADMIN",
        "OFFER_GET",
      ]) &&
        companyIsConsumer &&
        location.pathname === "/companies/list-supplier/company-details/offers" && (
          <Redirect to={`/companies/list-supplier/company-details/offers/out?id=${id}`} />
        )}
      <Tabs
        scrollButtons="auto"
        variant="scrollable"
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
        style={{ backgroundColor: "rgba(255, 255, 255, 0.6)", paddingLeft: 24, paddingRight: 24 }}
      >
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, ["COMPANY_GET"]) && (
          <Tab
            label={t("company.tabs.details")}
            component={Link}
            to={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
              COMPANY_DETAILS_TABS[0]
            }?id=${id}${isSupplier ? `&isSupplier=${isSupplier}` : ""}`}
            value={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
              COMPANY_DETAILS_TABS[0]
            }`}
          />
        )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, ["COMPANY_GET"]) && (
          <Tab
            label={t("reminder.reminders")}
            component={Link}
            to={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
              COMPANY_DETAILS_TABS[1]
            }?id=${id}${isSupplier ? `&isSupplier=${isSupplier}` : ""}`}
            value={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
              COMPANY_DETAILS_TABS[1]
            }`}
          />
        )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, ["ORDER_GET"]) && (
          <Tab
            label={t("planning.orders")}
            component={Link}
            to={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
              COMPANY_DETAILS_TABS[2]
            }?id=${id}${isSupplier ? `&isSupplier=${isSupplier}` : ""}`}
            value={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
              COMPANY_DETAILS_TABS[2]
            }`}
          />
        )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "REl_ITEM_COMPANY_ADMIN",
          "COMPANY_GET",
        ]) && (
          <Tab
            label={t("relItemCompany.title")}
            component={Link}
            to={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
              COMPANY_DETAILS_TABS[3]
            }?id=${id}${isSupplier ? `&isSupplier=${isSupplier}` : ""}`}
            value={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
              COMPANY_DETAILS_TABS[3]
            }`}
          />
        )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "COMPANY_ADMIN",
          "INVOICE_GET",
        ]) &&
          companyIsConsumer && (
            <Tab
              label={t("invoice.tab")}
              component={Link}
              to={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
                COMPANY_DETAILS_TABS[4]
              }?id=${id}${isSupplier ? `&isSupplier=${isSupplier}` : ""}`}
              value={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
                COMPANY_DETAILS_TABS[4]
              }`}
            />
          )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "COMPANY_ADMIN",
          "INVOICE_GET",
        ]) &&
          companyIsSupplier && (
            <Tab
              label={t("incomingInvoice.tab")}
              component={Link}
              to={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
                COMPANY_DETAILS_TABS[5]
              }?id=${id}${isSupplier ? `&isSupplier=${isSupplier}` : ""}`}
              value={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
                COMPANY_DETAILS_TABS[5]
              }`}
            />
          )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "COMPANY_ADMIN",
          "DELIVERY_GET",
        ]) && (
          <Tab
            label={t("deliveryNote.deliveryNoteTitle")}
            component={Link}
            to={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
              COMPANY_DETAILS_TABS[6]
            }?id=${id}${isSupplier ? `&isSupplier=${isSupplier}` : ""}`}
            value={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
              COMPANY_DETAILS_TABS[6]
            }`}
          />
        )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "COMPANY_ADMIN",
          "DELIVERY_GET",
        ]) && (
          <Tab
            label={t("vehicle.tabs.delivery")}
            component={Link}
            to={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
              COMPANY_DETAILS_TABS[7]
            }?id=${id}${isSupplier ? `&isSupplier=${isSupplier}` : ""}`}
            value={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
              COMPANY_DETAILS_TABS[7]
            }`}
          />
        )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "OFFER_ADMIN",
          "OFFER_GET",
        ]) &&
          companyIsSupplier && (
            <Tab
              label={t("project.tabs.offerIn")}
              component={Link}
              to={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
                COMPANY_DETAILS_TABS[8]
              }?id=${id}${isSupplier ? `&isSupplier=${isSupplier}` : ""}`}
              value={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
                COMPANY_DETAILS_TABS[8]
              }`}
            />
          )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "OFFER_ADMIN",
          "OFFER_GET",
        ]) &&
          companyIsConsumer && (
            <Tab
              label={t("project.tabs.offerOut")}
              component={Link}
              to={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
                COMPANY_DETAILS_TABS[9]
              }?id=${id}${isSupplier ? `&isSupplier=${isSupplier}` : ""}`}
              value={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
                COMPANY_DETAILS_TABS[9]
              }`}
            />
          )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "SUPPLIER_PRICE_WATCHER",
        ]) &&
          companyIsSupplier && (
            <Tab
              label={t("supplierPriceHistory.title")}
              component={Link}
              to={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
                COMPANY_DETAILS_TABS[10]
              }?id=${id}${isSupplier ? `&isSupplier=${isSupplier}` : ""}`}
              value={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
                COMPANY_DETAILS_TABS[10]
              }`}
            />
          )}
        {(account?.user?.isSuperAdmin || selectedRelTenant?.isTenantAdmin) && (
          <Tab
            label={t("drawer./admin/sent-emails")}
            component={Link}
            to={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
              COMPANY_DETAILS_TABS[11]
            }?id=${id}${isSupplier ? `&isSupplier=${isSupplier}` : ""}`}
            value={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
              COMPANY_DETAILS_TABS[11]
            }`}
          />
        )}
      </Tabs>
      <Container maxWidth="xl">
        <Box pt={2}>
          <Switch location={location}>
            <PrivateRoute
              path={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
                COMPANY_DETAILS_TABS[0]
              }`}
              component={() => (
                <CompanyDetails
                  company={companyQuery.data || null}
                  loading={companyQuery.isFetching}
                  refetch={companyQuery.refetch}
                />
              )}
              permissions={["COMPANY_GET"]}
            />
            <PrivateRoute
              path={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
                COMPANY_DETAILS_TABS[1]
              }`}
              component={() => <ReminderList companyId={Number(id)} needTitle={false} />}
            />
            <PrivateRoute
              path={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
                COMPANY_DETAILS_TABS[2]
              }`}
              component={CompanyOrders}
              permissions={["ORDER_GET"]}
            />
            <PrivateRoute
              path={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
                COMPANY_DETAILS_TABS[3]
              }`}
              component={RelItemCompanyList}
              permissions={["REl_ITEM_COMPANY_ADMIN", "COMPANY_GET"]}
            />
            <PrivateRoute
              path={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
                COMPANY_DETAILS_TABS[4]
              }`}
              component={InvoiceList}
              permissions={["COMPANY_ADMIN", "INVOICE_GET"]}
            />
            <PrivateRoute
              path={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
                COMPANY_DETAILS_TABS[5]
              }`}
              component={IncomingInvoiceList}
              permissions={["COMPANY_ADMIN", "INVOICE_GET"]}
            />
            <PrivateRoute
              path={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
                COMPANY_DETAILS_TABS[6]
              }`}
              component={DeliveryNoteList}
              permissions={["COMPANY_ADMIN", "DELIVERY_GET"]}
            />
            <PrivateRoute
              path={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
                COMPANY_DETAILS_TABS[7]
              }`}
              component={CompanyDelivery}
              permissions={["COMPANY_ADMIN", "DELIVERY_GET"]}
            />
            <PrivateRoute
              path={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
                COMPANY_DETAILS_TABS[8]
              }`}
              component={OfferList}
              permissions={["OFFER_ADMIN", "OFFER_GET"]}
            />
            <PrivateRoute
              path={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
                COMPANY_DETAILS_TABS[9]
              }`}
              component={OfferList}
              permissions={["OFFER_ADMIN", "OFFER_GET"]}
            />
            <PrivateRoute
              path={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
                COMPANY_DETAILS_TABS[10]
              }`}
              component={SupplierPriceHistoryList}
            />
            <PrivateRoute
              path={`${isAll ? LIST_ALL : isCustomer ? LIST_CUSTOMER : LIST_SUPPLIER}${
                COMPANY_DETAILS_TABS[11]
              }`}
              component={SentEmails}
            />
          </Switch>
        </Box>
      </Container>
    </>
  );
};

export default CompanyDetailsTabs;
