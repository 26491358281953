import axios from "config/axios";
import { Employee, Permission, User, UserNotificationEntry, Vehicle } from "shared/types";
import { GenericPageResponse, GenericResponse, HttpResponse } from "../common";

export type UserRequest = {
  id: number;
  name: string;
  email: string;
  isSuperAdmin: boolean;
  isTenantAdmin: boolean;
};

export type GetAccountResponse = {
  user: User;
  permissions: Permission[];
  hasOwnWarehouse: boolean;
  hasVehicle: boolean;
  vehicle: Vehicle;
};

const ENDPOINT = "/user";
const USER_NOTIFICATION_ENDPOINT = "/usernotification";

export const userLogin = (email: string, password: string, rememberMe: boolean) =>
  axios.post<{ item: string } & HttpResponse>(`${ENDPOINT}/login`, {
    email,
    password,
    rememberMe,
  });

export const listUsers = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
  companyName: string = "",
) =>
  axios.post<GenericPageResponse<User>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}&companyName=${companyName}`,
  ); //USER_ADMIN

export const listUsersForVehicle = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
  companyName: string = "",
) =>
  axios.post<GenericPageResponse<User>>(
    `${ENDPOINT}/get-page-has-employee?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}&companyName=${companyName}`,
  ); //USER_ADMIN

export const getUserById = (tenantId: number, param: number | string) =>
  axios.post<{ user: User; employee: Employee }>(`${ENDPOINT}/get-by-id`, {
    tenantId,
    param,
  }); //USER_ADMIN

export const getAccount = () => axios.get<GetAccountResponse>(`${ENDPOINT}/account`); //ACCOUNT

export const checkAccount = (token: string) =>
  axios.get<GetAccountResponse>(`${ENDPOINT}/account`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const registerUser = (param: UserRequest, companyId: string | number, tenantId: number) =>
  axios.post(`${ENDPOINT}/create${companyId ? `?companyId=${companyId}` : ""}`, {
    param,
    companyId,
    tenantId,
  }); //USER_ADMIN

export const modifyUser = (param: any, tenantId: number) =>
  axios.put(`${ENDPOINT}/modify`, {
    param,
    tenantId,
  }); //ACCOUNT

export const deleteUser = (param: User, tenantId: number) =>
  axios.put(`${ENDPOINT}/remove-from-tenant`, {
    param,
    tenantId,
  }); //ACCOUNT

export const modifyPassword = (
  param: { oldPassword: string; newPassword: string; email: string },
  tenantId: number,
) =>
  axios.post(`${ENDPOINT}/password`, {
    ...param,
    tenantId,
  }); //ACCOUNT

export const activateUser = (password: string, token: string | null) =>
  axios.put(`${ENDPOINT}/activate`, { password, token }); //nincs

export const forgottenPasswordStart = (data: string) =>
  axios.post<GenericResponse<String>>(`${ENDPOINT}/forgotten`, {
    param: data,
  }); //nincs

export const forgottenPasswordFinish = (data: {
  newPassword: string;
  newPassword2: string;
  token: string | null;
}) => axios.post(`${ENDPOINT}/forgotten/finish`, data); //nincs

export const setHomePage = (param: string, tenantId: number) =>
  axios.post(`${ENDPOINT}/home-page`, { param, tenantId });

export const postLogout = () => axios.post(`${ENDPOINT}/logout`); //login required

export const authenticatorLogout = (token: string) =>
  axios.post(`${ENDPOINT}/logout`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }); //login required

export const removeMarketingNotification = (rejectionKey: string) =>
  axios.post(
    `${USER_NOTIFICATION_ENDPOINT}/removemarketingnotification?rejectionKey=${rejectionKey}`,
  );

export const modifyUserNotifications = (param: UserNotificationEntry, tenantId: number) =>
  axios.post(`${USER_NOTIFICATION_ENDPOINT}/modify`, { param, tenantId });

export const getNotificationSettingsByEmail = (email: string, tenantId: number) =>
  axios.post<GenericResponse<UserNotificationEntry>>(`${USER_NOTIFICATION_ENDPOINT}/get-by-email`, {
    param: email,
    tenantId,
  });

export const sendLawyerEmail = (user: User, tenantId: number) =>
  axios.post(`${ENDPOINT}/lawyer-email`, {
    param: user,
    tenantId,
  });

export const registerLawyer = (param: UserRequest, companyId: string | number, tenantId: number) =>
  axios.post(`${ENDPOINT}/create-lawyer${companyId ? `?companyId=${companyId}` : ""}`, {
    param,
    companyId,
    tenantId,
  }); //USER_ADMIN
