import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import FormCard from "components/FormCard";
import { AREA_TYPES, LANGUAGES } from "config/constants";
import { RootState } from "config/store";
import { Dispatch, SetStateAction } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { listCurrencyByTenant } from "shared/network/currency.api";
import { Address, BaseCurrency, Company, CompanyNickname } from "shared/types";
import { EMAIL_REGEX } from "shared/validation";
import CompanySearchBar from "./CompanySearchBar";

type Props = {
  company?: Company;
  setCompanyCreateState?: Dispatch<SetStateAction<boolean>>;
};

export type CompanyFormValues = {
  createdBy: number;
  updatedBy: number;
  id: number | string;
  tenantId: number;
  name: string;
  taxNumber: string;
  registrationNumber: string;
  notificationEmail: string;
  phone: string;
  bankAccount: string;
  description: string;
  isActive: boolean;
  address: Address;
  baseCurrency: BaseCurrency;
  baseLanguage: string;
  isSupplier: boolean;
  isCustomer: boolean;
  isForeign: boolean;
  paymentDueDays: number;
  nicknames?: CompanyNickname[];
  identifier: string;
  numberOfProjects: number;
  isCompany: string;
  invoiceBeforeDelivery: boolean;
  monitoringCompanyName: string;
  isSignatureRequired: boolean;
  companySearch?: any;
  isAutoDeliveryNoteEmail: boolean;
};

const CompanySmallForm = ({ company, setCompanyCreateState }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { control, watch } = useFormContext<CompanyFormValues>();
  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const tenant = selectedRelTenant.tenant;

  const currencyList = useQuery(["currencyList"], async () => {
    const { data } = await listCurrencyByTenant(0, 1000000, tenant.id, "");
    return data.page;
  });

  const isCompany = watch("isCompany") === "true";
  const isForeign = watch("isForeign");

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" pt={1}>
            <CompanySearchBar />
          </Box>
        </Grid>
        <Grid item lg={6}>
          <FormCard title={t("company.companyData")}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="name"
                  defaultValue={company?.name || ""}
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      InputLabelProps={{ shrink: true, required: true }}
                      label={t("company.formValues.name")}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              {isCompany && (
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="taxNumber"
                    defaultValue={company?.taxNumber || ""}
                    rules={{
                      required: isCompany && t("validation.required").toString(),
                    }}
                    render={({ field, fieldState }) => (
                      <>
                        {isForeign ? (
                          <TextField
                            {...field}
                            label={t("company.formValues.taxNumber")}
                            InputLabelProps={{
                              shrink: true,
                              required: isCompany,
                            }}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        ) : (
                          <InputMask
                            mask="99999999-9-99"
                            {...field}
                            // @ts-ignore
                            children={() => (
                              <TextField
                                {...field}
                                label={t("company.formValues.taxNumber")}
                                InputLabelProps={{
                                  shrink: true,
                                  required: isCompany,
                                }}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                              />
                            )}
                          />
                        )}
                      </>
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="baseCurrency"
                  defaultValue={company?.baseCurrency || tenant.baseCurrency}
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      onChange={(_, value) => field.onChange(value)}
                      options={currencyList.data?.content || []}
                      getOptionLabel={(option: BaseCurrency) => option.name + ` (${option.code})`}
                      getOptionSelected={option => option.id === field.value?.id}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={t("company.baseCurrencyTitle")}
                          InputLabelProps={{ shrink: true, required: true }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="phone"
                  defaultValue={company?.phone || ""}
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("company.formValues.phone")}
                      InputLabelProps={{ shrink: true, required: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="baseLanguage"
                  defaultValue={company?.baseLanguage || "HU"}
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("company.formValues.baseLanguage")}
                      InputLabelProps={{ shrink: true, required: true }}
                      select
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    >
                      {LANGUAGES.map(language => (
                        <MenuItem key={language} value={language}>
                          {t(`common:language.${language}`)}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="notificationEmail"
                  defaultValue={company?.notificationEmail || ""}
                  rules={{
                    required: t("validation.required").toString(),
                    pattern: {
                      value: EMAIL_REGEX,
                      message: t("common:validation.email"),
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("company.formValues.email")}
                      InputLabelProps={{ shrink: true, required: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </FormCard>
        </Grid>
        <Grid item lg={6}>
          <Box mb={2}>
            <FormCard title={t("company.companyAddress")}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Controller
                    control={control}
                    name="address.country"
                    defaultValue={company?.address.country || "Magyarország"}
                    rules={{ required: t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label={t("company.formValues.address.country")}
                        InputLabelProps={{ shrink: true, required: true }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controller
                    control={control}
                    name="address.zipCode"
                    defaultValue={company?.address?.zipCode || ""}
                    rules={{ required: t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label={t("company.formValues.address.zipCode")}
                        InputLabelProps={{ shrink: true, required: true }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Controller
                    control={control}
                    name="address.city"
                    defaultValue={company?.address?.city || ""}
                    rules={{ required: t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label={t("company.formValues.address.city")}
                        InputLabelProps={{ shrink: true, required: true }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="address.areaName"
                    defaultValue={company?.address?.areaName || ""}
                    rules={{ required: t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label={t("company.formValues.address.areaName")}
                        InputLabelProps={{ shrink: true, required: true }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controller
                    control={control}
                    name="address.areaType"
                    defaultValue={company?.address?.areaType || "UTCA"}
                    rules={{ required: t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label={t("company.formValues.address.areaType")}
                        InputLabelProps={{ shrink: true, required: true }}
                        select
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      >
                        {AREA_TYPES.map(area => (
                          <MenuItem key={area} value={area}>
                            {t(`common:areaType.${area}`)}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controller
                    control={control}
                    name="address.houseNumber"
                    defaultValue={company?.address?.houseNumber || ""}
                    rules={{ required: t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label={t("company.formValues.address.houseNumber")}
                        InputLabelProps={{ shrink: true, required: true }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </FormCard>
          </Box>
          <FormCard title={t("company.otherData")}>
            <Grid container>
              <Grid item xs={6}>
                <Controller
                  name="isCompany"
                  defaultValue={company?.isCompany.toString() || "true"}
                  render={({ field }) => (
                    <FormControl component="fieldset">
                      <FormLabel component="legend">{t("company.formValues.type")}</FormLabel>
                      <RadioGroup {...field}>
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label={t("company.formValues.isCompany")}
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label={t("company.formValues.isPerson")}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item container direction="column" xs={6} style={{ marginTop: 16 }}>
                <Controller
                  control={control}
                  name="isCustomer"
                  defaultValue={!!company?.isCustomer}
                  render={props => (
                    <FormControlLabel
                      labelPlacement="end"
                      label={t("company.formValues.isCustomer")}
                      control={
                        <Checkbox
                          {...props.field}
                          checked={props.field.value}
                          onChange={e => props.field.onChange(e.target.checked)}
                        />
                      }
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="isSupplier"
                  defaultValue={!!company?.isSupplier}
                  render={props => (
                    <FormControlLabel
                      label={t("company.formValues.isSupplier")}
                      labelPlacement="end"
                      control={
                        <Checkbox
                          {...props.field}
                          checked={props.field.value}
                          onChange={e => props.field.onChange(e.target.checked)}
                        />
                      }
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="isForeign"
                  defaultValue={!!company?.isForeign}
                  render={props => (
                    <FormControlLabel
                      label={t("company.formValues.isForeign")}
                      labelPlacement="end"
                      control={
                        <Checkbox
                          {...props.field}
                          checked={props.field.value}
                          onChange={e => props.field.onChange(e.target.checked)}
                        />
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </FormCard>
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Box display="flex" justifyContent="center" width="100%" m={2} gridGap={8}>
          <Button
            color="primary"
            variant="text"
            onClick={() => {
              if (setCompanyCreateState) {
                setCompanyCreateState(false);
              } else {
                history.goBack();
              }
            }}
          >
            {t("common:button.cancel")}
          </Button>
          <Button type="submit" color="primary">
            {company ? t("common:button.save") : t("common:button.create")}
          </Button>
        </Box>
      </Grid>
    </Box>
  );
};
export default CompanySmallForm;
