import { Box, Button, Card, Container, Grid, Tooltip, Typography } from "@material-ui/core";
import { PictureAsPdf } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Loading from "components/Loading";
import { RootState } from "config/store";
import supportedLocales from "config/supportedLocales";
import { COLORS } from "config/theme";
import { endOfWeek, format, getDay, isValid, setDay, startOfWeek } from "date-fns";
import i18n from "i18n";
import { useSnackbar } from "notistack";
import { Fragment, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getCheckItemByHieararchy } from "shared/network/check-item";
import { exportCheckItems, getCheckItemsPage } from "shared/network/check-items";
import { weeklyCheck } from "shared/network/weekly-chek-comment.api";
import CommentList from "views/Comment/CommentList";
import HaccpCheckCheckInputCell from "./HaccpCheckCheckInputCell";

const HaccpCheckCheck = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);
  const form = useForm<any>();
  const { control, watch } = form;
  const [downloadLoading, setDownloadLoading] = useState(false);

  const date = watch("date") || null;

  const checkItemByHieararchyQuery = useQuery(
    ["checkItemByHieararchyQuery", tenant.id],
    async () => {
      const { data } = await getCheckItemByHieararchy(tenant.id);

      return data?.items || [];
    },
  );

  const weeklyCheckQuery = useQuery(["weeklyCheckQuery", tenant.id, date], async () => {
    if (isValid(date)) {
      const { data } = await weeklyCheck(
        format(
          startOfWeek(date, {
            locale: supportedLocales[i18n.language],
            weekStartsOn: 1,
          }),
          "yyyy-MM-dd",
        ),
        tenant.id,
      );

      return data?.item;
    }
  });

  const checkItemPagesQuery = useQuery(
    ["checkItemPagesQuery", tenant.id, date],
    async () => {
      const { data } = await getCheckItemsPage(
        0,
        2000,
        tenant.id,
        `checkAt>=${startOfWeek(new Date(date), {
          locale: supportedLocales[i18n.language],
          weekStartsOn: 1,
        }).toISOString()};checkAt<=${endOfWeek(new Date(date), {
          locale: supportedLocales[i18n.language],
          weekStartsOn: 1,
        }).toISOString()}`,
      );

      return data?.page?.content || [];
    },
    { enabled: !!date },
  );

  return (
    <Container>
      <Loading open={downloadLoading} />
      <Card style={{ padding: 16, overflow: "auto" }}>
        <FormProvider {...form}>
          <form>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Controller
                  control={control}
                  name="date"
                  defaultValue={new Date()}
                  render={({ field }) => (
                    <KeyboardDatePicker
                      {...field}
                      ref={undefined}
                      className="date"
                      variant="inline"
                      label={t("haccpItem.date")}
                      format="yyyy. MM. dd."
                      InputLabelProps={{ shrink: true }}
                      autoOk
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2} style={{ display: "flex" }}>
                <Tooltip title="PDF letöltése a kiválaszott hétre.">
                  <Button
                    fullWidth
                    startIcon={<PictureAsPdf />}
                    onClick={async () => {
                      try {
                        setDownloadLoading(true);
                        if (date) {
                          const { data } = await exportCheckItems(
                            format(date, "yyyy-MM-dd"),
                            tenant?.id,
                          );
                          const fileName = data?.item?.fileName || "";
                          let a = document.createElement("a");
                          a.setAttribute("download", fileName);
                          a.setAttribute(
                            "href",
                            "data:application/pdf;base64," + data.item.content,
                          );
                          document.body.appendChild(a);
                          a.click();
                          document.body.removeChild(a);
                          enqueueSnackbar(t("haccpItem.pdfDownloadSuccess"), {
                            variant: "success",
                          });
                        }
                      } catch (error: any) {
                        if (error?.data?.status === "NOT_FOUND") {
                          enqueueSnackbar(t("haccpItem.pdfNotFound"), {
                            variant: "error",
                          });
                        } else {
                          enqueueSnackbar(t("haccpItem.pdfDownloadError"), {
                            variant: "error",
                          });
                        }
                      }
                      setDownloadLoading(false);
                    }}
                  >
                    {t("haccpItem.pdfDownload")}
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        <Typography
          style={{
            textAlign: "center",
            paddingTop: 24,
            fontSize: 16,
            fontWeight: "bold",
            color: COLORS.mainLight,
          }}
        >
          {t("haccpCheckItem.help")}
        </Typography>
        {isValid(date) && (
          <Box display="flex" justifyContent="center" p={2} minWidth={1200}>
            <Box>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                p={1}
                width="100%"
                style={{
                  borderBottom: "1px solid lightgrey",
                }}
              >
                <Box display="flex" alignItems="center" p={1} width="100%" style={{ width: 400 }}>
                  <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
                    {t("haccpCheckItem.sub")}
                  </Typography>
                </Box>
                {["H", "K", "Sze", "Cs", "P", "Szo", "V"].map(weekday => (
                  <Box
                    key={weekday}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    p={1}
                    width={100}
                  >
                    <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
                      {t(`haccpCheckItem.${weekday}`)}
                    </Typography>
                  </Box>
                ))}
              </Box>
              {checkItemByHieararchyQuery.data?.map((checkItem, index) => {
                return (
                  <>
                    <Box
                      key={index}
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                      style={{
                        borderBottom: "1px solid lightgrey",
                        padding: 8,
                        height: 60,
                        background: "whitesmoke",
                      }}
                    >
                      <Box display="flex" alignItems="center" p={1} minWidth={400} maxWidth={400}>
                        <Typography style={{ fontSize: 16, fontWeight: "bold" }}>
                          {checkItem.title}
                        </Typography>
                      </Box>
                      {["H", "K", "Sze", "Cs", "P", "Szo", "V"].map(weekday => {
                        let checkAt = 1;
                        if (weekday === "H") {
                          checkAt = 1;
                        } else if (weekday === "K") {
                          checkAt = 2;
                        } else if (weekday === "Sze") {
                          checkAt = 3;
                        } else if (weekday === "Cs") {
                          checkAt = 4;
                        } else if (weekday === "P") {
                          checkAt = 5;
                        } else if (weekday === "Szo") {
                          checkAt = 6;
                        } else if (weekday === "V") {
                          checkAt = 0;
                        }

                        const checkItems = checkItemPagesQuery?.data?.find(value => {
                          return (
                            value.checkItem?.id === checkItem.id &&
                            checkAt === getDay(new Date(value.checkAt))
                          );
                        });

                        const columnCheckItems = checkItemPagesQuery?.data?.filter(
                          value => checkAt === getDay(new Date(value.checkAt)),
                        );

                        let asd: any[] = [];
                        columnCheckItems?.forEach(columnCheckItemsValue => {
                          if (
                            checkItem.children.find(
                              (children: any) => columnCheckItemsValue.checkItem.id === children.id,
                            )
                          ) {
                            asd.push(columnCheckItemsValue);
                          }
                        });

                        return (
                          <HaccpCheckCheckInputCell
                            key={weekday}
                            checkAt={setDay(new Date(date), checkAt, {
                              locale: supportedLocales[i18n.language],
                              weekStartsOn: 1,
                            }).toISOString()}
                            checkItem={checkItem}
                            checkItems={checkItems}
                            childrenCheckItems={asd}
                          />
                        );
                      })}
                    </Box>
                    {checkItem?.children?.map((children: any, index: number) => {
                      return (
                        <Box
                          key={index}
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                          style={{
                            borderBottom: "1px solid lightgrey",
                            padding: 8,
                            height: 60,
                          }}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            p={1}
                            minWidth={400}
                            maxWidth={400}
                          >
                            <Typography style={{ fontSize: 16 }}>{children.title}</Typography>
                          </Box>
                          {["H", "K", "Sze", "Cs", "P", "Szo", "V"].map(weekday => {
                            let checkAt = 1;
                            if (weekday === "H") {
                              checkAt = 1;
                            } else if (weekday === "K") {
                              checkAt = 2;
                            } else if (weekday === "Sze") {
                              checkAt = 3;
                            } else if (weekday === "Cs") {
                              checkAt = 4;
                            } else if (weekday === "P") {
                              checkAt = 5;
                            } else if (weekday === "Szo") {
                              checkAt = 6;
                            } else if (weekday === "V") {
                              checkAt = 0;
                            }

                            const checkItems = checkItemPagesQuery?.data?.find(value => {
                              return (
                                value.checkItem?.id === children?.id &&
                                checkAt === getDay(new Date(value.checkAt))
                              );
                            });
                            if (!children.isParent) {
                              return (
                                <HaccpCheckCheckInputCell
                                  key={weekday}
                                  checkAt={setDay(new Date(date), checkAt, {
                                    locale: supportedLocales[i18n.language],
                                    weekStartsOn: 1,
                                  }).toISOString()}
                                  checkItem={children}
                                  checkItems={checkItems}
                                />
                              );
                            } else {
                              return <Fragment key={weekday} />;
                            }
                          })}
                        </Box>
                      );
                    })}
                  </>
                );
              })}
            </Box>
          </Box>
        )}
      </Card>
      {!!weeklyCheckQuery?.data?.id && (
        <Box pt={2}>
          <CommentList paramId={weeklyCheckQuery.data.id} />
        </Box>
      )}
    </Container>
  );
};

export default HaccpCheckCheck;
