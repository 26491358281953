import { Box, Button, Card, IconButton, TextField, Tooltip } from "@material-ui/core";
import { AddBox, Assignment } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import PageableTable from "components/PageableTable/PageableTable";
import { ITEM_TYPES, SUB_ITEM_TYPE } from "config/constants";
import { RootState } from "config/store";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { getCompanySiteListPageable } from "shared/network/companysite.api";
import { getItemListByCompanySite } from "shared/network/rel-item-company-site";
import { CompanySite } from "shared/types";
import displayAddress from "shared/util/displayAddress";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "../CompanyList";
import TransactionDialog from "./TransactionDialog";

type Props = {
  companyId?: string;
  isOnOrder?: boolean;
  companySiteId?: number;
};

const RelItemCompanyList = ({ companyId, isOnOrder, companySiteId }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const isAll = location.pathname.includes("/list-all");
  const isCustomer = location.pathname.includes("/list-customer");
  const [open, setOpen] = useState<"IN" | "OUT" | "">("");

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey("rap-company-site-items-page-number"),
      ) || JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-company-site-items-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const { control, watch, setValue } = useForm<{ companySite: CompanySite | null }>();
  const companySite = watch("companySite");

  const itemListByCompanySiteQuerry = useQuery(
    [
      "itemListByCompanySiteQuerry",
      selectedRelTenant.tenant.id,
      page,
      pageSize,
      filterValue,
      sortState,
      companySite,
      companySiteId,
      isOnOrder,
    ],
    async () => {
      if (companySite || (isOnOrder && companySiteId)) {
        const { data } = await getItemListByCompanySite(
          page,
          pageSize,
          isOnOrder
            ? ({ id: companySiteId || 0 } as CompanySite)
            : companySite || ({ id: 0 } as CompanySite),
          selectedRelTenant.tenant.id,
          filterValue,
          sortState,
        );
        return data;
      }
      return Promise.reject();
    },
    {
      enabled: !!companySite || (isOnOrder && !!companySiteId),
    },
  );

  const companySiteListQuery = useQuery(
    ["companySiteListPageableQuery", selectedRelTenant.tenant.id],
    async () => {
      if (Number(id)) {
        const { data } = await getCompanySiteListPageable(
          0,
          100000,
          selectedRelTenant.tenant.id,
          `company.id:${id}`,
        );
        return data.page.content;
      }
      return Promise.reject();
    },
    {
      onSuccess: data => {
        setValue("companySite", data?.[0]);
      },
    },
  );

  const columns: GridColDef[] = [
    {
      field: "item.name",
      headerName: t("item.name"),
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams) => row.item.name,
    },
    {
      field: "item.productCode",
      headerName: t("item.productCode"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.item.productCode,
      type: "stringContainsNumber",
    },
    {
      field: "item.type",
      headerName: t("item.type"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => t(`item.types.${row.item.type}`),
      type: "select",
    },
    {
      field: "item.serialItemNumber",
      headerName: t("item.serialItemNumber"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => {
        if (row?.item?.type === "SERIAL_NUMBER") {
          return row?.itemSerial?.factoryNumber;
        } else {
          return "";
        }
      },
      type: "select",
    },
    {
      field: "quantity",
      headerName: t("item.quantity"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        t("common:number", { num: row.quantity }) + " " + t(`item.unit.${row.item.productUnit}`),
      type: "stringContainsNumber",
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["ITEM_GET"]) && (
            <Tooltip title={t("issues.createIssue").toString()}>
              <IconButton
                size="small"
                color="primary"
                onClick={() => history.push(`/issues/all/issue-create`, row)}
                style={{ margin: "0 8px" }}
              >
                <AddBox />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["ITEM_GET"]) && (
            <Tooltip title={t("item.itemDetails").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                component={Link}
                to={`/companies/${
                  isAll ? "list-all" : isCustomer ? "list-customer" : "list-supplier"
                }/company-details/products/item-details?id=${row.item.id}&backId=${id}${
                  isAll ? `&isAll=${isAll}` : ""
                }${isCustomer ? `&isCustomer=${isCustomer}` : ""}`}
              >
                <Assignment />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions") {
      history.push(
        `/companies/${
          isAll ? "list-all" : isCustomer ? "list-customer" : "list-supplier"
        }/company-details/products/item-details?id=${params?.row?.item?.id}&backId=${id}${
          isAll ? `&isAll=${isAll}` : ""
        }${isCustomer ? `&isCustomer=${isCustomer}` : ""}`,
      );
    }
  }

  return (
    <Card style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
      {!isOnOrder && (
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ padding: 16, paddingBottom: 0 }}
        >
          <Controller
            control={control}
            name="companySite"
            defaultValue={null}
            rules={{
              required: t("validation.required").toString(),
            }}
            render={({ field, fieldState }) => (
              <Autocomplete
                style={{ maxWidth: 400 }}
                {...field}
                fullWidth
                onChange={(_, value) => field.onChange(value)}
                options={companySiteListQuery.data || []}
                getOptionLabel={(option: CompanySite) =>
                  option.name + ", " + displayAddress(option.address) || ""
                }
                getOptionSelected={option => option.id === field.value?.id}
                renderInput={params => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true, required: true }}
                    SelectProps={{ displayEmpty: true }}
                    label={t("monitoring.formValues.location")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />
          {!!companySite && (
            <Box>
              <Button onClick={() => setOpen("OUT")} style={{ marginRight: 8 }}>
                Árukihelyezés
              </Button>
              <Button onClick={() => setOpen("IN")}>Áruvisszavétel</Button>
              <TransactionDialog open={open} setOpen={setOpen} companySite={companySite} />
            </Box>
          )}
        </Box>
      )}
      <PageableTable
        transparent
        filterOptions={[
          {
            columnName: "item.type",
            options: ITEM_TYPES.map(value => {
              return {
                translated: t(`item.types.${value}`),
                value,
                subItem: SUB_ITEM_TYPE.includes(value),
              };
            }),
          },
        ]}
        sortState={sortState}
        setSortState={setSortState}
        onCellClick={onCellClick}
        sessionStorageKey="company-site-items"
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={itemListByCompanySiteQuerry}
      />
    </Card>
  );
};

export default RelItemCompanyList;
