import { lazy } from "react";
import MageroLogin from "views/Login/MageroLogin";
import MolEnergeticList from "views/MolEnergetic/MolEnergeticList";
import FastOrderCreate from "views/Order/FastOrderCreate/FastOrderCreate";
import FastOrderModify from "views/Order/FastOrderModify";

const ProjectTemplateDetailsTabs = lazy(
  () => import("views/ProjectTemplate/ProjectTemplateDetailsTabs"),
);
const ProjectTemplateCreate = lazy(
  () => import("views/Project/ProjectTemplate/ProjectTemplateCreate"),
);
const ProjectTemplateModify = lazy(
  () => import("views/Project/ProjectTemplate/ProjectTemplateModify"),
);
const ProjectTabs = lazy(() => import("views/Project/ProjectTabs"));
const Home = lazy(() => import("views/Home/Home"));
const OfferTabs = lazy(() => import("views/Offer/OfferTabs"));
const OfferCreate = lazy(() => import("views/Offer/OfferCreate"));
const OfferModify = lazy(() => import("views/Offer/OfferModify"));
const OfferCopy = lazy(() => import("views/Offer/OfferCopy"));
const OfferDetails = lazy(() => import("views/Offer/OfferDetails"));
const OfferHistoryDetails = lazy(() => import("views/Offer/components/OfferHistoryDetails"));
const ContractCreate = lazy(() => import("views/Contract/ContractCreate"));
const ContractDetailsTabs = lazy(
  () => import("views/Contract/ContractDetails/ContractDetailsTabs"),
);
const ContractModify = lazy(() => import("views/Contract/ContractModify"));
const OrderCreate = lazy(() => import("views/Order/OrderCreate"));
const OrderDetails = lazy(() => import("views/Order/OrderDetails"));
const OrderModify = lazy(() => import("views/Order/OrderModify"));
const OrderTabs = lazy(() => import("views/Order/OrderTabs"));
const SupplierOrderCreate = lazy(() => import("views/Order/SupplierOrderCreate"));
const SupplierOrderModify = lazy(() => import("views/Order/SupplierOrderModify"));
const ProjectCreate = lazy(() => import("views/Project/ProjectCreate"));
const ProjectDetailsTabs = lazy(() => import("views/Project/ProjectDetailsTabs"));
const ProjectModify = lazy(() => import("views/Project/ProjectModify"));
const MilestoneCreate = lazy(() => import("views/Milestone/MilestoneCreate"));
const MilestoneModify = lazy(() => import("views/Milestone/MilestoneModify"));
const MilestoneDetails = lazy(() => import("views/Milestone/MilestoneDetails"));
const MilestoneList = lazy(() => import("views/Milestone/MilestoneList"));
const PlanningTabs = lazy(() => import("views/Vehicle/Planning/PlanningTabs"));
const Login = lazy(() => import("views/Login/Login"));
const RoraLogin = lazy(() => import("views/Login/RoraLogin"));
const SignUpFinish = lazy(() => import("views/User/UserCreate/SignUpFinish"));
const ForgottenPassword = lazy(() => import("views/ForgottenPassword/ForgottenPassword"));
const Tos = lazy(() => import("views/Tos"));
const Gdpr = lazy(() => import("views/Gdpr"));
const CustomEmail = lazy(() => import("views/Email/CustomEmail"));
const MarketingUnsubscribe = lazy(() => import("views/MarketingUnsubscribe"));
const PdfTemplateForm = lazy(() => import("components/PdfTemplate/PdfTemplateForm"));
const ContractTabs = lazy(() => import("views/Contract/ContractTabs"));

export const ROUTES = [
  {
    path: "/login",
    component: !!window.location.hostname.includes("rajosszikviz")
      ? RoraLogin
      : !!window.location.hostname.includes("magero")
      ? MageroLogin
      : Login,
  },
  {
    path: "/user",
    routes: [
      {
        path: "/activate",
        component: SignUpFinish,
      },
    ],
  },
  {
    path: "/reset",
    component: ForgottenPassword,
  },
  {
    path: "/tos",
    component: Tos,
  },
  {
    path: "/gdpr",
    component: Gdpr,
  },
  {
    path: "/unsubscribe",
    component: MarketingUnsubscribe,
  },
];

export const BASIC_PRIVATE_ROUTES = [
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/energetic",
    component: MolEnergeticList,
  },
  {
    path: "/offer",
    component: OfferTabs,
    permissions: ["OFFER_GET", "OFFER_ADMIN"],
    routes: [
      {
        path: "/out",
        component: OfferTabs,
        permissions: ["OFFER_GET", "OFFER_ADMIN"],
        routes: [
          {
            path: "/create",
            component: OfferCreate,
            permissions: ["OFFER_ADMIN", "PROJECT_ADMIN"],
          },
          {
            path: "/modify",
            component: OfferModify,
            permissions: ["OFFER_ADMIN"],
          },
          {
            path: "/copy",
            component: OfferCopy,
            permissions: ["OFFER_ADMIN"],
          },
          {
            path: "/details",
            component: OfferDetails,
            permissions: ["OFFER_GET"],
            routes: [
              {
                path: "/history-details",
                component: OfferHistoryDetails,
                permissions: ["OFFER_GET", "ISSUE_GET"],
              },
            ],
          },
        ],
      },
      {
        path: "/in",
        component: OfferTabs,
        permissions: ["OFFER_GET", "OFFER_ADMIN"],
        routes: [
          {
            path: "/create",
            component: OfferCreate,
            permissions: ["OFFER_GET", "OFFER_ADMIN"],
          },
          {
            path: "/modify",
            component: OfferModify,
            permissions: ["OFFER_ADMIN"],
          },
          {
            path: "/copy",
            component: OfferCopy,
            permissions: ["OFFER_ADMIN"],
          },
          {
            path: "/details",
            component: OfferDetails,
            permissions: ["OFFER_GET"],
            routes: [
              {
                path: "/history-details",
                component: OfferHistoryDetails,
                permissions: ["OFFER_GET", "ISSUE_GET"],
              },
            ],
          },
        ],
      },
      {
        path: "/pdf-template",
        component: OfferTabs,
        permissions: ["PDF_TEMPLATE_ADMIN"],
        routes: [
          {
            path: "/create",
            component: PdfTemplateForm,
            permissions: ["PDF_TEMPLATE_ADMIN"],
          },
          {
            path: "/modify",
            component: PdfTemplateForm,
            permissions: ["PDF_TEMPLATE_ADMIN"],
          },
        ],
      },
    ],
  },
  {
    path: "/contract",
    component: ContractTabs,
    permissions: ["CONTRACT_GET"],
    routes: [
      {
        path: "/details",
        component: ContractDetailsTabs,
        permissions: ["CONTRACT_GET"],
      },
      {
        path: "/create",
        component: ContractCreate,
        permissions: ["CONTRACT_GET"],
      },
      {
        path: "/modify",
        component: ContractModify,
        permissions: ["CONTRACT_GET"],
      },
    ],
  },
  {
    path: "/contract-pdf-template",
    component: ContractTabs,
    permissions: ["PDF_TEMPLATE_ADMIN"],
    routes: [
      {
        path: "/create",
        component: PdfTemplateForm,
        permissions: ["PDF_TEMPLATE_ADMIN"],
      },
      {
        path: "/modify",
        component: PdfTemplateForm,
        permissions: ["PDF_TEMPLATE_ADMIN"],
      },
    ],
  },
  {
    path: "/order",
    component: OrderTabs,
    permissions: ["ORDER_ADMIN", "ORDER_GET"],
    routes: [
      {
        path: "/list",
        component: OrderTabs,
        permissions: ["ORDER_ADMIN", "ORDER_GET"],
        routes: [
          {
            path: "/create",
            component: OrderCreate,
            permissions: ["ORDER_ADMIN"],
          },
          {
            path: "/fast-create",
            component: FastOrderCreate,
            permissions: ["ORDER_ADMIN"],
          },
          {
            path: "/fast-modify",
            component: FastOrderModify,
            permissions: ["ORDER_ADMIN"],
          },
          {
            path: "/modify",
            component: OrderModify,
            permissions: ["ORDER_ADMIN"],
          },
          {
            path: "/details",
            component: OrderDetails,
            permissions: ["ORDER_ADMIN", "ORDER_GET"],
          },
        ],
      },
      {
        path: "/supplier-list",
        component: OrderTabs,
        permissions: ["ORDER_ADMIN"],
        routes: [
          {
            path: "/create",
            component: SupplierOrderCreate,
            permissions: ["ORDER_ADMIN"],
          },
          {
            path: "/modify",
            component: SupplierOrderModify,
            permissions: ["ORDER_ADMIN"],
          },
          {
            path: "/details",
            component: OrderDetails,
            permissions: ["ORDER_ADMIN"],
          },
        ],
      },
    ],
  },
  {
    path: "/project",
    component: ProjectTabs,
    permissions: ["PROJECT_ADMIN"],
    routes: [
      {
        path: "/details",
        component: ProjectDetailsTabs,
        permissions: ["PROJECT_ADMIN"],
        routes: [
          {
            path: "/data",
            component: ProjectDetailsTabs,
            permissions: ["PROJECT_ADMIN", "MILESTONE_GET"],
            routes: [
              {
                path: "/milestone-details",
                component: MilestoneDetails,
                permissions: ["PROJECT_ADMIN", "MILESTONE_GET"],
              },
              {
                path: "/milestone-create",
                component: MilestoneCreate,
                permissions: ["PROJECT_ADMIN", "MILESTONE_ADMIN"],
              },
            ],
          },
          {
            path: "/milestone",
            component: ProjectDetailsTabs,
            permissions: ["PROJECT_ADMIN", "MILESTONE_GET"],
            routes: [
              {
                path: "/create",
                component: MilestoneCreate,
                permissions: ["PROJECT_ADMIN", "MILESTONE_ADMIN"],
              },
              {
                path: "/modify",
                component: MilestoneModify,
                permissions: ["PROJECT_ADMIN", "MILESTONE_ADMIN"],
              },
              {
                path: "/details",
                component: MilestoneDetails,
                permissions: ["PROJECT_ADMIN", "MILESTONE_GET"],
              },
            ],
          },
        ],
      },
      {
        path: "/template",
        component: ProjectTabs,
        permissions: ["PROJECT_TEMPLATE_GET", "PROJECT_TEMPLATE_ADMIN"],
        routes: [
          {
            path: "/create",
            component: ProjectTemplateCreate,
            permissions: ["PROJECT_TEMPLATE_ADMIN"],
          },
          {
            path: "/modify",
            component: ProjectTemplateModify,
            permissions: ["PROJECT_TEMPLATE_ADMIN"],
          },
          {
            path: "/details",
            component: ProjectTemplateDetailsTabs,
            permissions: ["PROJECT_TEMPLATE_GET", "PROJECT_TEMPLATE_ADMIN"],
          },
          {
            path: "/gantt",
            component: ProjectTemplateDetailsTabs,
            permissions: ["PROJECT_TEMPLATE_GET", "PROJECT_TEMPLATE_ADMIN"],
          },
        ],
      },
      {
        path: "/create",
        component: ProjectCreate,
        permissions: ["PROJECT_ADMIN"],
      },
      {
        path: "/modify",
        component: ProjectModify,
        permissions: ["PROJECT_ADMIN"],
      },
    ],
  },
  {
    path: "/milestone",
    component: MilestoneList,
    permissions: ["MILESTONE_GET"],
    routes: [
      {
        path: "/details",
        component: MilestoneDetails,
        permissions: ["MILESTONE_GET"],
      },
      {
        path: "/create",
        component: MilestoneCreate,
        permissions: ["MILESTONE_GET", "MILESTONE_ADMIN"],
      },
      {
        path: "/modify",
        component: MilestoneModify,
        permissions: ["MILESTONE_GET", "MILESTONE_ADMIN"],
      },
    ],
  },
  {
    path: "/planning",
    component: PlanningTabs,
    permissions: ["VEHICLE_ADMIN", "VEHICLE_GET"],
  },
  {
    path: "/email",
    component: CustomEmail,
    permissions: ["EMAIL_GET", "EMAIL_ADMIN"],
  },
];
