import { faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, IconButton, Tooltip } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import SingleQueryTable from "components/PageableTable/SingleQueryTable";
import { RootState } from "config/store";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listCustomNotification } from "shared/network/custom-notification.api";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";

const CustomNotificationList = () => {
  const { t } = useTranslation();
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey("rap-custom-notification-page-number"),
      ) || JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-custom-notification-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });

  const listCustomNotificationQuery = useQuery(
    ["listCustomNotificationQuery", selectedRelTenant.tenant.id],
    async () => {
      const { data } = await listCustomNotification(selectedRelTenant.tenant.id);
      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "title",
      headerName: t("customNotification.title"),
      flex: 1,
    },
    {
      field: "eventType",
      headerName: t("customNotification.eventType"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        t(`customNotification.eventTypes.${row.eventType}`),
    },

    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%" alignItems="center">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "CURRENCY_ADMIN",
          ]) && (
            <Tooltip title={t("drawer./admin/email-templates/email-template-modify").toString()}>
              <IconButton
                component={Link}
                to={`/admin/email-templates/email-template-modify?id=${row.id}`}
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
              >
                <Edit color="primary" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <Box display="flex" justifyContent="center" pb={2}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, ["CURRENCY_ADMIN"]) && (
          <Button
            variant="outlined"
            size="small"
            startIcon={<FontAwesomeIcon icon={faEnvelopeOpenText} />}
            component={Link}
            to="/admin/email-templates/email-template-create"
          >
            {t("drawer./admin/email-templates/email-template-create")}
          </Button>
        )}
      </Box>
      <SingleQueryTable
        query={listCustomNotificationQuery}
        columns={columns}
        setTableState={setTableState}
        tableState={tableState}
        sessionStorageKey={`custom-notification`}
      />
    </Box>
  );
};
export default CustomNotificationList;
