import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
} from "@material-ui/core";
import { AttachFile, Check } from "@material-ui/icons";
import Loading from "components/Loading";
import queryClient from "config/query";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { hasAuthority } from "shared/authorization";
import { SliceStatus } from "shared/common";
import { createComment } from "shared/network/comment.api";

export type CommentFormValues = {
  tenantId: number;
  type: string;
  originalFileName: string;
  subjectType: string;
  subjectId: number;
  note: string;
  document: FileList;
  isReportVisibleByReporter?: boolean;
  isReportVisibleByCompany?: boolean;
};
type Props = {
  subjectType: "INVOICE_DOCUMENT" | "INCOMING_INVOICE_DOCUMENT";
  subjectId: number;
  refetch: () => void;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const DocumentUpload = ({ subjectType, subjectId, open, setOpen, refetch }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<CommentFormValues>();
  const [status, setStatus] = useState<SliceStatus>("idle");

  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const account = useSelector((state: RootState) => state.authentication?.account);

  const tenant = selectedRelTenant.tenant;
  const type = form.watch("type");

  const onSubmitCreate = async (values: CommentFormValues) => {
    setStatus("pending");

    try {
      if (subjectId && subjectType) {
        await createComment(
          tenant.id,
          "DOCUMENT",
          subjectType,
          subjectId.toString(),
          "invoice-document",
          values.document?.[0],
          selectedRelTenant?.tenant?.isUseReport ? values.isReportVisibleByReporter : false,
          selectedRelTenant?.tenant?.isUseReport &&
            hasAuthority(account.user, account.permissions, selectedRelTenant, ["REPORT_ADMIN"])
            ? values.isReportVisibleByCompany
            : true,
        );
        setStatus("success");
        setOpen(false);
        refetch();
        form.reset();
        queryClient.invalidateQueries("issueCommentList");
      }
    } catch (e: any) {
      if (e.data?.status === "FILE_NOT_FOUND") {
        enqueueSnackbar(t("common:notification.FILE_NOT_FOUND"), {
          variant: "error",
        });
      } else {
        enqueueSnackbar(
          t("common:notification.create.failure", {
            subject: t("comment.subject"),
          }),
          { variant: "error" },
        );
      }
      setStatus("failure");
    }
    setStatus("success");
  };

  return (
    <>
      <Loading open={status === "pending"} />
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs" fullWidth>
        <DialogTitle>{t("invoice.document.add")}</DialogTitle>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmitCreate)}>
            <DialogContent style={{ paddingTop: 0 }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                >
                  {form.watch("document")?.[0]?.name}
                  <Button
                    variant="text"
                    component="label"
                    htmlFor="noteFile"
                    startIcon={
                      !form.watch("document")?.length ? (
                        <AttachFile />
                      ) : (
                        <Check style={{ color: COLORS.green }} />
                      )
                    }
                  >
                    {t("personalData.formValues.chooseFile")}
                    <input
                      id="noteFile"
                      style={{ display: "none" }}
                      type="file"
                      {...form.register("document", {
                        required: {
                          value: type === "DOCUMENT",
                          message: t("personalData.formValues.fileRequired"),
                        },
                        validate: value => {
                          if (value?.[0]?.size >= 5242880) {
                            return t("validation.maxSize", {
                              size: 5,
                              unit: "MB",
                            }).toString();
                          }
                        },
                      })}
                    />
                  </Button>
                  {form?.formState?.errors?.document?.message ? (
                    <FormHelperText error>{form.formState.errors.document.message}</FormHelperText>
                  ) : (
                    "Maximum 5 MB"
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Box display="flex" justifyContent="center" gridGap={8}>
                <Button color="primary" variant="text" onClick={() => setOpen(false)}>
                  {t("common:button.cancel")}
                </Button>
                <Button type="submit" color="primary">
                  {t("common:button.save")}
                </Button>
              </Box>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};
export default DocumentUpload;
