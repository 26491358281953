import { Box, Container, Tab, Tabs } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useHeader } from "components/Layout/HeaderContext";
import PrivateRoute from "components/PrivateRoute";
import { RootState } from "config/store";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router";
import { Link, Switch } from "react-router-dom";
import { hasAnyAuthority } from "shared/authorization";
import { getCompanyById } from "shared/network/company.api";
import RelItemCompanyList from "views/Company/ItemCompany/RelItemCompanyList";
import DeliveryNoteList from "views/Delivery/DeliveryNote/DeliveryNoteList";
import IncomingInvoiceList from "views/Invoice/IncomingInvoice/IncomingInvoiceList";
import InvoiceList from "views/Invoice/InvoiceList";
import OfferList from "views/Offer/OfferList";
import SupplierPriceHistoryList from "views/SupplierPriceHistory/SupplierPriceHistoryList";
import CompanyDelivery from "../CompanyDelivery";
import CompanyDetails from "./CompanyDetails/CompanyDetails";

export const OWN_COMPANY_DETAILS_TABS = [
  `/own-company/company-details/details`,
  `/own-company/company-details/products`,
  `/own-company/company-details/invoices`,
  `/own-company/company-details/incoming-invoices`,
  `/own-company/company-details/delivery-notes`,
  `/own-company/company-details/delivery`,
  "/own-company/company-details/offers/in",
  "/own-company/company-details/offers/out",
  "/own-company/company-details/supplier-prices",
];

const CompanyDetailsTabs = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { setHeaderName } = useHeader();

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const companyId =
    account.user.relUserCompany?.length !== 0 ? account.user.relUserCompany?.[0].company.id : 0;

  const companyQuery = useQuery(["companyByIdQuery", companyId], async () => {
    if (companyId) {
      const { data } = await getCompanyById(
        Number.parseInt(companyId),
        selectedRelTenant.tenant.id,
      );
      return data.item;
    }
    return Promise.reject();
  });
  const companyIsSupplier = companyQuery.data?.isSupplier;
  const companyIsConsumer = companyQuery.data?.isCustomer;

  useEffect(() => {
    if (companyQuery.isFetching) {
      setHeaderName(<Skeleton width={200} />);
    } else if (companyQuery.data?.name) {
      setHeaderName(`${companyQuery.data.name} ${t("common:details.details")}`);
    }
    return () => {
      setHeaderName(null);
    };
  }, [companyQuery.data?.name, companyQuery.isFetching]);

  const { setTopPadding } = useHeader();
  useEffect(() => {
    setTopPadding(false);

    return () => {
      setTopPadding(true);
    };
  }, [setTopPadding]);

  return (
    <>
      {location.pathname === `/own-company/company-details` && (
        <Redirect to={`/own-company/company-details/details?id=${companyId}`} />
      )}

      {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
        "OFFER_ADMIN",
        "OFFER_GET",
      ]) &&
        companyIsSupplier &&
        location.pathname === "/own-company/company-details/offers" && (
          <Redirect to={`/own-company/company-details/offers/in?id=${companyId}`} />
        )}
      {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
        "OFFER_ADMIN",
        "OFFER_GET",
      ]) &&
        companyIsSupplier &&
        location.pathname === "/own-company/company-details/offers" && (
          <Redirect to={`/own-company/company-details/offers/in?id=${companyId}`} />
        )}
      {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
        "OFFER_ADMIN",
        "OFFER_GET",
      ]) &&
        companyIsSupplier &&
        location.pathname === "/own-company/company-details/offers" && (
          <Redirect to={`/own-company/company-details/offers/in?id=${companyId}`} />
        )}

      {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
        "OFFER_ADMIN",
        "OFFER_GET",
      ]) &&
        companyIsConsumer &&
        location.pathname === "/own-company/company-details/offers" && (
          <Redirect to={`/own-company/company-details/offers/out?id=${companyId}`} />
        )}
      {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
        "OFFER_ADMIN",
        "OFFER_GET",
      ]) &&
        companyIsConsumer &&
        location.pathname === "/own-company/company-details/offers" && (
          <Redirect to={`/own-company/company-details/offers/out?id=${companyId}`} />
        )}
      {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
        "OFFER_ADMIN",
        "OFFER_GET",
      ]) &&
        companyIsConsumer &&
        location.pathname === "/own-company/company-details/offers" && (
          <Redirect to={`/own-company/company-details/offers/out?id=${companyId}`} />
        )}
      <Tabs
        scrollButtons="auto"
        variant="scrollable"
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
        style={{ backgroundColor: "rgba(255, 255, 255, 0.6)", paddingLeft: 24, paddingRight: 24 }}
      >
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, ["COMPANY_GET"]) && (
          <Tab
            label={t("company.tabs.details")}
            component={Link}
            to={`${OWN_COMPANY_DETAILS_TABS[0]}?id=${companyId}`}
            value={OWN_COMPANY_DETAILS_TABS[0]}
          />
        )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "REl_ITEM_COMPANY_ADMIN",
          "COMPANY_GET",
        ]) && (
          <Tab
            label={t("relItemCompany.title")}
            component={Link}
            to={`${OWN_COMPANY_DETAILS_TABS[1]}?id=${companyId}`}
            value={OWN_COMPANY_DETAILS_TABS[1]}
          />
        )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "COMPANY_ADMIN",
          "INVOICE_GET",
        ]) &&
          companyIsConsumer && (
            <Tab
              label={t("invoice.tab")}
              component={Link}
              to={`${OWN_COMPANY_DETAILS_TABS[2]}?id=${companyId}`}
              value={OWN_COMPANY_DETAILS_TABS[2]}
            />
          )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "COMPANY_ADMIN",
          "INVOICE_GET",
        ]) &&
          companyIsSupplier && (
            <Tab
              label={t("incomingInvoice.tab")}
              component={Link}
              to={`${OWN_COMPANY_DETAILS_TABS[3]}?id=${companyId}`}
              value={OWN_COMPANY_DETAILS_TABS[3]}
            />
          )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "COMPANY_ADMIN",
          "DELIVERY_GET",
        ]) && (
          <Tab
            label={t("deliveryNote.deliveryNoteTitle")}
            component={Link}
            to={`${OWN_COMPANY_DETAILS_TABS[4]}?id=${companyId}`}
            value={OWN_COMPANY_DETAILS_TABS[4]}
          />
        )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "COMPANY_ADMIN",
          "DELIVERY_GET",
        ]) && (
          <Tab
            label={t("vehicle.tabs.delivery")}
            component={Link}
            to={`${OWN_COMPANY_DETAILS_TABS[5]}?id=${companyId}`}
            value={OWN_COMPANY_DETAILS_TABS[5]}
          />
        )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "OFFER_ADMIN",
          "OFFER_GET",
        ]) &&
          companyIsSupplier && (
            <Tab
              label={t("project.tabs.offerIn")}
              component={Link}
              to={`${OWN_COMPANY_DETAILS_TABS[6]}?id=${companyId}`}
              value={OWN_COMPANY_DETAILS_TABS[6]}
            />
          )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "OFFER_ADMIN",
          "OFFER_GET",
        ]) &&
          companyIsConsumer && (
            <Tab
              label={t("project.tabs.offerOut")}
              component={Link}
              to={`${OWN_COMPANY_DETAILS_TABS[7]}?id=${companyId}`}
              value={OWN_COMPANY_DETAILS_TABS[7]}
            />
          )}

        <Tab
          label={t("supplierPriceHistory.title")}
          component={Link}
          to={`${OWN_COMPANY_DETAILS_TABS[8]}?id=${companyId}`}
          value={OWN_COMPANY_DETAILS_TABS[8]}
        />
      </Tabs>
      <Container maxWidth="xl">
        <Box pt={2}>
          <Switch location={location}>
            <PrivateRoute
              path={OWN_COMPANY_DETAILS_TABS[0]}
              component={() => (
                <CompanyDetails
                  company={companyQuery.data || null}
                  loading={companyQuery.isFetching}
                  refetch={companyQuery.refetch}
                />
              )}
              permissions={["COMPANY_GET"]}
            />
            <PrivateRoute
              path={OWN_COMPANY_DETAILS_TABS[1]}
              component={RelItemCompanyList}
              permissions={["REL_ITEM_COMPANY_ADMIN", "COMPANY_GET"]}
            />
            <PrivateRoute
              path={OWN_COMPANY_DETAILS_TABS[2]}
              component={InvoiceList}
              permissions={["COMPANY_ADMIN", "INVOICE_GET"]}
            />
            <PrivateRoute
              path={OWN_COMPANY_DETAILS_TABS[3]}
              component={IncomingInvoiceList}
              permissions={["COMPANY_ADMIN", "INVOICE_GET"]}
            />
            <PrivateRoute
              path={OWN_COMPANY_DETAILS_TABS[4]}
              component={DeliveryNoteList}
              permissions={["COMPANY_ADMIN", "DELIVERY_GET"]}
            />
            <PrivateRoute
              path={OWN_COMPANY_DETAILS_TABS[5]}
              component={CompanyDelivery}
              permissions={["COMPANY_ADMIN", "DELIVERY_GET"]}
            />
            <PrivateRoute
              path={OWN_COMPANY_DETAILS_TABS[6]}
              component={OfferList}
              permissions={["OFFER_ADMIN", "OFFER_GET"]}
            />
            <PrivateRoute
              path={OWN_COMPANY_DETAILS_TABS[7]}
              component={OfferList}
              permissions={["OFFER_ADMIN", "OFFER_GET"]}
            />
            <PrivateRoute path={OWN_COMPANY_DETAILS_TABS[8]} component={SupplierPriceHistoryList} />
          </Switch>
        </Box>
      </Container>
    </>
  );
};

export default CompanyDetailsTabs;
