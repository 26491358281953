import { GridLocaleText } from "@mui/x-data-grid";

export const deDE: GridLocaleText = {
  // Root
  noRowsLabel: "Keine Daten vorhanden",
  noResultsOverlayLabel: "Keine Ergebnisse",
  errorOverlayDefaultLabel: "Fehler beim Abrufen der Daten.",

  // Density selector toolbar button text
  toolbarDensity: "Dichte",
  toolbarDensityLabel: "Dichte",
  toolbarDensityCompact: "Kompakt",
  toolbarDensityStandard: "Standard",
  toolbarDensityComfortable: "Komfortabel",

  // Columns selector toolbar button text
  toolbarColumns: "Spalten",
  toolbarColumnsLabel: "Spalten auswählen",

  // Filters toolbar button text
  toolbarFilters: "Filter",
  toolbarFiltersLabel: "Filter anzeigen",
  toolbarFiltersTooltipHide: "Filter ausblenden",
  toolbarFiltersTooltipShow: "Filter anzeigen",
  toolbarFiltersTooltipActive: count => `${count} aktive Filter`,

  // Export selector toolbar button text
  toolbarExport: "Exportieren",
  toolbarExportLabel: "Exportieren",
  toolbarExportCSV: "CSV herunterladen",

  // Columns panel text
  columnsPanelTextFieldLabel: "Suchen",
  columnsPanelTextFieldPlaceholder: "Spaltenname",
  columnsPanelDragIconLabel: "Sortieren",
  columnsPanelShowAllButton: "Alle anzeigen",
  columnsPanelHideAllButton: "Alle ausblenden",

  // Filter panel text
  filterPanelAddFilter: "Filter hinzufügen",
  filterPanelDeleteIconLabel: "Löschen",
  filterPanelOperators: "Operatoren",
  filterPanelOperatorAnd: "Und",
  filterPanelOperatorOr: "Oder",
  filterPanelColumns: "Spalten",
  filterPanelInputLabel: "Wert",
  filterPanelInputPlaceholder: "Wert",

  // Filter operators text
  filterOperatorContains: "enthält",
  filterOperatorEquals: "gleich",
  filterOperatorStartsWith: "beginnt mit",
  filterOperatorEndsWith: "endet mit",
  filterOperatorIs: "ist",
  filterOperatorNot: "nicht",
  filterOperatorAfter: "nach",
  filterOperatorOnOrAfter: "am oder nach",
  filterOperatorBefore: "vor",
  filterOperatorOnOrBefore: "am oder vor",
  filterOperatorIsEmpty: "ist leer",
  filterOperatorIsNotEmpty: "ist nicht leer",

  // Filter values text
  filterValueAny: "beliebig",
  filterValueTrue: "wahr",
  filterValueFalse: "falsch",

  // Column menu text
  columnMenuLabel: "Menü",
  columnMenuShowColumns: "Spalten anzeigen",
  columnMenuFilter: "Filtern",
  columnMenuHideColumn: "Ausblenden",
  columnMenuUnsort: "Nicht sortieren",
  columnMenuSortAsc: "Aufsteigend sortieren",
  columnMenuSortDesc: "Absteigend sortieren",

  // Column header text
  columnHeaderFiltersTooltipActive: count => `${count} aktive Filter`,
  columnHeaderFiltersLabel: "Filter anzeigen",
  columnHeaderSortIconLabel: "Sortieren",

  // Rows selected footer text
  footerRowSelected: count =>
    count !== 1
      ? `${count.toLocaleString()} Zeilen ausgewählt`
      : `${count.toLocaleString()} Zeile ausgewählt`,

  // Total rows footer text
  footerTotalRows: "Gesamtzahl Zeilen:",

  // Total visible rows footer text
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} / ${totalCount.toLocaleString()}`,

  // Checkbox selection text
  checkboxSelectionHeaderName: "Auswahl",

  // Boolean cell text
  booleanCellTrueLabel: "wahr",
  booleanCellFalseLabel: "falsch",

  // Used core components translation keys
  MuiTablePagination: {
    nextIconButtonText: "Weiter",
    backIconButtonText: "Zurück",
    labelRowsPerPage: "Zeilen pro Seite:",
    labelDisplayedRows: ({ from, to, count }) => {
      return `${from}-${to} von ${count !== -1 ? count : `mehr als ${to}`}`;
    },
  },
};
