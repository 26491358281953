import { Box, Button, Grid, TextField } from "@material-ui/core";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";
import FormCard from "components/FormCard";
import Loading from "components/Loading";
import { PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY } from "config/constants";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery } from "react-query";
import { useSelector } from "react-redux";
import { DailyReport, createDailyReport, modifyDailyReport } from "shared/network/daily-report.api";
import { listProject } from "shared/network/project.api";
import { Project } from "shared/types";
import { useDebouncedCallback } from "use-debounce";
import { ListboxComponent } from "views/Project/components/ProjectOfferAddDialog";

type Props = {
  dailyReport: DailyReport | null;
  setDailyReport: Dispatch<SetStateAction<DailyReport | null>>;
  setActiveStep: Dispatch<SetStateAction<number>>;
  isClosed: boolean;
};

const DailyReportCreatePage1 = ({
  dailyReport,
  setDailyReport,
  setActiveStep,
  isClosed,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const tenant = useSelector(
    (state: RootState) => state?.authentication?.selectedRelTenant?.tenant,
  );
  const { control, getValues, trigger } = useFormContext();
  const [itemSearch, setItemSearch] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const { data, fetchNextPage } = useInfiniteQuery(
    [`dailyReportProjects`, tenant.id, itemSearch],
    async ({ pageParam = 0 }) => {
      const { data } = await listProject(
        pageParam,
        20,
        tenant.id,
        itemSearch ? `;name:${itemSearch}` : "",
      );
      return data;
    },
    {
      getNextPageParam: lastGroup => {
        const nextPage = lastGroup.page.number + 1;
        if (nextPage <= lastGroup.page.totalPages - 1) {
          return nextPage;
        }
        return false;
      },
    },
  );

  const flatPages = data?.pages.map(page => page.page?.content).flat();
  const hasNextPage =
    data && flatPages && data?.pages?.[0]?.page?.totalElements > flatPages?.length;

  const handleItemSearchChange = useDebouncedCallback((value: string) => {
    setItemSearch(value);
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  async function handleNext() {
    setLoading(true);
    const result = await trigger();
    if (result) {
      try {
        if (!dailyReport) {
          const values = getValues();
          const { data } = await createDailyReport(
            {
              project: values.project,
              reportedDate: values.reportedDate,
            },
            tenant?.id,
          );
          setDailyReport(data?.item || null);
          enqueueSnackbar(t("dailyReport.saveSuccess"), {
            variant: "success",
          });
        }
      } catch {
        enqueueSnackbar(t("dailyReport.saveError"), {
          variant: "error",
        });
      }
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
    setLoading(false);
  }

  return (
    <Box>
      <Box maxWidth={400} margin="auto">
        <Loading open={loading} />
        <FormCard title={t("dailyReport.tab1")}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="reportedDate"
                rules={{
                  required: t("validation.required").toString(),
                }}
                defaultValue={new Date()}
                render={({ field, fieldState }) => (
                  <DatePicker
                    {...field}
                    ref={undefined}
                    disabled={!!dailyReport?.id}
                    variant="inline"
                    label={t("planning.date")}
                    format="yyyy. MM. dd."
                    InputLabelProps={{ shrink: true, required: true }}
                    autoOk
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="project"
                defaultValue={dailyReport?.reportedDate ? new Date(dailyReport.reportedDate) : null}
                rules={{ required: t("validation.required").toString() }}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value);
                      handleItemSearchChange("");
                    }}
                    onInputChange={(event, newInputValue) => {
                      handleItemSearchChange(newInputValue);
                    }}
                    disabled={!!dailyReport?.id}
                    options={flatPages || []}
                    getOptionLabel={(option: Project) => option.name}
                    getOptionSelected={option => option.id === field.value?.id}
                    ListboxComponent={props => (
                      <ListboxComponent
                        {...props}
                        hasNextPage={hasNextPage}
                        fetchNextPage={fetchNextPage}
                      />
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true, required: true }}
                        label={t("common:project")}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormCard>
        <Box display="flex" justifyContent="center" gridGap={8} p={1.5}>
          <Button startIcon={<ArrowBack />} variant="outlined" disabled>
            {t("common:button.prev")}
          </Button>
          <Button
            endIcon={<ArrowForward />}
            variant="contained"
            color="primary"
            onClick={handleNext}
          >
            {t("common:button.next")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DailyReportCreatePage1;
