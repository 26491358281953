import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Tooltip,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { AddBox, Edit, Room } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import clsx from "clsx";
import Loading from "components/Loading";
import SignatureCanvas from "components/SignatureCanvas";
import theme from "config/roraTheme";
import { RootState } from "config/store";
import supportedLocales from "config/supportedLocales";
import { format } from "date-fns";
import i18n from "i18n";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import useGeolocation from "react-hook-geolocation";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Food } from "shared/network/food.api";
import {
  ServingCheck,
  createServingCheck,
  getServingCheckPage,
  modifyServingCheck,
  servingSignature,
} from "shared/network/serving-check.api";
import HaccpServingModifyRow from "./components/HaccpServingModifyRow";
import HaccpServingNewRow from "./components/HaccpServingNewRow";

type DateFormValues = {
  date: Date;
};

export type HaccpServingFormValues = {
  food: Food | string;
  date: string;
  portion: number;
  qualified: string;
  arrivalTime: string;
  arrivalTemperature: number;
  servingStartTime: string;
  servingStartTemperature: number;
  servingMidTime: string;
  servingMidTemperature: number;
  servingEndTime: string;
  servingEndTemperature: number;
  foodReheatingTime: string;
  foodReheatingTemperature: number;
  modify: {
    food: Food | string;
    date: string;
    portion: number;
    qualified: string;
    arrivalTime: string;
    arrivalTemperature: number;
    servingStartTime: string;
    servingStartTemperature: number;
    servingMidTime: string;
    servingMidTemperature: number;
    servingEndTime: string;
    servingEndTemperature: number;
    foodReheatingTime: string;
    foodReheatingTemperature: number;
  };
};

export const useStyles = makeStyles({
  tg: {
    borderCollapse: "collapse",
    borderSpacing: 0,
  },
  td: {
    borderColor: "darkgrey",
    borderStyle: "solid",
    borderWidth: "1px",
    fontFamily: "Arial, sansSerif",
    fontSize: "14px",
    overflow: "hidden",
    padding: "4px",
    wordBreak: "normal",
    height: "100%",
  },
  th: {
    borderColor: "darkgrey",
    borderStyle: "solid",
    borderWidth: "1px",
    fontFamily: "Arial, sans-serif",
    fontSize: "14px",
    fontWeight: "bold",
    overflow: "hidden",
    padding: "4px",
    wordBreak: "normal",
  },
  pky: {
    borderColor: "inherit",

    verticalAlign: "center",
  },
  lax: {
    textAlign: "center",
  },
  datepicker: {
    "& .MuiIconButton-root": {
      padding: 0,
      width: 20,
    },
  },
});

const HaccpServingArriving = ({ isLocalServing }: { isLocalServing: boolean }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

  const tenant = useSelector((state: RootState) => state.authentication?.selectedRelTenant?.tenant);
  const user = useSelector((state: RootState) => state.authentication.account?.user);

  const dateForm = useForm<DateFormValues>();
  const { setValue } = dateForm;
  const form = useForm<HaccpServingFormValues>();
  const { handleSubmit } = form;
  const date = dateForm.watch("date");

  const [openNew, setOpenNew] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedServingCheck, setSelectedServingCheck] = useState<ServingCheck | null>(null);

  const geolocation = useGeolocation({
    enableHighAccuracy: true,
    maximumAge: 1500,
    timeout: 1200,
  });

  const getServingCheckPageQuery = useQuery(
    ["getServingCheckPageQuery", tenant.id, date, isLocalServing],
    async () => {
      const { data } = await getServingCheckPage(
        0,
        2000,
        tenant.id,
        `date=${format(date, "yyyy-MM-dd")};isLocalServing=${isLocalServing}`,
        "createdOn,asc",
      );
      return data?.page?.content || [];
    },
    { enabled: !!date },
  );

  const [signatureOpen, setSignatureOpen] = useState(false);

  async function signSubmit(values: HaccpServingFormValues) {
    setSignatureOpen(true);
  }

  const [signatureFile, setSignatureFile] = useState<File | null>(new File([], ""));

  async function onSubmit(values: HaccpServingFormValues) {
    setLoading(true);

    const signature = document.getElementById("signature") as HTMLCanvasElement;

    try {
      if (!selectedServingCheck) {
        if (signature) {
          const { data } = await createServingCheck(
            {
              ...values,
              date,
              food: typeof values.food === "string" ? { name: values.food } : values.food,
              isLocalServing,
              isQualified:
                values.qualified === "OK" ? true : values.qualified === "NOT_OK" ? false : null,
              arrivalSignerUser: user,
              arrivalLongitude: geolocation.longitude,
              arrivalLatitude: geolocation.latitude,
              servingStartSignerUser: values?.servingStartTime ? user : null,
              servingStartLongitude: values?.servingStartTime ? geolocation.longitude : null,
              servingStartLatitude: values?.servingStartTime ? geolocation.latitude : null,
              servingMidSignerUser: values?.servingMidTime ? user : null,
              servingMidLongitude: values?.servingMidTime ? geolocation.longitude : null,
              servingMidLatitude: values?.servingMidTime ? geolocation.latitude : null,
              servingEndSignerUser: values?.servingEndTime ? user : null,
              servingEndLongitude: values?.servingEndTime ? geolocation.longitude : null,
              servingEndLatitude: values?.servingEndTime ? geolocation.latitude : null,
              foodReheatingSignerUser: values?.foodReheatingTime ? user : null,
              foodReheatingLongitude: values?.foodReheatingTime ? geolocation.longitude : null,
              foodReheatingLatitude: values?.foodReheatingTime ? geolocation.latitude : null,
            },
            tenant?.id,
          );
          signature.toBlob(blob => {
            if (blob) {
              setSignatureFile(
                new File([blob], "signature.png", {
                  type: "image/png",
                }),
              );
            }
          });

          let types = [];
          if (values?.arrivalTime) {
            types.push("arrival");
          }
          if (values?.foodReheatingTime) {
            types.push("reheat");
          }
          if (signatureFile) {
            await servingSignature(data.item.id, tenant?.id.toString(), types, signatureFile);
          }
          setSignatureOpen(false);
          setSignatureFile(null);
        }
      } else {
        if (signature) {
          const { data } = await modifyServingCheck(
            {
              ...selectedServingCheck,
              ...values.modify,
              date,
              food:
                typeof values?.modify?.food === "string"
                  ? { name: values?.modify.food }
                  : values?.modify.food,
              isLocalServing,
              isQualified:
                values.qualified === "OK"
                  ? true
                  : values?.modify?.qualified === "NOT_OK"
                  ? false
                  : null,
              arrivalSignerUser: user,
              arrivalLongitude: geolocation.longitude,
              arrivalLatitude: geolocation.latitude,
              servingStartSignerUser: values?.modify.servingStartTime ? user : null,
              servingStartLongitude: values?.modify.servingStartTime ? geolocation.longitude : null,
              servingStartLatitude: values?.modify.servingStartTime ? geolocation.latitude : null,
              servingMidSignerUser: values?.modify.servingMidTime ? user : null,
              servingMidLongitude: values?.modify.servingMidTime ? geolocation.longitude : null,
              servingMidLatitude: values?.modify.servingMidTime ? geolocation.latitude : null,
              servingEndSignerUser: values?.modify.servingEndTime ? user : null,
              servingEndLongitude: values?.modify.servingEndTime ? geolocation.longitude : null,
              servingEndLatitude: values?.modify.servingEndTime ? geolocation.latitude : null,
              foodReheatingSignerUser: values?.modify.foodReheatingTime ? user : null,
              foodReheatingLongitude: values?.modify.foodReheatingTime
                ? geolocation.longitude
                : null,
              foodReheatingLatitude: values?.modify.foodReheatingTime ? geolocation.latitude : null,
            },
            tenant?.id,
          );
          signature.toBlob(blob => {
            if (blob) {
              setSignatureFile(
                new File([blob], "signature.png", {
                  type: "image/png",
                }),
              );
            }
          });
          let types = [];
          if (values?.modify?.arrivalTime) {
            types.push("arrival");
          }
          if (values?.modify?.foodReheatingTime) {
            types.push("reheat");
          }
          if (signatureFile) {
            await servingSignature(data.item.id, tenant?.id.toString(), types, signatureFile);
          }
        }
      }
      getServingCheckPageQuery.refetch();
      setOpenNew(false);
      setSelectedServingCheck(null);
      form.reset();
      setSignatureOpen(false);
      setSignatureFile(null);
      enqueueSnackbar(t("common:notification.save.success"), { variant: "success" });
    } catch {
      enqueueSnackbar(t("common:notification.save.failure"), { variant: "error" });
    }
    setLoading(false);
  }

  const handleShowLocation = (latitude: number, longitude: number) => {
    const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      latitude + "," + longitude,
    )}`;
    window.open(mapsUrl, "_blank");
  };

  useEffect(() => {
    setValue("date", new Date());
  }, [setValue]);

  const ref = useRef<HTMLDivElement | null>();
  const [offset, setOffset] = useState<{ top: number; left: number }>({ top: 0, left: 0 });

  return (
    <Card style={{ padding: 16, overflow: "auto" }}>
      <Dialog open={signatureOpen} onClose={() => setSignatureOpen(false)} disablePortal>
        <DialogContent
          ref={ref}
          onScroll={() => {
            setOffset({
              top: ref.current?.scrollTop || 0,
              left: ref.current?.scrollLeft || 0,
            });
          }}
        >
          <Grid item xs={12} container justifyContent="center">
            <SignatureCanvas id={"signature"} offsetTop={offset.top} offsetLeft={offset.left} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="center" gridGap={8}>
            <Button color="primary" variant="text" onClick={() => setSignatureOpen(false)}>
              {t("common:button.cancel")}
            </Button>
            <Button onClick={() => onSubmit(form.watch())} color="primary">
              {t("common:button.save")}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Loading open={getServingCheckPageQuery?.isFetching || loading} />{" "}
      <Box style={{ overflow: "auto", padding: 8 }}>
        <FormProvider {...dateForm}>
          <form>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
              style={{ paddingBottom: 16 }}
            >
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Controller
                  control={dateForm.control}
                  name="date"
                  defaultValue={new Date()}
                  render={({ field }) => (
                    <KeyboardDatePicker
                      {...field}
                      ref={undefined}
                      className="date"
                      variant="inline"
                      label={t("haccpItem.date")}
                      format="yyyy.MM.dd."
                      InputLabelProps={{ shrink: true }}
                      autoOk
                      views={["year", "month", "date"]}
                      openTo="date"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        <Box overflow="auto">
          <FormProvider {...form}>
            <form onSubmit={handleSubmit(signSubmit)}>
              <table className={classes.tg} style={{ margin: "auto" }}>
                <thead className={classes.th}>
                  <tr>
                    <th
                      className={clsx(classes.tg, classes.th, classes.pky)}
                      rowSpan={3}
                      style={{ minWidth: 160 }}
                    >
                      {t("haccpServing.foodName")}
                    </th>
                    <th
                      className={clsx(classes.tg, classes.th, classes.pky)}
                      rowSpan={3}
                      style={{ writingMode: "vertical-lr" }}
                    >
                      {t("haccpServing.portion")}
                    </th>
                    <th
                      className={clsx(classes.tg, classes.th, classes.pky)}
                      rowSpan={3}
                      style={{ writingMode: "vertical-lr" }}
                    >
                      {t("haccpServing.taste")}
                    </th>
                    <th
                      className={clsx(classes.tg, classes.th, classes.pky)}
                      colSpan={2}
                      rowSpan={2}
                    >
                      {isLocalServing ? t("haccpServing.done") : t("haccpServing.arrived")}
                    </th>
                    <th className={clsx(classes.tg, classes.th, classes.lax)} colSpan={6}>
                      {t("haccpServing.serveTemp")}
                    </th>
                    <th className={clsx(classes.tg, classes.th, classes.lax)} rowSpan={3}>
                      {t("haccpServing.sign")}
                    </th>
                    <th
                      className={clsx(classes.tg, classes.th, classes.lax)}
                      colSpan={3}
                      rowSpan={2}
                    >
                      {t("haccpServing.reheat")}
                    </th>
                  </tr>
                  <tr>
                    <th className={clsx(classes.tg, classes.th, classes.lax)} colSpan={2}>
                      {t("haccpServing.serveStart")}
                    </th>
                    <th className={clsx(classes.tg, classes.th, classes.lax)} colSpan={2}>
                      {t("haccpServing.serveMid")}
                    </th>
                    <th className={clsx(classes.tg, classes.th, classes.lax)} colSpan={2}>
                      {t("haccpServing.serveEnd")}
                    </th>
                  </tr>
                  <tr>
                    <th className={clsx(classes.tg, classes.th, classes.lax)}>
                      {t("haccpServing.hour")}
                      <br />
                      {t("haccpServing.min")}
                    </th>
                    <th className={clsx(classes.tg, classes.th, classes.lax)}>°C</th>
                    <th className={clsx(classes.tg, classes.th, classes.lax)}>
                      {t("haccpServing.hour")}
                      <br />
                      {t("haccpServing.min")}
                    </th>
                    <th className={clsx(classes.tg, classes.th, classes.lax)}>°C</th>
                    <th className={clsx(classes.tg, classes.th, classes.lax)}>
                      {t("haccpServing.hour")}
                      <br />
                      {t("haccpServing.min")}
                    </th>
                    <th className={clsx(classes.tg, classes.th, classes.lax)}>°C</th>
                    <th className={clsx(classes.tg, classes.th, classes.lax)}>
                      {t("haccpServing.hour")}
                      <br />
                      {t("haccpServing.min")}
                    </th>
                    <th className={clsx(classes.tg, classes.th, classes.lax)}>°C</th>
                    <th className={clsx(classes.tg, classes.th, classes.lax)}>
                      {t("haccpServing.hour")}
                      <br />
                      {t("haccpServing.min")}
                    </th>
                    <th className={clsx(classes.tg, classes.th, classes.lax)}>°C</th>
                    <th className={clsx(classes.tg, classes.th, classes.lax)}>
                      {t("haccpServing.sign")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {getServingCheckPageQuery?.data?.map((serving, index) => (
                    <>
                      {selectedServingCheck?.id !== serving?.id ? (
                        <tr key={index}>
                          <td className={clsx(classes.tg, classes.td)}>{serving.food?.name}</td>
                          <td className={clsx(classes.tg, classes.td, classes.lax)}>
                            {serving?.portion}
                          </td>
                          <td className={clsx(classes.tg, classes.td, classes.lax)}>
                            {serving?.isQualified ? t(`haccpServing.OK`) : t(`haccpServing.NOT_OK`)}
                          </td>
                          <td className={clsx(classes.tg, classes.td, classes.lax)}>
                            {!!serving?.arrivalTime &&
                              format(new Date(serving?.arrivalTime), "p", {
                                locale: supportedLocales[i18n.language],
                              })}
                          </td>
                          <td className={clsx(classes.tg, classes.td, classes.lax)}>
                            {serving?.arrivalTemperature}
                          </td>
                          <td className={clsx(classes.tg, classes.td, classes.lax)}>
                            {!!serving?.servingStartTime &&
                              format(new Date(serving?.servingStartTime), "p", {
                                locale: supportedLocales[i18n.language],
                              })}
                          </td>
                          <td className={clsx(classes.tg, classes.td, classes.lax)}>
                            {serving?.servingStartTemperature}
                          </td>
                          <td className={clsx(classes.tg, classes.td, classes.lax)}>
                            {!!serving?.servingMidTime &&
                              format(new Date(serving?.servingMidTime), "p", {
                                locale: supportedLocales[i18n.language],
                              })}
                          </td>
                          <td className={clsx(classes.tg, classes.td, classes.lax)}>
                            {serving?.servingMidTemperature}
                          </td>
                          <td className={clsx(classes.tg, classes.td, classes.lax)}>
                            {!!serving?.servingEndTime &&
                              format(new Date(serving?.servingEndTime), "p", {
                                locale: supportedLocales[i18n.language],
                              })}
                          </td>
                          <td className={clsx(classes.tg, classes.td, classes.lax)}>
                            {serving?.servingEndTemperature}
                          </td>
                          <td className={clsx(classes.tg, classes.td)}>
                            <Box display="flex" alignItems="center" flexWrap="nowrap" gridGap={4}>
                              <div>{serving?.arrivalSignerUser?.name}</div>
                              {serving?.arrivalLatitude && serving?.arrivalLongitude && (
                                <div>
                                  <Tooltip title={t("haccpServing.location")}>
                                    <IconButton
                                      size="small"
                                      color="primary"
                                      onClick={() =>
                                        handleShowLocation(
                                          serving?.arrivalLatitude,
                                          serving?.arrivalLongitude,
                                        )
                                      }
                                    >
                                      <Room fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              )}
                            </Box>
                          </td>
                          <td className={clsx(classes.tg, classes.td, classes.lax)}>
                            {!!serving?.foodReheatingTime &&
                              format(new Date(serving?.foodReheatingTime), "p", {
                                locale: supportedLocales[i18n.language],
                              })}
                          </td>
                          <td className={clsx(classes.tg, classes.td, classes.lax)}>
                            {serving?.foodReheatingTemperature}
                          </td>
                          <td className={clsx(classes.tg, classes.td, classes.lax)}>
                            <Box display="flex" alignItems="center" flexWrap="nowrap" gridGap={4}>
                              <div>{serving?.foodReheatingSignerUser?.name}</div>
                              {serving?.foodReheatingLatitude &&
                                serving?.foodReheatingLongitude && (
                                  <div>
                                    <Tooltip title={t("haccpServing.location")}>
                                      <IconButton
                                        size="small"
                                        color="primary"
                                        onClick={() =>
                                          handleShowLocation(
                                            serving?.foodReheatingLatitude,
                                            serving?.foodReheatingLongitude,
                                          )
                                        }
                                      >
                                        <Room fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                )}
                            </Box>
                          </td>
                          <td className={clsx(classes.tg, classes.td, classes.lax)}>
                            <Button
                              size="small"
                              startIcon={<Edit />}
                              disabled={!!selectedServingCheck || openNew}
                              onClick={() => {
                                setSelectedServingCheck(serving);
                                setOpenNew(false);
                              }}
                            >
                              {t("common:button.modify")}
                            </Button>
                          </td>
                        </tr>
                      ) : (
                        <HaccpServingModifyRow
                          selectedServingCheck={selectedServingCheck}
                          setSelectedServingCheck={setSelectedServingCheck}
                        />
                      )}
                    </>
                  ))}
                  {openNew ? (
                    <HaccpServingNewRow setOpenNew={setOpenNew} />
                  ) : (
                    <tr>
                      <td
                        className={clsx(classes.tg, classes.td, classes.lax)}
                        colSpan={16}
                        style={{ textAlign: matchesSm ? "left" : "center" }}
                      >
                        <Button
                          size="small"
                          style={{ margin: 4 }}
                          startIcon={<AddBox />}
                          onClick={() => {
                            setOpenNew(true);
                            setSelectedServingCheck(null);
                          }}
                        >
                          {t("common:button.add")}
                        </Button>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </form>
          </FormProvider>
        </Box>
      </Box>
    </Card>
  );
};

export default HaccpServingArriving;
