import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Loading from "components/Loading";
import PasswordTextField from "components/PasswordTextField";
import { RootState } from "config/store";
import { COLORS, GRADIENT } from "config/theme";
import { trim } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { getSystemProperties } from "shared/network/properties.api";
import { login, loginFailure } from "shared/reducers/authentication";
import { getPageName } from "shared/util/getPageName";
import { Link } from "react-router-dom";
import AuthenticatorDialog from "./AuthenticatorDialog";
import { useState } from "react";
import { checkAccount, userLogin } from "shared/network/user.api";

type LoginForm = {
  email: string;
  password: string;
  rememberMe: boolean;
};

export type LocationState = {
  from: {
    pathname: string;
    search: any;
  };
};

type Props = RouteComponentProps<undefined, any, LocationState>;

const useStyles = makeStyles(
  {
    root: {
      display: "flex",
      justifyContent: "center",
      color: COLORS.white,
      background: GRADIENT,
    },
  },
  {
    name: "Login",
  },
);

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { status, isAuthenticated, error, account } = useSelector(
    (state: RootState) => state.authentication,
  );
  const { control, handleSubmit, watch } = useForm<LoginForm>();
  const [validateLoading, setValidateLoading] = useState(false);
  const [authenticatorOpen, setAuthenticatorOpen] = useState(false);
  const [authToken, setAuthToken] = useState<string | null>(null);

  const query = useQuery("version", async () => {
    const { data } = await getSystemProperties();
    return data.items;
  });
  const version = query.data?.find(property => property.name === "version")?.value;

  async function onSubmit(values: LoginForm) {
    try {
      setValidateLoading(true);
      const { data } = await userLogin(values.email, values.password, values.rememberMe);
      setAuthToken(data.item);
      const accountResponse = await checkAccount(data.item);
      if (accountResponse?.data?.user?.googleAuthenticatorEnabled) {
        setAuthenticatorOpen(true);
      } else {
        dispatch(login(trim(values.email), values.password, values.rememberMe));
      }
      setValidateLoading(false);
    } catch (e) {
      setValidateLoading(false);
      const error = e as any;
      const responseCode = error?.status || (error?.response?.status ?? 0);

      if (responseCode === 0) {
        dispatch(loginFailure("hostNotFound"));
        return;
      }
      if (responseCode === 401) {
        dispatch(loginFailure("notActivated"));
        return;
      }
      if (responseCode === 403) {
        dispatch(loginFailure("invalidCredentials"));
        return;
      }
      dispatch(loginFailure("failure"));
    }
  }

  if (status === "success" && isAuthenticated) {
    const localVersion = localStorage.getItem("version");
    if ((localVersion && localVersion !== version) || !localVersion) {
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem("version", version || "");
    }
    /*if (location?.state?.from && location?.state?.from?.pathname !== "/") {
      return <Redirect to={location.state.from} />;
    } else*/ if (account.user.homePage) {
      return <Redirect to={{ pathname: account.user.homePage }} />;
    } else {
      return <Redirect to={{ pathname: "/" }} />;
    }
  }

  return (
    <Box className={classes.root}>
      <Loading open={status === "pending" || validateLoading} />
      {authenticatorOpen && (
        <AuthenticatorDialog
          open={authenticatorOpen}
          onClose={() => setAuthenticatorOpen(false)}
          loginValues={watch()}
          authToken={authToken}
        />
      )}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-around"
        height="100vh"
        maxWidth={500}
      >
        <Typography variant="h1">{getPageName(window.location.hostname)}</Typography>
        <Card
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          elevation={10}
          style={{ borderRadius: 10 }}
        >
          <CardHeader title={t("login.title")} />
          <CardContent style={{ paddingTop: 0 }}>
            <Grid container spacing={2}>
              {status === "failure" && error && (
                <Grid item xs={12}>
                  <Alert severity="error" style={{ borderRadius: 10 }}>
                    {t([`login.${error}`, "login.failure"])}
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="email"
                  defaultValue=""
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("fields.email")}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="password"
                  defaultValue=""
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <PasswordTextField
                      {...field}
                      ref={undefined}
                      label={t("fields.password")}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="rememberMe"
                  control={control}
                  defaultValue={false}
                  render={({ field: { onChange, value, ref } }) => (
                    <FormControlLabel
                      label={t("login.rememberMe")}
                      labelPlacement="end"
                      control={
                        <Checkbox
                          onChange={onChange}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Link to="/reset">{t("login.forgottenPassword")}</Link>
                  <Button variant="contained" type="submit" color="primary" style={{ height: 35 }}>
                    {t("login.title")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography style={{ fontSize: 12, fontWeight: 300 }}>
            {t("login.supportedBrowsers")}: Mozilla Firefox, Microsoft Edge, Google Chrome
          </Typography>
          <Typography style={{ fontSize: 12 }}>
            ©&nbsp;
            {new Date().getFullYear() === 2021
              ? new Date().getFullYear()
              : `2021 - ${new Date().getFullYear()}`}
            &nbsp;
            <a
              style={{ color: COLORS.white, fontWeight: 300 }}
              href="https://promera.hu"
              target="_blank"
              rel="noopener noreferrer"
            >
              Promera Menedzsment Kft.
            </a>
            &nbsp;
            {!!version && t("login.version", { version: version })}
            {t("login.allRightsReserved")}
          </Typography>

          <Box style={{ textAlign: "center" }}>
            <Typography
              component={Link}
              to="/tos"
              style={{
                textAlign: "center",
                color: COLORS.white,
                textDecoration: "none",
                fontSize: 12,
              }}
            >
              {t("login.tos")}
            </Typography>
            {t("comma")}
            {t("space")}
            <Typography
              component={Link}
              to="/gdpr"
              style={{
                textAlign: "center",
                color: COLORS.white,
                textDecoration: "none",
                fontSize: 12,
              }}
            >
              {t("login.gdpr")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
