import {
  Box,
  Button,
  Collapse,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddBox, Check, Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { SliceStatus } from "shared/common";
import { createItemCategory, listItemCategory } from "shared/network/category.api";
import ItemCategoryRow from "./components/ItemCategoryRow";

type FormValues = {
  name: string;
  parentId: number;
};
type Props = {
  isLoading?: boolean;
};

const useStyles = makeStyles({
  createButtonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "16px",
  },
  createButton: {
    height: 36,
  },
});

const ItemCategories = ({ isLoading }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [openId, setOpenId] = useState<number | null>(null);
  const [status, setStatus] = useState<SliceStatus>("idle");

  const form = useForm<FormValues>();

  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const categoriesQuery = useQuery(["categoriesQuery", tenant.id], async () => {
    const { data } = await listItemCategory(tenant.id);
    return data.items?.sort((a, b) => a?.name?.localeCompare(b?.name));
  });
  const categories = categoriesQuery.data;

  const onSubmitCreate = async (values: FormValues) => {
    try {
      setStatus("pending");
      await createItemCategory(tenant.id, values);
      setStatus("success");
      categoriesQuery.refetch();
      setOpenId(null);
      enqueueSnackbar(
        t("common:notification.create.success", {
          subject: t("item.category.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch {
      enqueueSnackbar(
        t("common:notification.create.failure", {
          subject: t("item.category.subject"),
        }),
        {
          variant: "error",
        },
      );

      setStatus("failure");
    }
  };

  return (
    <Container maxWidth="md">
      <Loading open={categoriesQuery.isFetching || status === "pending"} />
      {categoriesQuery.isError ? (
        <Box style={{ margin: "20px auto", textAlign: "center" }}>
          <Typography
            variant="h5"
            align="center"
            color="secondary"
            style={{ marginBottom: "20px" }}
          >
            {t("common:errorWhileFetching")}
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddBox />}
            className={classes.createButton}
            onClick={() => setOpenId(prevState => (prevState === null ? 0 : null))}
          >
            {t("item.category.create")}
          </Button>
        </Box>
      ) : (
        <>
          {categoriesQuery.data?.length === 0 ? (
            <>
              <Box style={{ marginBottom: "20px", textAlign: "center" }}>
                <Typography
                  variant="h5"
                  align="center"
                  color="secondary"
                  style={{ marginBottom: "20px" }}
                >
                  {t("common:noData")}
                </Typography>
                <Button
                  variant="contained"
                  startIcon={<AddBox />}
                  className={classes.createButton}
                  onClick={() => setOpenId(prevState => (prevState === null ? 0 : null))}
                >
                  {t("item.category.create")}
                </Button>
              </Box>
              <Collapse in={openId === 0} style={{ width: "100%" }}>
                <form onSubmit={form.handleSubmit(onSubmitCreate)}>
                  <Box pt={2} maxWidth={450} display="flex" margin="auto">
                    <Controller
                      control={form.control}
                      name="name"
                      defaultValue=""
                      rules={{
                        required: t("validation.required").toString(),
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label={t("item.name")}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          InputLabelProps={{ shrink: true, required: true }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title={t("common:button.create").toString()}>
                                  <IconButton type="submit">
                                    <Check color="primary" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title={t("common:button.cancel").toString()}>
                                  <IconButton onClick={() => setOpenId(null)}>
                                    <Close color="primary" />
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Box>
                </form>
              </Collapse>
            </>
          ) : (
            <>
              <Box className={classes.createButtonContainer}>
                <Collapse in={openId !== 0} style={{ width: "100%", textAlign: "center" }}>
                  <Button
                    variant="contained"
                    startIcon={<AddBox />}
                    className={classes.createButton}
                    onClick={() => setOpenId(prevState => (prevState === null ? 0 : null))}
                  >
                    {t("item.category.create")}
                  </Button>
                </Collapse>
                <Collapse in={openId === 0} style={{ width: "100%" }}>
                  <form onSubmit={form.handleSubmit(onSubmitCreate)}>
                    <Box pt={2} maxWidth={450} display="flex" margin="auto">
                      <Controller
                        control={form.control}
                        name="name"
                        defaultValue=""
                        rules={{ required: t("validation.required").toString() }}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            label={t("item.name")}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true, required: true }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Tooltip title={t("common:button.create").toString()}>
                                    <IconButton type="submit" disabled={isLoading}>
                                      <Check color="primary" />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title={t("common:button.cancel").toString()}>
                                    <IconButton onClick={() => setOpenId(null)}>
                                      <Close color="primary" />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Box>
                  </form>
                </Collapse>
              </Box>
              {categories?.length &&
                categories.map(category => (
                  <ItemCategoryRow
                    key={category.id}
                    category={category}
                    refetch={categoriesQuery.refetch}
                  />
                ))}
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default ItemCategories;
