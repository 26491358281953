import { InputAdornment, TextField, TextFieldProps } from "@material-ui/core";
import { ControllerFieldState, ControllerRenderProps } from "react-hook-form";
import { useTranslation } from "react-i18next";

type Props = {
  field?: ControllerRenderProps<any, any>;
  fieldState?: ControllerFieldState;
  numberLimits?: NumberFieldLimits;
  unit?: string;
} & TextFieldProps;

export type NumberFieldLimits = {
  step?: string;
  min?: string;
  max?: string;
};

const NumberField = ({
  field,
  fieldState,
  disabled,
  label,
  InputLabelProps,
  numberLimits,
  unit,
  defaultValue,
  onChange,
  error,
  helperText,
  value,
  style,
  ...rest
}: Props) => {
  const { i18n } = useTranslation();

  return (
    <TextField
      {...rest}
      {...field}
      style={style}
      type="number"
      lang={i18n.language}
      inputProps={{
        ...rest.inputProps,
        step: numberLimits?.step || undefined,
        min: numberLimits?.min || undefined,
        max: numberLimits?.max || undefined,
      }}
      InputProps={{
        endAdornment: !!unit && <InputAdornment position="end">{unit}</InputAdornment>,
        ...rest.InputProps,
      }}
      disabled={disabled}
      defaultValue={defaultValue}
      label={label}
      InputLabelProps={InputLabelProps}
      error={error || !!fieldState?.error || undefined}
      helperText={helperText || fieldState?.error?.message || undefined}
      onChange={onChange || field?.onChange}
      onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
    />
  );
};

export default NumberField;
