import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Card, IconButton, Tooltip, Typography } from "@material-ui/core";
import { Check, Close, Edit } from "@material-ui/icons";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import ConfirmationButton from "components/ConfirmationButton";
import Loading from "components/Loading";
import PageableTable from "components/PageableTable/PageableTable";
import queryClient from "config/query";
import { RootState } from "config/store";
import supportedLocales from "config/supportedLocales";
import { COLORS } from "config/theme";
import { format } from "date-fns";
import i18n from "i18n";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { hasAuthority } from "shared/authorization";
import { listReminderPages, Reminder, updateStatus } from "shared/network/reminder.api";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";
import ReminderModifyDialog from "views/Reminder/ReminderModifyDialog";
import ReminderCreateDialog from "./ReminderCreateDialog";
import { getCompanyById } from "shared/network/company.api";
import { getProjectById } from "shared/network/project.api";

type Props = {
  needTitle: boolean;
  companyId?: number;
  projectId?: number;
};

const ReminderList = ({ needTitle, companyId, projectId }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const account = useSelector((state: RootState) => state.authentication?.account);
  const selectedRelTenant = useSelector(
    (state: RootState) => state.authentication?.selectedRelTenant,
  );

  const tenant = selectedRelTenant?.tenant;
  const isTenantAdmin = selectedRelTenant?.isTenantAdmin;
  const user = account?.user;

  const isSuperAdmin = user?.isSuperAdmin;

  const [loading, setLoading] = useState(false);
  const [selectedReminder, setSelectedReminder] = useState<Reminder | null>(null);
  const [reminderOpen, setReminderOpen] = useState(false);
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-reminder-list-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-reminder-list-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;
  const [sortState, setSortState] = useState("when,asc");

  const reminderList = useQuery(
    ["reminderList", page, pageSize, user?.id, tenant?.id, filterValue, sortState],
    async () => {
      if (tenant?.id) {
        const { data } = await listReminderPages(
          page,
          pageSize,
          tenant?.id,
          (!!companyId
            ? `company.id=${companyId};`
            : projectId
            ? `project.id=${projectId};`
            : getUserFilter()) + filterValue,
          sortState,
        );
        return data;
      }
      return Promise.reject();
    },
  );

  function getUserFilter() {
    if (isTenantAdmin || isSuperAdmin) {
      return "";
    } else {
      return `user.id=${user?.id};`;
    }
  }

  const columns: GridColDef[] = [
    {
      field: "when",
      headerName: t("reminder.when"),
      flex: 2,
      type: "dateTime",
      renderCell: ({ row }: GridValueGetterParams) => (
        <Tooltip
          title={
            format(new Date(row?.when), "Pp", {
              locale: supportedLocales[i18n.language],
            }) || "-"
          }
        >
          <span>
            {format(new Date(row?.when), "Pp", {
              locale: supportedLocales[i18n.language],
            }) || "-"}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "user.name",
      headerName: t("reminder.userName"),
      flex: 2,
      type: "stringContainsNumber",
      renderCell: ({ row }: GridValueGetterParams) => (
        <Tooltip title={row?.user?.name || "-"}>
          <span>{row?.user?.name || "-"}</span>
        </Tooltip>
      ),
    },
    {
      field: "company.name",
      headerName: t("reminder.companyName"),
      flex: 2,
      type: "stringContainsNumber",
      renderCell: ({ row }: GridValueGetterParams) => (
        <Tooltip title={row?.company?.name || "-"}>
          <span>{row?.company?.name || "-"}</span>
        </Tooltip>
      ),
    },
    {
      field: "issue.id",
      headerName: t("reminder.issueId"),
      flex: 1,

      renderCell: ({ row }: GridValueGetterParams) => (
        <Tooltip title={row?.issue?.id || "-"}>
          <span>{row?.issue?.id || "-"}</span>
        </Tooltip>
      ),
    },
    {
      field: "status",
      headerName: t("reminder.status"),
      hide: true,
      type: "select",
    },
    {
      field: "reminderMessage",
      headerName: t("reminder.reminderMessage"),
      flex: 3,
      type: "stringContainsNumber",
      renderCell: ({ row }: GridValueGetterParams) => {
        var html = row?.reminderMessage?.replace(/<\/p>/gi, " ");
        var div = document.createElement("div");
        div.innerHTML = html;
        var text = div.textContent || div.innerText || "";

        return (
          <Tooltip title={<div dangerouslySetInnerHTML={{ __html: row?.reminderMessage }} />}>
            <span>{row?.reminderMessage ? text : "-"}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "actions",
      headerName: " ",
      width: 180,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" alignItems="center" width="100%">
          {row.status === "ACTIVE" && (
            <Tooltip title={t("reminder.statusDoneTitle").toString()}>
              <span>
                <ConfirmationButton
                  variant="text"
                  size="small"
                  color="primary"
                  buttonType="ICON"
                  title={t("reminder.statusDoneTitle").toString()}
                  body={t("reminder.statusDoneBody").toString()}
                  onSubmit={async () => statusModify(row?.id, "DONE")}
                  style={{ color: COLORS.green }}
                >
                  <Check />
                </ConfirmationButton>
              </span>
            </Tooltip>
          )}
          {row.status === "ACTIVE" && (
            <Tooltip title={t("reminder.statusRemoveTitle").toString()}>
              <span>
                <ConfirmationButton
                  variant="text"
                  size="small"
                  color="primary"
                  buttonType="ICON"
                  title={t("reminder.statusRemoveTitle").toString()}
                  body={t("reminder.statusRemoveBody").toString()}
                  onSubmit={async () => statusModify(row?.id, "REMOVED")}
                  style={{ color: COLORS.red }}
                >
                  <Close />
                </ConfirmationButton>
              </span>
            </Tooltip>
          )}
          {row.status === "REMOVED" && (
            <Tooltip title={t("reminder.REMOVED").toString()}>
              <Close style={{ color: COLORS.lightGrey, margin: 4 }} />
            </Tooltip>
          )}
          {row.status === "DONE" && (
            <Tooltip title={t("reminder.DONE").toString()}>
              <Check style={{ color: COLORS.lightGrey, margin: 4 }} />
            </Tooltip>
          )}
          {hasAuthority(account?.user, account?.permissions, selectedRelTenant, [
            "EMPLOYEE_ADMIN",
          ]) && (
            <Box>
              <Tooltip title={t("common:button.modify").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                  onClick={() => {
                    setSelectedReminder(row as Reminder);
                  }}
                >
                  <Edit color="primary" />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
      ),
    },
  ];

  async function statusModify(id: number, status: "DONE" | "REMOVED") {
    setLoading(true);
    try {
      await updateStatus(id, status, tenant?.id);
      queryClient.refetchQueries("reminderList");
      setSelectedReminder(null);
      enqueueSnackbar(t("common:notification.save.success"), { variant: "success" });
    } catch {
      enqueueSnackbar(t("common:notification.save.failure"), { variant: "error" });
    }
    setLoading(false);
  }

  const companyQuery = useQuery(
    ["companyByIdQuery", companyId],
    async () => {
      if (companyId) {
        const { data } = await getCompanyById(companyId, tenant?.id);
        return data.item;
      }
      return Promise.reject();
    },
    { enabled: !!companyId },
  );

  const projectByIdQuery = useQuery(
    ["projectByIdQuery", tenant?.id, projectId],
    async () => {
      if (projectId) {
        const { data } = await getProjectById(tenant?.id, projectId);
        return data.item;
      }
      return Promise.reject();
    },
    { enabled: !!projectId },
  );

  function getDefaultFilters(columns: GridColDef[]) {
    const statusColumn = columns?.find(column => column.field === "status");
    if (statusColumn) {
      return [
        {
          column: statusColumn,
          isNegate: false,
          value: "ACTIVE",
        },
      ];
    } else {
      return [];
    }
  }

  return (
    <Box>
      {(companyQuery?.data || projectByIdQuery?.data) &&
        hasAuthority(account?.user, account?.permissions, selectedRelTenant, [
          "EMPLOYEE_ADMIN",
        ]) && (
          <Box display="flex" justifyContent="center">
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => setReminderOpen(true)}
              startIcon={<FontAwesomeIcon icon={faBell} width={14} />}
              style={{ marginBottom: 8 }}
            >
              {t("reminder.create")}
            </Button>
            <ReminderCreateDialog
              open={reminderOpen}
              setOpen={setReminderOpen}
              company={companyQuery?.data || undefined}
              project={projectByIdQuery?.data || undefined}
            />
          </Box>
        )}
      <Card style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
        <Loading open={loading} />
        {selectedReminder && (
          <ReminderModifyDialog
            selectedReminder={selectedReminder}
            setSelectedReminder={setSelectedReminder}
          />
        )}
        {!!needTitle && (
          <Box style={{ margin: 10 }}>
            <Typography variant="h2">{t("reminder.reminders")}</Typography>
          </Box>
        )}
        <PageableTable
          filterOptions={[
            {
              columnName: "status",
              options: ["ACTIVE", "DONE", "REMOVED"].map(value => {
                return {
                  translated: t(`reminder.${value}`),
                  value,
                };
              }),
            },
          ]}
          defaultFilters={getDefaultFilters(columns)}
          sortState={sortState}
          setSortState={setSortState}
          sessionStorageKey="warehouse"
          tableState={tableState}
          setTableState={setTableState}
          columns={columns}
          query={reminderList}
          minWidth={{ value: "md" }}
          filterable={
            isTenantAdmin || isSuperAdmin
              ? undefined
              : ["when", "company.name", "issue.id", "reminderMessage"]
          }
          transparent
          // noSearch
        />
      </Card>
    </Box>
  );
};

export default ReminderList;
