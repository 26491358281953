import {
  Box,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import ConfirmationButton from "components/ConfirmationButton";
import { RootState } from "config/store";
import supportedLocales from "config/supportedLocales";
import { COLORS } from "config/theme";
import { format, isSameDay } from "date-fns";
import i18n from "i18n";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getIssueById } from "shared/network/issues.api";
import { deleteTimeEntry } from "shared/network/time-entry.api";
import { Issue } from "shared/types";

type Props = {
  issue?: Issue;
  openTime: boolean;
  setOpenTime: Dispatch<SetStateAction<boolean>>;
  reportedDate?: string;
  isClosed: boolean;
};

const DailyReportCreateIssueTimeEntry = ({
  issue,
  openTime,
  setOpenTime,
  reportedDate,
  isClosed,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const tenant = useSelector((state: RootState) => state?.authentication?.selectedRelTenant);

  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));

  const getIssueByIdQuery = useQuery(
    [`getIssueByIdQuery`, issue?.id, tenant.id],
    async () => {
      if (issue?.id) {
        const { data } = await getIssueById(Number(issue.id), tenant.id);
        return data?.item?.timeEntries?.filter(timeEntry => {
          if (reportedDate && timeEntry.workDate) {
            return isSameDay(new Date(reportedDate), new Date(timeEntry.workDate));
          } else {
            return true;
          }
        });
      }
      return Promise.reject();
    },
    { enabled: openTime },
  );

  async function onDelete(id: number | undefined) {
    try {
      if (id) {
        await deleteTimeEntry(id, tenant?.id);
      }
      getIssueByIdQuery?.refetch();
      enqueueSnackbar(t("dailyReport.deleteSuccess"), {
        variant: "success",
      });
    } catch {
      enqueueSnackbar(t("dailyReport.deleteError"), {
        variant: "error",
      });
    }
  }

  return (
    <Box
      style={{
        border: "1px solid rgba(0,0,0,0.22)",
        borderRadius: 10,
        marginBottom: 16,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => setOpenTime(!openTime)}
        p={1}
        style={{
          cursor: "pointer",
        }}
      >
        <Typography variant="h3" style={{ paddingLeft: 8 }}>
          {`${t("dailyReport.allTimeEntry")} (${
            reportedDate
              ? format(new Date(reportedDate), "P", { locale: supportedLocales[i18n.language] })
              : "-"
          })`}
        </Typography>
      </Box>
      <Box p={2}>
        <Box>
          {getIssueByIdQuery?.isFetching ? (
            <Box display="flex" justifyContent="center" width="100%">
              <CircularProgress />
            </Box>
          ) : (
            <>
              {!getIssueByIdQuery?.data?.length ? (
                <Typography>{t("dailyReport.noTimeEntry")}</Typography>
              ) : (
                <>
                  <Grid
                    container
                    alignItems="center"
                    spacing={1}
                    style={{ borderBottom: `1px solid ${COLORS.mainGrey}` }}
                  >
                    <Grid item xs={7} sm={4}>
                      <Typography variant="body2">
                        {t("timeEntry.user")} / {t("timeEntry.company")}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                      <Typography variant="body2">{t("timeEntry.workTime")}</Typography>
                    </Grid>
                    {matchesSm && (
                      <Grid item xs={12} sm={5}>
                        <Typography variant="body2">{t("timeEntry.description")}</Typography>
                      </Grid>
                    )}
                    <Grid item xs={1} />
                  </Grid>
                  {getIssueByIdQuery?.data?.map(timeEntry => (
                    <Grid
                      key={timeEntry.id}
                      container
                      alignItems="center"
                      spacing={1}
                      style={{ borderBottom: `1px solid ${COLORS.mainGrey}`, paddingTop: 4 }}
                    >
                      <Grid item xs={7} sm={4}>
                        <Typography>{timeEntry?.user?.name || timeEntry?.company?.name}</Typography>
                      </Grid>
                      <Grid item xs={4} sm={2}>
                        <Typography>{timeEntry?.workTime}</Typography>
                      </Grid>
                      {matchesSm && (
                        <Grid item xs={12} sm={5}>
                          <Tooltip title={timeEntry?.description}>
                            <Typography noWrap>{timeEntry?.description || "-"}</Typography>
                          </Tooltip>
                        </Grid>
                      )}
                      <Grid item xs={1} container justifyContent="flex-end">
                        {!isClosed && (
                          <ConfirmationButton
                            variant="text"
                            size="small"
                            color="primary"
                            buttonType="ICON"
                            title={t("dailyReport.removeTimeEntryTitle")}
                            body={t("dailyReport.removeTimeEntryBody")}
                            onSubmit={() => onDelete(timeEntry?.id)}
                          >
                            <Delete />
                          </ConfirmationButton>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                </>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DailyReportCreateIssueTimeEntry;
