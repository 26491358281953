import { faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { BusinessCenter, Close, Email } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import { GridColDef } from "@mui/x-data-grid";
import FilterBar from "components/PageableTable/FilterBar";
import { RootState } from "config/store";
import supportedLocales from "config/supportedLocales";
import { COLORS } from "config/theme";
import { format } from "date-fns";
import i18n from "i18n";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { EmailLog, getEmailLogPage } from "shared/network/email-log.api";
import { TableState } from "views/Company/CompanyList";

const SentEmails = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const companyId = query.get("id");

  const tenantId = useSelector(
    (state: RootState) => state.authentication?.selectedRelTenant?.tenant?.id,
  );
  const [selectedEmailLog, setSelectedEmailLog] = useState<EmailLog | null>(null);
  const [tableState, setTableState] = useState<TableState>({
    page: 0,
    pageSize: 15,
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const emailListQuery = useQuery(
    ["emailListQuery", companyId, page, pageSize, filterValue, tenantId],
    async () => {
      const { data } = await getEmailLogPage({
        page,
        size: pageSize,
        search: companyId ? `company.id=${companyId};${filterValue}` : filterValue,
        sort: "",
        tenantId,
        onlyOfferEmails: false,
      });
      return data.page;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "receiver",
      headerName: t("emailLog.receiver"),
      type: "stringContainsNumber",
    },
    {
      field: "subject",
      headerName: t("emailLog.subject"),
      type: "stringContainsNumber",
    },
    {
      field: "company.name",
      headerName: t("emailLog.companyName"),
      type: "stringContainsNumber",
    },
    {
      field: "createdOn",
      headerName: t("emailLog.createdOn"),
      type: "dateTime",
    },
  ];

  return (
    <Container maxWidth="lg" style={{ padding: 0 }}>
      <Card style={{ marginBottom: 16 }}>
        <FilterBar
          columns={columns}
          setTableState={setTableState}
          sessionStorageKey={"email-list"}
          id={tenantId}
          smallMargin
        />
        <Divider />
        <Box style={{ display: "flex" }}>
          <Box
            minWidth={300}
            style={{
              minHeight: "calc(100vh - 250px)",
              maxHeight: "calc(100vh - 250px)",
              overflow: "auto",
            }}
          >
            {!emailListQuery?.data?.content?.length && !emailListQuery?.isFetching && (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Typography style={{ fontWeight: "bold" }}>{t("emailLog.emptyList")}</Typography>
              </Box>
            )}
            {emailListQuery?.isFetching ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
              </Box>
            ) : (
              <Box>
                {emailListQuery?.data?.content?.map(emailLog => (
                  <Box key={emailLog.id}>
                    <MenuItem
                      onClick={() => setSelectedEmailLog(emailLog)}
                      selected={selectedEmailLog?.id === emailLog?.id}
                    >
                      <Box>
                        <Box display="flex">
                          <Typography style={{ fontWeight: "bold", fontSize: 12 }}>
                            {emailLog?.subject}
                          </Typography>
                        </Box>
                        <Typography style={{ fontSize: 12 }}>
                          {emailLog?.receiver?.slice(1, -1)}
                        </Typography>
                        <Typography style={{ fontSize: 12 }}>
                          {format(new Date(emailLog?.createdOn), "Pp", {
                            locale: supportedLocales[i18n.language],
                          })}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <Divider />
                  </Box>
                ))}
                {emailListQuery.data && emailListQuery.data?.totalPages > 1 && (
                  <Pagination
                    size="small"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                    }}
                    count={emailListQuery?.data?.totalPages}
                    color="primary"
                    page={emailListQuery.data?.number + 1}
                    onChange={(_, page) => {
                      setTableState(state => {
                        return { ...state, page: page - 1 };
                      });
                    }}
                    siblingCount={1}
                  />
                )}
              </Box>
            )}
          </Box>
          <Box>
            <Divider orientation="vertical" flexItem style={{ height: "100%" }} />
          </Box>
          {!selectedEmailLog && (
            <Box width={"100%"} display="flex" justifyContent="center" alignItems="center">
              <Box textAlign="center">
                <FontAwesomeIcon
                  icon={faEnvelopeOpenText}
                  style={{
                    fontSize: 60,
                    width: 60,
                    color: COLORS.lighterGrey,
                    marginBottom: 16,
                  }}
                />
                <Typography style={{ fontWeight: "bold", color: COLORS.lighterGrey }}>
                  {t("emailLog.select")}
                </Typography>
              </Box>
            </Box>
          )}
          {selectedEmailLog && (
            <Box width="100%" p={2}>
              <Box display="flex" justifyContent="space-between" alignItems="center" pb={1}>
                <Box display="flex" alignItems="center">
                  {/* <IconButton
                    size="small"
                    disableRipple
                    style={{ marginRight: 12 }}
                    onClick={() => setSelectedEmailLog(null)}
                  >
                    <Close />
                  </IconButton> */}
                  <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
                    {selectedEmailLog?.subject}
                  </Typography>
                </Box>
                <Typography>
                  {format(new Date(selectedEmailLog?.createdOn), "Pp", {
                    locale: supportedLocales[i18n.language],
                  })}
                </Typography>
              </Box>
              <Divider />
              <Box display="flex" gridGap={16} pt={1.5}>
                <Box display="flex" alignItems="center" gridGap={8}>
                  <Avatar style={{ height: 32, width: 32 }}>
                    <Email fontSize="small" />
                  </Avatar>
                  <Typography style={{ fontWeight: "bold" }}>
                    {selectedEmailLog?.receiver?.slice(1, -1)}
                  </Typography>
                </Box>
                {selectedEmailLog?.company && (
                  <Box display="flex" alignItems="center" gridGap={8}>
                    <Tooltip title={t("emailLog.company").toString()}>
                      <Avatar style={{ height: 32, width: 32 }}>
                        <BusinessCenter fontSize="small" />
                      </Avatar>
                    </Tooltip>
                    <a
                      href={`/companies/list-all/company-details/details?id=${selectedEmailLog?.company?.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        {selectedEmailLog?.company?.name}
                      </Typography>
                    </a>
                  </Box>
                )}
              </Box>
              {selectedEmailLog?.content && (
                <Box
                  style={{
                    border: "1px solid rgba(0,0,0,0.15)",
                    borderRadius: 10,
                    marginTop: 12,

                    minHeight: "calc(100vh - 380px)",
                    maxHeight: "calc(100vh - 380px)",
                    overflow: "auto",
                  }}
                >
                  <div
                    style={{
                      padding: 16,
                      width: "100%",
                      height: "100%",
                      maxWidth: 1000,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: selectedEmailLog?.content || "",
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Card>
    </Container>
  );
};
export default SentEmails;
