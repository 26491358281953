import { Box, CircularProgress, Container } from "@material-ui/core";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SliceStatus } from "shared/common";
import { createCustomNotification } from "shared/network/custom-notification.api";
import { removeEmptyStrings } from "shared/util/removeEmptyStrings";
import CustomNotificationForm from "./CustomNotificationForm";

const CustomNotificationCreate = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const form = useForm();

  const { enqueueSnackbar } = useSnackbar();

  const [status, setStatus] = useState<SliceStatus>("idle");
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const onSubmitCreate = async (values: any) => {
    setStatus("pending");
    try {
      await createCustomNotification({
        ...removeEmptyStrings({
          ...values,
          containsCustomParameter:
            !!values?.content?.includes("##CompanyName##") ||
            !!values?.content?.includes("##NotificationEmail##") ||
            !!values?.content?.includes("##BankAccount##") ||
            !!values?.content?.includes("##OrderNumber##"),
        }),
        tenantId: tenant.id,
      });

      setStatus("success");
      enqueueSnackbar(t("common:notification.create.success"), { variant: "success" });
      history.push("/admin/email-templates");
    } catch (err: any) {
      enqueueSnackbar(t("common:notification.create.failure"), { variant: "error" });
      setStatus("failure");
    }
  };

  return (
    <Container maxWidth="lg">
      <form onSubmit={form.handleSubmit(onSubmitCreate)}>
        {status === "pending" ? (
          <Box display="flex" alignItems="center" justifyContent="center" height="300px">
            <CircularProgress />
          </Box>
        ) : (
          <FormProvider {...form}>
            <CustomNotificationForm />
          </FormProvider>
        )}
      </form>
    </Container>
  );
};
export default CustomNotificationCreate;
