import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { Autocomplete } from "@material-ui/lab";
import FormCard from "components/FormCard";
import NumberField from "components/NumberField";
import { RootState } from "config/store";
import { Fragment } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCompanySiteListPageable } from "shared/network/companysite.api";
import { CompanySite, Item } from "shared/types";
import displayAddress from "shared/util/displayAddress";

export type Props = {
  items?: Item[];
  companyId?: string;
};

export type ItemCompanyFormValues = {
  companySite: CompanySite | null;
  relItemCompanies: {
    item: Item | null;
    quantity: string | number;
  }[];
};

const RelCompanyItemForm = ({ items, companyId }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);
  const { control, watch } = useFormContext<ItemCompanyFormValues>();

  const { append, fields, remove } = useFieldArray({
    control,
    name: "relItemCompanies",
    keyName: "key",
  });
  const relItemCompany = watch("relItemCompanies");

  const companySiteListQuery = useQuery(
    ["companySiteListPageableQueryForITemEntryCreate", tenant.id],
    async () => {
      if (Number(companyId)) {
        const { data } = await getCompanySiteListPageable(
          0,
          10000000,
          tenant.id,
          companyId ? `company.id:${companyId}` : ``,
        );
        return data.page.content;
      }
      return Promise.reject();
    },
    { enabled: !!companyId },
  );

  return (
    <Container maxWidth="md">
      <FormCard title={""}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name={`companySite`}
              defaultValue={null}
              rules={{
                required: t("validation.required").toString(),
              }}
              render={({ field }) => (
                <Autocomplete
                  options={companySiteListQuery.data || []}
                  getOptionLabel={(option: CompanySite) =>
                    `${option.name} (${displayAddress(option.address)})`
                  }
                  getOptionSelected={option => option.id === field.value?.id}
                  value={field.value}
                  onChange={(_, value) => field.onChange(value)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      {...field}
                      label={t("relItemCompany.companySite")}
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                      }}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      </FormCard>
      <FormCard title={t("item.form.items")}>
        <Box>
          {fields.length === 0 ? (
            <Typography variant="h5" align="center" color="secondary">
              {t("common:noData")}
            </Typography>
          ) : (
            <>
              {fields.map((field, index) => {
                return (
                  <Fragment key={field.key}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {t("offer.relOfferItem.itemTitle", {
                          index: index + 1,
                        })}
                      </Typography>
                      {relItemCompany?.length > 1 && (
                        <Tooltip title={t("common:button.delete").toString()}>
                          <IconButton size="small" color="primary" onClick={() => remove(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                    <Box display="flex">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Controller
                            control={control}
                            name={`relItemCompanies.${index}.item`}
                            defaultValue={null}
                            rules={{
                              required: t("validation.required").toString(),
                            }}
                            render={({ field }) => (
                              <Autocomplete
                                options={items || []}
                                getOptionLabel={(option: Item) =>
                                  `${option.name} (${option.productCode})`
                                }
                                getOptionSelected={option => option.id === field.value?.id}
                                value={field.value}
                                onChange={(_, value) => field.onChange(value)}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    {...field}
                                    label={t("tool.formValues.itemNameOrProductNumber")}
                                    InputLabelProps={{
                                      shrink: true,
                                      required: true,
                                    }}
                                  />
                                )}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Controller
                            control={control}
                            name={`relItemCompanies.${index}.quantity`}
                            defaultValue={field?.quantity || 0}
                            rules={{
                              required: t("validation.required").toString(),
                            }}
                            render={({ field, fieldState }) => (
                              <NumberField
                                field={field}
                                fieldState={fieldState}
                                label={t("tenant.products.quantity")}
                                InputLabelProps={{
                                  shrink: true,
                                  required: true,
                                }}
                                numberLimits={{ step: "0.01", min: "0.01" }}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Fragment>
                );
              })}
            </>
          )}
        </Box>
        <Box display="flex" justifyContent="center">
          <Button
            variant="outlined"
            color="primary"
            style={{ marginBottom: 8, marginTop: 16 }}
            onClick={event => {
              event.stopPropagation();
              append({
                item: null,
                quantity: "",
              });
            }}
            startIcon={<AddIcon />}
          >
            {t("offer.relOfferItem.add")}
          </Button>
        </Box>
      </FormCard>
      <Box display="flex" justifyContent="center" m={2} gridGap={8}>
        <Button color="primary" variant="text" onClick={history.goBack}>
          {t("common:button.cancel")}
        </Button>
        <Button type="submit" color="primary">
          {tenant ? t("common:button.save") : t("common:button.create")}
        </Button>
      </Box>
    </Container>
  );
};
export default RelCompanyItemForm;
