import { faMoneyBillTransfer } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Collapse, Divider, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import {
  Edit,
  History,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { RootState } from "config/store";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  CashRegisterAmount,
  getPageCashRegisterAmount,
} from "shared/network/cash-register-amount.api";
import { CashRegister } from "shared/network/cash-register.api";
import AddCashRegisterAmountDialog from "./AddCashRegisterAmountDialog";
import CashRegisterAmountHistoryDialog from "./CashRegisterAmountHistoryDialog";
import CashRegisterModifyDialog from "./CashRegisterModifyDialog";
import { hasAnyAuthority } from "shared/authorization";

type Props = {
  cashRegister: CashRegister;
};

const CashRegisterRow = ({ cashRegister }: Props) => {
  const { t } = useTranslation();
  const tenantId = useSelector(
    (state: RootState) => state.authentication?.selectedRelTenant?.tenant?.id,
  );
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const [createOpen, setCreateOpen] = useState(false);
  const [cardOpen, setCardOpen] = useState(false);
  const [amountOpen, setAmountOpen] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState<CashRegisterAmount | null>(null);

  const cashRegisterAmountList = useQuery(
    ["cashRegisterAmountList", tenantId, cashRegister.id],
    async () => {
      const { data } = await getPageCashRegisterAmount({
        page: 0,
        size: 2000,
        search: `cashRegister.id:${cashRegister.id}`,
        tenantId,
      });
      return data?.page?.content?.sort((a, b) =>
        (a?.currency?.name || "")?.localeCompare(b?.currency?.name || ""),
      );
    },
    { enabled: cardOpen },
  );

  return (
    <Box>
      {createOpen && (
        <CashRegisterModifyDialog
          cashRegister={cashRegister}
          open={createOpen}
          setOpen={setCreateOpen}
        />
      )}
      {amountOpen && (
        <AddCashRegisterAmountDialog
          cashRegister={cashRegister}
          open={amountOpen}
          setOpen={setAmountOpen}
        />
      )}
      {selectedAmount && (
        <CashRegisterAmountHistoryDialog
          selectedAmount={selectedAmount}
          setSelectedAmount={setSelectedAmount}
        />
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gridGap={8}
        m={1}
        onClick={() => setCardOpen(state => !state)}
        style={{ cursor: "pointer" }}
      >
        <Typography style={{ fontWeight: "bold", fontSize: 18, paddingLeft: 8 }}>
          {cashRegister?.name}
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center" gridGap={8} p={1}>
          {selectedRelTenant.tenant.isUseDefault &&
            hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
              "CASH_REGISTER_HANDLE_AMOUNT",
            ]) &&
            (account?.user?.isSuperAdmin ||
              selectedRelTenant?.isTenantAdmin ||
              cashRegister?.responsibleUser?.id === account?.user?.id) && (
              <Tooltip title={t("cashRegister.addAmount")}>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={event => {
                    event?.stopPropagation();
                    setAmountOpen(true);
                  }}
                >
                  <FontAwesomeIcon icon={faMoneyBillTransfer} />
                </IconButton>
              </Tooltip>
            )}
          {selectedRelTenant.tenant.isUseDefault &&
            hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
              "CASH_REGISTER_ADMIN",
            ]) && (
              <Tooltip title={t("common:button.modify")}>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={event => {
                    event?.stopPropagation();
                    setCreateOpen(true);
                  }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            )}
          {selectedRelTenant.tenant.isUseDefault &&
            hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
              "CASH_REGISTER_AMOUNT_GET",
            ]) && (
              <IconButton
                color="primary"
                size="small"
                onClick={event => {
                  event?.stopPropagation();
                  setCardOpen(state => !state);
                }}
              >
                {cardOpen ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
              </IconButton>
            )}
        </Box>
      </Box>
      {selectedRelTenant.tenant.isUseDefault &&
        hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "CASH_REGISTER_AMOUNT_GET",
        ]) && (
          <Collapse in={cardOpen}>
            <Divider />
            <Box p={"8px 16px"}>
              {cashRegisterAmountList.isFetching ? (
                <>
                  <Box display="flex" justifyContent="space-between" p={"4px 0"}>
                    <Skeleton width={"30%"} />
                    <Skeleton width={"30%"} />
                  </Box>
                  <Divider />
                  <Box display="flex" justifyContent="space-between" p={"4px 0"}>
                    <Skeleton width={"30%"} />
                    <Skeleton width={"30%"} />
                  </Box>
                </>
              ) : !!cashRegisterAmountList.data?.length ? (
                cashRegisterAmountList.data?.map((amount, index, array) => (
                  <>
                    <Grid container justifyContent="space-around" alignItems="center">
                      <Grid key={amount.id} item xs={12} sm={6}>
                        <Typography style={{ fontWeight: "bold", padding: 4 }}>
                          {amount?.currency?.name}:
                        </Typography>
                      </Grid>
                      <Grid
                        key={amount.id}
                        item
                        xs={12}
                        sm={6}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Box display="flex" alignItems="center">
                          <Typography style={{ padding: 4 }}>
                            {amount?.currency?.prefix}
                            {t("common:currency", {
                              num: amount?.quantity,
                              cur: amount?.currency?.postfix,
                            })}
                          </Typography>
                          <Tooltip title="Előzmények">
                            <IconButton
                              size="small"
                              color="primary"
                              onClick={() => setSelectedAmount(amount)}
                            >
                              <History />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Grid>
                    </Grid>
                    {index + 1 !== array?.length && <Divider />}
                  </>
                ))
              ) : (
                <Typography>{t("tool.formValues.noData")}</Typography>
              )}
            </Box>
          </Collapse>
        )}
    </Box>
  );
};

export default CashRegisterRow;
