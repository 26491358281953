import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Add, CancelOutlined, Close, Edit } from "@material-ui/icons";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { uploadDonationFile, uploadSepaFile } from "shared/network/invoice-transaction.api";

type Props = {
  createOpen: boolean;
  setCreateOpen: Dispatch<SetStateAction<boolean>>;
  refetch: () => void;
  type: "SEPA" | "DONATION";
};

type FormValues = {
  files: FileList | null;
};

const InvoiceTransactionCreateDialog = ({ createOpen, setCreateOpen, refetch, type }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const tenant = useSelector(
    (state: RootState) => state?.authentication?.selectedRelTenant?.tenant,
  );
  const form = useForm<FormValues>();
  const { handleSubmit, register, watch, setValue } = form;
  const files = watch("files");
  const [loadingUpload, setLoadingUpload] = useState(false);

  async function uploadFile(values: FormValues) {
    setLoadingUpload(true);
    try {
      if (values?.files?.[0]) {
        if (type === "SEPA") {
          await uploadSepaFile(values.files[0], tenant?.id);
        } else {
          await uploadDonationFile(values.files[0], tenant?.id);
        }
      }

      refetch();
      setValue("files", null);
      setCreateOpen(false);
      enqueueSnackbar(t("common:notification.upload.success"), {
        variant: "success",
      });
    } catch {
      enqueueSnackbar(t("common:notification.upload.failure"), {
        variant: "error",
      });
    }
    setLoadingUpload(false);
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={createOpen} onClose={() => setCreateOpen(false)}>
      <Loading open={loadingUpload} />
      <DialogTitle disableTypography>
        <Box display="flex" justifyContent="space-between">
          <Typography style={{ fontWeight: "bold", fontSize: 20 }}>
            {type === "SEPA" && t("invoiceTransaction.upload")}
            {type === "DONATION" && t("invoiceTransaction.upload2")}
          </Typography>
          <IconButton size="small" onClick={() => setCreateOpen(false)}>
            <CancelOutlined />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...form}>
          <form id="transaction-file" onSubmit={handleSubmit(uploadFile)}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              gridGap={8}
              pb={1}
            >
              {!!files?.length && (
                <>
                  <Box display="flex" alignItems="center" gridGap={8}>
                    <Box
                      display="flex"
                      alignItems="center"
                      gridGap={8}
                      style={{
                        borderRadius: 20,
                        border: "2px solid",
                        paddingLeft: 8,
                        paddingRight: 8,
                      }}
                    >
                      <Typography>
                        {t("invoiceTransaction.fileSelected")} ({files?.[0]?.name})
                      </Typography>
                      <Typography>
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => form.setValue("files", null)}
                        >
                          <Close />
                        </IconButton>
                      </Typography>
                    </Box>
                  </Box>
                  {files?.[0]?.size / 1000000 > 2 && (
                    <Typography style={{ color: "red" }}>{t("overMaxSize")}</Typography>
                  )}
                </>
              )}
              <Box display="flex" alignItems="center" gridGap={8}>
                <Button
                  size="small"
                  variant="outlined"
                  component="label"
                  htmlFor="files"
                  startIcon={files?.length ? <Edit /> : <Add />}
                >
                  {files?.length
                    ? t("personalData.formValues.chooseAnotherFile")
                    : t("personalData.formValues.chooseFile")}
                  <input
                    id="files"
                    style={{ display: "none" }}
                    type="file"
                    {...register("files", {
                      required: {
                        value: true,
                        message: t("validation.required"),
                      },
                    })}
                  />
                </Button>
              </Box>
              {form?.formState?.errors?.files && (
                <Typography
                  style={{
                    color: COLORS.red,
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  {form?.formState?.errors.files.message}
                </Typography>
              )}
              <Typography
                style={{
                  color: COLORS.red,
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                max. 2 MB
              </Typography>
            </Box>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button
          form="transaction-file"
          type="submit"
          disabled={(files?.[0]?.size || 0) / 1000000 > 2 || loadingUpload}
        >
          {t("upload")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvoiceTransactionCreateDialog;
