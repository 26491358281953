import {
  Box,
  Button,
  Card,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { PictureAsPdf } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Loading from "components/Loading";
import { RootState } from "config/store";
import supportedLocales from "config/supportedLocales";
import { COLORS } from "config/theme";
import { endOfMonth, format, getDaysInMonth, setDate, startOfMonth } from "date-fns";
import i18n from "i18n";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getCheckTimePage } from "shared/network/check-time.api";
import { checklistPdfExport, getChecklistItemPage } from "shared/network/haccp-checklist.api";
import { getHaccpItemDateCommentPage } from "shared/network/haccp-item-date-comment.api";
import { getHaccpItemPage } from "shared/network/haccp-item.api";
import HaccpItemCheckComment from "./HaccpItemCheckComment";
import HaccpItemCheckInputCell from "./HaccpItemCheckInputCell";

const HaccpItemCheck = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);
  const form = useForm<any>();
  const { control, setValue, watch } = form;
  const date = watch("date");
  const haccpItemId = watch("haccpItemId");
  const [shouldSetDefault, setShouldSetDefault] = useState(true);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const haccpItemPageQuery = useQuery(
    ["haccpItemPageQuery", tenant.id],
    async () => {
      const { data } = await getHaccpItemPage(0, 2000, tenant.id);
      return data?.page?.content || [];
    },
    {
      onSuccess: data => {
        if (shouldSetDefault) {
          setValue("haccpItemId", data?.[0]?.id || "");
          setShouldSetDefault(false);
        }
      },
    },
  );

  const checkListItemPageQuery = useQuery(
    ["checkListItemPageQuery", tenant.id, haccpItemId, date],
    async () => {
      const { data } = await getChecklistItemPage(
        0,
        2000,
        tenant.id,
        haccpItemId
          ? `checkTime.haccpItem.id=${haccpItemId};realCheckTime>=${startOfMonth(
              date,
            ).toISOString()};realCheckTime<=${endOfMonth(date).toISOString()}`
          : "",
      );
      return data?.page?.content || [];
    },
    { enabled: !!haccpItemId },
  );

  const checkTimePageQuery = useQuery(
    ["checkTimePageQuery", tenant.id, haccpItemId],
    async () => {
      const { data } = await getCheckTimePage(
        0,
        2000,
        tenant.id,
        haccpItemId ? `haccpItem.id=${haccpItemId};isActive=true` : "",
        "checkAt,asc",
      );
      return data.page.content;
    },
    { enabled: !!haccpItemId },
  );

  const haccpItemDateCommentQuery = useQuery(
    ["haccpItemDateCommentQuery", haccpItemId, date, tenant.id],
    async () => {
      const { data } = await getHaccpItemDateCommentPage(
        0,
        2000,
        tenant.id,
        haccpItemId
          ? `haccpItemId=${haccpItemId};date>=${format(
              startOfMonth(date),
              "yyyy-MM-dd",
            )};date<=${format(endOfMonth(date), "yyyy-MM-dd")}`
          : "",
      );
      return data?.page?.content || [];
    },
  );

  const haccpItemDateComments = haccpItemDateCommentQuery.data || [];

  return (
    <Box>
      <Loading
        open={
          downloadLoading ||
          checkTimePageQuery?.isFetching ||
          checkListItemPageQuery?.isFetching ||
          haccpItemPageQuery?.isFetching ||
          haccpItemDateCommentQuery?.isFetching
        }
      />
      <Card style={{ padding: 16 }}>
        <FormProvider {...form}>
          <form>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Controller
                  control={control}
                  name="haccpItemId"
                  defaultValue={null}
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("haccpItem.item")}
                      select
                      InputLabelProps={{ shrink: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    >
                      <MenuItem disabled value="">
                        {t("common:choose")}
                      </MenuItem>
                      {haccpItemPageQuery?.data?.map(option => (
                        <MenuItem key={option?.id} value={option?.id}>
                          {option?.name || ""}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Controller
                  control={control}
                  name="date"
                  defaultValue={new Date()}
                  render={({ field }) => (
                    <KeyboardDatePicker
                      {...field}
                      ref={undefined}
                      className="date"
                      variant="inline"
                      label={t("haccpItem.date")}
                      format="yyyy.MM."
                      InputLabelProps={{ shrink: true }}
                      autoOk
                      views={["year", "month"]}
                      openTo="month"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2} style={{ display: "flex" }}>
                <Tooltip title={t("haccpItem.pdfTooltip")}>
                  <Button
                    fullWidth
                    startIcon={<PictureAsPdf />}
                    onClick={async () => {
                      try {
                        setDownloadLoading(true);
                        if (date) {
                          const { data } = await checklistPdfExport(
                            format(date, "yyyy"),
                            format(date, "M"),
                            tenant?.id,
                          );
                          const fileName = data?.item?.fileName || "";
                          let a = document.createElement("a");
                          a.setAttribute("download", fileName);
                          a.setAttribute(
                            "href",
                            "data:application/pdf;base64," + data.item.content,
                          );
                          document.body.appendChild(a);
                          a.click();
                          document.body.removeChild(a);
                          enqueueSnackbar(t("haccpItem.pdfDownloadSuccess"), {
                            variant: "success",
                          });
                        }
                      } catch (error: any) {
                        if (error?.data?.status === "NOT_FOUND") {
                          enqueueSnackbar(t("haccpItem.pdfNotFound"), {
                            variant: "error",
                          });
                        } else {
                          enqueueSnackbar(t("haccpItem.pdfDownloadError"), {
                            variant: "error",
                          });
                        }
                      }
                      setDownloadLoading(false);
                    }}
                  >
                    {t("haccpItem.pdfDownload")}
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        <Typography
          style={{
            textAlign: "center",
            paddingTop: 24,
            fontSize: 16,
            fontWeight: "bold",
            color: COLORS.mainLight,
          }}
        >
          {t("haccpItem.help1")}
        </Typography>
        <Typography
          style={{
            textAlign: "center",

            fontSize: 16,
            fontWeight: "bold",
            color: COLORS.mainLight,
          }}
        >
          {t("haccpItem.help2")}
        </Typography>
        <Box style={{ overflow: "auto" }}>
          <Box style={{ minWidth: 710 }}>
            {!!getDaysInMonth(date) && !!haccpItemId && !!checkTimePageQuery.data?.length ? (
              <Box display="flex" justifyContent="center" p={2}>
                <Box width={130}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    p={1}
                    width="100%"
                    style={{
                      borderBottom: "1px solid lightgrey",
                    }}
                  >
                    <Typography noWrap style={{ fontWeight: "bold", fontSize: 18 }}>
                      {t("haccpItem.days")}
                    </Typography>
                  </Box>
                  {Array.from(Array(getDaysInMonth(date))).map((_, index) => (
                    <Box
                      key={index}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      style={{
                        borderBottom: "1px solid lightgrey",
                        padding: 8,
                        height: 57,
                      }}
                    >
                      <Typography style={{ fontSize: 18, fontWeight: "bold" }}>
                        {index + 1}.
                      </Typography>
                    </Box>
                  ))}
                </Box>
                {checkTimePageQuery.data?.map(checkTime => {
                  const checkListItems = checkListItemPageQuery.data?.filter(
                    value => value.checkTime.id === checkTime.id,
                  );

                  return (
                    <Box
                      key={checkTime.id}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      width={110}
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        p={1}
                        width="100%"
                        style={{
                          borderBottom: "1px solid lightgrey",
                        }}
                      >
                        <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
                          {format(new Date(checkTime.checkAt), "p", {
                            locale: supportedLocales[i18n.language],
                          })}
                        </Typography>
                      </Box>
                      {Array.from(Array(getDaysInMonth(date))).map((_, index) => {
                        const checkListItem = checkListItems?.find(value => {
                          return (
                            format(setDate(new Date(date), index + 1), "yyyy-MM-dd") ===
                            format(new Date(value.realCheckTime), "yyyy-MM-dd")
                          );
                        });

                        return (
                          <HaccpItemCheckInputCell
                            key={index}
                            index={index}
                            checkTime={checkTime}
                            date={date}
                            checkListItem={checkListItem}
                          />
                        );
                      })}
                    </Box>
                  );
                })}
                <Box width={360}>
                  <HaccpItemCheckComment
                    date={date}
                    haccpItemId={haccpItemId}
                    haccpItemDateComments={haccpItemDateComments}
                    width={360}
                  />
                </Box>
              </Box>
            ) : checkTimePageQuery.data?.length === 0 ? (
              <Typography
                style={{ fontSize: 20, textAlign: "center", padding: 24, paddingTop: 36 }}
              >
                {t("common:noData")}.
              </Typography>
            ) : null}
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default HaccpItemCheck;
