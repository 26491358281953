import axios from "config/axios";
import { GenericListResponse, GenericPageResponse, GenericResponse } from "shared/common";
import { Company, Issue, Project, User } from "shared/types";

export type Reminder = {
  reminderUser?: User | null;
  company?: Company | null;
  issue?: Issue | null;
  message?: string;
  reminderMessage?: string;
  when?: string | null;
  tenantId: number;
  project?: Project | null;
  status: "ACTIVE" | "DONE" | "REMOVED";
};

const ENDPOINT = "/reminder";

export const createReminder = (param: Reminder) =>
  axios.post<GenericResponse<Reminder>>(`${ENDPOINT}`, param);

export const modifyReminder = (param: Reminder) =>
  axios.put<GenericResponse<Reminder>>(`${ENDPOINT}`, {
    reminder: param,
    tenantId: param.tenantId,
  });

export const listReminders = (userId: number, tenantId: number) =>
  axios.get<GenericListResponse<Reminder>>(`${ENDPOINT}/all?userId=${userId}&tenantId=${tenantId}`);

export const listReminderPages = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<Reminder>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  );

export const updateStatus = (reminderId: number, status: string, tenantId: number) =>
  axios.post<GenericResponse<Reminder>>(`${ENDPOINT}/update-status`, {
    reminderId,
    status,
    tenantId,
  });
