import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Loading from "components/Loading";
import queryClient from "config/query";
import { RootState } from "config/store";
import { endOfMonth, format, startOfMonth } from "date-fns";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { invoiceImportFromNav } from "shared/network/nav.api";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

type FormValues = {
  from: Date;
  to: Date;
};

const NavImportDialog = ({ open, setOpen }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant.tenant;

  const form = useForm<FormValues>();
  const { control, handleSubmit } = form;

  const [loading, setLoading] = useState(false);

  async function onSubmit(values: FormValues) {
    setLoading(true);
    try {
      await invoiceImportFromNav({
        from: format(values.from, "yyyy-MM-dd"),
        to: format(values.to, "yyyy-MM-dd"),
        tenantId: tenant?.id,
      });
      setOpen(false);
      queryClient.refetchQueries("IncomingInvoiceListQuery");
      enqueueSnackbar(t("Sikeres importálás"), { variant: "success" });
    } catch {
      enqueueSnackbar("Sikertelen importálás", { variant: "error" });
    }
    setLoading(false);
  }

  return (
    <>
      <Loading open={loading} />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{t("incomingInvoice.import")}</DialogTitle>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="from"
                    defaultValue={startOfMonth(new Date())}
                    rules={{ required: t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <KeyboardDatePicker
                        {...field}
                        ref={undefined}
                        variant="inline"
                        label={t("invoice.csvStartDate")}
                        format="yyyy.MM.dd"
                        InputLabelProps={{ shrink: true, required: true }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        autoOk
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="to"
                    defaultValue={endOfMonth(new Date())}
                    rules={{ required: t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <KeyboardDatePicker
                        {...field}
                        ref={undefined}
                        variant="inline"
                        label={t("invoice.csvEndDate")}
                        format="yyyy.MM.dd"
                        InputLabelProps={{ shrink: true, required: true }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        autoOk
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="text" onClick={() => setOpen(false)}>
                {t("common:button.cancel")}
              </Button>
              <Button type="submit">{t("common:button.ok")}</Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};
export default NavImportDialog;
