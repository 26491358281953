import { Box, Container, Tab, Tabs } from "@material-ui/core";
import { useHeader } from "components/Layout/HeaderContext";
import PrivateRoute from "components/PrivateRoute";
import { RootState } from "config/store";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router";
import { Link, Switch } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import HaccpItem from "./HaccpDepartment";
import HaccpItemCheck from "./HaccpDepartmentCheck";
import { useTranslation } from "react-i18next";

export const HACCP_ITEM_TABS = ["/haccp-department/check", `/haccp-department/settings`];

const HaccpDepartmentTabs = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { setTopPadding } = useHeader();
  const account = useSelector((state: RootState) => state.authentication.account);
  const selectedRelTenant = useSelector(
    (state: RootState) => state.authentication.selectedRelTenant,
  );

  useEffect(() => {
    setTopPadding(false);

    return () => {
      setTopPadding(true);
    };
  }, [setTopPadding]);

  return (
    <>
      {location.pathname === "/haccp-department" && <Redirect to="/haccp-department/check" />}
      <Tabs
        scrollButtons="auto"
        variant="scrollable"
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
        style={{ backgroundColor: "rgba(255, 255, 255, 0.6)", paddingLeft: 24, paddingRight: 24 }}
      >
        <Tab
          label={t("haccpDepartment.check")}
          component={Link}
          to={HACCP_ITEM_TABS[0]}
          value={HACCP_ITEM_TABS[0]}
        />
        {hasAuthority(account.user, account.permissions, selectedRelTenant, ["HACCP_ADMIN"]) && (
          <Tab
            label={t("statistics.settings")}
            component={Link}
            to={HACCP_ITEM_TABS[1]}
            value={HACCP_ITEM_TABS[1]}
          />
        )}
      </Tabs>
      <Container maxWidth="xl">
        <Box pt={2}>
          <Switch location={location}>
            <PrivateRoute path={HACCP_ITEM_TABS[0]} component={HaccpItemCheck} />
            {hasAuthority(account.user, account.permissions, selectedRelTenant, [
              "HACCP_ADMIN",
            ]) && <PrivateRoute path={HACCP_ITEM_TABS[1]} component={HaccpItem} />}
          </Switch>
        </Box>
      </Container>
    </>
  );
};

export default HaccpDepartmentTabs;
