import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@material-ui/core";
import HungarianFlag from "components/SvgIcons/Hu";
import GermanFlag from "components/SvgIcons/De";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const LanguageSelector = ({ menu }: { menu?: boolean }) => {
  const { i18n, t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  function onClick(lang: string) {
    i18n.changeLanguage(lang);
    setAnchorEl(null);
  }

  function getFlag() {
    if (i18n?.language === "hu") {
      return <HungarianFlag />;
    } else if (i18n?.language === "de") {
      return <GermanFlag />;
    } else {
      return null;
    }
  }

  return (
    <>
      {menu ? (
        <MenuItem
          //@ts-ignore
          onClick={event => setAnchorEl(event.currentTarget)}
        >
          <ListItemIcon>{getFlag()}</ListItemIcon>
          <Typography variant="inherit">
            {t(`common:language.${i18n?.language.toUpperCase()}`)}
          </Typography>
        </MenuItem>
      ) : (
        <IconButton
          onClick={event => setAnchorEl(event.currentTarget)}
          style={{ width: 48, height: 48 }}
        >
          <Box display="flex" width={48}>
            {getFlag()}
          </Box>
        </IconButton>
      )}
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem style={{ display: "flex", flexWrap: "nowrap" }} onClick={() => onClick("hu")}>
          <Box display="flex" flexWrap="nowrap" alignItems="center">
            <ListItemIcon>
              <Box width={24}>
                <HungarianFlag />
              </Box>
            </ListItemIcon>
            <ListItemText primary="Magyar" />
          </Box>
        </MenuItem>
        <MenuItem onClick={() => onClick("de")}>
          <Box display="flex" flexWrap="nowrap" alignItems="center">
            <ListItemIcon>
              <Box width={24}>
                <GermanFlag />
              </Box>
            </ListItemIcon>
            <ListItemText primary="Deutsch" />
          </Box>
        </MenuItem>
      </Popover>
    </>
  );
};

export default LanguageSelector;
