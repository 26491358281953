import axios from "config/axios";

const ENDPOINT = "/nav";

export const getCompanyInfoFromTaxNumber = (tenantId: number, param: string) =>
  axios.get(`${ENDPOINT}/company-info-from-tax-number?tenantId=${tenantId}&taxNumber=${param}`);

export const invoiceImportFromNav = (params: { from: string; to: string; tenantId: number }) =>
  axios.post(`${ENDPOINT}/import`, {
    from: params.from,
    to: params.to,
    tenantId: params.tenantId,
  });
