import axios from "config/axios";
import { GenericPageResponse, GenericResponse } from "shared/common";
import { User } from "shared/types";

export type CashRegister = {
  id: number;
  tenantId: number;
  responsibleUser: User;
  name: string;
  status: string;
  isDefault: boolean;
};

const ENDPOINT = "/cash-register";

export const createCashRegister = (param: Partial<CashRegister>, tenantId: number) =>
  axios.post<GenericResponse<CashRegister>>(`${ENDPOINT}`, { param, tenantId });

export const modifyCashRegister = (param: Partial<CashRegister>, tenantId: number) =>
  axios.put<GenericResponse<CashRegister>>(`${ENDPOINT}`, { param, tenantId });

export const getPageCashRegister = (param: {
  page: number;
  size: number;
  tenantId: number;
  search?: string;
  sort?: string;
}) =>
  axios.get<GenericPageResponse<CashRegister>>(
    `${ENDPOINT}/get-page?page=${param.page}&size=${param.size}&tenantId=${param.tenantId}&search=${
      param.search || ""
    }&sort=${param.sort || ""}`,
  );

export const getCashRegisterById = (param: number, tenantId: number) =>
  axios.post<GenericResponse<CashRegister>>(`${ENDPOINT}`, { param, tenantId });
