import { Address } from "shared/types";
import i18next from "i18next";
import { InvoiceTransaction } from "shared/network/invoice-transaction.api";

export const getInvoiceAddress = (address?: Address) => {
  return address
    ? `${address.areaName} ${i18next.t(`common:areaType.${address.areaType}`)} ${
        address.houseNumber
      } ${address.floor ? address.floor : ""}`
    : "";
};

const displayAddress = (address?: Address | InvoiceTransaction) => {
  let addressString = "";
  if ((address as Address)?.zipCode) {
    addressString += `${(address as Address)?.zipCode} `;
  } else if ((address as InvoiceTransaction)?.zip) {
    addressString += `${(address as InvoiceTransaction)?.zip} `;
  }
  if (address?.city) {
    addressString += `${address.city}, `;
  }
  if (address?.areaName) {
    addressString += `${address.areaName} `;
  }
  if (address?.areaType) {
    addressString += `${i18next.t(`common:areaType.${address.areaType}`)} `;
  }
  if (address?.houseNumber) {
    addressString += address.houseNumber;
  }
  return addressString;
};

export default displayAddress;
