import {
  Box,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import {
  AREA_TYPES,
  PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY,
  PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH,
} from "config/constants";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getCompanyInfoFromTaxNumber } from "shared/network/nav.api";
import {
  CompanyInfo,
  getCompanySearchFromPartnerControl,
} from "shared/network/partner-control.api";
import { useDebouncedCallback } from "use-debounce";
import { CompanyFormValues } from "./CompanyForm";
import { capitalize, toLower } from "lodash";

const CompanySearchBar = () => {
  const { t } = useTranslation();
  const tenantId = useSelector(
    (state: RootState) => state.authentication?.selectedRelTenant?.tenant?.id,
  );
  const { setValue } = useFormContext<CompanyFormValues>();
  const [loading, setLoading] = useState(false);
  const [companySearch, setCompanySearch] = useState<string>("");
  const [openAddressDialog, setOpenAddressDialog] = useState<any[] | null>(null);

  const companySearchQuery = useQuery(
    ["companyListPartnerControlQuery", companySearch],
    async () => {
      if (companySearch) {
        const { data } = await getCompanySearchFromPartnerControl(companySearch);
        return data;
      }
      return Promise.reject();
    },
  );

  async function getCompanyData(value: CompanyInfo) {
    if (tenantId && value?.taxNumber) {
      setValue("name", value?.displayName || "");
      setValue("taxNumber", value?.taxNumber || "");
      setValue("registrationNumber", value?.regNumber || "");
      setLoading(true);
      const { data } = await getCompanyInfoFromTaxNumber(tenantId, value?.taxNumber);
      setLoading(false);
      setValue("isCompany", "true");
      if (data?.detailedAddresses?.length <= 1) {
        const address = data?.detailedAddresses?.[0];
        if (address) {
          setValue("address.country", address.countryCode === "HU" ? "Magyarország" : "");
          setValue("address.city", address.city);
          setValue("address.zipCode", address.postalCode);
          setValue("address.areaName", address.streetName);
          setValue("address.houseNumber", address.number);
          setValue(
            "address.areaType",
            AREA_TYPES.find((key: any) => key === address.areaType) || "UTCA",
          );
        }
      } else {
        setOpenAddressDialog(data?.detailedAddresses);
      }
    }
  }

  const handleSearchCompanyChange = useDebouncedCallback((value: string) => {
    if (value.length >= PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH || value.length === 0) {
      setCompanySearch(value);
    }
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  return (
    <>
      <Controller
        name="companySearch"
        defaultValue={null}
        render={({ field, fieldState }) => (
          <Autocomplete
            {...field}
            onChange={(_, value: CompanyInfo) => {
              field.onChange(value);
              handleSearchCompanyChange("");
              getCompanyData(value);
            }}
            onInputChange={(_, newInputValue) => {
              handleSearchCompanyChange(newInputValue);
            }}
            options={companySearchQuery?.data || []}
            getOptionLabel={(option: any) => option.displayName}
            getOptionSelected={(option: any) => option.compId === field.value?.compId}
            style={{ width: 400 }}
            renderInput={params => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={t("company.searchTitle")}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start" style={{ margin: "0 0 0 4px" }}>
                      {companySearchQuery?.isFetching || loading ? (
                        <CircularProgress size={24} />
                      ) : (
                        <Search style={{ color: COLORS.lightGrey }} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        )}
      />
      <Dialog open={!!openAddressDialog?.length}>
        <DialogContent>
          <Typography style={{ fontWeight: "bold" }}>{t("common:choose")}</Typography>
          {openAddressDialog?.map(address => (
            <MenuItem
              onClick={() => {
                setValue("address.zipCode", address.postalCode);
                setValue("address.country", address.countryCode === "HU" ? "Magyarország" : "");
                setValue("address.city", address.city);
                setValue("address.areaName", address.streetName);
                setValue("address.houseNumber", address.number);
                setValue(
                  "address.areaType",
                  AREA_TYPES.find((key: any) => key === address.areaType) || "UTCA",
                );
                setOpenAddressDialog(null);
              }}
            >
              {`${address.postalCode}
        ${address.city
          .split(" ")
          .map((word: any) => capitalize(word))
          .join(" ")}
        ${address.streetName
          .split(" ")
          .map((word: any) => capitalize(word))
          .join(" ")},
        ${toLower(address.publicPlaceCategory)}
        ${address.number}`}
            </MenuItem>
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
};
export default CompanySearchBar;
