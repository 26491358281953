const Hu = () => {
  return (
    <svg width="24" height="24" version="1.1" viewBox="0 0 6 3">
      <rect fill="#477050" id="rect2" height="3" width="6" />
      <rect id="rect4" height="2" width="6" fill="#FFF" />
      <rect fill="#ce2939 " id="rect6" height="1" width="6" />
    </svg>
  );
};

export default Hu;
