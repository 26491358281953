import { faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Chip, IconButton, Tooltip, Typography } from "@material-ui/core";
import { GridColDef } from "@mui/x-data-grid";
import supportedLocales from "config/supportedLocales";
import { BOX_SHADOW, COLORS } from "config/theme";
import { endOfDay, format, startOfDay } from "date-fns";
import i18n from "i18n";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TableState } from "views/DeliveryRound/DeliveryRoundList";
import FilterPopover from "./FilterPopover";
import { FilterOptions } from "./PageableTable";

export type FilterOptionsEntry = {
  columnName: string;
  options?: FilterOptions[];
  subItem?: boolean;
};

type Props = {
  title?: string;
  columns: GridColDef[];
  filterable?: string[];
  filterOptions?: FilterOptionsEntry[];
  setTableState: Dispatch<SetStateAction<TableState>>;
  sessionStorageKey: string;
  id: number | string;
  defaultFilters?: Filter[];
  smallMargin?: boolean;
};

export type Filter = {
  column: GridColDef;
  value: string;
  valueFrom?: Date;
  valueTo?: Date;
  isNegate?: boolean;
};

const FilterBar = ({
  title,
  filterOptions,
  columns,
  filterable,
  setTableState,
  sessionStorageKey,
  id,
  defaultFilters,
  smallMargin,
}: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | HTMLButtonElement | null>(null);
  const [filterState, setFilterState] = useState<Filter[] | null>(defaultFilters || null);
  const [selectedFilter, setSelectedFilter] = useState<Filter | null>(null);

  function onDelete(field: string) {
    setFilterState(prevState => {
      return prevState?.filter(state => state.column.field !== field) || null;
    });
  }

  useEffect(() => {
    let temp = "";
    filterState?.forEach(filter => {
      if (
        (filter.column && filter.value) ||
        (filter.column && filter.valueFrom && filter.valueTo) ||
        (filter.column && filter.valueFrom) ||
        (filter.column && filter.valueTo)
      ) {
        if (filter.column.type === "stringContainsNumber") {
          temp += filter.isNegate
            ? `${filter.column.field}!=$${filter.value};`
            : `${filter.column.field}:$${filter.value};`;
        } else if (filter.column.type === "date") {
          temp += `${filter.column.field}>=${
            !!filter?.valueFrom && format(filter?.valueFrom, "yyyy-MM-dd")
          };${filter?.column.field}<=${!!filter.valueTo && format(filter.valueTo, "yyyy-MM-dd")}`;
        } else if (filter.column.type === "dateTime") {
          temp += `${filter.column.field}>=${
            !!filter?.valueFrom && filter?.valueFrom.toISOString()
          };${filter?.column.field}<=${!!filter.valueTo && (filter?.valueTo).toISOString()}`;
        } else if (filter.column.type === "singleDateStart") {
          temp += `${filter.column.field}=${
            !!filter?.valueFrom && format(filter?.valueFrom, "yyyy-MM-dd")
          };`;
        } else if (filter.column.type === "singleDateEnd") {
          temp += `${filter.column.field}=${
            !!filter?.valueTo && format(filter?.valueTo, "yyyy-MM-dd")
          };`;
        } else if (filter.column.type === "singleDateTimeStart") {
          temp += `${filter.column.field}=${!!filter?.valueFrom && filter?.valueFrom.toISOString()};
          `;
        } else if (filter.column.type === "singleDateTimeEnd") {
          temp += `${filter.column.field}=${!!filter?.valueTo && filter?.valueTo.toISOString()};
          `;
        } else {
          temp += filter.isNegate
            ? `${filter.column.field}!=${filter.value};`
            : `${filter.column.field}:${filter.value};`;
        }
      }
    });
    setTableState(prevState => {
      return {
        ...prevState,
        page: 0,
        filterValue: temp,
      };
    });

    sessionStorage.setItem(`rap-${sessionStorageKey}-page-number-${id}`, JSON.stringify(0));
  }, [filterState, setTableState]); //eslint-disable-line

  return (
    <Box
      display="flex"
      flexWrap={"wrap"}
      alignItems="center"
      gridGap={12}
      m={!!smallMargin ? 1 : 2}
    >
      {title && <Typography variant="h6">{title}</Typography>}
      <FilterPopover
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setSelectedFilter(null);
        }}
        columns={columns}
        filterState={filterState}
        setFilterState={setFilterState}
        defaultValues={selectedFilter}
        filterable={filterable}
        filterOptions={filterOptions}
      />
      {filterState?.map((value, index) => {
        let filterValue = value.value;
        if (value.column.type === "date") {
          filterValue = `${
            !!value?.valueFrom &&
            format(value.valueFrom, "P", {
              locale: supportedLocales[i18n.language],
            })
          } - ${
            !!value?.valueTo &&
            format(value.valueTo, "P", {
              locale: supportedLocales[i18n.language],
            })
          }`;
        } else if (value.column.type === "dateTime") {
          filterValue = `${
            !!value?.valueFrom &&
            format(value.valueFrom, "Pp", {
              locale: supportedLocales[i18n.language],
            })
          } - ${
            !!value?.valueTo &&
            format(value.valueTo, "Pp", {
              locale: supportedLocales[i18n.language],
            })
          }`;
        } else if (value.column.type === "singleDateStart") {
          filterValue = `${
            !!value.valueFrom &&
            format(value.valueFrom, "P", {
              locale: supportedLocales[i18n.language],
            })
          }`;
        } else if (value.column.type === "singleDateEnd") {
          filterValue = `${
            !!value.valueTo &&
            format(value.valueTo, "P", {
              locale: supportedLocales[i18n.language],
            })
          }`;
        } else if (value.column.type === "select") {
          filterValue =
            filterOptions
              ?.find(entry => entry.columnName === value.column.field)
              ?.options?.find(option => option.value === value.value)?.translated || "";
        } else if (value.column.type === "boolean") {
          filterValue = value.value === "true" ? "Igen" : "Nem";
        }

        return (
          <Chip
            key={index}
            label={
              <Box display="flex" gridGap={4}>
                <Typography style={{ fontWeight: "bold" }}>{value.column.headerName}:</Typography>
                {!!value.isNegate && (
                  <Typography style={{ fontStyle: "italic" }}>{t("filter.isNegate")}</Typography>
                )}
                <Typography>{filterValue}</Typography>
              </Box>
            }
            clickable
            variant="outlined"
            onClick={event => {
              setSelectedFilter(value);
              setAnchorEl(event.currentTarget);
            }}
            onDelete={() => {
              onDelete(value.column.field);
            }}
            style={{ background: "white" }}
          />
        );
      })}
      <Tooltip title={t("common:filter.newFilter").toString()}>
        <IconButton
          size="small"
          onClick={event => {
            event.stopPropagation();
            setSelectedFilter(null);
            setAnchorEl(event?.currentTarget);
          }}
          style={{
            color: COLORS.white,
            backgroundColor: COLORS.mainLight,
            boxShadow: BOX_SHADOW,
            height: 32,
            width: 32,
          }}
        >
          <FontAwesomeIcon
            icon={faFilter}
            style={{
              fontSize: 14,
              width: 14,
              color: COLORS.white,
            }}
          />
        </IconButton>
      </Tooltip>
      {!!filterState?.length && (
        <Tooltip title={t("filter.deleteAll")}>
          <IconButton
            size="small"
            onClick={event => {
              event.stopPropagation();
              setSelectedFilter(null);
              setFilterState(null);
            }}
            style={{
              backgroundColor: COLORS.white,
              color: COLORS.red,
              border: `2px solid ${COLORS.red}`,
              boxShadow: BOX_SHADOW,
              height: 32,
              width: 32,
            }}
          >
            <FontAwesomeIcon
              icon={faTimes}
              style={{
                fontSize: 20,
                width: 20,
              }}
            />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default FilterBar;
