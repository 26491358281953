import { Box, Button, Container, IconButton, Tooltip } from "@material-ui/core";
import { AddBox, Delete, Edit } from "@material-ui/icons";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import SingleQueryTable from "components/PageableTable/SingleQueryTable";
import { RootState } from "config/store";
import supportedLocales from "config/supportedLocales";
import { format } from "date-fns";
import i18n from "i18n";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { deleteSalary, listSalaryByEmployee } from "shared/network/salary.api";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";

const SalaryList = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const { enqueueSnackbar } = useSnackbar();
  const { setHeaderButtons } = useHeader();
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-list-salary-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-list-salary-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant.tenant;

  const salaryList = useQuery(["salaryList", id], async () => {
    if (id) {
      const { data } = await listSalaryByEmployee(id, tenant.id);
      return data;
    }
    return Promise.reject();
  });

  const columns: GridColDef[] = [
    {
      field: "superNet",
      headerName: t("salary.formValues.superNet"),
      flex: 1,
      sortable: false,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.salaryCurrency.prefix +
        t("common:number", { num: row.superNet }) +
        " " +
        row.salaryCurrency.postfix,
    },
    {
      field: "salary",
      headerName: t("salary.formValues.salary"),
      flex: 1,
      sortable: false,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.salaryCurrency.prefix +
        t("common:number", { num: row.salary }) +
        " " +
        row.salaryCurrency.postfix,
    },
    {
      field: "superGross",
      headerName: t("salary.formValues.superGross"),
      flex: 1,
      sortable: false,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.salaryCurrency.prefix +
        t("common:number", { num: row.superGross }) +
        " " +
        row.salaryCurrency.postfix,
    },
    {
      field: "hourlyWage",
      headerName: t("salary.formValues.hourlyWage"),
      flex: 1,
      sortable: false,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.salaryCurrency.prefix +
        t("common:number", { num: row.hourlyWage }) +
        " " +
        row.salaryCurrency.postfix,
    },
    {
      field: "overHeadCharge",
      headerName: t("salary.formValues.overHeadCharge"),
      flex: 1,
      sortable: false,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.salaryCurrency.prefix +
        t("common:number", { num: row.overHeadCharge }) +
        " " +
        row.salaryCurrency.postfix,
    },
    {
      field: "date",
      headerName: t("salary.formValues.date"),
      flex: 1.5,
      sortable: false,
      valueGetter: ({ row }: GridValueGetterParams) =>
        `${format(new Date(row.dateFrom), "P", {
          locale: supportedLocales[i18n.language],
        })} - ${format(new Date(row.dateTo), "P", {
          locale: supportedLocales[i18n.language],
        })}`,
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "EMPLOYEE_ADMIN",
          ]) && (
            <>
              <Tooltip title={t("salary.modify").toString()}>
                <IconButton
                  component={Link}
                  to={`/my-company/employees/employee-details/salaries/salary-modify?id=${row.id}&backId=${id}`}
                  size="small"
                  color="primary"
                >
                  <Edit color="primary" />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("salary.delete").toString()}>
                <IconButton
                  onClick={() => {
                    if (row.id) {
                      removeSalary(row.id);
                    }
                  }}
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                >
                  <Delete color="primary" />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      ),
    },
  ];

  async function removeSalary(id: number) {
    try {
      await deleteSalary(id, tenant.id);
      salaryList.refetch();
      enqueueSnackbar(
        t("common:notification.delete.success", {
          subject: t("salary.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch (e) {
      enqueueSnackbar(
        t("common:notification.delete.failure", {
          subject: t("salary.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  }

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, ["EMPLOYEE_ADMIN"]) && (
          <Button
            component={Link}
            to={`/my-company/employees/employee-details/salaries/salary-create?id=${id}`}
            startIcon={<AddBox />}
          >
            {t("salary.create")}
          </Button>
        )}
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant]); //eslint-disable-line

  return (
    <Container>
      <SingleQueryTable
        query={salaryList}
        columns={columns}
        setTableState={setTableState}
        tableState={tableState}
        sessionStorageKey={`salaryList_${tenant.id}`}
      />
    </Container>
  );
};

export default SalaryList;
