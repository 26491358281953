import { Box, CircularProgress, Container } from "@material-ui/core";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SliceStatus } from "shared/common";
import { createCompany } from "shared/network/company.api";
import CompanyForm, { CompanyFormValues } from "./components/CompanyForm";
import { removeEmptyStrings } from "shared/util/removeEmptyStrings";
import { Dispatch, SetStateAction, useState } from "react";
import queryClient from "config/query";
import CompanySmallForm from "./components/CompanySmallForm";

type Props = {
  setCompanyCreateState?: Dispatch<SetStateAction<boolean>>;
  isSmall?: boolean;
};

const CompanyCreate = ({ setCompanyCreateState, isSmall }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const form = useForm<CompanyFormValues>();

  const [status, setStatus] = useState<SliceStatus>("idle");

  const onSubmitCreate = async (values: CompanyFormValues) => {
    setStatus("pending");
    try {
      await createCompany(
        {
          ...removeEmptyStrings(values),
          paymentDueDays: values.paymentDueDays || 0,
          isCompany: values.isCompany === "true",
        },
        tenant.id,
      );
      setStatus("success");
      enqueueSnackbar(
        t("common:notification.create.success", {
          subject: t("company.subject"),
        }),
        {
          variant: "success",
        },
      );
      if (setCompanyCreateState) {
        setCompanyCreateState?.(false);
        queryClient.invalidateQueries("companyListForInvoice");
      } else {
        history.goBack();
      }
    } catch (error: any) {
      if (error.data?.status === "CONFLICT") {
        enqueueSnackbar(
          t("common:notification.create.failureWithConflict", {
            subject: t("company.subject"),
          }),
          {
            variant: "error",
          },
        );
        setStatus("failure");
      } else {
        enqueueSnackbar(
          t("common:notification.create.failure", {
            subject: t("company.subject"),
          }),
          {
            variant: "error",
          },
        );
      }
      setStatus("failure");
    }
  };
  return (
    <Container maxWidth="lg">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmitCreate)}>
          {status === "pending" ? (
            <Box display="flex" alignItems="center" justifyContent="center" height="300px">
              <CircularProgress />
            </Box>
          ) : isSmall ? (
            <CompanySmallForm setCompanyCreateState={setCompanyCreateState} />
          ) : (
            <CompanyForm setCompanyCreateState={setCompanyCreateState} />
          )}
        </form>
      </FormProvider>
    </Container>
  );
};

export default CompanyCreate;
