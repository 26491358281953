import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Switch,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import {
  AddBox,
  AddCircle,
  Assignment,
  Info,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Print,
  Receipt,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useHeader } from "components/Layout/HeaderContext";
import Loading from "components/Loading";
import {
  BACKEND_URL,
  PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY,
  PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH,
  PAYMENT_TYPE,
} from "config/constants";
import theme from "config/roraTheme";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { addDays, format } from "date-fns";
import { round } from "lodash";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { getCompanyById, listCompanyByTenant } from "shared/network/company.api";
import { getCompanySiteListPageable } from "shared/network/companysite.api";
import { getContractById, listContract } from "shared/network/contract.api";
import { listCurrencyByTenant } from "shared/network/currency.api";
import { createLightDelivery } from "shared/network/delivery.api";
import {
  getInvoiceForOrder,
  getInvoicePreview,
  invoiceGenerateFromOrder,
} from "shared/network/invoice.api";
import { getOfferById, listOfferByTenant } from "shared/network/offer.api";
import { addItemToOrder, createLightOrder, getOrderPdf } from "shared/network/order.api";
import { getProjectById, listProject } from "shared/network/project.api";
import { ScalingX } from "shared/network/scaling-x.api";
import { ScalingY } from "shared/network/scaling-y.api";
import {
  BaseCurrency,
  Company,
  CompanySite,
  Contract,
  Item,
  ItemPackaging,
  ItemPriceHistory,
  ItemTaxHistory,
  Offer,
  Order,
  Packaging,
  Project,
  RelContractItemEntry,
  RelOfferItemEntry,
  RelProjectItemEntry,
} from "shared/types";
import displayAddress from "shared/util/displayAddress";
import { b64toBlob } from "shared/util/fileDownload";
import { removeEmptyStrings } from "shared/util/removeEmptyStrings";
import { useDebouncedCallback } from "use-debounce";
import CompanyCreate from "views/Company/CompanyCreate";
import ItemQrReader from "views/Items/ItemDetails/ItemQrCode";
import FastOrderFormItem from "./FastOrderFormItem";

export type OrderFormValues = {
  id: undefined;
  isWithoutPrice: boolean;
  order: {
    isPaid: boolean;
    deliveryName: string;
    deliveryComment: string;
    deliveryFrom: Date | null;
    currency: BaseCurrency;
    orderName: string;
    isOneItem: boolean;
  };
  company: any; //Company | null;
  companySite: CompanySite | null;
  offer: Offer | null;
  contract: Contract | null;
  project: any; //Project | null;
  relOrderItem: any[]; //OrderItemEntry[];
  fullNetPrice: string;
  fullGrossPrice: string;
  paymentType: string;
  showComment: boolean;
  showPackageSize: boolean;
};

export type OrderItemEntry = {
  id?: string;
  quantity: string;
  price: string;
  netPrice: string;
  tax: string;
  amountType: ItemPackaging | null;
  item: Item;
  serialNumber?: string;
  scalingX?: ScalingX;
  scalingY?: ScalingY;
  discount?: number;
  description?: string;
};

type Props = {
  isLoading?: boolean;
  order: Order | null;
  setOrder: any;
  setStatus: any;
  setStatusDialogOpen: Dispatch<SetStateAction<boolean>>;
  saveForm: (values: any) => Promise<void>;
};

const useStyles = makeStyles({
  switch: {
    "&.MuiFormControlLabel-labelPlacementStart": {
      paddingLeft: 8,
      marginRight: 0,
      flexDirection: "row-reverse",
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
  },
});

export const noPackage = {
  packaging: { name: "Egység" } as Packaging,
  quantity: 1,
} as ItemPackaging;

const FastOrderForm = ({ order, setOrder, setStatus, saveForm, isLoading }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const ownCompany = account?.user?.relUserCompany?.[0]?.company;
  const tenant = selectedRelTenant.tenant;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const urlCreator = window.URL || window.webkitURL;
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const companyId = query.get("companyId");
  const offerId = new URLSearchParams(location.search).get("offerId");

  const { control, watch, getValues, setValue, trigger, reset, formState } =
    useFormContext<OrderFormValues>();
  const { append, fields, remove, replace } = useFieldArray({
    control,
    name: "relOrderItem",
    keyName: "key",
  });

  const company = watch("company");
  const project = watch("project");
  const offer = watch("offer");
  const contract = watch("contract");
  const currency = watch("order.currency");
  const isWithoutPrice = watch("isWithoutPrice");
  const relOrderItems = watch("relOrderItem");
  const showPackageSize = watch("showPackageSize");
  const isOneItem = watch("order.isOneItem");

  useEffect(() => {
    if (relOrderItems?.length === 0) {
      append({});
    }
  }, [append, relOrderItems?.length]);

  const [companySearch, setCompanySearch] = useState("");
  const [companySiteSearch, setCompanySiteSearch] = useState("");
  const [contractSearch, setContractSearch] = useState("");
  const [offerSearch, setOfferSearch] = useState("");
  const [projectSearch, setProjectSearch] = useState("");
  const [openScanner, setOpenScanner] = useState(false);
  const [companyCreateState, setCompanyCreateState] = useState(false);

  useQuery(
    ["offerQuery", tenant.id, offerId],
    async () => {
      if (offerId) {
        const { data } = await getOfferById(offerId, tenant.id);
        return data.item;
      }
      return Promise.reject();
    },
    {
      enabled: !!offerId,
      onSuccess: data => {
        if (data && !order) {
          setValue("company", data.company);
          setValue("offer", data);
          setValue("order.deliveryComment", data.company?.description || "");
          setValue(
            "relOrderItem",
            data?.relOfferItems?.map((entry: RelOfferItemEntry) => {
              return {
                quantity: entry?.quantity,
                netPrice: entry?.netPrice,
                tax:
                  entry?.item?.itemTaxHistories.find((entry: any) => entry.isActive)?.tax
                    ?.percent || 0,
                item: entry?.item,
                serialNumber: entry?.serialNumber,
                amountType: noPackage,
              };
            }) as any,
          );
        }
      },
    },
  );

  const offerByIdQuery = useQuery(
    ["offerByIdQuery", offer?.id],
    async () => {
      if (offer?.id) {
        const { data } = await getOfferById(offer.id, tenant.id);
        return data.item;
      }
      return Promise.reject();
    },
    { enabled: !!offer },
  );

  useEffect(() => {
    fields.forEach((_, index) => setItemPrice(index));
  }, [offer, offerByIdQuery.data]); //eslint-disable-line

  useEffect(() => {
    if (fields.length === 0 || (fields.length === 1 && !fields?.[0]?.item)) {
      replace(
        offerByIdQuery.data?.relOfferItems?.map((relItem: RelOfferItemEntry) => {
          return {
            quantity: relItem.quantity.toString(),
            netPrice: (relItem.netPrice * relItem.quantity).toString(),
            tax:
              relItem.item?.itemTaxHistories
                .find(entry => entry.isActive)
                ?.tax?.percent?.toString() || "0",
            amountType: noPackage,
            item: relItem.item || undefined,
            serialNumber: "",
          };
        }) || [],
      );
    }
  }, [offerByIdQuery.data, setValue, replace, order]); //eslint-disable-line

  const contractByIdQuery = useQuery(
    ["contractByIdQuery", tenant.id, contract?.id],
    async () => {
      const { data } = await getContractById(tenant.id, contract?.id);
      return data.item;
    },
    { enabled: !!contract },
  );

  const projectByIdQuery = useQuery(
    ["projectByIdQuery", tenant.id, project?.id],
    async () => {
      if (project?.id) {
        const { data } = await getProjectById(tenant.id, project?.id);
        return data.item;
      }
      return Promise.reject();
    },
    { enabled: !!project },
  );

  const companyListQuery = useQuery(
    ["orderCompanyListQuery", tenant.id, companySearch],
    async () => {
      const { data } = await listCompanyByTenant(
        0,
        10,
        tenant.id,
        companySearch ? `name:${companySearch}` : "",
      );
      return data?.page?.content?.sort((a, b) => a.name.localeCompare(b.name));
    },
  );

  const offerListForOrderQuery = useQuery(
    ["offerListForOrderQuery", tenant.id, company?.id, offerSearch],
    async () => {
      const { data } = await listOfferByTenant(
        0,
        10,
        tenant.id,
        `company.id:${company?.id};status!=DECLINED;` +
          (offerSearch ? `(name:${offerSearch};(OR)identifier:${offerSearch};)` : ""),
      );

      return data.page.content;
    },
    { enabled: !!company },
  );

  const contractListForOrderQuery = useQuery(
    ["contractListForOrderQuery", tenant.id, company?.id, offer, contractSearch],
    async () => {
      const { data } = await listContract(
        0,
        10,
        tenant.id,
        (contractSearch ? `(name:${contractSearch};(OR)identifier:${contractSearch};)` : "") +
          (offer ? `offer.id:${offer.id};` : "") +
          `company.id:${company?.id}`,
      );

      return data.page.content;
    },
    { enabled: !!company },
  );

  const projectListQuery = useQuery(
    ["projectListQuery", tenant.id, company?.id, offer, contract, projectSearch],
    async () => {
      const { data } = await listProject(
        0,
        10,
        tenant.id,
        (projectSearch ? `(name:${projectSearch};(OR)identifier:${projectSearch};)` : "") +
          (offer ? `offer.id:${offer.id};` : "") +
          (contract ? `contract.id:${contract.id};` : "") +
          `company.id:${company?.id}`,
      );

      return data.page.content;
    },
    { enabled: !!company },
  );

  const companySiteListQuery = useQuery(
    ["companySiteListPageableQuery", tenant.id, company?.id, companySiteSearch],
    async () => {
      if (company?.id) {
        const { data } = await getCompanySiteListPageable(
          0,
          10,
          tenant.id,
          (companySiteSearch ? `name:${companySiteSearch};` : "") + `company.id:${company.id}`,
        );
        return data.page.content;
      }
      return Promise.reject();
    },
    { enabled: !!company },
  );

  const currencyListQuery = useQuery(["currencyListForOffer", tenant.id], async () => {
    const { data } = await listCurrencyByTenant(0, 1000000, tenant.id, "");
    return data.page.content;
  });

  const getInvoiceForOrderQuery = useQuery(
    ["getInvoiceForOrderQuery", order?.id, tenant?.id],
    async () => {
      if (order?.id) {
        const { data } = await getInvoiceForOrder(order.id, tenant.id);
        return data;
      }
    },
  );

  const normalInvoice = getInvoiceForOrderQuery.data?.normalInvoice;
  const requesterInvoice = getInvoiceForOrderQuery.data?.requesterInvoice;

  function getNetAndGrossPrice() {
    let netPrice = 0;
    let grossPrice = 0;
    fields.forEach((field, index) => {
      let item = watch(`relOrderItem.${index}`);
      netPrice += Number.parseFloat(item?.netPrice);
      grossPrice += Number.parseFloat(item?.netPrice) * (1 + Number.parseFloat(item?.tax) / 100);
    });
    return { netPrice, grossPrice };
  }

  function getItemPrice(item: Item, index: number) {
    if (!item) {
      return { price: undefined, tax: undefined, quantity: undefined };
    }
    let id = Number.parseInt(item.id.toString());
    let price = 0;
    if (item.isScalingPricing) {
      price = Number(getValues(`relOrderItem.${index}.netPrice`));
    } else {
      price =
        getItemPriceFromProject(id) ||
        getItemPriceFromContract(id) ||
        getItemPriceFromOffer(id) ||
        getItemPriceFromBase(item);
    }

    const tax = item?.itemTaxHistories?.find(tax => tax.isActive)?.tax?.percent || 0;
    const selectedPackage = getValues(`relOrderItem.${index}.amountType`);
    const selectedQuantity = Number.parseFloat(getValues(`relOrderItem.${index}.quantity`)) || 1;
    const quantity =
      selectedPackage?.packaging?.name === "Egység"
        ? selectedQuantity
        : (selectedPackage?.quantity || 1) * selectedQuantity;

    return { price, tax, quantity };
  }

  function getItemPriceFromProject(id: number) {
    if (projectByIdQuery.data) {
      const relItem = projectByIdQuery.data?.projectItems?.find(
        (relItem: RelProjectItemEntry) => relItem?.item?.id === id,
      );
      setValue("order.currency", projectByIdQuery.data?.currency);
      return relItem?.netPrice || null;
    }
    return null;
  }

  function getItemPriceFromContract(id: number) {
    if (contractByIdQuery?.data) {
      const relItem = contractByIdQuery?.data?.contractItems?.find(
        relItem => relItem?.item?.id === id,
      );
      setValue("order.currency", contractByIdQuery.data?.currency);
      return relItem?.netPrice || null;
    }
    return null;
  }

  function getItemPriceFromOffer(id: number) {
    if (offerByIdQuery.data) {
      const relItem = offerByIdQuery.data?.relOfferItems?.find(relItem => relItem?.item?.id === id);
      setValue("order.currency", offerByIdQuery.data?.currency);
      return relItem?.netPrice || null;
    } else {
      return null;
    }
  }

  function getItemPriceFromBase(item: Item) {
    const orderCurrency = getValues("order.currency");
    const itemPriceHistory = item?.itemPriceHistories?.find(
      price => price.isActive && price?.currency?.id === orderCurrency?.id,
    );
    return itemPriceHistory?.price || 0;
  }

  function setItemPrice(index: number) {
    const item: Item = getValues(`relOrderItem.${index}.item`);

    const { price, tax, quantity } = getItemPrice(item, index);
    if (price !== undefined && tax !== undefined && quantity !== undefined) {
      const rowPrice = (Number.parseFloat(price?.toString() || "") || 0) * quantity;
      setValue(`relOrderItem.${index}.netPrice`, (rowPrice || 0).toFixed(2));
      setValue(`relOrderItem.${index}.tax`, (tax || 0).toString());
      const { netPrice, grossPrice } = getNetAndGrossPrice();
      setValue("fullNetPrice", netPrice.toFixed(0));
      setValue("fullGrossPrice", grossPrice.toFixed(0));
    }
  }

  const handleSearch = useDebouncedCallback((value: string, source: string) => {
    if (value.length >= PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH || value.length === 0) {
      switch (source) {
        case "COMPANY":
          setCompanySearch(value);
          break;
        case "CONTRACT":
          setContractSearch(value);
          break;
        case "OFFER":
          setOfferSearch(value);
          break;
        case "PROJECT":
          setProjectSearch(value);
          break;
        case "COMPANY_SITE":
          setCompanySiteSearch(value);
          break;
        default:
          return;
      }
    }
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  useEffect(() => {
    fields.forEach((_, index) => setItemPrice(index));
  }, [offer, contractByIdQuery.data, offerByIdQuery.data, projectByIdQuery.data]); //eslint-disable-line

  useEffect(() => {
    if (offerByIdQuery.data?.relOfferItems && fields.length === 0) {
      replace(
        offerByIdQuery.data?.relOfferItems?.map((relItem: RelOfferItemEntry) => {
          return {
            quantity: relItem.quantity.toString(),
            netPrice: (relItem.netPrice * relItem.quantity).toString(),
            tax:
              relItem.item?.itemTaxHistories
                .find(entry => entry.isActive)
                ?.tax?.percent?.toString() || "0",
            amountType: noPackage,
            item: relItem.item || undefined,
            serialNumber: "",
          };
        }) || [],
      );
    }
  }, [offerByIdQuery.data, setValue, replace]); //eslint-disable-line

  useEffect(() => {
    if (contractByIdQuery.data?.contractItems && fields.length === 0) {
      replace(
        contractByIdQuery.data?.contractItems.map((relItem: RelContractItemEntry) => {
          return {
            quantity: relItem.quantity.toString(),
            netPrice: (relItem.netPrice * relItem.quantity).toString(),
            tax:
              relItem.item?.itemTaxHistories
                .find((entry: ItemTaxHistory) => entry.isActive)
                ?.tax?.percent?.toString() || "0",
            amountType: noPackage,
            item: relItem.item || undefined,
            serialNumber: "",
          };
        }) || [],
      );
      if (contractByIdQuery.data?.paymentType) {
        setValue("paymentType", contractByIdQuery.data.paymentType);
      }
    }
  }, [contractByIdQuery.data, setValue, replace]); //eslint-disable-line

  useEffect(() => {
    if (projectByIdQuery.data?.projectItems && fields.length === 0) {
      replace(
        projectByIdQuery.data?.projectItems.map((relItem: RelProjectItemEntry) => {
          return {
            quantity: relItem.quantity.toString(),
            netPrice: (relItem.netPrice * relItem.quantity).toString(),
            tax:
              relItem.item?.itemTaxHistories
                .find(entry => entry.isActive)
                ?.tax?.percent?.toString() || "0",
            amountType: noPackage,
            item: relItem.item || undefined,
            serialNumber: "",
          };
        }) || [],
      );
    }
  }, [projectByIdQuery.data, setValue, replace]); //eslint-disable-line

  useEffect(() => {
    if (company) {
      setValue("companySite", companySiteListQuery?.data?.[0] || null);
      setValue("order.deliveryName", company?.name || "");
      setValue("order.deliveryComment", company?.description || "");
    }
  }, [company, companySiteListQuery?.data, getValues, setValue]);

  let relOrderItem = watch(`relOrderItem`);

  const [optionsOpen, setOptionsOpen] = useState(false);
  const [openItem, setOpenItem] = useState<number | null>(null);
  const [openAmountType, setOpenAmountType] = useState<number | null>(null);
  const [rowLoading, setRowLoading] = useState(false);

  const firstSave = async (values: OrderFormValues) => {
    setRowLoading(true);
    setStatus("pending");
    try {
      const request = {
        ...removeEmptyStrings(values),
        order: {
          ...values.order,
          deliveryFrom: values.order.deliveryFrom
            ? format(new Date(values.order.deliveryFrom), "yyyy-MM-dd")
            : undefined,
          deliveryTo: values.order.deliveryFrom
            ? format(new Date(values.order.deliveryFrom), "yyyy-MM-dd")
            : undefined,
          deliveryName: values.order.deliveryName || null,
          deliveryComment: values.order.deliveryComment || null,
          deliveryAddress: values?.companySite?.address || null,
          paymentType: values.paymentType,
        },
        offer: values.offer || undefined,
        project: values.project || undefined,
        contract: values.contract || undefined,
        fullGrossPrice: undefined,
        fullNetPrice: undefined,
        companySiteId: values?.companySite?.id || null,
        relOrderItem: values.relOrderItem.map(item => {
          return {
            ...item,
            description: item.description,
            quantity: Number(item.quantity || 1) * Number(item.amountType?.quantity || 1),
          };
        }),
        companySite: values.companySite,
      };

      const { data } = await createLightOrder(request, tenant.id);
      setOrder(data?.item);
      append({});

      enqueueSnackbar(
        t("common:notification.create.success", {
          subject: t("order.subject"),
        }),
        { variant: "success" },
      );
      setStatus("success");
    } catch (e: any) {
      enqueueSnackbar(
        t("common:notification.create.failure", {
          subject: t("order.subject"),
        }),
        { variant: "error" },
      );
      setStatus("failure");
    }
    setStatus("success");
    setRowLoading(false);
  };

  useEffect(() => {
    document.getElementById(`relOrderItem.${fields?.length - 1}.item`)?.focus();
  }, [fields?.length]);

  const [loading, setLoading] = useState(false);

  async function invoiceGenerate(isRequester: boolean) {
    const result = await trigger();
    if (result) {
      await saveForm(getValues());
      setLoading(true);
      try {
        if (order) {
          const { data } = await invoiceGenerateFromOrder({
            orderId: order.id,
            tenantId: tenant.id,
          });

          if (data?.invoice) {
            history.push(
              `/invoices/invoices-list/invoice-create?isLight=true&isRequester=${isRequester}`,
              data,
            );
          } else {
            enqueueSnackbar(t("contract.noInvoiceData"), { variant: "info" });
          }
        }
      } catch {
        enqueueSnackbar(t("contract.invoiceError"), {
          variant: "error",
        });
      }
      setLoading(false);
    }
  }

  async function deliveryGenerate() {
    const result = await trigger();
    if (result) {
      await saveForm(getValues());
      setLoading(true);
      try {
        if (order) {
          const { data } = await createLightDelivery(
            {
              date: format(new Date(), "yyyy-MM-dd"),
              order: order,
              deliveryOrder: 1,
            },
            tenant?.id,
          );
          if (data?.item?.deliveryNote?.id) {
            window.open(
              `${BACKEND_URL}/files/delivery-note/${data.item.deliveryNote.id}`,
              "_blank",
              "resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no",
            );
          } else {
            enqueueSnackbar(t("fastOrder.noteError"), { variant: "error" });
          }
        }
      } catch {
        enqueueSnackbar(t("fastOrder.noteError"), { variant: "error" });
      }
      setLoading(false);
    }
  }

  const [disableDownKey, setDisableDownKey] = useState(false);
  async function addNewRow(event: any, index: number) {
    setDisableDownKey(true);
    const result = await trigger();
    if (result) {
      if (
        (event?.key === "ArrowDown" || event?.key === "Tab") &&
        openItem === null &&
        openAmountType === null
      ) {
        if (!order) {
          setRowLoading(true);
          await firstSave(watch());
          setRowLoading(false);
        } else {
          try {
            setRowLoading(true);
            await Promise.all(
              relOrderItems
                ?.filter(
                  (item, index) => !item?.id || formState?.dirtyFields?.relOrderItem?.[index],
                )
                ?.map(async (item, index) => {
                  return await addItemToOrder(
                    {
                      ...item,
                      order,
                      tenantId: tenant?.id,
                      createdBy: account?.user.id,
                      updatedBy: account?.user.id,
                      price: item.netPrice * (0.01 * item?.tax + 1),
                    },
                    tenant?.id,
                  );
                }),
            ).then(data => {
              data?.[data?.length - 1]?.data?.item?.relOrderItems?.forEach((relItem, i) => {
                setValue(`relOrderItem.${i}.id`, relItem.id);
              });
            });
            setRowLoading(false);
            append({});
            if (
              relOrderItems?.filter(
                (item, index) => !item?.id || formState?.dirtyFields?.relOrderItem?.[index],
              )?.length > 0
            ) {
              enqueueSnackbar(
                t("common:notification.save.success", {
                  subject: t("order.subject"),
                }),
                { variant: "success" },
              );
            }
          } catch {
            enqueueSnackbar(
              t("common:notification.save.failure", {
                subject: t("order.subject"),
              }),
              { variant: "error" },
            );
          }
        }
        getInvoiceForOrderQuery.refetch();
      } else {
        append({});
      }
    } else {
      document.getElementById(`relOrderItem.${index}.item`)?.focus();
    }
    setDisableDownKey(false);
    setRowLoading(false);
    reset({}, { keepValues: true });
  }

  const companyQuery = useQuery(["companyByIdQuery", companyId], async () => {
    if (companyId) {
      const { data } = await getCompanyById(Number(companyId), selectedRelTenant.tenant.id);
      return data.item;
    }
    return Promise.reject();
  });

  useEffect(() => {
    setValue(`company`, order?.company || companyQuery?.data || null);
    order?.relOrderItems?.forEach((relItem, index) => {
      setValue(`relOrderItem.${index}.id`, relItem.id);
    });
  }, [companyQuery?.data, order?.company, order?.relOrderItems, setValue]);

  async function getPreview(invoice: any) {
    const result = await trigger();
    if (result) {
      await saveForm(getValues());
      setLoading(true);
      try {
        const { data } = await getInvoicePreview(invoice, tenant.id);
        const link = document.createElement("a");
        link.href = urlCreator.createObjectURL(b64toBlob(data?.item?.content, "application/pdf"));
        window.open(
          link.href,
          "_blank",
          "resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no",
        );
        link.remove();
      } catch (error) {
        enqueueSnackbar(t("fastOrder.pdfError"), { variant: "error" });
      }
      setLoading(false);
    }
  }

  const { setHeaderName } = useHeader();
  useEffect(() => {
    if (order?.orderId) {
      setHeaderName(`${order?.orderId} megrendelés`);
    }
    return () => {
      setHeaderName(null);
    };
  }, [order?.orderId]);

  let allNetPrice = 0;
  let allGrossPrice = 0;
  let allDiscountPrice = 0;
  fields.forEach((_, index) => {
    const item = relOrderItem?.[index];

    allNetPrice += Number(item.netPrice) * (1 - item.discount * 0.01);
    const grossPrice = Number(item.netPrice * (1 + Number(item.tax) * 0.01));
    allGrossPrice += grossPrice * (1 - item.discount * 0.01);
    allDiscountPrice += grossPrice * item.discount * 0.01;
  });

  return (
    <>
      <Dialog
        open={companyCreateState}
        fullWidth
        maxWidth="md"
        onClose={() => setCompanyCreateState(false)}
      >
        <DialogTitle>{t("company.create")}</DialogTitle>
        <DialogContent style={{ padding: 0 }}>
          <CompanyCreate setCompanyCreateState={setCompanyCreateState} isSmall />
        </DialogContent>
      </Dialog>
      <Loading open={companyListQuery.isFetching || loading || rowLoading} />

      {hasAuthority(account.user, account.permissions, selectedRelTenant, ["ORDER_ADMIN"]) && (
        <Box display="flex" flexWrap="wrap" gridGap={8} paddingBottom={1}>
          <ButtonGroup color="primary" aria-label="outlined primary button group">
            <Button
              variant="outlined"
              style={{
                height: 30,
                borderColor: COLORS.main,
                color: "white",
                backgroundColor: COLORS.main,
                fontSize: 14,
                textTransform: "unset",
              }}
              disabled
            >
              {t("invoice.titleView")}
            </Button>
            {normalInvoice ? (
              <Button
                disabled={!order}
                variant="outlined"
                style={{ height: 30, borderColor: COLORS.main, fontSize: 12, padding: 8 }}
                onClick={() => getPreview(normalInvoice)}
              >
                <Print fontSize="small" style={{ marginRight: 4 }} />
                {t("fastOrder.print")}
              </Button>
            ) : (
              <Button
                disabled={!order}
                variant="outlined"
                style={{ height: 30, borderColor: COLORS.main, fontSize: 12 }}
                onClick={() => invoiceGenerate(false)}
                startIcon={<Receipt />}
              >
                {t("fastOrder.createDoc")}
              </Button>
            )}
            {normalInvoice && (
              <Button
                disabled={!order}
                variant="outlined"
                style={{ height: 30, borderColor: COLORS.main, fontSize: 12, padding: 6 }}
                onClick={() =>
                  history.push(`/invoices/invoices-list/invoice-details?id=${normalInvoice?.id}`)
                }
              >
                <Assignment fontSize="small" style={{ marginRight: 4 }} />
                {t("fastOrder.open")}
              </Button>
            )}
          </ButtonGroup>

          <ButtonGroup color="primary" aria-label="outlined primary button group">
            <Button
              variant="outlined"
              style={{
                height: 30,
                borderColor: COLORS.main,
                color: "white",
                backgroundColor: COLORS.main,
                fontSize: 14,
                textTransform: "unset",
              }}
              disabled
            >
              {t("invoice.formValues.invoiceType.REQUESTER")}
            </Button>
            {/* {requesterInvoice?.paymentType === "CARD" && !requesterInvoice?.paidDate && (
              <Button
                variant="outlined"
                style={{ height: 30, borderColor: COLORS.main, fontSize: 12, padding: 8 }}
                onClick={() => openRequesterQr()}
              >
                <Tooltip title={t("invoice.qrTitle")}>
                  <Payment />
                </Tooltip>
              </Button>
            )} */}
            {requesterInvoice ? (
              <Button
                variant="outlined"
                style={{ height: 30, borderColor: COLORS.main, fontSize: 12, padding: 8 }}
                onClick={() => getPreview(requesterInvoice)}
                disabled={!order}
              >
                <Print fontSize="small" style={{ marginRight: 4 }} />
                {t("fastOrder.print")}
              </Button>
            ) : (
              <Button
                disabled={!order}
                variant="outlined"
                style={{ height: 30, borderColor: COLORS.main, fontSize: 12 }}
                onClick={() => invoiceGenerate(true)}
                startIcon={<Receipt />}
              >
                {t("fastOrder.createDoc")}
              </Button>
            )}
            {requesterInvoice && (
              <Button
                disabled={!order}
                variant="outlined"
                style={{ height: 30, borderColor: COLORS.main, fontSize: 12, padding: 6 }}
                onClick={() =>
                  history.push(`/invoices/invoices-list/invoice-details?id=${requesterInvoice?.id}`)
                }
              >
                <Assignment fontSize="small" style={{ marginRight: 4 }} />
                {t("fastOrder.open")}
              </Button>
            )}
          </ButtonGroup>

          <ButtonGroup color="primary" aria-label="outlined primary button group">
            <Button
              variant="outlined"
              style={{
                height: 30,
                borderColor: COLORS.main,
                color: "white",
                backgroundColor: COLORS.main,
                fontSize: 14,
                textTransform: "unset",
              }}
              disabled
            >
              {t("connectionData.deliveryNote")}
            </Button>
            <Button
              disabled={!order}
              variant="outlined"
              style={{ height: 30, borderColor: COLORS.main, fontSize: 12, padding: 8 }}
              onClick={() => deliveryGenerate()}
            >
              <Print fontSize="small" style={{ marginRight: 4 }} />
              {t("fastOrder.print")}
            </Button>
          </ButtonGroup>
          <ButtonGroup color="primary" aria-label="outlined primary button group">
            <Button
              variant="outlined"
              style={{
                height: 30,
                borderColor: COLORS.main,
                color: "white",
                backgroundColor: COLORS.main,
                fontSize: 14,
                textTransform: "unset",
              }}
              disabled
            >
              {t("connectionData.warehouseLetter")}
            </Button>
            <Button
              disabled={!order}
              variant="outlined"
              style={{ height: 30, borderColor: COLORS.main, fontSize: 12, padding: 8 }}
              onClick={async () => {
                const result = await trigger();
                if (result) {
                  await saveForm(getValues());
                  setLoading(true);
                  if (order) {
                    try {
                      await getOrderPdf({ ...order, isWithoutPrice: !!isWithoutPrice }, tenant?.id);
                      window.open(
                        `${BACKEND_URL}/files/order/${order?.id}`,
                        "_blank",
                        "resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no",
                      );
                    } catch (error) {
                      enqueueSnackbar(t("fastOrder.pdfError"), {
                        variant: "error",
                      });
                    }
                  }
                  setLoading(false);
                }
              }}
            >
              <Print fontSize="small" style={{ marginRight: 4 }} />
              {t("fastOrder.print")}
            </Button>
          </ButtonGroup>
        </Box>
      )}
      <Box>
        <Card style={{ padding: 16 }}>
          <Box display="flex" alignItems="center" width="100%">
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <Controller
                  control={control}
                  name="order.orderName"
                  defaultValue={""}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("order.orderName")}
                      InputLabelProps={{ shrink: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <Controller
                  control={control}
                  name="company"
                  defaultValue={order?.company || null}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      onChange={(_, value) => {
                        field.onChange(value);
                        setValue("contract", null);
                        setValue("project", null);
                        setValue("offer", null);
                        setValue("companySite", null);
                        handleSearch("", "COMPANY");
                      }}
                      onInputChange={(_, newInputValue) => {
                        handleSearch(newInputValue, "COMPANY");
                      }}
                      options={companyListQuery.data || []}
                      getOptionLabel={(option: Company) =>
                        ownCompany ? ownCompany.name : option.name
                      }
                      getOptionSelected={option => option.id === field.value?.id}
                      disabled={ownCompany?.id}
                      disableClearable
                      renderInput={params => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {params.InputProps.endAdornment}
                                <InputAdornment position="end">
                                  <Tooltip title="Új partner felvétele">
                                    <IconButton
                                      size="small"
                                      onClick={() => setCompanyCreateState(true)}
                                    >
                                      <AddCircle color="primary" />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              </>
                            ),
                          }}
                          InputLabelProps={{ shrink: true }}
                          SelectProps={{ displayEmpty: true }}
                          label={t("order.formValues.companyName")}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <Controller
                  control={control}
                  name="order.deliveryName"
                  defaultValue={ownCompany?.name || ""}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("order.deliveryName")}
                      InputLabelProps={{ shrink: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <Controller
                  control={control}
                  name="paymentType"
                  defaultValue={"CARD"}
                  rules={{ required: !contract && t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("contract.formValues.paymentType")}
                      InputLabelProps={{ shrink: true, required: !contract }}
                      select
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    >
                      <MenuItem disabled value="">
                        {t("common:choose")}
                      </MenuItem>
                      {PAYMENT_TYPE.map(type => (
                        <MenuItem key={type} value={type}>
                          {t(`project.paymentTypes.${type}`)}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <Controller
                  control={control}
                  name="order.currency"
                  defaultValue={tenant.baseCurrency}
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      disableClearable
                      onChange={(_, value) => {
                        field.onChange(value);
                        const relOrderItems = getValues("relOrderItem");
                        const curr = getValues("order.currency");
                        relOrderItems.forEach((rel, index) => {
                          if (
                            !rel.item.itemPriceHistories.find(
                              (history: ItemPriceHistory) =>
                                history?.currency?.id === curr.id && history.isActive,
                            )
                          ) {
                            remove(index);
                          } else {
                            setItemPrice(index);
                          }
                        });
                      }}
                      options={currencyListQuery.data || []}
                      getOptionLabel={(option: BaseCurrency) => option.name}
                      getOptionSelected={option => option.id === field.value?.id}
                      disabled={!!offer || !!contract || !!project}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={t("offer.formValues.currency")}
                          InputLabelProps={{ shrink: true, required: true }}
                          disabled={!!offer || !!contract || !!project}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <Controller
                  control={control}
                  name="order.deliveryFrom"
                  defaultValue={null}
                  render={({ field, fieldState }) => (
                    <KeyboardDatePicker
                      {...field}
                      ref={undefined}
                      label={t("order.deliveryFrom")}
                      format="yyyy.MM.dd."
                      InputLabelProps={{ shrink: true }}
                      error={!!fieldState.error}
                      minDate={ownCompany?.id ? addDays(new Date(), 2) : addDays(new Date(), 0)}
                      helperText={fieldState.error?.message}
                      cancelLabel={t("common:button.cancel")}
                      invalidDateMessage={t("invalidDate")}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Box>
              <IconButton
                size="small"
                onClick={() => setOptionsOpen(optionsOpen => !optionsOpen)}
                style={{ marginBottom: 4, marginLeft: 8 }}
              >
                {optionsOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </Box>
          </Box>
          <Collapse in={optionsOpen}>
            <Card
              variant="outlined"
              style={{
                boxShadow: "unset",
                padding: 16,
                marginBottom: 8,
                marginTop: 8,
              }}
            >
              <Grid container spacing={2} style={{ paddingBottom: 16 }}>
                {!ownCompany?.id && (
                  <Grid item xs={12} sm={12} md={3}>
                    <Controller
                      name="order.isPaid"
                      control={control}
                      defaultValue={false}
                      render={({ field: { onChange, value, ref } }) => (
                        <FormControlLabel
                          className={classes.switch}
                          label={t("order.isPaid.title")}
                          labelPlacement="start"
                          control={
                            <Switch
                              onChange={(e, checked) => {
                                onChange(checked);
                              }}
                              checked={value}
                              inputRef={ref}
                              color="primary"
                            />
                          }
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>
              <Collapse in={!!company}>
                <Grid container spacing={2} style={{ paddingBottom: 16 }}>
                  <Grid item xs={12} sm={12} md={3}>
                    <Controller
                      control={control}
                      name="offer"
                      defaultValue={null}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          {...field}
                          onChange={(_, value) => {
                            field.onChange(value);
                            setValue("contract", null);
                            setValue("project", null);
                            handleSearch("", "OFFER");
                          }}
                          onInputChange={(event, newInputValue) => {
                            handleSearch(newInputValue, "OFFER");
                          }}
                          options={offerListForOrderQuery.data || []}
                          getOptionLabel={(option: Offer) =>
                            option.identifier + (option.name ? ` (${option.name})` : "") || ""
                          }
                          getOptionSelected={option => option.id === field.value?.id}
                          renderInput={params => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <Tooltip
                                    style={{
                                      paddingRight: "2px",
                                    }}
                                    title={t("tooltip.offer").toString()}
                                  >
                                    <Info style={{ color: COLORS.lightBlue }} />
                                  </Tooltip>
                                ),
                              }}
                              InputLabelProps={{ shrink: true }}
                              SelectProps={{ displayEmpty: true }}
                              label={t("order.formValues.offer")}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <Controller
                      control={control}
                      name="contract"
                      defaultValue={null}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          {...field}
                          onChange={(_, value) => {
                            field.onChange(value);
                            setValue("project", null);
                            handleSearch("", "CONTRACT");
                          }}
                          onInputChange={(event, newInputValue) => {
                            handleSearch(newInputValue, "CONTRACT");
                          }}
                          options={contractListForOrderQuery.data || []}
                          getOptionLabel={(option: Contract) =>
                            option.name + (option.identifier ? ` (${option.identifier})` : "") || ""
                          }
                          getOptionSelected={option => option.id === field.value?.id}
                          renderInput={params => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <Tooltip
                                    style={{
                                      paddingRight: "2px",
                                    }}
                                    title={t("tooltip.contract").toString()}
                                  >
                                    <Info style={{ color: COLORS.lightBlue }} />
                                  </Tooltip>
                                ),
                              }}
                              InputLabelProps={{ shrink: true }}
                              SelectProps={{ displayEmpty: true }}
                              label={t("order.formValues.contract")}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <Controller
                      control={control}
                      name="project"
                      defaultValue={null}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          {...field}
                          onChange={(_, value) => {
                            field.onChange(value);
                            handleSearch("", "PROJECT");
                          }}
                          onInputChange={(event, newInputValue) => {
                            handleSearch(newInputValue, "PROJECT");
                          }}
                          options={projectListQuery.data || []}
                          getOptionLabel={(option: Project) =>
                            (option.name || "") + option.identifier ? `(${option.identifier})` : ""
                          }
                          getOptionSelected={option => option.id === field.value?.id}
                          renderInput={params => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <Tooltip
                                    style={{
                                      paddingRight: "2px",
                                    }}
                                    title={t("tooltip.project").toString()}
                                  >
                                    <Info style={{ color: COLORS.lightBlue }} />
                                  </Tooltip>
                                ),
                              }}
                              InputLabelProps={{ shrink: true }}
                              SelectProps={{ displayEmpty: true }}
                              label={t("issues.formValues.project")}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <Controller
                      control={control}
                      name="companySite"
                      defaultValue={null}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          {...field}
                          onChange={(_, value) => {
                            field.onChange(value);
                            handleSearch("", "COMPANY_SITE");
                          }}
                          onInputChange={(event, newInputValue) => {
                            handleSearch(newInputValue, "COMPANY_SITE");
                          }}
                          options={companySiteListQuery.data || []}
                          getOptionLabel={(option: CompanySite) =>
                            option.name + ", " + displayAddress(option.address) || ""
                          }
                          getOptionSelected={option => option.id === field.value?.id}
                          renderInput={params => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <Tooltip
                                    style={{
                                      paddingRight: "2px",
                                    }}
                                    title={t("tooltip.companySite").toString()}
                                  >
                                    <Info style={{ color: COLORS.lightBlue }} />
                                  </Tooltip>
                                ),
                              }}
                              InputLabelProps={{ shrink: true, required: true }}
                              SelectProps={{ displayEmpty: true }}
                              label={
                                company?.isCompany
                                  ? t("order.companySite").toString()
                                  : t("order.deliveryAddress").toString()
                              }
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Collapse>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <Controller
                    control={control}
                    name="order.deliveryComment"
                    defaultValue={""}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label={t("order.deliveryComment")}
                        InputLabelProps={{ shrink: true }}
                        multiline
                        minRows={2}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Card>
          </Collapse>
          <Divider style={{ marginTop: 8 }} />
          <Grid container>
            <Grid item xs={12} sm={3}>
              <Typography
                style={{
                  paddingLeft: 8,
                  fontFamily: "Montserrat",
                  fontWeight: "bold",
                  fontSize: 16,
                  marginTop: 8,
                }}
              >
                {t("order.item")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} style={{ padding: 4 }}>
              <Controller
                name="showPackageSize"
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value, ref }, fieldState }) => (
                  <FormControlLabel
                    className={classes.switch}
                    label={t("fastOrder.showPackageSize")}
                    labelPlacement="start"
                    control={
                      <Switch
                        size="small"
                        onChange={(e, checked) => {
                          onChange(checked);
                        }}
                        checked={value}
                        inputRef={ref}
                        color="primary"
                      />
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3} style={{ padding: 4 }}>
              <Controller
                name="showComment"
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value, ref }, fieldState }) => (
                  <FormControlLabel
                    className={classes.switch}
                    label={t("fastOrder.showComment")}
                    labelPlacement="start"
                    control={
                      <Switch
                        size="small"
                        onChange={(e, checked) => {
                          onChange(checked);
                        }}
                        checked={value}
                        inputRef={ref}
                        color="primary"
                      />
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3} style={{ padding: 4 }}>
              <Controller
                name="isWithoutPrice"
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value, ref }, fieldState }) => (
                  <FormControlLabel
                    className={classes.switch}
                    label={t("fastOrder.isWithoutPrice")}
                    labelPlacement="start"
                    control={
                      <Switch
                        size="small"
                        onChange={(e, checked) => {
                          onChange(checked);
                        }}
                        checked={value}
                        inputRef={ref}
                        color="primary"
                      />
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box style={{ overflowX: "auto", overflowY: "hidden", marginBottom: 8 }}>
            <Box
              display="flex"
              alignItems="center"
              minWidth={isSmallScreen || isWithoutPrice ? 700 : 1040}
            >
              <Box width={40}></Box>
              <Box width={"35%"}>
                <Typography
                  style={{ fontWeight: "bold", paddingRight: 4, paddingLeft: 4, fontSize: 12 }}
                >
                  {t("fastOrder.item")}
                </Typography>
              </Box>
              <Box
                style={{
                  width: isSmallScreen || !showPackageSize ? 0 : "10%",
                  overflow: "hidden",
                }}
              >
                <Typography
                  style={{ fontWeight: "bold", paddingRight: 4, paddingLeft: 4, fontSize: 12 }}
                >
                  {t("fastOrder.packaging")}
                </Typography>
              </Box>
              <Box width={"6%"}>
                <Typography
                  style={{ fontWeight: "bold", paddingRight: 4, paddingLeft: 4, fontSize: 12 }}
                >
                  {t("fastOrder.amount")}
                </Typography>
              </Box>
              <Box width={"8%"}>
                <Typography
                  style={{ fontWeight: "bold", paddingRight: 4, paddingLeft: 4, fontSize: 12 }}
                >
                  {t("fastOrder.amountType")}
                </Typography>
              </Box>
              <Box
                style={{
                  width: !isSmallScreen ? "6%" : 0,
                  overflow: "hidden",
                }}
              >
                <Typography
                  style={{ fontWeight: "bold", paddingRight: 4, paddingLeft: 4, fontSize: 12 }}
                >
                  {t("fastOrder.discount")}
                </Typography>
              </Box>
              {!isWithoutPrice && (
                <>
                  <Box style={{ width: !isSmallScreen ? "13%" : 0, overflow: "hidden" }}>
                    <Typography
                      style={{ fontWeight: "bold", paddingRight: 4, paddingLeft: 4, fontSize: 12 }}
                    >
                      {t("fastOrder.net")}
                    </Typography>
                  </Box>
                  <Box style={{ width: !isSmallScreen ? "7%" : 0, overflow: "hidden" }}>
                    <Typography
                      style={{ fontWeight: "bold", paddingRight: 4, paddingLeft: 4, fontSize: 12 }}
                    >
                      {t("fastOrder.vat")}
                    </Typography>
                  </Box>
                  <Box width={"13%"}>
                    <Typography
                      style={{ fontWeight: "bold", paddingRight: 4, paddingLeft: 4, fontSize: 12 }}
                    >
                      {t("fastOrder.vatValue")}
                    </Typography>
                  </Box>
                  <Box width={"13%"}>
                    <Typography
                      style={{ fontWeight: "bold", paddingRight: 4, paddingLeft: 4, fontSize: 12 }}
                    >
                      {t("fastOrder.gross")}
                    </Typography>
                  </Box>
                </>
              )}
              <Box width={40} />
            </Box>
            {fields.length === 0 ? (
              <Grid item container xs={12} justifyContent="center">
                <Box textAlign="center">
                  <Typography style={{ fontSize: 16 }}>{t("common:emptyItemList2")}</Typography>
                </Box>
              </Grid>
            ) : (
              fields.map((field, index) => (
                <FastOrderFormItem
                  key={field.key}
                  index={index}
                  entry={relOrderItems?.[index]}
                  remove={remove}
                  setItemPrice={setItemPrice}
                  openItem={openItem}
                  setOpenItem={setOpenItem}
                  openAmountType={openAmountType}
                  setOpenAmountType={setOpenAmountType}
                  addNewRow={addNewRow}
                  rowCount={fields?.length || 0}
                  disableDownKey={disableDownKey || rowLoading || !!isLoading}
                />
              ))
            )}
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            gridGap={8}
            mb={2}
            flexDirection={isSmallScreen ? "column" : "row"}
          >
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={event => {
                event.stopPropagation();
                append({});
              }}
              startIcon={<AddBox />}
            >
              {t("offer.relOfferItem.add")}
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={event => {
                event.stopPropagation();
                setOpenScanner(true);
              }}
              startIcon={<FontAwesomeIcon icon={faQrcode} style={{ fontSize: 20, width: 20 }} />}
            >
              {t("fastOrder.addQr")}
            </Button>
          </Box>
          <ItemQrReader
            openScanner={openScanner}
            setOpenScanner={setOpenScanner}
            append={append}
            currency={currency}
          />
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Controller
                name="order.isOneItem"
                defaultValue={!!order?.oneItemName}
                render={props => (
                  <FormControlLabel
                    label={t("offer.formValues.isOneItem")}
                    labelPlacement="end"
                    control={
                      <Checkbox
                        {...props.field}
                        size="small"
                        checked={props.field.value}
                        onChange={e => props.field.onChange(e.target.checked)}
                      />
                    }
                  />
                )}
              />
              <Collapse in={!!isOneItem}>
                <Controller
                  name="order.oneItemName"
                  defaultValue={order?.oneItemName || ""}
                  rules={{ required: isOneItem && t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("offer.formValues.oneItemName")}
                      InputLabelProps={{ shrink: true }}
                      required={isOneItem}
                      style={{ maxWidth: 350 }}
                    />
                  )}
                />
              </Collapse>
            </Grid>
            <Grid item xs={12} sm={6}>
              {!isWithoutPrice && (
                <Box width="100%" textAlign="right" pb={2}>
                  <Typography style={{ fontWeight: "bold", fontSize: 16, paddingBottom: 8 }}>
                    {t("fastOrder.full")}
                  </Typography>
                  <Box>
                    <Typography style={{ fontSize: 16 }}>
                      <span style={{ fontWeight: "bold" }}>
                        {t("order.formValues.fullNetPrice")}
                        {": "}
                      </span>
                      {(currency?.prefix ? currency.prefix + " " : "") +
                        t("common:number", { num: allNetPrice }) +
                        (currency?.postfix ? " " + currency.postfix : "")}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography style={{ fontSize: 16 }}>
                      <span style={{ fontWeight: "bold" }}>{t("fastOrder.fullVat")} </span>
                      {(currency?.prefix ? currency.prefix + " " : "") +
                        t("common:number", {
                          num: allGrossPrice - allNetPrice,
                        }) +
                        (currency?.postfix ? " " + currency.postfix : "")}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography style={{ fontSize: 16 }}>
                      <span style={{ fontWeight: "bold" }}>
                        {t("order.formValues.fullGrossPrice")}
                        {": "}
                      </span>
                      {(currency?.prefix ? currency.prefix + " " : "") +
                        t("common:number", { num: allGrossPrice }) +
                        (currency?.postfix ? " " + currency.postfix : "")}
                    </Typography>
                  </Box>
                  {!!allDiscountPrice && (
                    <Box pt={2}>
                      <Typography style={{ fontSize: 16 }}>
                        <span style={{ fontWeight: "bold" }}>{t("fastOrder.allDiscount")}</span>
                        {(currency?.prefix ? currency.prefix + " " : "") +
                          t("common:number", {
                            num: allDiscountPrice,
                          }) +
                          (currency?.postfix ? " " + currency.postfix : "")}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" width="100%" gridGap={8}>
            <Button color="primary" variant="text" onClick={history.goBack}>
              {t("common:button.cancel")}
            </Button>
            <Button type="submit" color="primary">
              {t("common:button.save")}
            </Button>
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default FastOrderForm;
