import { Box, Button, IconButton, Tooltip } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { ABSENCE_TYPES } from "config/constants";
import { RootState } from "config/store";
import supportedLocales from "config/supportedLocales";
import { format, isAfter, startOfMonth } from "date-fns";
import i18n from "i18n";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { deleteAbsence, listAllAbsence } from "shared/network/absence.api";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";

const EmployeeAbsenceList = () => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const { setHeaderButtons } = useHeader();

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-employee-absence-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-employee-absence-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const employeeAbsenceListQuery = useQuery(
    ["employeeDetailsAbsenceList", page, id, page, pageSize, filterValue, sortState],
    async () => {
      if (id) {
        const { data } = await listAllAbsence(
          page,
          pageSize,
          selectedRelTenant.tenant.id,
          id ? `employee.id:${id};${filterValue}` : filterValue,
          sortState,
        );
        return data;
      }
      return Promise.reject();
    },
  );
  const { t } = useTranslation();

  const columns: GridColDef[] = [
    {
      field: "type",
      headerName: t("absence.formValues.absenceType"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.type ? t(`common:absenceTypes.${row.type}`) : "-",
      type: "select",
    },
    {
      field: "dateFrom",
      headerName: t("absence.formValues.date"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        format(new Date(row.dateFrom), "P", {
          locale: supportedLocales[i18n.language],
        }),
      type: "date",
    },
    {
      field: "isOrdered",
      headerName: t("absence.formValues.isOrdered"),
      flex: 1,
      type: "select",
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.isOrdered ? t("common:YES") : t("common:NO"),
    },
    {
      field: "actions",
      headerName: " ",
      width: 60,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["COMPANY_ADMIN"]) &&
            isAfter(new Date(row.dateFrom), startOfMonth(new Date())) && (
              <Tooltip title={t("absence.delete").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                  onClick={() => {
                    if (row.id) {
                      removeAbsence(row.id);
                    }
                  }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            )}
        </Box>
      ),
    },
  ];

  async function removeAbsence(id: number) {
    try {
      await deleteAbsence(id, selectedRelTenant.tenant.id);
      employeeAbsenceListQuery.refetch();
      enqueueSnackbar(
        t("common:notification.delete.success", {
          subject: t("absence.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch (e) {
      enqueueSnackbar(
        t("common:notification.delete.failure", {
          subject: t("absence.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  }

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, ["ABSENCE_ADMIN"]) && (
          <Button
            component={Link}
            to={`/my-company/employees/employee-details/absence/absence-create?id=${id}`}
            startIcon={<AddIcon />}
          >
            {t("absence.absenceAdd")}
          </Button>
        )}
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant, filterValue]); //eslint-disable-line

  return (
    <>
      <PageableTable
        filterOptions={[
          {
            columnName: "type",
            options: ABSENCE_TYPES.map(value => {
              return {
                translated: t(`common:absenceTypes.${value}`),
                value,
              };
            }),
          },
          {
            columnName: "isOrdered",
            options: [
              {
                translated: t(`common:YES`),
                value: "true",
              },
              {
                translated: t(`common:NO`),
                value: "false",
              },
            ],
          },
        ]}
        filterable={["type", "isOrdered", "dateFrom"]} //ezt a sort ki kell szedni, ha a backend jó
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="employee-absence"
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={employeeAbsenceListQuery}
        minWidth={{ value: "md" }}
      />
    </>
  );
};

export default EmployeeAbsenceList;
