import axios from "config/axios";
import { GenericPageResponse } from "shared/common";
import { Company, Invoice, User } from "shared/types";

const ENDPOINT = "/invoice-transaction";

export type InvoiceTransaction = {
  areaName: string;
  areaType: string;
  city: string;
  comment: string;
  country: string;
  currency: string;
  customerName: string;
  grossPrice: number;
  houseNumber: string;
  id: number;
  paidDate: string;
  parentInvoiceId: Invoice;
  transactionId: number;
  zip: string;
  paymentType: TransactionPaymentType;
  eventType: TransactionEventType;
  user: User;
};

export type InvoiceTransactionSummary = {
  company: Company;
  transactionPrice: number;
  currency: string;
};

export type TransactionPaymentType = ["CASH", "CREDIT_CARD", "TRANSFER", "PASS", "FREE"];

export type TransactionEventType = [
  "PASS",
  "BUNDLE",
  "APPOINTMENT_PREPAYMENT",
  "APPOINTMENT_PAYMENT",
  "RESERVATION",
  "RESERVATION_MONTHLY",
  "MEMBERSHIP",
  "APPOINTMENT_CANCELLATION",
  "TICKET",
  "ORDER",
  "GIFT_CARD",
  "SEPA",
];

export const uploadSepaFile = (file: File, tenantId: number) => {
  const formData = new FormData();

  formData.append("file", file);
  formData.append("tenantId", tenantId.toString());

  return axios.post<GenericPageResponse<InvoiceTransaction>>(`${ENDPOINT}/from-sepa`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const uploadDonationFile = (file: File, tenantId: number) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("tenantId", tenantId.toString());

  return axios.post<GenericPageResponse<InvoiceTransaction>>(
    `${ENDPOINT}/donation-statement-processing`,
    formData,
    { headers: { "Content-Type": "multipart/form-data" } },
  );
};

export const getInvoiceTransactionPage = (
  tenantId: number,
  page = 0,
  size = 10,
  search?: string | null,
  sort?: string | null,
) =>
  axios.get<GenericPageResponse<InvoiceTransaction>>(
    `${ENDPOINT}/all?tenantId=${tenantId}&page=${page}&size=${size}&search=${search || ""}${
      sort ? `&sort=${sort}` : ""
    }`,
  );

export const getInvoiceTransactionSummary = ({
  page,
  size,
  tenantId,
  isDonation,
  from,
  to,
}: {
  page: number;
  size: number;
  tenantId: number;
  isDonation: boolean;
  from: string;
  to: string;
}) =>
  axios.get<GenericPageResponse<InvoiceTransactionSummary>>(
    `${ENDPOINT}/get-transaction-summary-between-dates?page=${page}&size=${size}&tenantId=${tenantId}&from=${from}&to=${to}${
      isDonation ? "&isDonation=true" : ""
    }`,
  );
