import axios from "config/axios";
import { User } from "shared/types";

export type RelUserGoogleCalendar = {
  id: number;
  user: User;
  googleId: string;
  name: string;
  autoSyncEnabled: boolean;
};

const ENDPOINT = "/google-auth";

export const googleAuthSetUp = (tenantId: number) =>
  axios.get(`${ENDPOINT}/get-authenticator-data?tenantId=${tenantId}`);

export const googleAuthValidate = (givenKey: string, googleAccount: string) =>
  axios.post(`${ENDPOINT}/validate`, {
    givenKey,
    googleAccount,
  });
