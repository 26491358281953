import axios from "config/axios";
import { GenericPageResponse, GenericResponse } from "shared/common";
import { BaseCurrency } from "shared/types";
import { CashRegister } from "./cash-register.api";

export type CashRegisterAmountHistory = {
  id: number;
  tenantId: number;
  currency: BaseCurrency | null;
  quantity: string;
  cashRegister: CashRegister;
  amountType: string;
  amountDiff: number;
  createdOn: string;
  commentNumber: number;
};

const ENDPOINT = "/cash-register-amount-history";

export const getPageCashRegisterAmountHistory = (param: {
  page: number;
  size: number;
  tenantId: number;
  search?: string;
  sort?: string;
}) =>
  axios.get<GenericPageResponse<CashRegisterAmountHistory>>(
    `${ENDPOINT}/get-page?page=${param.page}&size=${param.size}&tenantId=${param.tenantId}&search=${
      param.search || ""
    }&sort=${param.sort || ""}`,
  );

export const getCashRegisterAmountHistoryById = (param: number, tenantId: number) =>
  axios.post<GenericResponse<CashRegisterAmountHistory>>(`${ENDPOINT}`, { param, tenantId });
