import { Button, Card, CardContent, Grow, makeStyles } from "@material-ui/core";
import { RootState } from "config/store";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import ReminderCreateDialog from "../Reminder/ReminderCreateDialog";

const useStyles = makeStyles({
  card: {
    width: "fit-content",
  },

  content: {
    "&.MuiCardContent-root": {
      paddingBottom: 16,
    },
    padding: 16,
  },

  growStyle: {
    marginLeft: 12,
  },
});

const AddEntityBar = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  const account = useSelector((state: RootState) => state.authentication?.account);
  const selectedRelTenant = useSelector(
    (state: RootState) => state.authentication?.selectedRelTenant,
  );

  const isUserAdmin = hasAuthority(account?.user, account?.permissions, selectedRelTenant, [
    "USER_ADMIN",
  ]);
  const isEmployeeAdmin = hasAuthority(account?.user, account?.permissions, selectedRelTenant, [
    "EMPLOYEE_ADMIN",
  ]);
  const isCompanyAdmin = hasAuthority(account?.user, account?.permissions, selectedRelTenant, [
    "COMPANY_ADMIN",
  ]);
  const isOfferAdmin = hasAuthority(account?.user, account?.permissions, selectedRelTenant, [
    "OFFER_ADMIN",
  ]);
  const isContractAdmin = hasAuthority(account?.user, account?.permissions, selectedRelTenant, [
    "CONTRACT_GET",
  ]);
  const isOrderAdmin = hasAuthority(account?.user, account?.permissions, selectedRelTenant, [
    "ORDER_ADMIN",
  ]);
  const isWarehouseAdmin = hasAuthority(account?.user, account?.permissions, selectedRelTenant, [
    "WAREHOUSE_ADMIN",
  ]);
  const isProjectAdmin = hasAuthority(account?.user, account?.permissions, selectedRelTenant, [
    "PROJECT_ADMIN",
  ]);

  const [reminderOpen, setReminderOpen] = useState(false);

  return (
    <>
      <Card className={classes.card} style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
        <CardContent className={classes.content} style={{ height: "100%" }}>
          <>
            <Button
              variant={isOpen ? "contained" : "outlined"}
              color="primary"
              style={{ fontSize: 20, width: 200, margin: 6 }}
              onClick={() => {
                if (isOpen) {
                  setIsOpen(false);
                } else {
                  setIsOpen(true);
                }
              }}
            >
              {isOpen ? t("home.addEntities.titleOpen") : t("home.addEntities.title")}
            </Button>
          </>

          {isOpen && (
            <>
              {isUserAdmin && (
                <Grow in={isOpen} style={{ transformOrigin: "0 0 0", margin: 6 }}>
                  <Button
                    component={Link}
                    to="/my-company/users/user-create"
                    variant="outlined"
                    color="primary"
                    style={{ fontSize: 16 }}
                  >
                    {t("home.addEntities.addUser")}
                  </Button>
                </Grow>
              )}
              {isEmployeeAdmin && (
                <Grow in={isOpen} style={{ transformOrigin: "0 0 0", margin: 6 }}>
                  <Button
                    component={Link}
                    to="/my-company/employees/employee-create"
                    variant="outlined"
                    color="primary"
                    style={{ fontSize: 16 }}
                  >
                    {t("home.addEntities.addEmployee")}
                  </Button>
                </Grow>
              )}
              {isCompanyAdmin && (
                <Grow in={isOpen} style={{ transformOrigin: "0 0 0", margin: 6 }}>
                  <Button
                    component={Link}
                    to="/companies/list-all/company-create"
                    variant="outlined"
                    color="primary"
                    style={{ fontSize: 16 }}
                  >
                    {t("home.addEntities.addCompany")}
                  </Button>
                </Grow>
              )}
              {isOfferAdmin && (
                <Grow in={isOpen} style={{ transformOrigin: "0 0 0", margin: 6 }}>
                  <Button
                    component={Link}
                    to="/offer/out/create"
                    variant="outlined"
                    color="primary"
                    style={{ fontSize: 16 }}
                  >
                    {t("home.addEntities.addOffer")}
                  </Button>
                </Grow>
              )}
              {isContractAdmin && (
                <Grow in={isOpen} style={{ transformOrigin: "0 0 0", margin: 6 }}>
                  <Button
                    component={Link}
                    to="/contract/create"
                    variant="outlined"
                    color="primary"
                    style={{ fontSize: 16 }}
                  >
                    {t("home.addEntities.addContract")}
                  </Button>
                </Grow>
              )}
              {isOrderAdmin && (
                <Grow in={isOpen} style={{ transformOrigin: "0 0 0", margin: 6 }}>
                  <Button
                    component={Link}
                    to="/order/list/create"
                    variant="outlined"
                    color="primary"
                    style={{ fontSize: 16 }}
                  >
                    {t("home.addEntities.addOrder")}
                  </Button>
                </Grow>
              )}
              {isWarehouseAdmin && (
                <Grow in={isOpen} style={{ transformOrigin: "0 0 0", margin: 6 }}>
                  <Button
                    component={Link}
                    variant="outlined"
                    color="primary"
                    to={`/warehouse/list/warehouse-details/expense/create?from=expense`}
                    style={{ fontSize: 16 }}
                  >
                    {t("warehouse.tabs.expense")}
                  </Button>
                </Grow>
              )}
              {isProjectAdmin && (
                <Grow in={isOpen} style={{ transformOrigin: "0 0 0", margin: 6 }}>
                  <Button
                    component={Link}
                    variant="outlined"
                    color="primary"
                    to="/my-company/daily-report/create"
                    style={{ fontSize: 16 }}
                  >
                    {t("drawer./my-company/daily-report")}
                  </Button>
                </Grow>
              )}
              <Grow in={isOpen} style={{ transformOrigin: "0 0 0", margin: 6 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ fontSize: 16 }}
                  onClick={() => setReminderOpen(true)}
                >
                  {t("reminder.title")}
                </Button>
              </Grow>
              {reminderOpen && (
                <ReminderCreateDialog open={reminderOpen} setOpen={setReminderOpen} />
              )}
            </>
          )}
        </CardContent>
      </Card>
      {!account?.user?.isSuperAdmin &&
        !selectedRelTenant?.isTenantAdmin &&
        hasAuthority(account?.user, account?.permissions, selectedRelTenant, ["WAREHOUSE_GET"]) && (
          <Card className={classes.card} style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
            <CardContent className={classes.content} style={{ height: "100%" }}>
              <Button
                variant="outlined"
                component={Link}
                color="primary"
                style={{ fontSize: 20, width: 200, margin: 6 }}
                to="/ownwarehouse"
              >
                {t("home.showMyWarehouse")}
              </Button>
            </CardContent>
          </Card>
        )}
    </>
  );
};
export default AddEntityBar;
