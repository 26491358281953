import { FormHelperText, Grid, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { addDays, getMinutes, isPast, set } from "date-fns";
import JoditEditor from "jodit-react";
import { useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery } from "react-query";
import { useSelector } from "react-redux";
import { GenericPageResponse } from "shared/common";
import { listCompanyByTenant } from "shared/network/company.api";
import { listIssues } from "shared/network/issues.api";
import { Reminder } from "shared/network/reminder.api";
import { listUsers } from "shared/network/user.api";
import { Company, Issue, Project, User } from "shared/types";
import { useDebouncedCallback } from "use-debounce";
import { ListboxComponent } from "views/Project/components/ProjectOfferAddDialog";
import { ReminderFormValues } from "./ReminderCreateDialog";
import { listProject } from "shared/network/project.api";

type Props = {
  reminder?: Reminder;
  company?: Company;
  issue?: Issue;
  project?: Project;
};

export const defaultTextAreaConfig: any = {
  minHeight: 400,
  maxHeight: 700,
  showPlaceholder: false,
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  addNewLine: false,
  uploader: {
    insertImageAsBase64URI: true,
  },
};

export function nextPageFunction(lastGroup: GenericPageResponse<any>) {
  const nextPage = lastGroup.page.number + 1;
  if (nextPage <= lastGroup.page.totalPages - 1) {
    return nextPage;
  }
  return false;
}

const ReminderForm = ({ reminder, company, project, issue }: Props) => {
  const { t } = useTranslation();
  const tenant = useSelector((state: RootState) => state.authentication?.selectedRelTenant?.tenant);
  const isTenantAdmin = useSelector(
    (state: RootState) => state.authentication?.selectedRelTenant?.isTenantAdmin,
  );
  const user = useSelector((state: RootState) => state.authentication?.account?.user);
  const isSuperAdmin = user?.isSuperAdmin;

  const form = useFormContext<ReminderFormValues>();
  const { control } = form;

  const [userSearch, setUserSearch] = useState("");
  const [companySearch, setCompanySearch] = useState("");
  const [issueSearch, setIssueSearch] = useState("");
  const [projectSearch, setProjectSearch] = useState("");

  const userListQuery = useInfiniteQuery(
    [`userListQuery`, tenant.id, userSearch],
    async ({ pageParam = 0 }) => {
      const { data } = await listUsers(
        pageParam,
        20,
        tenant.id,
        userSearch ? `name:${userSearch}` : "",
      );
      return data;
    },
    { getNextPageParam: lastGroup => nextPageFunction(lastGroup) },
  );

  const companyListQuery = useInfiniteQuery(
    [`companyListQuery`, tenant.id, companySearch],
    async ({ pageParam = 0 }) => {
      const { data } = await listCompanyByTenant(
        pageParam,
        20,
        tenant.id,
        companySearch ? `name:${companySearch}` : "",
      );
      return data;
    },
    { getNextPageParam: lastGroup => nextPageFunction(lastGroup) },
  );

  const issueListQuery = useInfiniteQuery(
    [`issueListQuery`, tenant.id, issueSearch],
    async ({ pageParam = 0 }) => {
      const { data } = await listIssues(
        pageParam,
        20,
        tenant.id,
        issueSearch ? `name:${issueSearch}` : "",
      );
      return data;
    },
    { getNextPageParam: lastGroup => nextPageFunction(lastGroup) },
  );

  const projectListQuery = useInfiniteQuery(
    [`projectListQuery`, tenant.id, projectSearch],
    async ({ pageParam = 0 }) => {
      const { data } = await listProject(
        pageParam,
        20,
        tenant.id,
        projectSearch ? `name:${projectSearch}` : "",
      );
      return data;
    },
    { getNextPageParam: lastGroup => nextPageFunction(lastGroup) },
  );

  const userFlatPages = userListQuery.data?.pages.map(page => page.page?.content).flat();
  const userHasNextPage =
    userListQuery.data &&
    userFlatPages &&
    userListQuery.data?.pages?.[0]?.page?.totalElements > userFlatPages?.length;

  const companyFlatPages = companyListQuery.data?.pages.map(page => page.page?.content).flat();
  const companyHasNextPage =
    companyListQuery.data &&
    companyFlatPages &&
    companyListQuery.data?.pages?.[0]?.page?.totalElements > companyFlatPages?.length;

  const issueFlatPages = issueListQuery.data?.pages.map(page => page.page?.content).flat();
  const issueHasNextPage =
    issueListQuery.data &&
    issueFlatPages &&
    issueListQuery.data?.pages?.[0]?.page?.totalElements > issueFlatPages?.length;

  const projectFlatPages = projectListQuery.data?.pages.map(page => page.page?.content).flat();
  const projectHasNextPage =
    projectListQuery.data &&
    projectFlatPages &&
    projectListQuery.data?.pages?.[0]?.page?.totalElements > projectFlatPages?.length;

  const onUserChange = useDebouncedCallback((value: string) => {
    setUserSearch(value);
  }, 200);

  const onCompanyChange = useDebouncedCallback((value: string) => {
    setCompanySearch(value);
  }, 200);

  const onIssueChange = useDebouncedCallback((value: string) => {
    setIssueSearch(value);
  }, 200);
  const onProjectChange = useDebouncedCallback((value: string) => {
    setProjectSearch(value);
  }, 200);

  const textAreaConfig = useMemo(() => {
    return defaultTextAreaConfig;
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <Controller
          control={control}
          name="reminderUser"
          defaultValue={reminder?.reminderUser || user}
          rules={{ required: t("validation.required").toString() }}
          render={({ field, fieldState }) => (
            <Autocomplete
              {...field}
              disabled={!(isSuperAdmin || isTenantAdmin) || !!reminder}
              onChange={(_, value) => {
                field.onChange(value);
                onUserChange("");
              }}
              onInputChange={(event, newInputValue) => {
                onUserChange(newInputValue);
              }}
              options={userFlatPages || []}
              getOptionLabel={(option: User) => option.name}
              getOptionSelected={option => option.id === field.value?.id}
              renderInput={params => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true, required: true }}
                  label={t("user.formValues.name")}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
              ListboxComponent={props => (
                <ListboxComponent
                  {...props}
                  hasNextPage={!!userHasNextPage}
                  fetchNextPage={userListQuery.fetchNextPage}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          control={control}
          name="when"
          defaultValue={
            reminder?.when
              ? new Date(reminder?.when)
              : set(addDays(new Date(), 1), { minutes: 0, seconds: 0 })
          }
          rules={{
            required: t("validation.required").toString(),
            validate: value => {
              if (value && isPast(value)) {
                return t("reminder.dateValidateFuture");
              }
              if (value && getMinutes(value) % 5 !== 0) {
                return t("reminder.dateValidate");
              }
            },
          }}
          render={({ field, fieldState }) => (
            <KeyboardDateTimePicker
              {...field}
              ref={undefined}
              label={t("reminder.when")}
              format="yyyy.MM.dd. HH:mm"
              InputLabelProps={{ shrink: true }}
              minutesStep={5}
              ampm={false}
              disablePast
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          control={control}
          name="company"
          defaultValue={company ? company : reminder?.company || null}
          render={({ field, fieldState }) => (
            <Autocomplete
              {...field}
              onChange={(_, value) => {
                field.onChange(value);
                onCompanyChange("");
              }}
              onInputChange={(_, newInputValue) => {
                onCompanyChange(newInputValue);
              }}
              options={companyFlatPages || []}
              getOptionLabel={(option: Company) => option.name}
              getOptionSelected={option => option.id === field.value?.id}
              renderInput={params => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label={t("companySite.formValues.company")}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
              ListboxComponent={props => (
                <ListboxComponent
                  {...props}
                  hasNextPage={!!companyHasNextPage}
                  fetchNextPage={companyListQuery.fetchNextPage}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          control={control}
          name="issue"
          defaultValue={issue ? issue : reminder?.issue || null}
          render={({ field, fieldState }) => (
            <Autocomplete
              {...field}
              onChange={(_, value) => {
                field.onChange(value);
                onIssueChange("");
              }}
              onInputChange={(_, newInputValue) => {
                onIssueChange(newInputValue);
              }}
              options={issueFlatPages || []}
              getOptionLabel={(option: Issue) => option.name}
              getOptionSelected={option => option.id === field.value?.id}
              renderInput={params => (
                <TextField
                  {...params}
                  label={t("milestone.formValues.issueList.issue")}
                  InputLabelProps={{ shrink: true }}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
              ListboxComponent={props => (
                <ListboxComponent
                  {...props}
                  hasNextPage={!!issueHasNextPage}
                  fetchNextPage={issueListQuery.fetchNextPage}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          control={control}
          name="project"
          defaultValue={project ? project : reminder?.project || null}
          render={({ field, fieldState }) => (
            <Autocomplete
              {...field}
              onChange={(_, value) => {
                field.onChange(value);
                onProjectChange("");
              }}
              onInputChange={(_, newInputValue) => {
                onProjectChange(newInputValue);
              }}
              options={projectFlatPages || []}
              getOptionLabel={(option: Project) => option.name}
              getOptionSelected={option => option.id === field.value?.id}
              renderInput={params => (
                <TextField
                  {...params}
                  label={t("drawer./project")}
                  InputLabelProps={{ shrink: true }}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
              ListboxComponent={props => (
                <ListboxComponent
                  {...props}
                  hasNextPage={!!projectHasNextPage}
                  fetchNextPage={projectListQuery.fetchNextPage}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: "bold", paddingBottom: 4, paddingLeft: 4 }}>
          {t("reminder.reminderMessage")}
        </Typography>
        <Controller
          control={control}
          name="reminderMessage"
          defaultValue={reminder?.reminderMessage || ""}
          rules={{
            required: t("validation.required").toString(),
            validate: value => {
              if (value && value === "<p><br></p>") {
                return t("validation.required").toString();
              }
            },
          }}
          render={({ field, fieldState }) => (
            <>
              <JoditEditor
                config={textAreaConfig}
                ref={field.ref}
                value={field.value}
                onChange={content => {
                  field.onChange(content);
                }}
              />
              {fieldState.error?.message && (
                <FormHelperText style={{ color: COLORS.red }}>
                  {fieldState.error?.message}
                </FormHelperText>
              )}
            </>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default ReminderForm;
