import { Box, Button, Container, Typography } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { useHeader } from "components/Layout/HeaderContext";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { SliceStatus } from "shared/common";
import { fastModifyOrder, getOrderById } from "shared/network/order.api";
import { Order } from "shared/types";
import { removeEmptyStrings } from "shared/util/removeEmptyStrings";
import FastOrderForm from "./FastOrderCreate/components/FastOrderForm";
import FastOrderStatusModifyDialog from "./FastOrderCreate/components/FastOrderStatusModifyDialog";
import { OrderFormValues } from "./components/OrderForm";

const FastOrderModify = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const idNullable = query.get("id");
  const id = idNullable ? idNullable : "0";
  const { t } = useTranslation();
  const { setHeaderButtons } = useHeader();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<OrderFormValues>({ mode: "onBlur" });
  const { setValue } = form;

  const [order, setOrder] = useState<Order | null>(null);
  const [status, setStatus] = useState<SliceStatus>("idle");

  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const { tenant } = selectedRelTenant;

  const getOrderByIdQuery = useQuery(["getOrderByIdQuery", id], async () => {
    if (id) {
      const { data } = await getOrderById(id, tenant.id);
      return data.item;
    }
    return Promise.reject();
  });

  const onSubmit = async (values: OrderFormValues) => {
    setStatus("pending");
    try {
      if (id) {
        const { data } = await fastModifyOrder(
          {
            ...removeEmptyStrings(values),
            order: {
              ...values.order,
              id: id,
              deliveryFrom:
                values.order.deliveryFrom &&
                format(new Date(values.order.deliveryFrom), "yyyy-MM-dd"),
              deliveryTo:
                values.order.deliveryFrom &&
                format(new Date(values.order.deliveryFrom), "yyyy-MM-dd"),
              deliveryName: values.order.deliveryName || null,
              deliveryComment: values.order.deliveryComment || null,
              deliveryAddress: values?.companySite?.address || null,
              paymentType: values.paymentType,
            },
            company: values.company,
            offer: values.offer || undefined,
            project: values.project || undefined,
            contract: values.contract || undefined,
            fullGrossPrice: undefined,
            fullNetPrice: undefined,
            companySiteId: values?.companySite?.id || "",
            relOrderItem: values.relOrderItem.map(item => {
              return {
                ...item,
                quantity: Number(item.quantity || 1) * Number(item.amountType?.quantity || 1),
                netPrice: Number.parseInt(item.netPrice?.toString()),
                tax: item.tax,
                amountType: item.amountType,
                item: item.item,
                serialNumber: item.serialNumber,
                discount: item.discount,
                description: item.description,
              };
            }),
          },
          tenant.id,
        );
        data?.item?.relOrderItems?.forEach((relItem: any, i: number) => {
          setValue(`relOrderItem.${i}.id`, relItem.id);
        });
        enqueueSnackbar(
          t("common:notification.modify.success", {
            subject: t("order.subject"),
          }),
          {
            variant: "success",
          },
        );
      }
      setStatus("success");
    } catch (e: any) {
      enqueueSnackbar(
        t("common:notification.modify.failure", {
          subject: t("order.subject"),
        }),
        {
          variant: "error",
        },
      );
      setStatus("failure");
    }
    form.reset({}, { keepValues: true });
  };

  const defaultOrder = getOrderByIdQuery.data || order;

  useEffect(() => {
    if (defaultOrder?.status) {
      setHeaderButtons(
        <Box>
          <Box display="flex" gridGap={8} alignItems="center">
            <Typography style={{ fontWeight: "bold" }}>Státusz:</Typography>
            <Typography>{t(`order.statuses.${defaultOrder?.status}`)}</Typography>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setStatusDialogOpen(true)}
              startIcon={<Edit />}
            >
              Státusz módosítása
            </Button>
          </Box>
        </Box>,
      );
    }
    return () => {
      setHeaderButtons(null);
    };
  }, [defaultOrder?.status]); //eslint-disable-line

  useEffect(() => {
    if (defaultOrder) {
      setValue("contract", defaultOrder.contract);
      setValue("project", defaultOrder.project);
      setValue("offer", defaultOrder.offer);
      setValue("paymentType", defaultOrder.paymentType);
      setValue("paymentType", defaultOrder.paymentType);
      setValue("order.deliveryName", defaultOrder.deliveryName);
      setValue("order.deliveryComment", defaultOrder.deliveryComment || "");
      if (defaultOrder.deliveryFrom) {
        setValue("order.deliveryFrom", new Date(defaultOrder.deliveryFrom));
      }
      if (defaultOrder.relOrderItems) {
        setValue(
          "relOrderItem",
          defaultOrder.relOrderItems.map(value => {
            return {
              ...value,
              netPrice: value.netPrice * value.quantity,
              tax:
                value.item?.itemTaxHistories
                  .find((entry: any) => entry.isActive)
                  ?.tax?.percent?.toString() || "0",
            };
          }),
        );
      }
      setValue("company", defaultOrder?.company || null);
      setValue("companySite", defaultOrder?.companySite || null);
    }
  }, [defaultOrder, setValue]);

  const [statusDialogOpen, setStatusDialogOpen] = useState(false);

  return (
    <Container maxWidth="lg">
      <Loading open={status === "pending"} />
      {getOrderByIdQuery.data && (
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormProvider {...form}>
            <FastOrderForm
              order={getOrderByIdQuery.data || order}
              setOrder={setOrder}
              setStatus={setStatus}
              setStatusDialogOpen={setStatusDialogOpen}
              saveForm={onSubmit}
              isLoading={status === "pending"}
            />
          </FormProvider>
        </form>
      )}
      <FastOrderStatusModifyDialog
        order={getOrderByIdQuery.data || order}
        open={statusDialogOpen}
        setOpen={setStatusDialogOpen}
      />
    </Container>
  );
};

export default FastOrderModify;
