import { Box, Card, Typography } from "@material-ui/core";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { format } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getMonitoringAlertPageable } from "shared/network/monitoring-alert.api";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";

type Props = {
  title: string;
  isDashboard: boolean;
  machineId?: string;
};

const MonitoringAlertList = ({ title, isDashboard, machineId }: Props) => {
  const { t } = useTranslation();

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-monitoring-alert-list-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-monitoring-alert-list-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const columns: GridColDef[] = [
    {
      field: "machineId",
      headerName: t("monitoring.alert.machineId"),
      flex: 1,
      type: "stringContainsNumber",
    },
    {
      field: "dateTime",
      headerName: t("monitoring.alert.dateTime"),
      flex: 1,
      type: "date",
      valueGetter: ({ row }: GridValueGetterParams) =>
        format(new Date(row.dateTime), "yyyy.MM.dd. HH:mm"),
    },
    {
      field: "saverity",
      headerName: t("monitoring.alert.severity"),
      flex: 1,
      type: "stringContainsNumber",
    },
    {
      field: "errorType",
      headerName: t("monitoring.alert.errorType"),
      flex: 1,
      type: "stringContainsNumber",
    },
    {
      field: "errorCode",
      headerName: t("monitoring.alert.errorCode"),
      flex: 1,
      type: "stringContainsNumber",
    },
    {
      field: "describe",
      headerName: t("monitoring.alert.describe"),
      flex: 2,
    },
  ];

  const monitoringAlertListQuerry = useQuery(
    [
      "monitoringAlertListQuerry",
      selectedRelTenant.tenant.id,
      page,
      pageSize,
      filterValue,
      sortState,
    ],
    async () => {
      const { data } = await getMonitoringAlertPageable(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        (!!account.user.relUserCompany?.[0]
          ? `company.id:${account.user.relUserCompany?.[0]?.company?.id};`
          : "") +
          (isDashboard ? `errorCode!=$${"00000000"};` : `machineId:$${machineId};`) +
          filterValue,
        sortState,
      );
      return data;
    },
    { enabled: isDashboard || (!isDashboard && !!machineId) },
  );

  return (
    <Card style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
      <Box p="16px 16px 0 16px">
        <Typography variant="h2">{title}</Typography>
      </Box>
      <PageableTable
        transparent
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="monitoring-alert-list"
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={monitoringAlertListQuerry}
        getRowClassName={
          !isDashboard
            ? params =>
                params.getValue(params.id, "errorCode") === "00000000"
                  ? ""
                  : "monitoring-alert-error"
            : undefined
        }
      />
    </Card>
  );
};

export default MonitoringAlertList;
