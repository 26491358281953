import { Box, CircularProgress, Container } from "@material-ui/core";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { SliceStatus } from "shared/common";
import { BaseCurrency } from "shared/types";
import { getCurrencyById, modifyCurrency } from "shared/network/currency.api";
import { removeEmptyStrings } from "shared/util/removeEmptyStrings";
import { useLocation } from "react-router-dom";
import CustomNotificationForm from "./CustomNotificationForm";
import {
  customNotificationGetById,
  modifyCustomNotification,
} from "shared/network/custom-notification.api";

type Params = {};

type Props = RouteComponentProps<Params>;

const CustomNotificationModify = ({ history }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const { enqueueSnackbar } = useSnackbar();
  const tenant = useSelector((state: RootState) => state.authentication.selectedRelTenant?.tenant);
  const form = useForm<any>();

  const [status, setStatus] = useState<SliceStatus>("idle");

  const customNotificationGetByIdQuery = useQuery(
    ["customNotificationGetByIdQuery", id],
    async () => {
      const { data } = await customNotificationGetById(Number(id), tenant.id);
      return data.item;
    },
    { onSuccess: data => form.reset(data) },
  );

  const onSubmitModify = async (values: any) => {
    setStatus("pending");
    try {
      await modifyCustomNotification({
        ...customNotificationGetByIdQuery?.data,
        containsCustomParameter:
          !!values?.content?.includes("##CompanyName##") ||
          !!values?.content?.includes("##NotificationEmail##") ||
          !!values?.content?.includes("##BankAccount##") ||
          !!values?.content?.includes("##OrderNumber##"),
        ...removeEmptyStrings(values),
        tenantId: tenant?.id,
      });
      setStatus("success");
      enqueueSnackbar(t("common:notification.modify.success"), { variant: "success" });
      history.push("/admin/email-templates");
    } catch {
      enqueueSnackbar(t("common:notification.modify.failure"), { variant: "error" });
      setStatus("failure");
    }
  };

  useEffect(() => {}, []);

  return (
    <Box>
      {customNotificationGetByIdQuery.isFetching || status === "pending" ? (
        <Box display="flex" alignItems="center" justifyContent="center" height="300px">
          <CircularProgress />
        </Box>
      ) : (
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmitModify)}>
            <CustomNotificationForm customNotification={customNotificationGetByIdQuery.data} />
          </form>
        </FormProvider>
      )}
    </Box>
  );
};
export default CustomNotificationModify;
