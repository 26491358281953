import { Box, Button, Container, IconButton, Tooltip } from "@material-ui/core";
import { AddBox, Description } from "@material-ui/icons";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import PageableTable from "components/PageableTable/PageableTable";
import supportedLocales from "config/supportedLocales";
import { format } from "date-fns";
import i18n from "i18n";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import InvoiceTransactionCreateDialog from "./InvoiceTransactionCreateDialog";
import displayAddress from "shared/util/displayAddress";
import {
  InvoiceTransaction,
  getInvoiceTransactionPage,
} from "shared/network/invoice-transaction.api";
import { useSelector } from "react-redux";
import { RootState } from "config/store";

export type TableState = {
  page: number;
  pageSize: number;
  filterOpen: boolean;
  filterValue: string;
};

const InvoiceTransactionList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const tenant = useSelector(
    (state: RootState) => state?.authentication?.selectedRelTenant?.tenant,
  );
  const [sortState, setSortState] = useState("");
  const [createOpen, setCreateOpen] = useState(false);
  const [createSepaOpen, setCreateSepaOpen] = useState(false);
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(window.sessionStorage.getItem("rap-invoice-transaction-page-number") || "0"),
    pageSize: parseInt(window.sessionStorage.getItem("rap-invoice-transaction-page-size") || "10"),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: t("invoiceTransaction.id"),
      sortable: true,
      flex: 1,
    },
    {
      field: "transactionId",
      headerName: t("invoiceTransaction.transactionId"),
      sortable: true,
      flex: 1,
    },
    {
      field: "paidDate",
      headerName: t(`invoiceTransaction.paidDate`),
      sortable: true,
      type: "date",
      flex: 1,
      renderCell: ({ row }: GridValueGetterParams) =>
        format(new Date(row?.paidDate), "Pp", {
          locale: supportedLocales[i18n.language],
        }),
    },
    {
      field: "actions",
      headerName: " ",
      sortable: false,
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Tooltip
          title={
            row.parentInvoiceId?.id ? row?.parentInvoiceId?.serialNumber || "Ugrás a számlára" : ""
          }
        >
          <div>
            <IconButton
              disabled={!row.parentInvoiceId?.id}
              color="primary"
              onClick={() => history.push(`/invoice/${row.parentInvoiceId?.id}`)}
            >
              <Description />
            </IconButton>
          </div>
        </Tooltip>
      ),
    },
    {
      field: "paymentType",
      headerName: t(`invoiceTransaction.paymentType.title`),
      sortable: true,
      type: "date",
      flex: 0.75,
      renderCell: ({ row }: GridValueGetterParams) =>
        t(`invoiceTransaction.paymentType.${row.paymentType}`),
    },
    {
      field: "grossPrice",
      headerName: t(`invoiceTransaction.grossPrice`),
      sortable: true,
      align: "right",
      flex: 0.75,
      renderCell: ({ row }: GridValueGetterParams) => t("common:number", { num: row.grossPrice }),
    },
    {
      field: "currency",
      headerName: t(`invoiceTransaction.currency`),
      sortable: true,
      flex: 1,
    },
    {
      field: "eventType",
      headerName: t(`invoiceTransaction.eventType.title`),
      sortable: true,
      type: "date",
      flex: 0.75,
      renderCell: ({ row }: GridValueGetterParams) =>
        t(`invoiceTransaction.eventType.${row.eventType}`),
    },
    {
      field: "customerName",
      headerName: t(`invoiceTransaction.customerName`),
      sortable: true,
      flex: 1,
      renderCell: ({ row }: GridValueGetterParams) => (
        <Tooltip title={displayAddress(row as InvoiceTransaction)}>
          <span>{row.customerName}</span>
        </Tooltip>
      ),
    },
    {
      field: "comment",
      headerName: t(`invoiceTransaction.comment`),
      sortable: true,
      flex: 3,
      renderCell: ({ row }: GridValueGetterParams) => (
        <Tooltip title={row.comment}>
          <span>{row.comment}</span>
        </Tooltip>
      ),
    },
  ];

  const invoiceTransactionPageQuery = useQuery(
    ["invoiceTransactionPageQuery", page, pageSize, sortState],
    async () => {
      if (tenant?.id) {
        const { data } = await getInvoiceTransactionPage(
          tenant.id,
          page,
          pageSize,
          filterValue,
          sortState,
        );

        return data;
      }
      return Promise.reject();
    },
  );

  return (
    <Container maxWidth="xl">
      <Box display="flex" justifyContent="center" gridGap={8}>
        <Button
          size="small"
          variant="outlined"
          startIcon={<AddBox />}
          onClick={() => setCreateSepaOpen(true)}
          style={{ marginBottom: 16 }}
        >
          {t("invoiceTransaction.upload")}
        </Button>
        <Button
          size="small"
          variant="outlined"
          startIcon={<AddBox />}
          onClick={() => setCreateOpen(true)}
          style={{ marginBottom: 16 }}
        >
          {t("invoiceTransaction.upload2")}
        </Button>
      </Box>
      <InvoiceTransactionCreateDialog
        createOpen={createSepaOpen}
        setCreateOpen={setCreateSepaOpen}
        refetch={invoiceTransactionPageQuery.refetch}
        type="SEPA"
      />
      <InvoiceTransactionCreateDialog
        createOpen={createOpen}
        setCreateOpen={setCreateOpen}
        refetch={invoiceTransactionPageQuery.refetch}
        type="DONATION"
      />
      <PageableTable
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="invoice-transaction"
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={invoiceTransactionPageQuery}
        filterable={["customerName", "comment", "paidDate"]}
        minWidth={{ value: "xl" }}
      />
    </Container>
  );
};

export default InvoiceTransactionList;
