import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import Loading from "components/Loading";
import queryClient from "config/query";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Reminder, modifyReminder } from "shared/network/reminder.api";
import { ReminderFormValues } from "./ReminderCreateDialog";
import ReminderForm from "./ReminderForm";

type Props = {
  selectedReminder: Reminder | null;
  setSelectedReminder: Dispatch<SetStateAction<Reminder | null>>;
};

const ReminderModifyDialog = ({ selectedReminder, setSelectedReminder }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const tenant = useSelector((state: RootState) => state.authentication?.selectedRelTenant?.tenant);
  const form = useForm<ReminderFormValues>({ mode: "all" });
  const { handleSubmit } = form;
  const [loading, setLoading] = useState(false);

  async function onSubmit(values: ReminderFormValues) {
    setLoading(true);
    try {
      await modifyReminder({
        ...selectedReminder,
        ...values,
        when: values.when?.toISOString(),
        tenantId: tenant?.id,
      });
      queryClient.refetchQueries("reminderList");
      setSelectedReminder(null);
      enqueueSnackbar(
        t("common:notification.modify.success", {
          subject: t("reminder.subject"),
        }),
        { variant: "success" },
      );
    } catch {
      enqueueSnackbar(
        t("common:notification.modify.failure", {
          subject: t("reminder.subject"),
        }),
        { variant: "error" },
      );
    }
    setLoading(false);
  }

  return (
    <>
      <Loading open={loading} />
      <Dialog
        maxWidth="sm"
        fullWidth
        open={!!selectedReminder}
        onClose={() => setSelectedReminder(null)}
      >
        <DialogTitle>{t("reminder.modify")}</DialogTitle>
        <DialogContent>
          <FormProvider {...form}>
            <form id="reminder-modify" onSubmit={handleSubmit(onSubmit)}>
              {selectedReminder && <ReminderForm reminder={selectedReminder} />}
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => setSelectedReminder(null)}>
            {t("common:button.cancel")}
          </Button>
          <Button form="reminder-modify" type="submit">
            {t("common:button.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReminderModifyDialog;
