import {
  Box,
  Collapse,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import {
  NUMBER_FORMAT,
  PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY,
  PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH,
} from "config/constants";
import theme from "config/roraTheme";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { round } from "lodash";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { listItems } from "shared/network/item.api";
import { Item, ItemPackaging } from "shared/types";
import { useDebouncedCallback } from "use-debounce";
import OfferItemScalingSelect from "views/Offer/OfferItemScalingSelect";
import { OrderFormValues, OrderItemEntry, noPackage } from "./FastOrderForm";
import { deleteItemFromOrder } from "shared/network/order.api";
import { useSnackbar } from "notistack";
import Loading from "components/Loading";

type Props = {
  index: number;
  entry: OrderItemEntry;
  remove: (index?: number | number[] | undefined) => void;
  setItemPrice: (index: number) => void;
  addNewRow: (event: any, index: number) => Promise<void>;
  openItem: number | null;
  setOpenItem: (openItem: number | null) => void;
  openAmountType: number | null;
  setOpenAmountType: (openAmountType: number | null) => void;
  rowCount: number;
  disableDownKey: boolean;
};

export const itemTableUseStyles = makeStyles({
  border: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.25)",
    borderLeft: "1px solid rgba(0, 0, 0, 0.25)",
    borderRight: "1px solid rgba(0, 0, 0, 0.25)",
  },
  borderTop: {
    borderTop: "1px solid rgba(0, 0, 0, 0.25)",
  },
  firstCell: {
    paddingLeft: 4,
    paddingRight: 4,
    borderRight: "1px solid rgba(0, 0, 0, 0.25)",
    borderLeft: "1px solid rgba(0, 0, 0, 0.25)",
    display: "flex",
    alignItems: "center",
  },
  middleCell: {
    paddingLeft: 4,
    paddingRight: 4,
    borderRight: "1px solid rgba(0, 0, 0, 0.25)",
    display: "flex",
    alignItems: "center",
  },
  table: {
    border: "1px solid #D9D9D9",
    borderCollapse: "collapse",
    padding: 0,
  },
});

const FastOrderFormItem = ({
  index,
  entry,
  remove,
  setItemPrice,
  addNewRow,
  openItem,
  setOpenItem,
  openAmountType,
  setOpenAmountType,
  rowCount,
  disableDownKey,
}: Props) => {
  const { t } = useTranslation();
  const classes = itemTableUseStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { watch, setValue } = useFormContext<OrderFormValues>();
  //@ts-ignore
  const currency = watch(`order.currency`);
  const selectedItem = watch(`relOrderItem.${index}.item`);
  const netPrice = watch(`relOrderItem.${index}.netPrice`);
  const tax = watch(`relOrderItem.${index}.tax`) * 0.01;
  const discountNetPrice = (1 - watch(`relOrderItem.${index}.discount`) * 0.01) * netPrice;
  const discountPrice =
    (1 - watch(`relOrderItem.${index}.discount`) * 0.01) * (netPrice * (1 + tax));
  const relOrderItem = watch(`relOrderItem.${index}`);
  const [itemSearch, setItemSearch] = useState<string>("");
  const [openScaling, setOpenScaling] = useState<Item | null>(null);
  const [loading, setLoading] = useState(false);
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const isWithoutPrice = watch("isWithoutPrice");
  const showComment = watch("showComment");
  const showPackageSize = watch("showPackageSize");

  const itemListQuery = useQuery(["itemListQuery", itemSearch], async () => {
    const { data } = await listItems(
      0,
      10,
      tenant.id,
      `purchasable:true;${
        itemSearch ? `(name:$${itemSearch};(OR)productCode:$${itemSearch};)` : ""
      }`,
    );
    return data.page.content;
  });

  const handleItemSearchChange = useDebouncedCallback((value: string) => {
    if (value.length >= PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH || value.length === 0) {
      setItemSearch(value);
    }
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  async function handleKeyDown(event: any, inputName: string) {
    if (event?.key === "ArrowRight" && openItem === null && openAmountType === null) {
      if (inputName === "item") {
        if (showPackageSize) {
          document.getElementById(`relOrderItem.${index}.amountType`)?.focus();
        } else {
          document.getElementById(`relOrderItem.${index}.quantity`)?.focus();
        }
      }
      if (inputName === "amountType") {
        document.getElementById(`relOrderItem.${index}.quantity`)?.focus();
      }
      if (inputName === "quantity") {
        document.getElementById(`relOrderItem.${index}.discount`)?.focus();
      }
      if (inputName === "discount") {
        document.getElementById(`relOrderItem.${index}.delete`)?.focus();
      }
    }
    if (event?.key === "ArrowLeft" && openItem === null && openAmountType === null) {
      if (inputName === "delete") {
        document.getElementById(`relOrderItem.${index}.discount`)?.focus();
      }
      if (inputName === "discount") {
        document.getElementById(`relOrderItem.${index}.quantity`)?.focus();
      }
      if (inputName === "quantity") {
        if (showPackageSize) {
          document.getElementById(`relOrderItem.${index}.amountType`)?.focus();
        } else {
          document.getElementById(`relOrderItem.${index}.item`)?.focus();
        }
      }
      if (inputName === "amountType") {
        document.getElementById(`relOrderItem.${index}.item`)?.focus();
      }
    }
    if (event?.key === "ArrowDown" && openItem === null && openAmountType === null) {
      if (!disableDownKey) {
        if (
          showComment &&
          inputName === "description" &&
          !document.getElementById(`relOrderItem.${index + 1}.item`)
        ) {
          await addNewRow(event, index);
        }

        if (!showComment && !document.getElementById(`relOrderItem.${index + 1}.item`)) {
          await addNewRow(event, index);
        }
      }

      if (inputName === "description") {
        document.getElementById(`relOrderItem.${index + 1}.item`)?.focus();
      }
      if (
        (inputName === "delete" ||
          inputName === "item" ||
          inputName === "amountType" ||
          inputName === "quantity" ||
          inputName === "discount") &&
        showComment
      ) {
        if (selectedItem) {
          document.getElementById(`relOrderItem.${index}.description`)?.focus();
        }
      } else {
        if (inputName === "delete") {
          document.getElementById(`relOrderItem.${index + 1}.delete`)?.focus();
        }
        if (inputName === "item") {
          document.getElementById(`relOrderItem.${index + 1}.item`)?.focus();
        }
        if (inputName === "amountType") {
          document.getElementById(`relOrderItem.${index + 1}.amountType`)?.focus();
        }
        if (inputName === "quantity") {
          document.getElementById(`relOrderItem.${index + 1}.quantity`)?.focus();
        }
        if (inputName === "discount") {
          document.getElementById(`relOrderItem.${index + 1}.discount`)?.focus();
        }
      }
    }
    if (event?.key === "ArrowUp" && openItem === null && openAmountType === null) {
      if (inputName === "description") {
        document.getElementById(`relOrderItem.${index}.item`)?.focus();
      }
      if (
        (inputName === "delete" ||
          inputName === "item" ||
          inputName === "amountType" ||
          inputName === "quantity" ||
          inputName === "discount") &&
        showComment
      ) {
        document.getElementById(`relOrderItem.${index - 1}.description`)?.focus();
      } else {
        if (inputName === "delete") {
          document.getElementById(`relOrderItem.${index - 1}.delete`)?.focus();
        }
        if (inputName === "item") {
          document.getElementById(`relOrderItem.${index - 1}.item`)?.focus();
        }
        if (inputName === "amountType") {
          document.getElementById(`relOrderItem.${index - 1}.amountType`)?.focus();
        }
        if (inputName === "quantity") {
          document.getElementById(`relOrderItem.${index - 1}.quantity`)?.focus();
        }
        if (inputName === "discount") {
          document.getElementById(`relOrderItem.${index - 1}.discount`)?.focus();
        }
      }
    }
  }

  return (
    <>
      <Loading open={loading} />
      <OfferItemScalingSelect
        item={openScaling}
        setItem={setOpenScaling}
        inputName={`relOrderItem.${index}.netPrice`}
        inputNameX={`relOrderItem.${index}.scalingX`}
        inputNameY={`relOrderItem.${index}.scalingY`}
        isIncoming={false}
        onSuccess={() => setItemPrice(index)}
      />
      <Box
        display="flex"
        alignItems="center"
        className={clsx(classes.border, { [classes.borderTop]: index === 0 })}
        minWidth={isSmallScreen || isWithoutPrice ? 700 : 1040}
      >
        <Box textAlign="center" width={40}>
          <Typography style={{ fontWeight: "bold" }}>{index + 1}.</Typography>
        </Box>
        <Box className={classes.firstCell} width={"35%"}>
          <Controller
            name={`relOrderItem.${index}.scalingX`}
            defaultValue={entry?.scalingX || null}
            render={() => <></>}
          />
          <Controller
            name={`relOrderItem.${index}.scalingY`}
            defaultValue={entry?.scalingY || null}
            render={() => <></>}
          />
          <Controller
            name={`relOrderItem.${index}.item`}
            rules={{
              required: t("validation.requiredShort").toString(),
            }}
            defaultValue={entry?.item || null}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                style={{ width: "100%" }}
                disableClearable
                open={openItem === index}
                onOpen={() => setOpenItem(index)}
                onClose={() => setOpenItem(null)}
                onChange={(_, value) => {
                  field.onChange(value);
                  if (value?.type === "SERIAL_NUMBER") {
                    setValue(`relOrderItem.${index}.quantity`, "1");
                  }

                  if (value?.isScalingPricing) {
                    setOpenScaling(value);
                  } else {
                    setItemPrice(index);
                  }

                  if (value.description) {
                    setValue(`relOrderItem.${index}.description`, value.description || "");
                  }

                  handleItemSearchChange("");
                }}
                onInputChange={(_, newInputValue) => {
                  handleItemSearchChange(newInputValue);
                }}
                options={itemListQuery.data || []}
                getOptionLabel={(option: Item) => `${option.name} (${option.productCode})`}
                getOptionSelected={option => option.id === field.value.id}
                renderInput={params => (
                  <TextField
                    {...params}
                    onKeyDown={event => handleKeyDown(event, `item`)}
                    onFocus={event => event.target.select()}
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                    error={
                      !!fieldState.error ||
                      (selectedItem &&
                        !(selectedItem as Item)?.itemPriceHistories.find(history => {
                          return history?.currency?.id === currency?.id && history.isActive;
                        }))
                    }
                    helperText={
                      fieldState.error?.message ||
                      (selectedItem &&
                        !(selectedItem as Item)?.itemPriceHistories.find(history => {
                          return history?.currency?.id === currency?.id && history.isActive;
                        }) &&
                        "Ennek a terméknek ebben a pénznemben nincs megadva ára!")
                    }
                    InputLabelProps={{ shrink: true, required: true }}
                    inputProps={{
                      ...params.inputProps,
                      id: `relOrderItem.${index}.item`,
                      style: { padding: 0, fontSize: 14, paddingTop: 2 },
                      onKeyDown: event => {
                        if (
                          event.key === "ArrowDown" &&
                          openItem === null &&
                          //@ts-ignore
                          event.target.value
                        ) {
                          event.stopPropagation();
                          handleKeyDown(event, `item`);
                          event.preventDefault();
                        }
                        if (event.key === "ArrowUp" && openItem === null) {
                          event.stopPropagation();
                          handleKeyDown(event, `item`);
                          event.preventDefault();
                        }
                        if (event?.key === "ArrowLeft" || event?.key === "ArrowRight") {
                          event.preventDefault();
                        }
                        if (event?.key === "ArrowDown") {
                          event.preventDefault();
                        }
                      },
                    }}
                  />
                )}
              />
            )}
          />
        </Box>
        <Box
          className={isSmallScreen || !showPackageSize ? undefined : classes.middleCell}
          style={{
            width: isSmallScreen || !showPackageSize ? 0 : "10%",
            overflow: "hidden",
          }}
        >
          <Controller
            name={`relOrderItem.${index}.amountType`}
            rules={{
              required:
                selectedItem?.type !== "SERIAL_NUMBER" && t("validation.requiredShort").toString(),
            }}
            defaultValue={{ packaging: { name: "Egység" } }}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                style={{ width: "100%" }}
                open={openAmountType === index}
                onOpen={() => setOpenAmountType(index)}
                onClose={() => setOpenAmountType(null)}
                onChange={(_, value) => {
                  field.onChange(value);
                  setItemPrice(index);
                }}
                options={[...(selectedItem?.packaging || []), noPackage]}
                getOptionLabel={(option: ItemPackaging) => option?.packaging?.name || ""}
                getOptionSelected={option => option?.id === field?.value?.id}
                disableClearable
                renderInput={params => (
                  <TextField
                    {...params}
                    onKeyDown={event => handleKeyDown(event, `amountType`)}
                    onFocus={event => event.target.select()}
                    variant="standard"
                    InputProps={{ ...params.InputProps, disableUnderline: true }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    inputProps={{
                      ...params.inputProps,
                      id: `relOrderItem.${index}.amountType`,
                      style: {
                        textAlign: "right",
                        padding: 0,
                        fontSize: 14,
                        paddingTop: 2,
                      },
                      onKeyDown: event => {
                        if (event.key === "ArrowDown" && openAmountType === null) {
                          event.stopPropagation();
                          handleKeyDown(event, `amountType`);
                          event.preventDefault();
                        }
                        if (event.key === "ArrowUp" && openAmountType === null) {
                          event.stopPropagation();
                          handleKeyDown(event, `amountType`);
                          event.preventDefault();
                        }
                        if (event?.key === "ArrowLeft" || event?.key === "ArrowRight") {
                          event.preventDefault();
                        }
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      required: selectedItem?.type !== "SERIAL_NUMBER",
                    }}
                  />
                )}
              />
            )}
          />
        </Box>
        <Box className={classes.middleCell} width={"6%"} style={{ display: "flex" }}>
          <Controller
            name={`relOrderItem.${index}.quantity`}
            defaultValue={entry?.quantity || "1"}
            rules={{
              required: t("validation.requiredShort").toString(),
              validate: value => {
                if (value && !value.toString().match(NUMBER_FORMAT)) {
                  return t("common:validation.numberFormat").toString();
                }
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                onKeyDown={event => handleKeyDown(event, `quantity`)}
                onFocus={event => event.target.select()}
                {...field}
                type="number"
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                inputProps={{
                  id: `relOrderItem.${index}.quantity`,
                  step: "0.01",
                  min: "0.01",
                  style: { textAlign: "right", padding: 0, fontSize: 14 },
                  onKeyDown: async event => {
                    if (
                      event?.key === "ArrowDown" ||
                      event?.key === "ArrowUp" ||
                      event?.key === "ArrowLeft" ||
                      event?.key === "ArrowRight"
                    ) {
                      event.preventDefault();
                    }
                  },
                }}
                onChange={event => {
                  field.onChange(event.target.value);
                  setItemPrice(index);
                }}
                InputLabelProps={{ shrink: true, required: true }}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Box>
        <Box className={classes.middleCell} width={"8%"}>
          <Typography style={{ paddingLeft: 4, fontSize: 14 }}>
            {selectedItem?.productUnit === "OWN"
              ? selectedItem?.customUnitTypeName
              : selectedItem?.productUnit
              ? t(`item.unit.${selectedItem?.productUnit}`)
              : "db"}
          </Typography>
        </Box>
        <Box
          className={!isSmallScreen ? classes.middleCell : undefined}
          style={{
            width: !isSmallScreen ? "6%" : 0,
            overflow: "hidden",
          }}
        >
          <Controller
            name={`relOrderItem.${index}.discount`}
            defaultValue={entry?.discount || "0"}
            rules={{
              required: t("validation.requiredShort").toString(),
              validate: value => {
                if (value && !value.toString().match(NUMBER_FORMAT)) {
                  return t("common:validation.numberFormat").toString();
                }
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                onKeyDown={event => handleKeyDown(event, `discount`)}
                onFocus={event => event.target.select()}
                {...field}
                type="number"
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  endAdornment: <Typography style={{ paddingLeft: 4, fontSize: 14 }}>%</Typography>,
                }}
                inputProps={{
                  id: `relOrderItem.${index}.discount`,
                  step: "0.01",
                  min: "0",
                  style: { textAlign: "right", padding: 0, fontSize: 14 },
                  onKeyDown: event => {
                    if (
                      event?.key === "ArrowDown" ||
                      event?.key === "ArrowUp" ||
                      event?.key === "ArrowLeft" ||
                      event?.key === "ArrowRight"
                    ) {
                      event.preventDefault();
                    }
                  },
                }}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                onChange={event => {
                  field.onChange(event.target.value);
                  setItemPrice(index);
                }}
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
              />
            )}
          />
        </Box>
        <Box
          className={!isSmallScreen && !isWithoutPrice ? classes.middleCell : undefined}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            color: "darkgrey",
            width: !isSmallScreen && !isWithoutPrice ? "13%" : 0,
            height: !isSmallScreen && !isWithoutPrice ? undefined : 21,
            overflow: "hidden",
          }}
        >
          <Controller
            name={`relOrderItem.${index}.netPrice`}
            defaultValue={entry?.netPrice || "0"}
            rules={{
              required: t("validation.requiredShort").toString(),
            }}
            render={({ field, fieldState }) => <input {...field} type="hidden" />}
          />
          <Typography style={{ paddingRight: 4, fontSize: 14, lineHeight: 1.25 }}>
            {watch("order.currency")?.prefix ? watch("order.currency")?.prefix + " " : ""}
          </Typography>
          <Typography style={{ fontSize: 14, lineHeight: 1.25 }}>
            {t("common:number", { num: round(discountNetPrice, 2) })}
          </Typography>
          <Typography style={{ paddingLeft: 4, fontSize: 14, lineHeight: 1.25 }}>
            {watch("order.currency")?.postfix ? " " + watch("order.currency")?.postfix : ""}
          </Typography>
        </Box>
        <Box
          className={!isSmallScreen && !isWithoutPrice ? classes.middleCell : undefined}
          style={{
            width: !isSmallScreen && !isWithoutPrice ? "7%" : 0,
            overflow: "hidden",
            height: !isSmallScreen && !isWithoutPrice ? undefined : 21,
          }}
        >
          <Controller
            name={`relOrderItem.${index}.tax`}
            defaultValue={entry?.tax || "0"}
            rules={{
              required: t("validation.requiredShort").toString(),
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  endAdornment: <Typography style={{ paddingLeft: 4, fontSize: 14 }}>%</Typography>,
                }}
                inputProps={{ style: { textAlign: "right", padding: 0, fontSize: 14 } }}
                disabled
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Box>
        <Box
          className={!isWithoutPrice ? classes.middleCell : undefined}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            color: "darkgrey",
            width: !isWithoutPrice ? "13%" : 0,
            height: !isWithoutPrice ? undefined : 21,
            overflow: "hidden",
          }}
        >
          <Typography style={{ paddingRight: 4, fontSize: 14, lineHeight: 1.25 }} noWrap>
            {watch("order.currency")?.prefix ? watch("order.currency")?.prefix + " " : ""}
          </Typography>
          <Typography style={{ fontSize: 14, lineHeight: 1.25 }} noWrap>
            {t("common:number", { num: round(discountPrice - discountNetPrice, 2) })}
          </Typography>
          <Typography style={{ paddingLeft: 4, fontSize: 14, lineHeight: 1.25 }} noWrap>
            {watch("order.currency")?.postfix ? " " + watch("order.currency")?.postfix : ""}
          </Typography>
        </Box>
        <Box
          className={!isWithoutPrice ? classes.middleCell : undefined}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            color: "darkgrey",
            width: !isWithoutPrice ? "13%" : 0,
            height: !isWithoutPrice ? "100%" : 21,
            overflow: "hidden",
          }}
        >
          <Typography style={{ paddingRight: 4, fontSize: 14, lineHeight: 1.25 }} noWrap>
            {watch("order.currency")?.prefix ? watch("order.currency")?.prefix + " " : ""}
          </Typography>
          <Typography style={{ fontSize: 14, lineHeight: 1.25 }} noWrap>
            {t("common:number", { num: round(discountPrice, 2) })}
          </Typography>
          <Typography style={{ paddingLeft: 4, fontSize: 14, lineHeight: 1.25 }} noWrap>
            {watch("order.currency")?.postfix ? " " + watch("order.currency")?.postfix : ""}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" width={40}>
          <Tooltip title={t("common:button.delete").toString()}>
            <IconButton
              id={`relOrderItem.${index}.delete`}
              size="small"
              color="primary"
              onClick={async () => {
                setLoading(true);
                try {
                  if (entry?.id) {
                    await deleteItemFromOrder(
                      Number(watch(`relOrderItem.${index}.id`)),
                      tenant?.id,
                    );
                  }
                  remove(index);
                } catch {
                  enqueueSnackbar("Hiba történt a törlés közben.", {
                    variant: "error",
                  });
                }
                setLoading(false);
              }}
              style={{ height: 16 }}
              onKeyDown={async event => {
                if (
                  event.key === "ArrowLeft" ||
                  event.key === "ArrowRight" ||
                  event.key === "ArrowDown" ||
                  event.key === "ArrowUp"
                ) {
                  event.stopPropagation();
                  handleKeyDown(event, `delete`);
                  event.preventDefault();
                }
                if (event?.key === "Tab" && index === rowCount - 1) {
                  if (!disableDownKey) {
                    await addNewRow(event, index);
                  }
                  event.preventDefault();
                }
              }}
            >
              <Delete fontSize="small" style={{ height: 16 }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Collapse in={showComment}>
        <Box
          display="flex"
          alignItems="center"
          className={classes.border}
          minWidth={isSmallScreen || isWithoutPrice ? 700 : 1040}
        >
          <Typography
            style={{
              fontWeight: "bold",
              paddingLeft: 4,
              paddingRight: 4,
            }}
          >
            {t("comment.title")}
          </Typography>
          <Box
            style={{
              paddingLeft: 4,
              borderLeft: "1px solid rgba(0,0,0,0.15)",
              width: "100%",
            }}
          >
            <Controller
              name={`relOrderItem.${index}.description`}
              defaultValue={entry?.description || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  onKeyDown={event => handleKeyDown(event, `description`)}
                  fullWidth
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  inputProps={{
                    id: `relOrderItem.${index}.description`,
                    autoComplete: "off",
                    style: { padding: 0, fontSize: 14 },
                    onKeyDown: event => {
                      if (event?.key === "ArrowDown" || event?.key === "ArrowUp") {
                        event.preventDefault();
                      }
                    },
                  }}
                  InputLabelProps={{ shrink: true, required: true }}
                />
              )}
            />
          </Box>
        </Box>
      </Collapse>
      {relOrderItem?.scalingX && (
        <Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography style={{ fontWeight: "bold", paddingRight: 4, color: COLORS.main }}>
              Kiválasztott méret:
            </Typography>
            <Typography style={{ fontWeight: "bold", paddingRight: 4 }}>
              {relOrderItem?.item?.scalingXName}
              {": "}
            </Typography>
            <Typography style={{ paddingRight: 4 }}>
              {relOrderItem?.scalingX?.valueFrom},
            </Typography>
            <Typography style={{ fontWeight: "bold", paddingRight: 4 }}>
              {relOrderItem?.item?.scalingYName}
              {": "}
            </Typography>
            <Typography style={{ paddingRight: 4 }}>{relOrderItem?.scalingY?.valueFrom}</Typography>
            <IconButton
              color="primary"
              size="small"
              onClick={() => setOpenScaling(relOrderItem?.item)}
            >
              <Edit fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      )}
    </>
  );
};

export default FastOrderFormItem;
