import { faDownload, faEdit, faFileArrowUp, faPenFancy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Switch,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  AccessTime,
  AddBox,
  Assignment,
  AssignmentTurnedInOutlined,
  Block,
  CancelOutlined,
  Check,
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
  CheckCircleOutline,
  Edit,
  GetApp,
  IndeterminateCheckBox,
  MonetizationOnOutlined,
  Replay,
  SaveOutlined,
  Send,
  SendOutlined,
} from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowData,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import Loading from "components/Loading";
import PageableTable from "components/PageableTable/PageableTable";
import { INVOICE_TYPE } from "config/constants";
import { RootState } from "config/store";
import supportedLocales from "config/supportedLocales";
import { COLORS, GRADIENT } from "config/theme";
import { format, isPast, subDays } from "date-fns";
import i18n from "i18n";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { getFileByPath } from "shared/network/file.api";
import { getInvoiceFile } from "shared/network/files.api";
import { approveInvoice, exportInvoiceCSV, listInvoicePageable } from "shared/network/invoice.api";
import { Invoice } from "shared/types";
import { csvDownload, fileDownload, pdfDownload } from "shared/util/fileDownload";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";
import DocumentUpload from "views/Invoice/components/DocumentUpload";
import InvoiceStatusModifyDialog from "views/Invoice/InvoiceStatusModifyDialog";
import { useColoredRowStyles } from "views/Issues/NewIssueOverviewList";
import InvoiceSendAttachmentDialog from "./InvoiceSendAttachmentDialog";

const useStyles = makeStyles(
  {
    progressPercentage1: {
      "& .MuiLinearProgress-barColorPrimary": {
        background: GRADIENT,
        width: "100%",
        height: 10,
        borderRadius: 5,
      },
      "&.MuiLinearProgress-colorPrimary": {
        background: COLORS.mainGrey,
        width: "100%",
        height: 10,
        borderRadius: 5,
      },
    },
    switch: {
      "&.MuiFormControlLabel-labelPlacementStart": {
        marginLeft: 0,
        marginRight: 0,
        flexDirection: "row-reverse",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
      },
    },
  },
  { name: "OrderlistByStatus" },
);

const PROGRESS_STATUS = [
  "PENDING",
  "REJECTED",
  "ACCEPTED",
  "SENT_TO_PARTNER",
  "SAVED",
  "CLOSED",
  "RECEIVABLES_MANAGEMENT",
  "PAID",
];

function getProgressValue(row: GridRowData) {
  let progress = 0;
  switch (row.status) {
    case PROGRESS_STATUS[0]: {
      progress = 12.5;
      break;
    }
    case PROGRESS_STATUS[1]: {
      progress = 25;
      break;
    }
    case PROGRESS_STATUS[2]: {
      progress = 37.5;
      break;
    }
    case PROGRESS_STATUS[3]: {
      progress = 50;
      break;
    }
    case PROGRESS_STATUS[4]: {
      progress = 62.5;
      break;
    }
    case PROGRESS_STATUS[5]: {
      progress = 75;
      break;
    }
    case PROGRESS_STATUS[6]: {
      progress = 87.5;
      break;
    }
    case PROGRESS_STATUS[7]: {
      progress = 100;
      break;
    }
    default: {
      progress = 0;
      break;
    }
  }
  return progress;
}

export function getFilterAndSearchItemName(filterValue: string) {
  let splittedFilterValue = filterValue.split(";");
  let searchItemNameString = splittedFilterValue.find(value => value.startsWith("searchItemName"));

  let filteredString = filterValue;
  let searchItemNameParam = "";
  if (searchItemNameString) {
    filteredString = filterValue.replace(searchItemNameString + ";", "");

    searchItemNameParam = searchItemNameString.split(":$")?.[1];
  }

  return { filter: filteredString, itemName: searchItemNameParam };
}

const TenantAllInvoice = () => {
  const classes = useStyles();
  const colorClasses = useColoredRowStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { setHeaderButtons } = useHeader();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const { tenant } = selectedRelTenant;

  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-tenantallinvoice-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-tenantallinvoice-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const [multiSelectTarget, setMultiSelectTarget] = useState<string>("");
  const [toSelectedList, setToSelectedList] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [approveOpen, setApproveOpen] = useState<boolean>(false);
  const [csvOpen, setCsvOpen] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);

  const form = useForm<{ sendToNav: boolean }>();

  const getAllInvoiceListQuery = useQuery(
    [
      "getAllInvoiceListQuery",
      page,
      pageSize,
      tenant.id,
      filterValue,
      sortState,
      multiSelectTarget,
      selectedStatus,
      showDeleted,
    ],
    async () => {
      const filterContent = getFilterAndSearchItemName(filterValue);

      const { data } = await listInvoicePageable(
        page,
        pageSize,
        tenant.id,
        (!showDeleted ? `status!=DELETED;` : "") +
          (!!account.user.relUserCompany?.length
            ? `customerId:${account?.user?.relUserCompany?.[0]?.company.id || ""};`
            : "") +
          (multiSelectTarget === "APPROVE" ? `isApproved:false;` : "") +
          (multiSelectTarget === "SEND" ? `status:PENDING;invoiceType!=REQUESTER` : "") +
          (!multiSelectTarget && selectedStatus ? `status:${selectedStatus};` : "") +
          filterContent?.filter,
        sortState,
        filterContent?.itemName,
      );
      return data;
    },
  );

  const { control, handleSubmit } = useForm<{
    startDate: Date;
    endDate: Date;
  }>();

  function isSelected(id: number) {
    return toSelectedList.includes(id);
  }

  async function approveInvoiceFunction(values: { sendToNav: boolean }) {
    try {
      if (!isEmpty(toSelectedList)) {
        setLoading(true);
        await approveInvoice(toSelectedList, values.sendToNav, tenant.id);
        enqueueSnackbar(t("common:notification.crediting.success"), {
          variant: "success",
        });
        setToSelectedList([]);
        setMultiSelectTarget("");
        getAllInvoiceListQuery.refetch();
        setApproveOpen(false);
        setLoading(false);
      }
    } catch {
      setLoading(false);
      enqueueSnackbar(t("common:notification.crediting.failure"), {
        variant: "error",
      });
    }
  }

  async function exportCsv(values: { startDate: Date; endDate: Date }) {
    try {
      const { data } = await exportInvoiceCSV(
        selectedRelTenant.tenant.id,
        format(values.startDate, "yyyy-MM-dd"),
        format(values.endDate, "yyyy-MM-dd"),
      );
      csvDownload(
        data.contentBase64,
        `${t("common:csvDownload.title")}-${t("common:csvDownload.INVOICE")}-${data.fileName}`,
      );
      enqueueSnackbar(
        t("common:notification.csvExport.success", {
          subject: t("invoice.subject"),
        }),
        {
          variant: "success",
        },
      );
      setCsvOpen(false);
    } catch {
      enqueueSnackbar(
        t("common:notification.csvExport.failure", {
          subject: t("invoice.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  }

  const [selectedPicInvoice, setSelectedPicInvoice] = useState<Invoice | null>(null);

  const columns: GridColDef[] = [
    {
      field: "multiSelect",
      headerName: " ",
      width: 50,
      hide: !multiSelectTarget,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => {
        if (toSelectedList?.length === getAllInvoiceListQuery.data?.page?.content?.length) {
          return (
            <IconButton size="small" color="primary" onClick={() => setToSelectedList([])}>
              <CheckBoxOutlined />
            </IconButton>
          );
        } else if (toSelectedList?.length === 0) {
          return (
            <IconButton
              size="small"
              color="primary"
              onClick={() =>
                setToSelectedList(
                  getAllInvoiceListQuery.data?.page?.content?.map(value => value.id as number) ||
                    [],
                )
              }
            >
              <CheckBoxOutlineBlankOutlined />
            </IconButton>
          );
        } else {
          return (
            <IconButton
              size="small"
              color="primary"
              onClick={() =>
                setToSelectedList(
                  getAllInvoiceListQuery.data?.page?.content?.map(value => value.id as number) ||
                    [],
                )
              }
            >
              <IndeterminateCheckBox />
            </IconButton>
          );
        }
      },
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {isSelected(row.id) ? (
            <IconButton
              size="small"
              color="primary"
              onClick={() =>
                setToSelectedList(prevState => [...prevState].filter(entry => entry !== row.id))
              }
            >
              <CheckBoxOutlined />
            </IconButton>
          ) : (
            <IconButton
              size="small"
              color="primary"
              onClick={() => setToSelectedList(prevState => [...prevState, row.id])}
            >
              <CheckBoxOutlineBlankOutlined />
            </IconButton>
          )}
        </Box>
      ),
    },
    {
      field: "serialNumber",
      headerName: t("invoice.serialNumber"),
      flex: 1,
      type: "stringContainsNumber",
    },
    {
      field: "customerName",
      headerName: t("invoice.formValues.customer.customerName"),
      flex: 2,
      type: "stringContainsNumber",
    },
    {
      field: "creationDate",
      headerName: t("invoice.formValues.creationDate"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => {
        return row.creationDate
          ? format(new Date(row.creationDate), "P", { locale: supportedLocales[i18n.language] })
          : "";
      },
      type: "date",
    },
    {
      field: "completionDate",
      headerName: t("invoice.formValues.completionDate"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => {
        return row.completionDate
          ? format(new Date(row.completionDate), "P", { locale: supportedLocales[i18n.language] })
          : "";
      },
      type: "date",
    },
    {
      field: "paymentDeadline",
      headerName: t("invoice.formValues.paymentDeadline"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => {
        return row.paymentDeadline
          ? format(new Date(row.paymentDeadline), "P", { locale: supportedLocales[i18n.language] })
          : "";
      },
      type: "date",
    },
    {
      field: "invoiceType",
      headerName: t("invoice.formValues.invoiceType.title"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        t(`invoice.formValues.invoiceType.${row.invoiceType}`),
      type: "select",
    },
    {
      //kiallitott szamla
      field: "grossPrice",
      headerName: t("invoice.invoiceItem.grossPrice"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => {
        if (
          account.user.isSuperAdmin ||
          selectedRelTenant.isTenantAdmin ||
          hasAuthority(account.user, account.permissions, selectedRelTenant, ["PRICE_WATCHER"])
        ) {
          return t("common:number", { num: row.grossPrice || "0" }) + " Ft";
        } else return null;
      },
    },
    {
      field: "isApproved",
      headerName: t("invoice.isApproved"),
      flex: 1,
      type: "boolean",
      hide: !tenant.invoiceCreditingIsRequired,
      renderCell: ({ row }: GridRenderCellParams) =>
        row.isApproved ? (
          <CheckCircleOutline style={{ color: COLORS.green }} />
        ) : (
          <Block style={{ color: COLORS.red }} />
        ),
    },
    {
      field: "status",
      headerName: t("order.status"),
      width: 350,
      sortable: false,
      hide: !!multiSelectTarget,
      type: "select",
      renderHeader: () => (
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Tooltip title={t("invoice.formValues.status.PENDING").toString()} placement="top" arrow>
            <IconButton
              size="small"
              children={
                <AccessTime
                  style={{
                    color:
                      selectedStatus === PROGRESS_STATUS[0] ? COLORS.statusBlue : COLORS.greyWater,
                  }}
                />
              }
              onClick={() => {
                setSelectedStatus(
                  selectedStatus === PROGRESS_STATUS[0] ? null : PROGRESS_STATUS[0],
                );
                setTableState(prevState => {
                  return { ...prevState, page: 0 };
                });
              }}
            />
          </Tooltip>
          <Tooltip title={t("invoice.formValues.status.REJECTED").toString()} placement="top" arrow>
            <IconButton
              size="small"
              children={
                <CancelOutlined
                  style={{
                    color:
                      selectedStatus === PROGRESS_STATUS[1] ? COLORS.statusBlue : COLORS.greyWater,
                  }}
                />
              }
              onClick={() => {
                setSelectedStatus(
                  selectedStatus === PROGRESS_STATUS[1] ? null : PROGRESS_STATUS[1],
                );

                setTableState(prevState => {
                  return { ...prevState, page: 0 };
                });
              }}
            />
          </Tooltip>
          <Tooltip title={t("invoice.formValues.status.ACCEPTED").toString()} placement="top" arrow>
            <IconButton
              size="small"
              children={
                <CheckCircleOutline
                  style={{
                    color:
                      selectedStatus === PROGRESS_STATUS[2] ? COLORS.statusBlue : COLORS.greyWater,
                  }}
                />
              }
              onClick={() => {
                setSelectedStatus(
                  selectedStatus === PROGRESS_STATUS[2] ? null : PROGRESS_STATUS[2],
                );

                setTableState(prevState => {
                  return { ...prevState, page: 0 };
                });
              }}
            />
          </Tooltip>
          <Tooltip
            title={t("invoice.formValues.status.SENT_TO_PARTNER").toString()}
            placement="top"
            arrow
          >
            <IconButton
              size="small"
              children={
                <SendOutlined
                  style={{
                    color:
                      selectedStatus === PROGRESS_STATUS[3] ? COLORS.statusBlue : COLORS.greyWater,
                  }}
                />
              }
              onClick={() => {
                setSelectedStatus(
                  selectedStatus === PROGRESS_STATUS[3] ? null : PROGRESS_STATUS[3],
                );

                setTableState(prevState => {
                  return { ...prevState, page: 0 };
                });
              }}
            />
          </Tooltip>
          <Tooltip title={t("invoice.formValues.status.SAVED").toString()} placement="top" arrow>
            <IconButton
              size="small"
              children={
                <SaveOutlined
                  style={{
                    color:
                      selectedStatus === PROGRESS_STATUS[4] ? COLORS.statusBlue : COLORS.greyWater,
                  }}
                />
              }
              onClick={() => {
                setSelectedStatus(
                  selectedStatus === PROGRESS_STATUS[4] ? null : PROGRESS_STATUS[4],
                );

                setTableState(prevState => {
                  return { ...prevState, page: 0 };
                });
              }}
            />
          </Tooltip>
          <Tooltip title={t("invoice.formValues.status.CLOSED").toString()} placement="top" arrow>
            <IconButton
              size="small"
              children={
                <AssignmentTurnedInOutlined
                  style={{
                    color:
                      selectedStatus === PROGRESS_STATUS[5] ? COLORS.statusBlue : COLORS.greyWater,
                  }}
                />
              }
              onClick={() => {
                setSelectedStatus(
                  selectedStatus === PROGRESS_STATUS[5] ? null : PROGRESS_STATUS[5],
                );

                setTableState(prevState => {
                  return { ...prevState, page: 0 };
                });
              }}
            />
          </Tooltip>
          <Tooltip
            title={t("invoice.formValues.status.RECEIVABLES_MANAGEMENT").toString()}
            placement="top"
            arrow
          >
            <IconButton
              size="small"
              children={
                <FontAwesomeIcon
                  icon={faPenFancy}
                  style={{
                    fontSize: 20,
                    width: 20,

                    color:
                      selectedStatus === PROGRESS_STATUS[6] ? COLORS.statusBlue : COLORS.greyWater,
                  }}
                />
              }
              onClick={() => {
                setSelectedStatus(
                  selectedStatus === PROGRESS_STATUS[6] ? null : PROGRESS_STATUS[6],
                );

                setTableState(prevState => {
                  return { ...prevState, page: 0 };
                });
              }}
            />
          </Tooltip>
          <Tooltip title={t("invoice.formValues.status.PAID").toString()} placement="top" arrow>
            <IconButton
              size="small"
              children={
                <MonetizationOnOutlined
                  style={{
                    color:
                      selectedStatus === PROGRESS_STATUS[7] ? COLORS.statusBlue : COLORS.greyWater,
                  }}
                />
              }
              onClick={() => {
                setSelectedStatus(
                  selectedStatus === PROGRESS_STATUS[7] ? null : PROGRESS_STATUS[7],
                );

                setTableState(prevState => {
                  return { ...prevState, page: 0 };
                });
              }}
            />
          </Tooltip>
        </Box>
      ),
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Grid container>
            <Grid container item xs={12}>
              <Tooltip
                arrow
                placement="top"
                title={
                  !isEmpty(row.orderStatusHistoryList)
                    ? `${format(
                        new Date(
                          row.orderStatusHistoryList[
                            row.orderStatusHistoryList.length - 1
                          ].createdOn,
                        ),
                        "Pp",
                        {
                          locale: supportedLocales[i18n.language],
                        },
                      )}`
                    : ""
                }
              >
                <LinearProgress
                  className={classes.progressPercentage1}
                  value={getProgressValue(row)}
                  variant="determinate"
                />
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: " ",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["INVOICE_ADMIN"]) &&
            row.pdfPath && (
              <Tooltip title={t("invoice.downloadPdf").toString()}>
                <IconButton
                  disabled={row?.status === "DELETED"}
                  size="small"
                  color="primary"
                  style={{ margin: "0 4px" }}
                  onClick={async () => {
                    if (row.id) {
                      try {
                        const { data } = await getInvoiceFile(row.id, tenant.id);
                        pdfDownload(data, row?.serialNumber, t("common:pdfDownload.INVOICE"));
                        enqueueSnackbar(
                          t("common:notification.download.success", {
                            subject: t("invoice.subject"),
                          }),
                          {
                            variant: "success",
                          },
                        );
                      } catch {
                        enqueueSnackbar(
                          t("common:notification.download.failure", {
                            subject: t("invoice.subject"),
                          }),
                          {
                            variant: "error",
                          },
                        );
                      }
                    }
                  }}
                >
                  <GetApp />
                </IconButton>
              </Tooltip>
            )}
          {!row?.uploadedDocumentPath ? (
            <Tooltip title={t("invoice.document.add").toString()}>
              <IconButton
                color="primary"
                size="small"
                style={{ marginRight: 4 }}
                onClick={() => setSelectedPicInvoice(row as Invoice)}
              >
                <FontAwesomeIcon
                  icon={faFileArrowUp}
                  style={{
                    fontSize: 20,
                    width: 20,
                    color: COLORS.main,
                  }}
                />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={t("invoice.document.download").toString()}>
              <IconButton
                color="primary"
                size="small"
                style={{ marginRight: 4 }}
                onClick={async () => onDownload(row as Invoice)}
              >
                <FontAwesomeIcon
                  icon={faDownload}
                  style={{
                    fontSize: 20,
                    width: 20,
                    color: COLORS.main,
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["INVOICE_GET"]) && (
            <Tooltip title={t("invoice.details").toString()}>
              <IconButton
                disabled={row?.status === "DELETED"}
                component={Link}
                to={`/invoices/invoices-list/invoice-details?id=${row.id}`}
                size="small"
                color="primary"
                style={{ margin: "0 4px" }}
              >
                <Assignment />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "INVOICE_ADMIN",
          ]) && (
            <Tooltip title={t("invoice.modifyStatus").toString()}>
              <IconButton
                disabled={row?.status === "DELETED"}
                onClick={() => setSelectedInvoice(row as Invoice)}
                color="primary"
                size="small"
                style={{ margin: "0 4px" }}
              >
                <FontAwesomeIcon icon={faEdit} width={24} height={24} />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["INVOICE_ADMIN"]) &&
            row.status !== "PENDING" &&
            row.invoiceType !== "STORNO" && (
              <Tooltip title={t("invoice.storno").toString()}>
                <IconButton
                  disabled={row?.status === "DELETED" || row.invoiceType !== "NORMAL"}
                  size="small"
                  color="primary"
                  style={{ margin: "0 4px" }}
                  component={Link}
                  to={`/invoices/invoices-list/invoice-create?id=${row.id}&type=del`}
                >
                  <Replay />
                </IconButton>
              </Tooltip>
            )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["INVOICE_ADMIN"]) &&
            row.status === "PENDING" && (
              <Tooltip title={t("invoice.modify").toString()}>
                <IconButton
                  disabled={row?.status === "DELETED"}
                  component={Link}
                  to={`/invoices/invoices-list/invoice-modify?id=${row.id}`}
                  size="small"
                  color="primary"
                  style={{ margin: "0 4px" }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["INVOICE_ADMIN"]) &&
            row.status !== "PENDING" && (
              <Tooltip title={t("invoice.modifyInvoice").toString()}>
                <IconButton
                  disabled={row?.status === "DELETED" || row.invoiceType !== "NORMAL"}
                  component={Link}
                  to={`/invoices/invoices-list/invoice-create?id=${row.id}&type=mod`}
                  size="small"
                  color="primary"
                  style={{ margin: "0 4px" }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            )}
        </Box>
      ),
    },
    {
      field: "searchItemName",
      headerName: "Számlatétel",
      hide: true,
      type: "stringContainsNumber",
    },
  ];

  const [docOpenLoading, setDocOpenLoading] = useState(false);

  async function onDownload(invoice: Invoice) {
    setDocOpenLoading(true);
    if (invoice?.id) {
      try {
        const { data } = await getFileByPath(invoice?.uploadedDocumentPath, tenant.id);
        fileDownload(data, `${t("common:pdfDownload.INVOICE")}_${invoice?.id || ""}`);
      } catch (e) {
        enqueueSnackbar(
          t("common:notification.download.failure", {
            subject: t("comment.subject"),
          }),
          {
            variant: "error",
          },
        );
      }
    }
    setDocOpenLoading(false);
  }

  function onCellClick(params: GridCellParams) {
    if (!["actions", "multiSelect"].includes(params.field)) {
      history.push(`/invoices/invoices-list/invoice-details?id=${params.row.id}`);
    }
  }

  useEffect(() => {
    setHeaderButtons(
      <>
        <Box display="flex" gridGap={12}>
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "INVOICE_ADMIN",
          ]) && (
            <Button
              component={Link}
              to="/invoices/invoices-list/invoice-create"
              startIcon={<AddBox />}
            >
              {t("invoice.create")}
            </Button>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "INVOICE_GET",
            "INVOICE_CSV_GET",
          ]) && (
            <Button
              onClick={() => {
                setCsvOpen(true);
              }}
              startIcon={<GetApp />}
            >
              {t("invoice.csvExport")}
            </Button>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["INVOICE_ADMIN"]) &&
            multiSelectTarget !== "SEND" &&
            tenant.invoiceCreditingIsRequired && (
              <Button
                onClick={() => {
                  multiSelectTarget !== "APPROVE"
                    ? setMultiSelectTarget("APPROVE")
                    : setApproveOpen(true) /*approveInvoiceFunction()*/;
                }}
                variant={multiSelectTarget === "APPROVE" ? "outlined" : "contained"}
                startIcon={<Check />}
              >
                {t("invoice.multiApprove")}
              </Button>
            )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["INVOICE_ADMIN"]) &&
            !!multiSelectTarget && (
              <Button
                variant="text"
                onClick={() => {
                  setMultiSelectTarget("");
                  setToSelectedList([]);
                }}
              >
                {t("common:button.cancel")}
              </Button>
            )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["INVOICE_ADMIN"]) &&
            multiSelectTarget !== "APPROVE" &&
            tenant.technicalSignatureKey &&
            (tenant?.address?.country === "HUNGARY" ||
              tenant?.address?.country === "Magyarország") && (
              <Button
                disabled={multiSelectTarget === "SEND" && toSelectedList?.length === 0}
                onClick={() => {
                  if (multiSelectTarget !== "SEND") {
                    setMultiSelectTarget("SEND");
                  } else {
                    setSendOpen(true);
                  }
                }}
                variant={multiSelectTarget === "SEND" ? "outlined" : "contained"}
                startIcon={<Send />}
              >
                {multiSelectTarget === "SEND"
                  ? t("common:button.submit")
                  : t("invoice.multiNavSend")}
              </Button>
            )}
        </Box>
      </>,
    );

    return () => {
      setHeaderButtons(null);
    };
  }, [
    account.user,
    account.permissions,
    selectedRelTenant,
    filterValue,
    multiSelectTarget,
    toSelectedList,
  ]);

  const [sendOpen, setSendOpen] = useState(false);

  return (
    <Box mb={2.5}>
      <Loading open={loading || docOpenLoading} />
      {selectedPicInvoice?.id && (
        <DocumentUpload
          subjectType={"INVOICE_DOCUMENT"}
          subjectId={selectedPicInvoice.id}
          open={!!selectedPicInvoice}
          setOpen={() => setSelectedPicInvoice(null)}
          refetch={() => getAllInvoiceListQuery?.refetch()}
        />
      )}
      {multiSelectTarget === "SEND" && (
        <>
          <Typography style={{ fontWeight: "bold", color: COLORS.red, textAlign: "center" }}>
            {t("invoice.sendHelp")}
          </Typography>
          <InvoiceSendAttachmentDialog
            sendOpen={sendOpen}
            setSendOpen={setSendOpen}
            setLoading={setLoading}
            toSelectedList={toSelectedList}
            onSuccess={() => {
              setToSelectedList([]);
              setMultiSelectTarget("");
            }}
          />
        </>
      )}
      <Box mb={0.5} display="flex" justifyContent="flex-end">
        <FormControlLabel
          labelPlacement="end"
          label={t("invoice.deletedRequester")}
          control={
            <Switch
              checked={showDeleted}
              size="small"
              onChange={e => setShowDeleted(e.target.checked)}
              color="primary"
            />
          }
        />
      </Box>
      <InvoiceStatusModifyDialog
        setSelectedInvoice={setSelectedInvoice}
        selectedInvoice={selectedInvoice}
        refetch={getAllInvoiceListQuery.refetch}
      />
      <PageableTable
        filterOptions={[
          {
            columnName: "invoiceType",
            options: INVOICE_TYPE.map(value => {
              return {
                translated: t(`invoice.formValues.invoiceType.${value}`),
                value,
              };
            }),
          },
          {
            columnName: "status",
            options: PROGRESS_STATUS.map(value => {
              return {
                translated: t(`common:progress.status.${value}`),
                value,
              };
            }),
          },
        ]}
        filterable={[
          "customerName",
          "creationDate",
          "serialNumber",
          "status",
          "invoiceType",
          "searchItemName",
        ]}
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="tenantallinvoice"
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={getAllInvoiceListQuery}
        onCellClick={onCellClick}
        minWidth={{ value: "xl" }}
        getRowClassName={params => {
          if (selectedRelTenant?.tenant?.isInvoiceColoring) {
            if (params.row?.paidDate) {
              return colorClasses.green;
            } else if (isPast(new Date(params.row?.paymentDeadline))) {
              return colorClasses.red;
            } else if (isPast(subDays(new Date(params.row?.paymentDeadline), 2))) {
              return colorClasses.orange;
            } else {
              return colorClasses.green;
            }
          } else {
            return "";
          }
        }}
      />
      <Dialog open={csvOpen} maxWidth="xs" onClose={() => setCsvOpen(false)}>
        <DialogTitle>{t("invoice.csvExport")}</DialogTitle>
        <DialogContent>
          <form id="csvexport" onSubmit={handleSubmit(exportCsv)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="startDate"
                  defaultValue={new Date()}
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <KeyboardDatePicker
                      {...field}
                      ref={undefined}
                      className="validTo"
                      variant="inline"
                      label={t("invoice.csvStartDate")}
                      format="yyyy.MM.dd"
                      InputLabelProps={{ shrink: true, required: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      autoOk
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="endDate"
                  defaultValue={new Date()}
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <KeyboardDatePicker
                      {...field}
                      ref={undefined}
                      className="validTo"
                      variant="inline"
                      label={t("invoice.csvEndDate")}
                      format="yyyy.MM.dd"
                      InputLabelProps={{ shrink: true, required: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      autoOk
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setCsvOpen(false);
            }}
          >
            {t("common:button.cancel")}
          </Button>
          <Button type="submit" form="csvexport">
            {t("common:button.ok")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={approveOpen} maxWidth="xs" onClose={() => setApproveOpen(false)}>
        <DialogTitle>{t("invoice.approveConfirmTitle")}</DialogTitle>
        <DialogContent>
          <form id="approveForm" onSubmit={form.handleSubmit(approveInvoiceFunction)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="sendToNav"
                  control={form.control}
                  defaultValue={true}
                  render={({ field: { onChange, value, ref }, fieldState }) => (
                    <FormControlLabel
                      className={classes.switch}
                      label={t("invoice.formValues.sendToNavAfterApprove")}
                      labelPlacement="start"
                      control={
                        <Switch
                          onChange={(e, checked) => {
                            onChange(checked);
                          }}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setApproveOpen(false);
            }}
          >
            {t("common:button.cancel")}
          </Button>
          <Button type="submit" form="approveForm">
            {t("common:button.ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TenantAllInvoice;
